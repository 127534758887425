<div class="m-auto mt-5 col-11">
  <div class="d-flex row">
    <div class="title col-sm-8 col-md-8">
      <h2><a href="/app/admin/dashboard">Bacheca</a> / <span class="text">{{path}}</span></h2>
    </div>
    <div class="search col-sm-4 col-md-4">
      <mat-form-field appearance="fill">
        <mat-label>Ricerca {{path}}</mat-label>
        <input matInput [formControl]="searchField">
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>


  <div class="mb-4">
    <div>
      <p>
        <!-- <strong class="status" [ngClass]="{'selected' : status.length == 3}" (click)="setStatus('tutte')">Tutte <span
            class="grey">({{totalSum}})</span></strong> | -->
        <strong *ngIf="url.includes('comunicazioni')" class="status"
          [ngClass]="{'selected' : status[0] == 'publish' && status.length != 3, 'active': activeStatus === 'publish'}"
          (click)="onStatusChange('publish')">Pubblicate <span>({{totalpost != null ? totalpost.publish :
            0}})</span></strong>
        <strong *ngIf="!url.includes('comunicazioni')" class="status"
          [ngClass]="{'selected' : status[0] == 'publish' && status.length != 3, 'active': activeStatus === 'publish'}"
          (click)="onStatusChange('publish')">Pubblicati <span>({{totalpost != null ? totalpost.publish :
            0}})</span></strong> |
        <strong class="status"
          [ngClass]="{'selected' : status[0] == 'draft' && status.length != 3, 'active': activeStatus === 'draft'}"
          (click)="onStatusChange('draft')">Bozze <span>({{totalpost != null ? totalpost.draft : 0}})</span>
        </strong> |
        <strong class="status"
          [ngClass]="{'selected' : status[0] == 'trash' && status.length != 3, 'active': activeStatus === 'trash'}"
          (click)="onStatusChange('trash')">Cestino <span>({{totalpost != null ? totalpost.trash :
            0}})</span></strong>|
        <strong *ngIf="!(url.includes('comunicazioni') || this.url.includes('bandi'))" class=" status"
          [ngClass]="{'selected' : status[0] == 'closed' && status.length != 3, 'active': activeStatus === 'closed'}"
          (click)="onStatusChange('closed')">Chiusi <span>({{totalpost != null ? totalpost.closed :
            0}})</span></strong>
        <strong *ngIf="url.includes('comunicazioni')" class="status"
          [ngClass]="{'selected' : status[0] == 'closed' && status.length != 3, 'active': activeStatus === 'closed'}"
          (click)="onStatusChange('closed')">Scadute <span>({{totalpost != null ? totalpost.closed :
            0}})</span></strong>
        <strong *ngIf="url.includes('bandi')" class="status"
          [ngClass]="{'selected' : status[0] == 'closed' && status.length != 3, 'active': activeStatus === 'closed'}"
          (click)="onStatusChange('closed')">Scaduti <span>({{totalpost != null ? totalpost.closed :
            0}})</span></strong>
      </p>
    </div>

    <div class="buttonspace">
      <button type="button" class="btn blu me-4" (click)="goTo('create-post');">Aggiungi</button>
      <!-- <button type="button" class="btn griggio" disabled> Duplica </button> -->
      <button *ngIf="rows.length > 0 && status != 'trash'" type="button" data-bs-toggle="modal"
        data-bs-target="#cestino" class="btn rosso me-4 ">Elimina</button>
      <button *ngIf="rows.length > 0 && status == 'trash'" type="button" data-bs-toggle="modal"
        data-bs-target="#exampleModal" class="btn rosso me-4">Elimina Definitivamente</button>
      <button
        *ngIf="!url.includes('faq') && !url.includes('video-istituzionali') && !url.includes('tecnica-e-ricostruzione') && !url.includes('rassegna-stampa')"
        type="button" class="btn blu me-4" (click)="exportExcel()" [disabled]="cols.length == 0">EXPORT LISTA
        {{path}}</button>
    </div>
  </div>

  <!-- Modal -->
  <div *ngIf="rows.length!=0">
    <div class="modal fade" id="cestino" tabindex="-1" aria-labelledby="cestinoLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content p-4">
          <div class="modal-body">
            <p>Sei sicuro di voler eliminare {{rows.length}}
              <span *ngIf="rows.length == 1; else multiple">elemento</span>
              <ng-template #multiple>elementi</ng-template>?
            </p>
          </div>
          <div class="d-flex flex-row justify-content-between mb-4">
            <button class="btn annulla pr-3 me-2" data-bs-dismiss="modal" aria-label="Close" id="closebutton">
              ANNULLA
            </button>
            <button type="button" demon-step-btn="hide" class="btn elimina ms-2" data-bs-dismiss="modal"
              (click)="deletePost()">
              ELIMINA
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="rows.length!=0">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content p-4">
          <div class="modal-body">
            <p>Sei sicuro di voler eliminare definitivamente {{rows.length}}
              <span *ngIf="rows.length == 1; else multiple">elemento</span>
              <ng-template #multiple>elementi</ng-template>?
            </p>
          </div>
          <div class="d-flex flex-row justify-content-between mb-4">
            <button class="btn annulla pr-3 me-2" data-bs-dismiss="modal" aria-label="Close" id="closebutton">
              Annulla
            </button>
            <button type="button" demon-step-btn="hide" class="btn elimina ms-2" data-bs-dismiss="modal"
              (click)="deletePost()">
              Elimina Definitivamente
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- DataTable -->
  <div>
    <app-dataTable #datatablechild [cols]="cols" [colsHead]="colsHead" (datatable)="datatable($event)"
      (sortChange)="onSortChange($event)">
    </app-dataTable>

    <!-- Pagination -->
    <mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
      *ngIf="length != 0; else noUsers" [showFirstLastButtons]="showFirstLastButtons"
      [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex" aria-label="Select page">
    </mat-paginator>

    <ng-template #noUsers></ng-template>
  </div>
</div>