<div class="m-auto mt-5 col-11">
  <div class="d-flex row">
    <div class="title col-sm-8 col-md-8">
      <h2>Link utili</h2>
    </div>
    <div class="search col-sm-4 col-md-4">
      <mat-form-field appearance="fill">
        <mat-label>Ricerca per nome </mat-label>
        <input matInput [formControl]="filtroRicerca">
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12 col-md-2 mt-sm-1">
      <button type="button" class="btn blu btn-block-sm w-100" (click)="isVisibleCreate = true">
        AGGIUNGI
      </button>
    </div>
    <div class="col-12 col-md-2 mt-sm-1">
      <button *ngIf="selectedLink?.length!=0" type="button" class="btn rosso btn-block-sm w-100" data-bs-toggle="modal"
        data-bs-target="#cestino">ELIMINA</button>
    </div>
  </div>

  <nz-datatable [columns]="columns" [data]="data" [data_count]="data_count" (page_changed)="onPageChange($event)"
    (sort_changed)="onSortChange($event)" (selected_row)="onRowCheck($event)" [actions]="['edit']" (action_clicked)="onActionClick($event)">
  </nz-datatable>
</div>


<!-- Modal-Delete -->
<div *ngIf="selectedLink?.length!=0">
  <div class="modal fade" id="cestino" tabindex="-1" aria-labelledby="cestinoLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content p-4">
        <div class="modal-body">
          <p>Sei sicuro di voler eliminare {{selectedLink?.length}}
            <span *ngIf="selectedLink?.length == 1; else multiple">elemento</span>
            <ng-template #multiple>elementi</ng-template>?
          </p>
        </div>
        <div class="d-flex flex-row justify-content-between mb-4">
          <button class="btn annulla pr-3 me-2" data-bs-dismiss="modal" aria-label="Close" id="closebutton">
            ANNULLA
          </button>
          <button type="button" demon-step-btn="hide" class="btn elimina ms-2" data-bs-dismiss="modal"
            (click)="deleteLink()">
            ELIMINA
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<nz-modal [(nzVisible)]="isVisibleCreate" [nzStyle]="{ width: '50%' }" nzCentered (nzOnCancel)="handleCancelCreate()"
          [nzTitle]="createTile" [nzContent]="content" [nzFooter]="createFooter">
</nz-modal>

<nz-modal [(nzVisible)]="isVisibleUpdate" [nzStyle]="{ width: '50%' }" nzCentered (nzOnCancel)="handleCancelCreate()"
          [nzTitle]="updateTile" [nzContent]="content" [nzFooter]="updateFooter">
</nz-modal>


<ng-template #content>
    <form class="col-12 mt-2 mb-2 row" [formGroup]="modalForm">
        <div class="col-12 my-2">
            <label class="mb-2 blue-title">
                Nome link visibile:
            </label><br>
            <input nz-input placeholder="Nome link" formControlName="name"/>
            <div *ngIf="modalForm.get('name')?.hasError('required') && modalForm.get('name')?.dirty" class="text-danger">
                Il nome link è richiesto
            </div>
        </div>
        <div class="col-12 my-2">
            <label class="mb-2 blue-title"> URL link: </label><br>
            <input nz-input formControlName="url" placeholder="es. https://www.google.it"/>
            <div *ngIf="modalForm.get('url')?.hasError('required') && modalForm.get('url')?.dirty" class="text-danger">
                L'url del link è richiesto
            </div>
            <div *ngIf="modalForm.get('url')?.hasError('pattern') && modalForm.get('url')?.dirty" class="text-danger">
                Formato del link non corretto
            </div>
        </div>
        <!-- <div class="col-12 my-2">
            <label for="section" class="mb-2 blue-title">
                Sezione link:
            </label><br>
            <input nz-input placeholder="Sezione link" formControlName="section" />
            <div *ngIf="modalForm.get('section')?.hasError('required') && modalForm.get('section')?.touched"
                class="text-danger">
                La sezione del link è richiesta
            </div>
        </div> -->
    </form>
</ng-template>


<!-- CREAZIONE -->
<ng-template #createTile>
    <h4 class="blue-title" style="margin-bottom: 0px;">Inserisci un nuovo link</h4>
</ng-template>

<ng-template #createFooter>
    <button nz-button class="btn blu me-2" (click)="onSave()" [disabled]="modalForm.invalid">AGGIUNGI</button>
</ng-template>


<!-- MODIFICA -->
<ng-template #updateTile>
    <h4 class="blue-title" style="margin-bottom: 0px;">Modifica il link</h4>
</ng-template>

<ng-template #updateFooter>
    <button nz-button class="btn blu me-2" (click)="onUpdate()">MODIFICA</button>
</ng-template>
