<div class="mx-5 my-4">
    <div class="d-flex justify-content-between align-items-center">
        <h3 class="pb-0 mb-0">Richiesta di iscrizione</h3>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </div>

    <p class="mt-3 text-black">Richiesta di iscrizione nell'elenco degli Ingegneri abilitati allo svolgimento del ruolo di coordinatore di
        sicurezza e di salute durante la progettazione e la realizzazione dell'opera (D.Lgs. 81/08, art.98)</p>

    <p class="text-black">scegliere il caso che ricorre:</p>

    <form [formGroup]="form_dlgs81" class="mt-3">
        <div *ngFor="let chiose of scelta_1">
            <label for="enum_answer_{{chiose.label}}"></label>
            <input id="enum_answer_{{chiose.value}}" [value]='chiose.value' type="radio" formControlName="convalida"
                (click)="setFilter(chiose.value)">
            <span class="ms-1 text-black">{{chiose.label}}</span>
        </div>

        <div class="my-3" *ngIf="filter_status == true">
            <p class="text-black">Completare i seguenti campi: </p>

            <!--? Amministrazione d'appartenenza  -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Amministrazione d'appartenenza</p>
                </div>
                <div class="col-sm-6 col-md-8">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input type="text" class="text" matInput formControlName="amminAppartenenza" required>
                    </mat-form-field>
                </div>
            </div>

            <!--? Qualifica rivestita  -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Qualifica rivestita</p>
                </div>
                <div class="col-sm-6 col-md-8">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input type="text" class="text" matInput formControlName="qualifica" required>
                    </mat-form-field>
                </div>
            </div>

            <!--? Periodo di svolgimento  -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Periodo di svolgimento</p>
                </div>
                <div class="col-sm-6 col-md-8">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input type="text" class="text" matInput formControlName="periodoDiSvolgimento" required>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="mt-5 mb-3">
            <p class="text-black">Specifica di:</p> 

            <div *ngFor="let chiose of scelta_2" class="mt-2">
                <label for="enum_answer_{{chiose.label}}"></label>
                <input id="enum_answer_{{chiose.value}}" [value]='chiose.label' type="radio"
                    formControlName="periodoSettoreCostruzioni">
                <span class="ms-1 text-black">{{chiose.label}}</span>
            </div>
        </div>

        <div class="my-3">
            <p class="text-black">Indicare ultima esperienza lavorativa:</p>

            <!--? Datore di lavoro  -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Datore di lavoro</p>
                </div>
                <div class="col-sm-6 col-md-8">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input type="text" class="text" matInput formControlName="datoreLavoro" required>
                    </mat-form-field>
                </div>
            </div>

            <!--? Periodo di svolgimento  -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Periodo di svolgimento</p>
                </div>
                <div class="col-sm-6 col-md-8">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input type="text" class="text" matInput formControlName="periodoLavoro" required>
                    </mat-form-field>
                </div>
            </div>

            <!--? Tipologia dell'attività  -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Tipologia dell'attività</p>
                </div>
                <div class="col-sm-6 col-md-8">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <input type="text" class="text" matInput formControlName="tipoLavoro" required>
                    </mat-form-field>
                </div>
            </div>

        </div>

        <div class="d-flex justify-content-end">
            <button class="btn aggiorna mt-3 px-4" (click)="invia()">Invia</button>
        </div>
    </form>
</div>