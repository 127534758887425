<div class="m-auto mt-5 col-12">
  <h2 class="mb-4">Albo</h2>

  <form nz-form [nzLayout]="'vertical'" [formGroup]="form">
    <section nz-row [nzGutter]="24">
      <article nz-col [nzXs]="24" [nzXl]="8">
        <nz-form-item>
          <nz-form-label>Nome</nz-form-label>
          <nz-form-control>
            <input type="text" class="form-control" formControlName="name" placeholder="Nome">
          </nz-form-control>
        </nz-form-item>
      </article>

      <article nz-col [nzXs]="24" [nzXl]="8">
        <nz-form-item>
          <nz-form-label>Cognome</nz-form-label>
          <nz-form-control>
            <input type="text" class="form-control" formControlName="lastname" placeholder="Cognome">
          </nz-form-control>
        </nz-form-item>
      </article>

      <article nz-col [nzXs]="24" [nzXl]="8">
        <nz-form-item>
          <nz-form-label>E-mail</nz-form-label>
          <nz-form-control>
            <input type="text" class="form-control" formControlName="email" placeholder="E-mail">
          </nz-form-control>
        </nz-form-item>
      </article>

      <article nz-col [nzXs]="24" [nzXl]="8">
        <nz-form-item>
          <nz-form-label>Sezione</nz-form-label>
          <nz-form-control>
            <nz-radio-group formControlName="sector">
              <label nz-radio [nzValue]="'A'">A</label>
              <label nz-radio [nzValue]="'B'">B</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </article>

      <article nz-col [nzXs]="24" [nzXl]="8">
        <nz-form-item>
          <nz-form-label>Specializzazione</nz-form-label>
          <nz-form-control>
            <input type="text" class="form-control" formControlName="specialization" placeholder="Specializzazione">
          </nz-form-control>
        </nz-form-item>
      </article>

      <article nz-col [nzXs]="12" [nzXl]="4">
        <nz-form-item>
          <nz-form-label></nz-form-label>
          <nz-form-control>
            <button class="btn blu col-12" nz-button nzType="primary" (click)="submitForm()">Cerca</button>
          </nz-form-control>
        </nz-form-item>
      </article>

      <article nz-col [nzXs]="12" [nzXl]="4">
        <nz-form-item>
          <nz-form-label></nz-form-label>
          <nz-form-control>
            <button class="btn blu col-12" nz-button nzType="primary" (click)="initForm()">Pulisci filtri</button>
          </nz-form-control>
        </nz-form-item>
      </article>
    </section>
  </form>

  <h4 class="mt-3 mt-xl-5 mb-3">Risultati</h4>
  <ng-container *ngIf="isFiltered; else noData">
    <nz-table class="d-none d-xl-block" #basicTable [nzData]="listOfData" [nzPageIndex]="pageIndex"
      [nzPageSize]="pageSize" [nzFrontPagination]="false" [nzTotal]="total" (nzPageIndexChange)="changePage($event)"
      [nzLoading]="!isTableLoaded">
      <thead>
        <tr>
          <th *ngFor="let column of columns">{{ column.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of basicTable.data">
          <td *ngFor="let column of columns">
            <ng-container [ngSwitch]="column.type">
              <span *ngSwitchCase="'date'">
                {{ data[column.key] }}
              </span>
              <span *ngSwitchDefault>
                {{ data[column.key] }}
              </span>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>

  <section class="d-block d-xl-none">
    <section class="col-12 px-0 d-flex flex-row justify-content-between flex-wrap"
      [ngClass]="{'divider': i < listOfData.length - 1}" *ngFor="let element of listOfData; let i = index">
      <article class="d-flex flex-row flex-start col-12 col-xl-auto my-3 mb-xl-0">
        <article class="mx-3">
          <h5 class="fw-700">
            <span>{{ element.name }}</span>
            <span class="small text-muted fw-normal"> {{ element.registrationNumber }}</span>
          </h5>
          <p>
            <span *ngIf="element.rollRegistrationDate"><b>Data di iscrizione all'albo:</b> {{
              element.rollRegistrationDate}}<br></span>
            <span *ngIf="element.birthDate">
              <b>Data di nascita:</b> {{ element.birthDate}} <br>
            </span>
            <span *ngIf="element.birthplace"><b>Luogo di nascita:</b> {{ element.birthplace }}<br></span>
            <span *ngIf="element.specialization"><b>Specializzazione:</b> {{ element.specialization }}<br></span>
            <span *ngIf="element.sector"><b>Sezione:</b> {{ element.sector }}</span>
          </p>
        </article>
      </article>
    </section>
  </section>

  <ng-template #noData>
    <section class="mt-5 d-flex flex-row justify-content-center">
      <i>Imposta i filtri per visualizzare i risultati</i>
    </section>
  </ng-template>
</div>
