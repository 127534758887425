import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UserService } from 'src/app/modules/application/services/user.service';
import { ResourcesServices } from 'src/app/shared/services/resources.service';

@Component({
  selector: 'app-albo',
  templateUrl: './albo.component.html',
  styleUrls: ['./albo.component.scss']
})
export class AlboComponent implements OnInit {
  public form!: FormGroup;
  public columns: { key: string, name: string, type: string }[] = [
    { key: 'registrationNumber', name: 'Cod. Iscrizione', type: 'string' },
    { key: 'name', name: 'Nominativo', type: 'string' },
    { key: 'birthDate', name: 'Nato il', type: 'date' },
    { key: 'birthPlace', name: 'Nato a', type: 'string' },
    { key: 'specialization', name: 'Specializzazione', type: 'string' },
    { key: 'sector', name: 'Sezione', type: 'string' },
    { key: 'degreed_at', name: 'Data di laurea', type: 'date' },
    { key: 'rollRegistrationDate', name: 'Iscrizione', type: 'date' }
  ];
  public listOfData: any[] = [];
  public pageIndex = 1;
  public pageSize = 10;
  public total = 0;
  public isTableLoaded: boolean = false;
  public isFiltered: boolean = false;

  constructor(
    private _users: UserService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      name: null,
      lastname: null,
      email: null,
      sector: null,
      specialization: null
    });

    this.isFiltered = false;
  }

  getData() {
    this.isTableLoaded = false;
    this._users.getAlboList(this.form.value).subscribe({
      next: (response) => {
        this.listOfData = response.data;
        this.total = response.total;
        this.isTableLoaded = true;
      }
    });
  }

  changePage(event: any) {
    this.pageIndex = event;
    this.getData();
  }

  submitForm() {
    this.isFiltered = true;
    this.getData();
  }

  handlePage(e: any) {
    this.getData();
    return e;
  }
}
