import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from "rxjs";

export interface TableColumn {
    name: string;
    key: string;
    sortable?: boolean;
    type?: string;
    width?: string;
}

export interface TableSort {
    key: string;
    direction: string;
}

export interface TablePagination {
    size: number;
    index: number;
}

@Component({
    selector: 'nz-datatable',
    templateUrl: './nz-datatable.component.html',
    styleUrls: ['./nz-datatable.component.scss']
})

export class NzDatatableComponent implements OnInit {
    @Input() columns: TableColumn[] = [];
    @Input() data: any[] = [];
    @Input() data_count: number = 0;
    @Input() actions: any[] = [];
    @Input() row_actions: boolean = false;
    @Input() clickable: boolean = false;
    @Input() pageSize: number = 10;
    @Input() default_sort: TableSort = { key: '', direction: '' };
    @Input() checked_rows: any[] = [];
    @Input() label_azioni: string | null = null;

    @Output() action_clicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() row_clicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() sort_changed: EventEmitter<TableSort> = new EventEmitter<TableSort>();
    @Output() page_changed: EventEmitter<TablePagination> = new EventEmitter<TablePagination>();
    @Output() selected_row: EventEmitter<any> = new EventEmitter<any>();

    sort: TableSort = { key: '', direction: '' };
    pagination: TablePagination = { size: 10, index: 0 };
    $destroy: Subject<void> = new Subject<void>();

    constructor() { }

    ngOnInit(): void { }

    ngOnChanges(changes: any) {
        if (changes.default_sort) this.sort = this.default_sort;
    }

    ngOnDestroy(): void {
        this.$destroy.next();
        this.$destroy.complete();
    }

    /**
     * Al click sulla riga se l'opzione clickable è true invio un evento al componente padre
     * @param data informazioni sulla riga cliccata
     */
    onRowClick(data: any) {
        if (this.clickable) this.row_clicked.emit(data);
    }

    /**
     * Al click su una delle azioni invio un evento al componente padre
     * @param action stringa dell'azione cliccata
     * @param event evento click
     */
    onActionClick(action: any, event: any, row: any, sub_action = null) {
        //serve per evitare che cliccando sull'azione si triggeri il click sulla riga
        event.stopPropagation();

        this.action_clicked.emit({ action: action, data: row, sub_action: sub_action });
    }

    /**
     * Al click su una colonna sortabile calcolo il sorting
     * @param column colonna cliccata
     */
    onSortChange(column: TableColumn) {
        if (column.sortable) {

            //se nessuna key è impostata oppure se è diversa dalla colonna cliccata => la imposto con direzione ascendente
            if (this.sort.key == '' || (this.sort.key != '' && this.sort.key != column.key)) {
                this.sort.key = column.key;
                this.sort.direction = 'asc';
            }

            //se la key impostata è la stessa che è stata cliccata => la imposto con direzione discendente
            else if (this.sort.key == column.key && this.sort.direction == 'asc') {
                this.sort.direction = 'desc';
            }

            //se la key impostata è la stessa che è stata cliccata e ha direzione discendente => resetto il sorting
            else if (this.sort.key == column.key && this.sort.direction == 'desc') {
                this.sort.key = '';
                this.sort.direction = '';
            }

            this.pagination.index = 0;
            this.sort_changed.emit(this.sort);
        }
    }

    /**
     * Al cambio pagina aggiorno l'indice della paginazione
     * @param index indice corrente
     */
    onPageChange(index: any) {
        this.pagination.index = index - 1;
        this.page_changed.emit(this.pagination);
    }

    /**
     * Al check di un record
     * @param $event evento click
     * @param id id del record selezionato
     */
    onRowCheck($event: any, id: any) {
        this.selected_row.emit({ checked: $event.target.checked, id: id });
    }
}
