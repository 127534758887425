import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DialogComponent } from '../dialog/dialog.component';
import { ElenchiDlgs81DialogComponent } from '../elenchiDlgs81Dialog/elenchiDlgs81Dialog.component';
import { ElenchiPrevincendiDialogComponent } from '../elenchiPrevincendiDialog/elenchiPrevincendiDialog.component';
import { ManifestazioneInteresseDialogComponent } from '../manifestazioneInteresseDialog/manifestazioneInteresseDialog.component';
import { VariazioneAlboDialogComponent } from '../variazioneAlboDialog/variazioneAlboDialog.component';

@Component({
  selector: 'app-stampe',
  templateUrl: './stampe.component.html',
  styleUrls: ['./stampe.component.scss']
})
export class StampeComponent implements OnInit {
  documenti = [
    { titolo: 'Richiesta Inclusione negli Elenchi del Ministero dell\'Interno', type: 'domanda-iscrizione-elenchi-previncendi' },
    { titolo: 'Iscrizione elenchi dell\'Ordine D.Lgs. 81/08', type: 'domanda-elenchi-dlgd81-08' },
    { titolo: 'Modulo Manifestazione Interesse', type: 'manifestazione-interesse-fac-simile' },
    { titolo: 'Modulo per la richiesta di Consulenza Tributaria', type: 'richiesta-consulenza-tributaria' },
    { titolo: 'Modulo Variazioni Albo', type: 'variazione-albo-2009-posta-residenza-2009' },
  ]

  data = 'SONO IL MODALE'
  profile: any;

  constructor(
    private userService: UserService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.userService.me().subscribe(response => {
      this.profile = response.data;
      //console.log('miei dati', this.profile);
    })
  }

  async openDoc(type: any) {
    //console.log(type);
    switch (type) {
      case 'documentazione-4690': {
        if (!this.profile.birthPlace || !this.profile.birthDate || !this.profile.street_residence || !this.profile.city_residence || !this.profile.studyTitle ||
          !this.profile.rollRegistrationDate || !this.profile.registrationNumber) {
          Swal.fire({
            icon: 'warning',
            title: 'Dati mancanti',
            html: `
              <p>Completa correttamente tutti i dati del profilo necessari:</p><br>
              Luogo di nascita: `+ (this.profile.birthPlace ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Data di nascita: ` + (this.profile.birthDate ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Indirizzo di residenza: ` + (this.profile.street_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Città di residenza: ` + (this.profile.city_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Titolo di studio: ` + (this.profile.studyTitle ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Data registrazione albo: ` + (this.profile.rollRegistrationDate ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Codice registrazione albo: ` + (this.profile.registrationNumber ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>'),
            showCancelButton: true,
            confirmButtonText: "<a style='text-decoration: none!important; color:white!important' href='client/profile'>Vai al profilo</a>",
            cancelButtonText: "Annulla"
          })
        } else {
          let initialState: any;

          initialState = {
            data: this.data,
            tipo: type
          }
          this.modalService.show(DialogComponent, { initialState: initialState, class: 'modal-lg' });
        }


        break;
      }
      case 'domanda-iscrizione-elenchi-previncendi': {

        if (!this.profile.birthPlace || !this.profile.birthDate || !this.profile.fiscalCode || !this.profile.city_residence || !this.profile.street_residence
          || !this.profile.rollRegistrationDate || !this.profile.registrationNumber) {
          Swal.fire({
            icon: 'warning',
            title: 'Dati mancanti',
            html: `
              <p>Completa correttamente tutti i dati del profilo necessari:</p><br>
              Luogo di nascita: `+ (this.profile.birthPlace ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Data di nascita: ` + (this.profile.birthDate ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Codice fiscale: ` + (this.profile.fiscalCode ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Indirizzo di residenza: ` + (this.profile.street_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Città di residenza: ` + (this.profile.city_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Data registrazione albo: ` + (this.profile.rollRegistrationDate ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Codice registrazione albo: ` + (this.profile.registrationNumber ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>'),
            showCancelButton: true,
            confirmButtonText: "<a style='text-decoration: none!important; color:white!important' href='client/profile'>Vai al profilo</a>",
            cancelButtonText: "Annulla"
          })
        } else {
          let initialState: any;

          initialState = {
            data: this.data,
            tipo: type
          }
          this.modalService.show(ElenchiPrevincendiDialogComponent, { initialState: initialState, class: 'modal-lg' });
        }
        break;
      }
      case 'domanda-elenchi-dlgd81-08': {

        if (!this.profile.birthPlace || !this.profile.birthDate || !this.profile.city_residence || !this.profile.street_residence || !this.profile.cap_residence
          || !this.profile.phone_residence || !this.profile.registrationNumber) {
          Swal.fire({
            icon: 'warning',
            title: 'Dati mancanti',
            html: `
              <p>Completa correttamente tutti i dati del profilo necessari:</p><br>
              Luogo di nascita: `+ (this.profile.birthPlace ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Data di nascita: ` + (this.profile.birthDate ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Indirizzo di residenza: ` + (this.profile.street_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Città di residenza: ` + (this.profile.city_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `CAP: ` + (this.profile.cap_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Numero di telefono: ` + (this.profile.phone_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Codice registrazione albo: ` + (this.profile.registrationNumber ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>'),
            showCancelButton: true,
            confirmButtonText: "<a style='text-decoration: none!important; color:white!important' href='client/profile'>Vai al profilo</a>",
            cancelButtonText: "Annulla"
          })
        } else {
          let initialState: any;

          initialState = {
            data: this.data,
            tipo: type
          }
          this.modalService.show(ElenchiDlgs81DialogComponent, { initialState: initialState, class: 'modal-lg' });
        }
        break;
      }
      case 'manifestazione-interesse-fac-simile': {

        if (!this.profile.birthPlace || !this.profile.birthDate || !this.profile.fiscalCode || !this.profile.city_residence || !this.profile.street_residence
          || !this.profile.rollRegistrationDate || !this.profile.registrationNumber) {
          Swal.fire({
            icon: 'warning',
            title: 'Dati mancanti',
            html: `
              <p>Completa correttamente tutti i dati del profilo necessari:</p><br>
              Luogo di nascita: `+ (this.profile.birthPlace ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Data di nascita: ` + (this.profile.birthDate ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Codice fiscale: ` + (this.profile.fiscalCode ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Indirizzo di residenza: ` + (this.profile.street_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Città di residenza: ` + (this.profile.city_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Data registrazione albo: ` + (this.profile.rollRegistrationDate ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Codice registrazione albo: ` + (this.profile.registrationNumber ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>'),
            showCancelButton: true,
            confirmButtonText: "<a style='text-decoration: none!important; color:white!important' href='client/profile'>Vai al profilo</a>",
            cancelButtonText: "Annulla"
          })
        } else {
          let initialState: any;

          initialState = {
            data: this.data,
            tipo: type
          }
          this.modalService.show(ManifestazioneInteresseDialogComponent, { initialState: initialState, class: 'modal-lg' });
        }
        break;
      }
      case 'richiesta-consulenza-tributaria': {

        if (!this.profile.registrationNumber || !this.profile.phone_residence || !this.profile.pec) {
          Swal.fire({
            icon: 'warning',
            title: 'Dati mancanti',
            html: `
              <p>Completa correttamente tutti i dati del profilo necessari:</p><br>
              Numero di telefono: `+ (this.profile.phone_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `PEC: ` + (this.profile.pec ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Codice registrazione albo: ` + (this.profile.registrationNumber ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>'),
            showCancelButton: true,
            confirmButtonText: "<a style='text-decoration: none!important; color:white!important' href='client/profile'>Vai al profilo</a>",
            cancelButtonText: "Annulla"
          })
        } else {
          this.getDocument('richiesta-consulenza-tributaria');
        }
        break;
      }
      case 'variazione-albo-2008-posta-residenza-2009-1': {

        if (!this.profile.birthPlace || !this.profile.birthDate || !this.profile.residence || !this.profile.address || !this.profile.civic
          || !this.profile.cap || !this.profile.province || !this.profile.telephone || !this.profile.landlinePhone || !this.profile.fax
          || !this.profile.actualJob || !this.profile.rollRegistrationDate || !this.profile.registrationNumber) {
          Swal.fire({
            icon: 'warning',
            title: 'Dati mancanti',
            text: 'Completa correttamente tutti i dati del profilo necessari',
            showCancelButton: true,
            confirmButtonText: "<a style='text-decoration: none!important; color:white!important' href='client/profile'>Vai al profilo</a>",
            cancelButtonText: "Annulla"
          })
        } else {
          let initialState: any;

          initialState = {
            data: this.data,
            tipo: type
          }
          this.modalService.show(VariazioneAlboDialogComponent, { initialState: initialState, class: 'modal-lg' });
        }
        break;
      }
      case 'variazione-albo-2009-posta-residenza-2009': {

        if (!this.profile.birthPlace || !this.profile.birthDate || !this.profile.city_residence || !this.profile.street_residence
          || !this.profile.cap_residence || !this.profile.phone_residence
          || !this.profile.actualJob || !this.profile.rollRegistrationDate || !this.profile.registrationNumber) {
          Swal.fire({
            icon: 'warning',
            title: 'Dati mancanti',
            html: `
              <p>Completa correttamente tutti i dati del profilo necessari:</p><br>
              Luogo di nascita: `+ (this.profile.birthPlace ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Data di nascita: ` + (this.profile.birthDate ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Codice fiscale: ` + (this.profile.fiscalCode ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Indirizzo di residenza: ` + (this.profile.street_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Città di residenza: ` + (this.profile.city_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `CAP: ` + (this.profile.cap_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Numero di cellulare: ` + (this.profile.phone_residence ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Occupazione attuale: ` + (this.profile.actualJob ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Data registrazione albo: ` + (this.profile.rollRegistrationDate ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>') +
              `Codice registrazione albo: ` + (this.profile.registrationNumber ? '<p>Presente</p>' : '<p><strong>Non presente</strong></p>'),
            showCancelButton: true,
            confirmButtonText: "<a style='text-decoration: none!important; color:white!important' href='client/profile'>Vai al profilo</a>",
            cancelButtonText: "Annulla"
          })
        } else {
          let initialState: any;

          initialState = {
            data: this.data,
            tipo: type
          }
          this.modalService.show(VariazioneAlboDialogComponent, { initialState: initialState, class: 'modal-lg' });
        }
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  getDocument(tipo: any) {
    this.userService.getDocument(tipo).subscribe((r: Blob) => {
      const blobFile: Blob = new Blob([r], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(blobFile);
      window.open(fileURL, '_blank');
    })
  }

  public openModal() {
  }

}
