<div class="col-11 m-auto mt-5">

    <!-- ? Titolo e searchbar -->
    <div class="d-flex row">
        <div class="title col-sm-8 col-md-8">
            <h2 *ngIf="(ruolo | async) == 1"><a href="/app/admin/dashboard">Bacheca</a> / <span class="text">Tavoli
                    tematici</span></h2>
            <h2 *ngIf="(ruolo | async) == 2"><a href="/app/client/subscriptions">Bacheca</a> / <span class="text">Tavoli
                    tematici</span></h2>
            <h2 *ngIf="(ruolo | async) == 3"><a href="/app/guest/subscriptions">Bacheca</a> / <span class="text">Tavoli
                    tematici</span></h2>
        </div>
        <div class="search col-sm-4 col-md-4">
            <mat-form-field appearance="fill">
                <mat-label>Ricerca per titolo</mat-label>
                <input matInput [formControl]="filtroRicerca">
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <button type="button" class="btn blu me-4" [routerLink]="'create'" *ngIf="(ruolo | async) == 1">Aggiungi</button>
    <button type="button" class="btn rosso " (click)="removeElement()" *ngIf="(ruolo | async) == 1">Elimina</button>

    <!-- ? Tabella -->
    <div class="mt-4">
        <table mat-table class="mt-4" [dataSource]="(tables | async)">

            <ng-container matColumnDef="check" *ngIf="(ruolo | async) == 1">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Seleziona </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    <input class="form-check-input ms-2 radio" role="button" type="checkbox" value="" name="selection"
                        id="flexCheckDefault" [ngModel]="selectedIndex === element.id"
                        (change)="changeSelection($any($event), element.id)">
                </td>
            </ng-container>


            <ng-container matColumnDef="titles" *ngIf="(ruolo | async) != 1">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Titolo </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    <a class="clickon" [routerLink]="'show/' + element.id">{{element.title}}</a>
                </td>
            </ng-container>


            <ng-container matColumnDef="titolo" *ngIf="(ruolo | async) == 1">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Titolo </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    <a class="clickon" [routerLink]="'admission/' + element.id">{{element.title}}</a>
                </td>
            </ng-container>


            <ng-container matColumnDef="posti">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Posti disp. </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    {{element?.total_seats}}
                </td>
            </ng-container>


            <ng-container matColumnDef="cfp">
                <th mat-header-cell *matHeaderCellDef class="py-3"> CFP </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    {{element?.cfp}}
                </td>
            </ng-container>


            <ng-container matColumnDef="durata">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Durata </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    {{element?.duration}}
                </td>
            </ng-container>


            <ng-container matColumnDef="data">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Data </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    {{element?.start_date | date:"dd-MM-YYYY"}}
                </td>
            </ng-container>


            <ng-container matColumnDef="azione" *ngIf="(ruolo | async) == 1">
                <th mat-header-cell *matHeaderCellDef class="py-3 large"> Azione </th>
                <td mat-cell *matCellDef="let element" class="py-3 large">
                    <button class="btn-edit" [routerLink]="'show/' + element.id"><img class="m-auto pointer"
                            src="../../../assets/icons/pencil.svg" alt=""></button>
                </td>
            </ng-container>


            <ng-container matColumnDef="prenota" *ngIf="(ruolo | async) != 1" class="d-flex justify-content-center">
                <th mat-header-cell *matHeaderCellDef class="py-3 last-cell large">Azione
                </th>
                <td mat-cell *matCellDef="let element" class="py-3 last-cell large">
                    <button *ngIf="element.subscriptions.length == 0" class="btn button"
                        [routerLink]="'payment/' + element.id">
                        Prenota
                    </button>
                    <button *ngIf="element.subscriptions.length > 0" class="btn bianco"
                        (click)="unsubscribe(element.id)">
                        Disiscriviti
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="pageEvent = handlePage($event)" showFirstLastButtons>
        </mat-paginator>
    </div>


</div>