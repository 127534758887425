<div class="col-11 m-auto mt-5">
     <!-- ? Titolo e searchbar -->
     <div class="d-flex row">
          <div class="title col-sm-8 col-md-8">
               <h2 class="text">Commisioni
                    interne</h2>
          </div>
          <div class="search col-sm-4 col-md-4">
               <mat-form-field appearance="fill">
                    <mat-label>Ricerca per titolo</mat-label>
                    <input matInput [formControl]="filtroRicerca">
                    <mat-icon matPrefix>search</mat-icon>
               </mat-form-field>
          </div>
     </div>

     <button type="button" class="btn blu me-4" [routerLink]="'create'" *ngIf="(ruolo | async) == 4">Aggiungi</button>
     <button type="button" class="btn rosso " (click)="removeElement()" *ngIf="(ruolo | async) == 4">Elimina</button>

     <div class="mt-4">
          <table mat-table class="mt-4" [dataSource]="(tables | async)">
               <ng-container matColumnDef="title" *ngIf="(ruolo | async) == 4">
                    <th mat-header-cell *matHeaderCellDef class="py-3"> Nome </th>
                    <td mat-cell *matCellDef="let element" class="py-3 col-4">
                         <a class="clickon" (click)="goTo(element.id)">{{element.name}}</a>
                    </td>
               </ng-container>

               <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="py-3">Descrizione </th>
                    <td mat-cell *matCellDef="let element" class="py-3 col-4">
                         {{element?.description}}
                    </td>
               </ng-container>

               <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef class="py-3"> Data </th>
                    <td mat-cell *matCellDef="let element" class="py-3 col-4">
                         {{element?.created_at | date:"dd-MM-YYYY"}}
                    </td>
               </ng-container>

               <ng-container matColumnDef="action" *ngIf="(ruolo | async) == 4">
                    <th mat-header-cell *matHeaderCellDef class="py-3 large"> Azione </th>
                    <td mat-cell *matCellDef="let element; let i = index" class="py-3 large col-4">
                         <input type="checkbox" (change)="changeSelection($event,element.id)">
                    </td>
               </ng-container>

               <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
               <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
     </div>

     <mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="pageEvent = handlePage($event)" showFirstLastButtons>
     </mat-paginator>
</div>