<div class="example-container" *ngIf="cols.length; else noData">
  <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
    <ng-container [matColumnDef]="col" *ngFor="let col of colsHead">
      <ng-container [ngSwitch]="col">
        <ng-container *ngSwitchCase="'select'">
          <mat-header-cell *matHeaderCellDef class="spacecheck">
            <!-- <mat-checkbox *ngIf="showCheckbox" (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox> -->
          </mat-header-cell>
          <mat-cell *matCellDef="let row"
            [ngClass]="{'disabled' : row.status == 'trash' || (row.sub_status != 'In attesa' && row.category_id == 2 && !free)}"
            class="spacecheck">
            <mat-checkbox (click)="$event.stopPropagation(); getElement(row)"
              *ngIf="(!url.includes('client') && (row.category_id == 1|| row.category_id==2|| row.category_id==3  || row.category_id==4)) || (!url.includes('client') && row.request_certificate == 'Non richiesto')"
              (change)=" $event ? selection.toggle(row) : null; takedatarow(row, $event); isAllSelected(row)"
              [disabled]="row.sub_status != 'In attesa' && !free">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'title'">
          <mat-header-cell mat-sort-header *matHeaderCellDef class="responsive"> TITOLO
          </mat-header-cell>

          <ng-container
            *ngIf="!client && (url.includes('comunicazioni') || url.includes('faq') || url.includes('bandi') || url.includes('attivita-aggregative') || url.includes('convenzioni') || url.includes('video-istituzionali') || url.includes('tecnica-e-ricostruzione') || url.includes('rassegna-stampa')); else clickableTitle">
            <mat-cell *matCellDef="let element"
              [ngClass]="(element.status == 'trash' ? 'disabled' : '') || (element.postStatus == 'In programmazione' ? 'grey' : '')
           || (element.action == 'already-subscribed' && element.postStatus =='Scaduto' ? 'bianco' : '' ) || (element.action == 'subscribe' ? 'blue' : '') || (element.action == 'already-subscribed' ?  'verde' : '')"
              class="color">
              <span class="text-truncate pe-2">{{element[col] }}</span>
            </mat-cell>
          </ng-container>

          <!--serve per nascondere la riga in preparazione  [ngClass]="{'preparation' : row.action == 'preparation'}" -->

          <!-- <div *ngIf="client; else comunicazioni"> -->
          <!-- ( element.postStatus =='Scaduto' ? 'grey' : '') ||  -->
          <ng-template #clickableTitle>
            <mat-cell *matCellDef="let element" (click)="getPath(element)"
              [ngClass]="(element.status == 'trash' ? 'disabled' : '') || (element.postStatus == 'In programmazione' ? 'grey' : '')
           || (element.action == 'already-subscribed' && element.postStatus =='Scaduto' ? 'bianco' : '' ) || (element.action == 'subscribe' ? 'blue' : '') || (element.action == 'already-subscribed' ?  'verde' : '')"
              class="color pointer">
              <span *ngIf="!client" class="text-truncate pe-2"
                matTooltip="Clicca qui per vedere la lista degli iscritti">{{element[col]
                }}</span>
              <span *ngIf="client" matTooltip="Clicca qui per vedere il riepilogo"
                class="text-truncate pe-2">{{element[col]
                }}</span>
            </mat-cell>
          </ng-template>

          <!-- </div> -->
          <!-- <ng-template #comunicazioni>
            <mat-cell *matCellDef="let element" class="color spaceTitle bold"
              [ngClass]="element.status == 'trash' ? 'disabled' : ''">{{element[col].slice(0,45)}}<span
                *ngIf="element[col].length > 45">...</span>
            </mat-cell>
          </ng-template> -->
        </ng-container>

        <ng-container *ngSwitchCase="'path'">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let element" class="color" (click)="downloadFile(element)" class="space">
            <i class="fa fa-download" aria-hidden="true"></i>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'lastname'">
          <mat-header-cell *matHeaderCellDef> {{ col | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element" class="color" class="space lastname" [innerHtml]="element[col]">
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'list'">
          <mat-header-cell *matHeaderCellDef> {{ col | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element" class="color" class="space lastname"
            [innerHtml]="element['subscriber_list']['name']">
          </mat-cell>
        </ng-container>
        <!-- * visto solo dall'admin -->
        <ng-container *ngSwitchCase="'request_certificate'">
          <mat-header-cell *matHeaderCellDef> {{ col | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element" class="color" class="space lastname">
            <!-- {{element[col]}} -->
            {{ element.request_certificate}}
            <button *ngIf="element.request_certificate == 'Richiesto'" type="button" class="ms-3 btn blu"
              data-bs-toggle="modal" data-bs-target="#caricafile" (click)="getElement(element)">
              <i class="fa-solid fa-upload"></i>
            </button>
          </mat-cell>
        </ng-container>
        <!-- <ng-container *ngSwitchCase="'accetta'">
          <mat-header-cell *matHeaderCellDef> {{ col | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element" class="color" class="space lastname">
            <mat-checkbox (change)="changeStatusCertificate(element.id, element.post_id, $event)"
              [checked]="checked(element.request_certificate)"></mat-checkbox>
          </mat-cell>
        </ng-container> -->
        <ng-container *ngSwitchCase="'terne'">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" class="color" class="space" [innerHtml]="element[col]">
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'edit'">
          <mat-header-cell *matHeaderCellDef> Azione </mat-header-cell>
          <!--serve per nascondere la riga in preparazione  [ngClass]="{'preparation' : row.action == 'preparation'}" -->
          <mat-cell *matCellDef="let element" class="color space">
            <button class="table-button blu" matTooltip="Modifica" (click)="getSinglePost(element)"
              *ngIf="isUserAdmin() || element.author_id == user_wp_id">
              <i class="fa-solid fa-pencil"></i>
            </button>
          </mat-cell>
        </ng-container>


        <ng-container *ngSwitchCase="'details'">
          <mat-header-cell *matHeaderCellDef> Azione </mat-header-cell>
          <!--serve per nascondere la riga in preparazione  [ngClass]="{'preparation' : row.action == 'preparation'}" -->
          <mat-cell *matCellDef="let element" class="color" class="space spacecheck">
            <button class=" btn blu" (click)="getPath(element)">
              <span nz-icon nzType="eye" nzTheme="outline"></span>
              <!-- <span class="ms-2">Visualizza dettaglio</span> -->
            </button>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'sub_status'">
          <mat-header-cell *matHeaderCellDef> Stato </mat-header-cell>
          <!--serve per nascondere la riga in preparazione  [ngClass]="{'preparation' : row.action == 'preparation'}" -->
          <mat-cell *matCellDef="let element" class="color" class="space" [innerHtml]="element[col]">
          </mat-cell>
        </ng-container>
        <!-- * visto solo dall'utente -->
        <ng-container *ngSwitchCase="'request_attestato'">
          <mat-header-cell *matHeaderCellDef> Attestato </mat-header-cell>
          <!--serve per nascondere la riga in preparazione  [ngClass]="{'preparation' : row.action == 'preparation'}" -->
          <!--               [checked]="row.request_certificate" -->
          <mat-cell *matCellDef="let row" [ngClass]="{'disabled' : row.status == 'trash'}" class="space">
            <button type="button" class="btn blu"
              (click)="$event.stopPropagation(); getElement(row); sendRequestCertificate()"
              *ngIf="!['subscribe', 'not-admitted', 'waiting'].includes(row.action) && row.request_certificate == null && row.is_enable_certificate == 1; else richiesto"
              (change)="$event ? selection.toggle(row) : null; takedatarow(row, $event); isAllSelected(row)">
              Richiedi
            </button>
            <ng-template #richiesto>
              <p class="mt-3" *ngIf="row.is_enable_certificate == 1 && row.request_certificate == 1">Richiesto</p>
              <!-- <p class="mt-3 verde" *ngIf="row.request_certificate == 0">Ricevuto</p> -->
              <button class="table-button blu" *ngIf="row.request_certificate == 2 && row.is_enable_certificate == 1"
                matTooltip="Scarica l'attestato" (click)="downloadFile(row); print()">
                <i class="fa-solid fa-file-arrow-down"></i>
              </button>
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'status'">
          <mat-header-cell *matHeaderCellDef> Stato </mat-header-cell>
          <mat-cell *matCellDef="let element" class="color space-button">
            <span *ngIf="element.subscription_date; else notSubscribed">
              Iscritto il {{element.subscription_date | date: 'dd-MM-YYYY'}}, {{element.subscription_date | date:
              'HH:mm'}}
              <br><small *ngIf="element.registrationNumber">Numero di iscrizione all'albo:
                {{element.registrationNumber}}</small>
            </span>
            <ng-template #notSubscribed>
              {{ element.sub_status }}
            </ng-template>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'free'">
          <mat-header-cell *matHeaderCellDef> Gratuito </mat-header-cell>
          <mat-cell *matCellDef="let element" class="color space-button">
            <span>
              {{element.free == 0 ? 'No' : 'Sì' }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchCase="'action'">
          <mat-header-cell *matHeaderCellDef> Azione </mat-header-cell>
          <mat-cell *matCellDef="let element" class="color space-button">
            <div *ngIf="element.category_id != 6">
              <button type="button" class="btn" [disabled]="element.button_disabled"
                *ngIf="['2', '3'].includes(role!) && ((element.category_id == 2 && element.free == 0 && element.button_text != 'Iscriviti') || (element.category_id == 2 && element.free == 1) || element.category_id != 2)"
                [ngClass]="{'btn-light': (element.button_text == 'Iscrizioni chiuse' || element.button_text === ''), 'button': element.button_text != 'Iscrizioni chiuse' || element.button_text == 'In attesa'}"
                (click)="element.button_text == 'Iscriviti' ? subscribe(element.id) : unsubscribe(element.id)">
                {{element.button_text}}
              </button>
              <button
                *ngIf="['2', '3'].includes(role!) && ((element.category_id == 2 && element.free == 0 && element.button_text == 'Iscriviti'))"
                type="button" class="btn blu" data-bs-toggle="modal" data-bs-target="#uploadFiles"
                [ngClass]="{'btn-light': (element.button_text == 'Iscrizioni chiuse' || element.button_text === ''), 'button': element.button_text != 'Iscrizioni chiuse'}"
                (click)="getElement(element); post = element.id">
                {{element.button_text}}
              </button>
            </div>

            <div *ngIf="element.category_id == 6">
              <button type="button" class="btn button"
                (click)="element.button_text == 'Iscriviti' ? subscribe(element.id) : unsubscribe(element.id)">
                {{element.button_text}}
              </button>
            </div>

            <button type="button" class="btn button" *ngIf="role == '1' && !free" (click)="openFiles(element)">
              VISUALIZZA FILE
            </button>
          </mat-cell>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <mat-header-cell mat-sort-header *matHeaderCellDef class="responsive"> {{ col |
            translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"
            [ngClass]="(element.status == 'trash' ? 'disabled' : '') ||  (element.action == 'already-subscribed' ?  'verde' : '') || (element.status == 'trash' ? 'disabled' : '' && element.postStatus == 'Scaduto' ? 'grey' : '' ) || ( element.postStatus == 'Scaduto' ? 'grey' : '') "
            class="color responsive">
            {{ element[col] == 'Scaduto' ? 'Concluso' : element[col] }} {{ col == 'duration' ? element[col] > 1 ? 'ore'
            : 'ora' : ''}}
          </mat-cell>
        </ng-container>
      </ng-container>
    </ng-container>
    <mat-header-row *matHeaderRowDef="colsHead"></mat-header-row>
    <!--serve per nascondere la riga in preparazione  [ngClass]="{'preparation' : row.action == 'preparation'}" -->
    <mat-row *matRowDef="let row; columns: colsHead;"></mat-row>
  </mat-table>

</div>


<!-- Modal CARICA ATTESTATO -->
<div class="modal fade" id="caricafile" tabindex="-1" aria-labelledby="caricafileLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="caricafileLabel">CARICA ATTESTATO</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <label class="color me-2">Allegato</label>
        <br>
        <nz-form-item class="mt-3">
          <nz-form-control>
            <section class="d-flex flex-row justify-content-start">
              <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzMultiple]="false"
                [nzBeforeUpload]="beforeUpload">
                <button nz-button class="color">
                  <span nz-icon nzType="upload"></span>
                  Aggiungi file
                </button>
              </nz-upload>
            </section>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn grey" data-bs-dismiss="modal" (click)="_initAllegaFile()">ANNULLA</button>
        <button type=" button" class="btn blu" (click)="uploadFile()" data-bs-dismiss="modal"
          [disabled]="fileList.length == 0">CARICA</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal CARICA ATTESTATO -->
<div class="modal fade" id="uploadFiles" tabindex="-1" aria-labelledby="uploadFilesLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="caricafileLabel">CARICA FILE</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p class="color me-2">Si richiede ricevuta di pagamento per potersi iscrivere</p>
        <label class="color me-2">Allegato</label>
        <br>
        <nz-form-item class="mt-3">
          <nz-form-control>
            <section class="d-flex flex-row justify-content-start">
              <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzMultiple]="true"
                [nzBeforeUpload]="beforeUpload" [nzAccept]="'.pdf'">
                <button nz-button class="color">
                  <span nz-icon nzType="upload"></span>
                  Aggiungi file
                </button>
              </nz-upload>
            </section>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn grey" data-bs-dismiss="modal" (click)="_initAllegaFile()">ANNULLA</button>
        <button type="button" class="btn blu" (click)="uploadFileForCoursePayment()" data-bs-dismiss="modal"
          [disabled]="this.allegafile.get('attachmentPaths').value == null">CARICA E ISCRIVITI</button>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="example-container mt-5" *ngIf="url.includes('booked'); else otherData">
    <em>Non vi sono ancora iscritti</em>
  </div>
  <ng-template #otherData class="example-container mt-5">
    <em>Nessun dato disponibile</em>
  </ng-template>
</ng-template>