import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-access-documents',
    templateUrl: './access-documents.component.html',
    styleUrls: ['./access-documents.component.scss']
})
export class AccessDocumentsComponent implements OnInit {
    name: string = '';
    lastname: string = '';
    refreshData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    formDocuments: FormGroup = this.formBuilder.group({
        name: [this.name, Validators.required],
        lastname: [this.lastname, Validators.required],
        email_content: [null, Validators.required],
    });

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService
    ) { }

    ngOnInit(): void {
        this.userService.me().subscribe(user => {
            this.name = user.data.name;
            this.lastname = user.data.lastname;
            this._initForm();
        });
    }

    _initForm() {
        this.formDocuments = this.formBuilder.group({
            name: [this.name, Validators.required],
            lastname: [this.lastname, Validators.required],
            email_content: [null, Validators.required],
        });

    }
    sendMail() {
        this.userService.accessDocument(this.formDocuments.value).subscribe({
            next: (response) => {
                Swal.fire({
                    icon: 'success',
                    title: '<div style="font-size:18px;">' + response.message + '</div>',
                    showConfirmButton: false,
                    timer: 3000,
                });
                this._initForm();

                // aggiorna Storico richieste dopo il corretto invio della mail
                this.refreshData.next(true);
            },
            error: (error) => {
                Swal.fire({
                    icon: 'error',
                    title: '<div style="font-size:18px;">' + error.message + '</div>',
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        });
    }

}
