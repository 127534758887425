<div class="m-auto mt-5 col-11">
  <div class="d-flex flex-row justify-content-between">
    <div class=" title ">
      <h2 *ngIf=" (ruolo | async)==2"><a href="/app/client/subscriptions">Bacheca</a> / <span
          class="text">{{path.replace("-", " ")}}</span></h2>
      <h2 *ngIf="(ruolo | async) == 3"><a href="/app/guest/subscriptions">Bacheca</a> / <span
          class="text">{{path.replace("-", " ")}}</span>
      </h2>
    </div>
    <div class="search">
      <mat-form-field appearance="fill">
        <mat-label>Ricerca {{path.replace("-", " ")}}</mat-label>
        <input matInput [formControl]="searchField">
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>


  <!--  <div class="mb-4">-->
  <!--    <p>-->
  <!--      <strong (click)="getData()"> Tutti <span>({{total}})</span></strong> | <strong-->
  <!--        (click)="indexSubs()">Iscrizioni <span>({{subCount}})</span></strong>-->
  <!--    </p>-->
  <!--  </div>-->

  <!-- Modal -->
  <div *ngIf="rows.length!=0">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body col-12">
            <p>Eliminare {{rows.length}} {{path}}?</p>
            <div class="d-flex justify-content-between">
              <button type="button" class="btn annulla" data-bs-dismiss="modal"> ANNULLA </button>
              <button type="button" class="btn elimina">ELIMINA</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- DataTable -->
  <div>
    <app-dataTable [cols]="cols" [colsHead]="colsHead" (datatable)="datatable($event)" (course)="getData()"
      (sortChange)="onSortChange($event)" (reloadData)="reloadData()">
    </app-dataTable>
    <mat-paginator *ngIf="length != 0; else noData" (page)="handlePageEvent($event)" [length]="length"
      [pageSize]="pageSize" [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex" aria-label="Select page">
    </mat-paginator>
    <ng-template #noData>
    </ng-template>
  </div>
</div>
