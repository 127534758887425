import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-conferma-email',
  templateUrl: './conferma-email.component.html',
  styleUrls: ['./conferma-email.component.scss']
})
export class ConfermaEmailComponent implements OnInit {
  email = localStorage.getItem('email');
  emailResended = false;
  constructor(
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
  ) { }
  ngOnInit(): void {
  }
  sendEmail() {
    this.emailResended = false;
    this.authService.resendEmailConfirmation(this.email).subscribe(
      r => {
        this.emailResended = true;
      }
    )
  }

  goTo() {
    this.document.location.href = `${environment.project}/`;
  }
}
