<!--EXPORT EXCEL-->
<div class="row mt-2">
  <div class="col-12 col-md-4">
    <button (click)="exportExcel()" type="button" class="btn blu btn-block-sm w-100"
      [disabled]="(data|async)?.length== 0">EXPORT LISTA CONSULENZE RICHIESTE</button>
  </div>
</div>

<table mat-table class="box_super mt-4 w-100" [dataSource]="(data | async)">
  <ng-container matColumnDef="type">
    <th mat-header-cell mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Tipologia </th>
    <td mat-cell *matCellDef="let element" class="py-4">
      {{element.consultant?.type}}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Giorno </th>
    <td mat-cell *matCellDef="let element" class="py-4">
      {{element.date | date:'dd-MM-YYYY'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="hour_slot">
    <th mat-header-cell mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Orario </th>
    <td mat-cell *matCellDef="let element" class="py-4">
      {{element.hour_slot}}
    </td>
  </ng-container>

  <ng-container matColumnDef="consultant_name">
    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Consulente </th>
    <td mat-cell *matCellDef="let element" class="py-4">
      {{element.consultant?.name}}
    </td>
  </ng-container>

  <ng-container matColumnDef="user_id" *ngIf="current_user.value.role_id === 1">
    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Richiedente </th>
    <td mat-cell *matCellDef="let element" class="py-4">
      {{element.user.name}} {{element.user.lastname}}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Stato </th>
    <td mat-cell *matCellDef="let element" class="py-4">
      <i [ngClass]="(element.status === 'Approvata')?('fa-solid fa-check'):('fa-regular fa-clock')"
        [ngStyle]="(element.status === 'Approvata')?({'color':'green'}):({'color':'orange'})"></i>
      {{element.status}}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga large"> Azione </th>
    <td mat-cell *matCellDef="let element" class="py-4 large">
      <button class="btn blu btn-sm p-2" nz-dropdown [nzDropdownMenu]="menu" [nzPlacement]="'bottomCenter'"
        *ngIf="element.status === 'In attesa di approvazione'">PROCESSA</button>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item (click)="sendToConsultant(element)">Invia al consulente</li>
          <li nz-menu-item (click)="reject(element)">Rigetta richiesta</li>
        </ul>
      </nz-dropdown-menu>
      <!-- <button
                *ngIf="current_user.value.role_id === 1 && element.status === 'In attesa di approvazione'"
                class="btn btn-success btn-sm col-12 mb-4 verde"
                matTooltip="Invia al consulente"
                (click)="sendToConsultant(element)"
            >
                INVIA AL CONSULENTE
            </button>
            <button
                *ngIf="current_user.value.role_id === 1 && element.status === 'In attesa di approvazione'"
                class="btn btn-error btn-sm col-12 mb-4 rosso"
                matTooltip="Rigetta richiesta"
                (click)="reject(element)"
            >
                Rigetta richiesta
            </button> -->
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div *ngIf="(data | async)?.length === 0" class="example-container mt-5">
  <em>Nessun dato disponibile</em>
</div>

<mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
  (page)="pageEvent = handlePage($event)" showFirstLastButtons>
</mat-paginator>


<nz-modal [(nzVisible)]="isVisibleRequestValue" nzTitle="Imposta la disponibilità delle consulenze"
  (nzOnCancel)="handleCancelCreate()" (nzOnOk)="onSaveModalCreate()" [nzStyle]="{ width: '75%' }" nzCentered>
  <ng-container *nzModalTitle>
    <h4 class="blue-title" style="margin-bottom: 0px;">Richiedi la tua consulenza</h4>
  </ng-container>
  <ng-container *nzModalContent>
    <form class="col-12 mt-2 mb-2 row" [formGroup]="modalForm">
      <div class="col-4">
        <label for="type" class="mb-2 blue-title">
          Tipologia di consulenza:
        </label><br>
        <nz-select class="w-100" id="type" [formControlName]="'type'"
          nzPlaceHolder="Seleziona la tipologia di consulenza" (ngModelChange)="getDates()">
          <nz-option *ngFor="let consulence_type of typeList" [nzValue]="consulence_type"
            [nzLabel]="consulence_type"></nz-option>
        </nz-select>
        <div *ngIf="modalForm.get('type')?.hasError('required') && modalForm.get('type')?.touched" class="text-danger">
          La tipologia di consulenza è richiesta
        </div>
      </div>
      <div class="col-4">
        <label class="mb-2 blue-title">
          Seleziona un giorno tra i disponibili:
        </label><br>
        <nz-select class="w-100" nzPlaceHolder="Seleziona una data disponibile" id="date" [formControlName]="'date'"
          [nzMaxTagCount]="2" [nzDisabled]="modalDates === null || modalDates.length <= 0" (ngModelChange)="getHours()">
          <nz-option *ngFor="let date of modalDates" [nzValue]="date"
            [nzLabel]="moment(date).format('DD-MM-YYYY')"></nz-option>
        </nz-select>
        <div *ngIf="modalForm.get('date')?.hasError('required') && modalForm.get('date')?.touched" class="text-danger">
          La data è richiesta
        </div>
      </div>
      <div class="col-4">
        <label for="hour_slot" class="mb-2 blue-title">
          Seleziona una fascia oraria tra le disponibili:
        </label><br>
        <nz-select class="w-100" nzPlaceHolder="Seleziona uno slot disponibile" id="hour_slot"
          [formControlName]="'hour_slot'" [nzMaxTagCount]="2"
          [nzDisabled]="modalHours === null || modalHours.length <= 0">
          <nz-option *ngFor="let hour of modalHours" [nzValue]="hour" [nzLabel]="hour"></nz-option>
        </nz-select>
        <div *ngIf="modalForm.get('hour_slot')?.hasError('required') && modalForm.get('hour_slot')?.touched"
          class="text-danger">
          La fascia oraria è richiesta
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *nzModalFooter>
    <button [disabled]="!modalForm.valid" nz-button class="btn blu me-2" (click)="onSaveModalCreate()"
      style="font-size: 0.9rem">INVIA
      RICHIESTA</button>
  </ng-container>
</nz-modal>