<div class="m-auto center">
    <div class="">
        <h2 class="text-color ">Accedi</h2>
        <div class="col-12 input-group center mb-3 p-2">
            <form [formGroup]="loginForm" class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                    <input class="text" matInput placeholder="Nome Utente" formControlName="username">
                </mat-form-field>
                <!-- <div class="d-flex justify-between"> -->
                <mat-form-field class="example-full-width " appearance="fill">
                    <input #password type="password" class="password position text" matInput placeholder="Password"
                        formControlName="password" required>
                    <!-- <i class="float fa-solid fa-eye"></i> -->
                </mat-form-field>
                <span class="border right" id="basic-addon2" (click)="changePasswordInput(password)">
                    <i class="fa-solid fa-eye"></i>
                </span>

                <!-- </div> -->

                <div class="d-flex flex-row justify-content-center">
                    <a href=" recovery-password" class='psw'>Password dimenticata?</a>
                </div>
                <div class="accedi">
                    <button type="submit" class="btn button" (click)="login()">ACCEDI</button>
                </div>
                <a [href]="'register?origin=' + origin" class='register'>Registrati</a>
            </form>
            <!-- ACCEDI CON SPID -->
            <div class="accedi">
                <button (click)="goToSpid()" class="btn btn-primary-spid btn-primary d-flex align-items-center">
                    <img src="../../../../../assets/img/spid-ico-circle-bb.svg" alt="" class="mr-2"
                        style="height: 24px; width: 24px;" />
                    <span class="flex-grow-1 ms-2">Entra con SPID</span>
                </button>
            </div>
            <!-- FINE ACCEDI CON SPID -->
        </div>
    </div>
</div>