import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostsServices {
  route = `${environment.api}`;

  constructor(private http: HttpClient) { }

  getPosts(category_id: any, sticky: boolean | null = false, status: string = 'publish', search: string | null = null, orderby: string = '', orderdir: string = '', skip: number = 0, take: number = 10) {
    return this.http.get<any>(`${environment.api}/posts?category_id=${category_id}&sticky=${sticky}&status=${status}&search=${search}&orderby=${orderby}&orderdir=${orderdir}&skip=${skip}&take=${take}`);
  }

  getPost(id: any) {
    return this.http.get<any>(`${environment.api}/posts/${id}`);
  }

  getCounters(id: any) {
    return this.http.get<any>(`${environment.api}/posts/counters/${id}`);
  }

  createPost(params: any) {
    return this.http.post<any>(`${environment.api}/posts`, params);
  }
  updatePost(params: any, id: any) {
    return this.http.put<any>(`${environment.api}/posts/${id}`, params);
  }

  delete(posts: any, force?: any) {
    return this.http.post<any>(`${environment.api}/multipleDelete`, {posts, force});
  }
}
