<div class="m-auto mt-5 col-11">
  <h2 class="mb-4">Iscrizioni al Corso: {{titleCourse}}</h2>
  <p class="size">Metodi di pagamento</p>



  <div class="row">
    <div class="col-sm-12 col-md-8 mb-4">

      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="btn blu me-3 mb-2 active" id="pills-home-tab" data-bs-toggle="pill"
            data-bs-target="#pills-creditCard" type="button" role="tab" aria-controls="pills-creditCard"
            aria-selected="true">
            <i class="fa-regular fa-credit-card sizeIcon"></i>
            Carta di Credito
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="btn blu me-3 mb-2" id="pills-profile-tab" data-bs-toggle="pill"
            data-bs-target="#pills-bonifico" type="button" role="tab" aria-controls="pills-bonifico"
            aria-selected="false">
            <i class="fa-solid fa-money-bill-transfer sizeIcon"></i>
            Bonifico Bancario
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="btn blu me-3 mb-2" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-paypal"
            type="button" role="tab" aria-controls="pills-paypal" aria-selected="false">
            <i class="fa-brands fa-paypal sizeIcon"></i>
            PayPal
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="btn blu me-3 mb-2" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-pagoPA"
            type="button" role="tab" aria-controls="pills-pagoPA" aria-selected="false">
            PagoPA
          </button>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-creditCard" role="tabpanel"
          aria-labelledby="pills-creditCard-tab">
          <div class="">
            <div class="d-flex row col-11 mt-5">
              <p class="size">Nome sulla carta</p>
              <input type="text" class="text height ms-3" required>
            </div>

            <div class="d-flex row col-11 mt-3">
              <p class="size">Numero carta</p>
              <input type="number" class="text height ms-3" required>
            </div>

            <div class="d-flex row col-11 mt-3">
              <div class="col-sm-4 col-md-4 me-4">
                <p class="size">Mese</p>
                <select name="" id="" class="text height">
                  <option value="Seleziona mese">Seleziona mese</option>
                  <option value="mese" *ngFor="let mese of mesi">{{mese}}</option>
                </select>
              </div>

              <div class="col-sm-4 col-md-4 me-3">
                <p class="size">Anno</p>
                <select name="" id="" class="text height">
                  <option value="Seleziona anno">Seleziona anno</option>
                  <option value="mese" *ngFor="let anno of anni">{{anno}}</option>

                </select>
              </div>

              <div class="col-sm-6 col-md-3 m-0">
                <p class="size">CVV</p>
                <input type="number" class="text height w" required
                  oninput="this.value = this.value.replace(/[^0-9: ]/g, '').replace(/( .*?),(.* )?/, '$1')">
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-bonifico" role="tabpanel" aria-labelledby="pills-bonifico-tab">
          <div class="">
            <div class="d-flex row col-11 mt-3">
              <p class="size">Causale del Bonifico Bancario</p>
              <input type="text" class="text height ms-3" required>
            </div>

            <div class="d-flex row col-11 mt-2">
              <p class="size">Numero conto corrente</p>
              <label for="Iban"> 123456 </label>
            </div>

            <div class="d-flex row col-11 mt-2">
              <p class="size">Iban</p>
              <label for="Iban"> IT 00 X 12345 67890 123456789012 </label>
            </div>

            <div class="d-flex row col-11 mt-2">
              <p class="size">Codice swift</p>
              <label for="Iban"> XXXXXX12X00 </label>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-paypal" role="tabpanel" aria-labelledby="pills-paypal-tab">
          <div class="">
            <div class="d-flex row col-11 mt-5">
              <p class="size">Pagamento con </p>
              <a type="button" class="btn yellow ms-3 mb-2" target="_blank" href="https://www.paypal.com/it/home">
                PayPal
              </a>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-pagoPA" role="tabpanel" aria-labelledby="pills-pagoPA-tab">
          <div class="">
            <div class="d-flex row col-11 mt-5">
              <p class="size">Pagamento con </p>
              <a type="button" class="btn ms-3 mb-2" target="_blank" href="https://catania.ingegneri.plugandpay.it/">
                <img src="../../../../../../assets/icons_payment/pagoPA.png" width="80%" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-10 col-md-4 box">
      <p class="m-0">Riepilogo</p>
      <span> _____ </span>
      <p class="mt-4 m-0">Corso</p>
      <p class="color">{{titleCourse}}</p>
      <p class="color">
        <img src="../../../../../../assets/icons_payment/duration.svg" alt="">
        {{duration}} ore
      </p>
      <p class="color">
        <img src="../../../../../../assets/icons_payment/cfp.svg" alt="">
        {{cfp}} CFP
      </p>
      <p class="color">
        <img src="../../../../../../assets/icons_payment/data.svg" alt="">
        {{date | date:"dd-MM-YYYY"}}
      </p>

      <div class="d-flex justify-content-center altezza">
        <button type="button" class="btn payment" (click)=" subscribe()">
          CONFERMA PAGAMENTO
        </button>
      </div>
    </div>

  </div>

  <hr class="blu_line mt-5">

  <div class="d-flex left">
    <div class="">
      <button type="button" class="btn grey" (click)="goTo()">
        ANNULLA </button>
    </div>
  </div>
</div>