<form nz-form [nzLayout]="'vertical'" [formGroup]="form" (ngSubmit)="submitForm()">
  <section nz-row [nzGutter]="24">
    <article nz-col [nzSpan]="24">
      <nz-form-item>
        <nz-form-label>Lista</nz-form-label>
        <nz-form-control nzErrorTip="La lista è obbligatoria">
          <nz-select formControlName="list_id" nzPlaceHolder="Scegli..." (ngModelChange)="changeList($event)">
            <nz-option *ngFor="let list of lists" [nzValue]="list.id"
              [nzLabel]="list.name + ' (' + list.countmembers + (list.countmembers == 0 || list.countmembers > 1 ? ' contatti)' : ' contatto)')"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </article>
  </section>

  <section nz-row [nzGutter]="24">
    <article nz-col [nzXs]="24" [nzXl]="12">
      <nz-form-item>
        <nz-form-label>E-mail da aggiungere</nz-form-label>
        <nz-form-control nzErrorTip="La lista è obbligatoria">
          <textarea formControlName="emailToAdd" nz-input rows="4"
            placeholder="Separare gli indirizzi e-mail con punto e virgola (;)"></textarea>
        </nz-form-control>
      </nz-form-item>
    </article>

    <article nz-col [nzXs]="24" [nzXl]="12">
      <nz-form-item>
        <nz-form-label>E-mail da rimuovere</nz-form-label>
        <nz-form-control nzValidatingTip="Separare gli indirizzi e-mail con punto e virgola (;)">
          <textarea formControlName="emailToRemove" nz-input rows="4"
            placeholder="Separare gli indirizzi e-mail con punto e virgola (;)"></textarea>
        </nz-form-control>
      </nz-form-item>
    </article>
  </section>

  <section class="d-flex flex-row justify-content-end">
    <nz-form-item>
      <nz-form-control>
        <button class="btn blu" nz-button nzType="primary">Invia</button>
      </nz-form-control>
    </nz-form-item>
  </section>
</form>

<!-- <nz-table #rowSelectionTable nzShowPagination nzShowSizeChanger [nzData]="listOfData"
  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
  <thead>
    <tr>
      <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" nzLabel="Seleziona tutti"
        (nzCheckedChange)="onAllChecked($event)"></th>
      <th>Nome</th>
      <th>Cognome</th>
      <th>E-mail</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of rowSelectionTable.data">
      <td [nzChecked]="setOfCheckedId.has(data.id)" [nzDisabled]="data.disabled"
        (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
      <td>{{ data.user?.name }}</td>
      <td>{{ data.user?.lastname }}</td>
      <td>{{ data.user?.email }}</td>
    </tr>
  </tbody>
</nz-table> -->