<div class="m-auto mt-5 col-11">
     <div class="title" *ngIf="create; else edit">
          <h2>
               <span (click)="goTo(true)" class="clickable"><i class="fa-solid fa-arrow-left"></i></span> Aggiungi
          </h2>
     </div>
     <ng-template #edit>
          <h2 *ngIf="postmodify">
               <span (click)="goTo(true)" class="clickable"><i class="fa-solid fa-arrow-left"></i></span> Modifica
               <span>{{(displayedTitle.length>50)? (displayedTitle | slice:0:50)+'...':(displayedTitle)}}</span>
          </h2>
     </ng-template>

     <form [formGroup]="formPost">
          <div class="input-group mb-3 d-flex justify-content-between">
               <div class="col">
                    <label for="basic-url" class="form-label">Titolo</label>
                    <p *ngIf="showCourse else titolo" rows="3">
                         {{formPost.get('title')?.value}}</p>
                    <ng-template #titolo>
                         <textarea class="form-control" rows="3" formControlName="title"></textarea>
                    </ng-template>
               </div>
          </div>


          <div class="input-group mb-3 d-flex justify-content-between">
               <div class="col">
                    <label for="basic-url" class="form-label">Descrizione</label>
                    <p *ngIf="showCourse else description" rows="3">
                         {{formPost.get('description')?.value}}</p>
                    <ng-template #description>
                         <textarea class="form-control" rows="3" formControlName="description"></textarea>
                    </ng-template>
               </div>
          </div>


          <div class="input-group mb-3 d-flex justify-content-between">
               <div class="col">
                    <label for="basic-url" class="form-label">Riunioni</label>
                    <p *ngIf="showCourse else plan" rows="3">
                         {{formPost.get('plan')?.value}}</p>
                    <ng-template #plan>
                         <textarea class="form-control" rows="3" formControlName="plan"></textarea>
                    </ng-template>
               </div>
          </div>


          <!--CODICE GESTIONE ALLEGATI FORM ARRAY-->
          <h3>Allegati</h3>
          <form [formGroup]="formPost" *ngIf="attachments.length > 0">
               <div formArrayName="attachments">
                    <div class="attachment-row" *ngFor="let attachment of attachments.controls; let i = index"
                         [formGroupName]="i">
                         <nz-form-item>
                              <nz-form-control>
                                   <section class="attachment-section">
                                        <div class="form-group">
                                             <label for="title-{{i}}">Titolo</label>
                                             <input id="title-{{i}}" type="text" (input)="setItemTitle(i, $event)"
                                                  formControlName="title_file" class="form-control" />
                                        </div>
                                        <div class="form-group">
                                             <label for="description-{{i}}">Descrizione</label>
                                             <input id="description-{{i}}" type="text"
                                                  (input)="setItemDescription(i, $event)"
                                                  formControlName="description_file" class="form-control" />
                                        </div>
                                        <div class="form-group">
                                             <div class="d-flex align-items-center">
                                                  <nz-upload class="upload-control"
                                                       [nzFileList]="attachment.get('fileList')?.value"
                                                       [nzMultiple]="true" [nzBeforeUpload]="getBeforeUploadFn(i)"
                                                       (nzChange)="handleRemove($event, i)">
                                                       <button nz-button>
                                                            <span nz-icon nzType="upload"></span> Seleziona file
                                                       </button>
                                                  </nz-upload>
                                             </div>
                                        </div>
                                        <button type="button" class="btn rosso" (click)="removeAttachment(i)">Rimuovi
                                             allegato</button>
                                   </section>
                              </nz-form-control>
                         </nz-form-item>
                    </div>
               </div>
          </form>
          <button type="button" class="btn blu" (click)="addAttachment()">Aggiungi allegato</button>




          <!--GESTIONE VERBALI-->
          <br>
          <br>
          <h3>Verbali</h3>
          <form [formGroup]="formPost" *ngIf="verbali.length > 0">
               <div formArrayName="verbali">
                    <div class="attachment-row" *ngFor="let attachment of verbali.controls; let i = index"
                         [formGroupName]="i">
                         <nz-form-item>
                              <nz-form-control>
                                   <section class="attachment-section">
                                        <div class="form-group">
                                             <label for="title-{{i}}">Titolo</label>
                                             <input id="title-{{i}}" type="text"
                                                  (input)="setItemTitleVerbali(i, $event)" formControlName="title_file"
                                                  class="form-control" />
                                        </div>
                                        <div class="form-group">
                                             <label for="description-{{i}}">Descrizione</label>
                                             <input id="description-{{i}}" type="text"
                                                  (input)="setItemDescriptionVerbali(i, $event)"
                                                  formControlName="description_file" class="form-control" />
                                        </div>
                                        <div class="form-group">
                                             <div class="d-flex align-items-center">
                                                  <nz-upload class="upload-control"
                                                       [nzFileList]="attachment.get('fileList')?.value"
                                                       [nzMultiple]="true"
                                                       [nzBeforeUpload]="getBeforeUploadFnVerbali(i)"
                                                       (nzChange)="handleRemoveVerbali($event, i)">
                                                       <button nz-button>
                                                            <span nz-icon nzType="upload"></span> Seleziona file
                                                       </button>
                                                  </nz-upload>
                                             </div>
                                        </div>
                                        <button type="button" class="btn rosso"
                                             (click)="removeAttachmentVerbali(i)">Rimuovi
                                             Verbale</button>
                                   </section>
                              </nz-form-control>
                         </nz-form-item>
                    </div>
               </div>
          </form>
          <button type="button" class="btn blu" (click)="addAttachmentVerbali()">Aggiungi Verbale</button>







          <!--PARTE FINALE DELLA MODALE CON INDIETRO E SALVA-->
          <hr>
          <div class="d-flex justify-content-between right">
               <div>
                    <button *ngIf="showCourse; else annulla" type="button" class="btn grey space" (click)="goTo(true)">
                         INDIETRO</button>
                    <ng-template #annulla>
                         <button type="button" class="btn grey space" (click)="goTo(true)"> INDIETRO</button>
                         <button type="button" class="btn blu " (click)="createPost()"
                              *ngIf="create; else modify">AGGIUNGI</button>
                         <!--MODALE DELLA  MODIFICA-->
                         <ng-template #modify>
                              <button type="button" class="btn blu " (click)="updatePost()">MODIFICA</button>
                         </ng-template>
                    </ng-template>
               </div>
          </div>
     </form>
</div>