<div class="m-auto mt-5 col-10">
    <div class="row justify-content-between mb-4">
        <h2 class="col-auto">Certificati</h2>
        <button class="btn blu col-auto" matTooltip="Richiedi un nuovo certificato" (click)="isVisibleCreate = true"
            *ngIf="current_user.value.role_id === 2">
            RICHIEDI UN NUOVO CERTIFICATO
        </button>


    </div>

    <!--EXPORT EXCEL-->
    <div class="row mt-2">
        <div class="col-12 d-flex">
            <div class="col-md-4">
                <button (click)="exportExcel()" type="button" class="btn blu btn-block-sm w-100"
                    [disabled]="(data|async)?.length== 0">EXPORT LISTA CERTIFICATI</button>
            </div>
            <div class="col-md-6 d-flex justify-content-evenly">

                <button class="btn blu col-auto" matTooltip="Storico certificati" (click)="getData(true)"
                    *ngIf="current_user.value.role_id === 1">
                    STORICO CERTIFICATI
                </button>
                <button class="btn blu col-auto" matTooltip="Certificati da caricare" (click)="getData(false)"
                    *ngIf="current_user.value.role_id === 1 && this.labelStatus==true">
                    CERTIFICATI DA CARICARE
                </button>
            </div>

        </div>


        <div class="mt-4">
            <table mat-table class="box_super mt-4 w-100" [dataSource]="(data | async)">
                <ng-container matColumnDef="type">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga">
                        Tipologia </th>
                    <td mat-cell *matCellDef="let element" class="py-4">
                        {{element.type}}
                    </td>
                </ng-container>

                <ng-container *ngIf="current_user.value.role_id === 1" matColumnDef="applicant">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Richiedente </th>
                    <td mat-cell *matCellDef="let element" class="py-4">
                        {{element.applicant}}
                    </td>
                </ng-container>

                <ng-container *ngIf="current_user.value.role_id === 1" matColumnDef="registrationNumber">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Matricola </th>
                    <td mat-cell *matCellDef="let element" class="py-4">
                        {{element.registrationNumber}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga">
                        Data e ora </th>
                    <td mat-cell *matCellDef="let element" class="py-4">
                        {{formatDate(element.date)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Stato </th>
                    <td mat-cell *matCellDef="let element" class="py-4">
                        <i [ngClass]="(element.status === 'Disponibile al download')?('fa-solid fa-check'):('fa-regular fa-clock')"
                            [ngStyle]="(element.status === 'Disponibile al download')?({'color':'green'}):({'color':'orange'})"></i>
                        {{element.status}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga large"> Azione </th>
                    <td mat-cell *matCellDef="let element" class="py-4 large">
                        <nz-upload *ngIf="current_user.value.role_id === 1" class="col-12 col-xl-auto"
                            [(nzFileList)]="fileList" [nzMultiple]="false" [nzBeforeUpload]="beforeUpload"
                            [nzShowUploadList]="false" (click)="idToUpload = element.id"
                            [nzAccept]="'.doc,.docx,.pdf,.png,.jpeg'">
                            <button nz-button class="btn blu mb-2">
                                <!-- <span nz-icon nzType="upload"></span> -->
                                CARICA CERTIFICATO
                            </button>
                        </nz-upload>
                        <br>
                        <button *ngIf="current_user.value.role_id <= 2 && element.status === 'Disponibile al download'"
                            nz-button class="btn blu" (click)="downloadFile(element.id)">SCARICA
                            CERTIFICATO</button>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="pageEvent = handlePage($event)" showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>

    <nz-modal [(nzVisible)]="isVisibleCreate" (nzOnCancel)="handleCancelCreate()" (nzOnOk)="onSaveModalCreate()"
        [nzStyle]="{ width: '50%' }" nzCentered>
        <ng-container *nzModalTitle>
            <h4 class="blue-title" style="margin-bottom: 0px;">Richiedi il tuo certificato</h4>
        </ng-container>
        <ng-container *nzModalContent>
            <form class="col-12 mt-2 mb-2 row" [formGroup]="modalForm">
                <div class="col-12 my-2">
                    <label for="type" class="mb-2 blue-title">
                        Tipologia di certificato:
                    </label><br>
                    <nz-select class="w-100" id="type" [formControlName]="'type'"
                        nzPlaceHolder="Seleziona la tipologia di certificato">
                        <nz-option *ngFor="let certificate_type of typeList" [nzValue]="certificate_type"
                            [nzLabel]="certificate_type"></nz-option>
                    </nz-select>
                    <div *ngIf="modalForm.get('type')?.hasError('required') && modalForm.get('type')?.touched"
                        class="text-danger">
                        La tipologia di certificato è richiesta
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-container *nzModalFooter>
            <button nz-button class="btn blu me-2" (click)="onSaveModalCreate()">INVIA RICHIESTA</button>
        </ng-container>
    </nz-modal>