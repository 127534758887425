<div class="m-auto col-11 mt-5">
    <div class="title">
        <h2>Eventi</h2>
    </div>
    <!--FILTER-->
    <div [formGroup]="filters">
        <div class="row mt-2 mb-3">
            <div class="col-12 col-md-4">
                <label class="mb-1" for="search">Ricerca</label>
                <nz-input-group>
                    <input nz-input placeholder="Cerca per titolo..." formControlName="search" />
                </nz-input-group>
            </div>
            <div class="col-12 col-md-3">
                <label class="mb-1" for="start_date">Data di inizio</label>
                <nz-date-picker class="w-100" nzFormat="dd-MM-yyyy" formControlName="start_date"></nz-date-picker>
            </div>
            <div class="col-12 col-md-3">
                <label class="mb-1" for="end_date">Data di fine</label>
                <nz-date-picker class="w-100" nzFormat="dd-MM-yyyy" formControlName="end_date"></nz-date-picker>
            </div>
            <div class="mt-4 col-12 col-md-2">
                <button type="button" (click)="resetFilter()" class="btn blu btn-block-md w-100">Reset</button>
            </div>
        </div>
    </div>

    <!--COUNTER-->
    <div *ngIf="client == 1 || client == 4">
        <p>
            <strong class="status" [ngClass]="{'selected' : status == 'publish' }" style="cursor: pointer;"
                (click)="status = 'publish'; getData()">Pubblicati <span>({{totalPost != null ? totalPost.publish :
                    0}})</span></strong> |
            <strong class="status" [ngClass]="{'selected' : status == 'draft' }" style="cursor: pointer;"
                (click)="status = 'draft'; getData()">Bozze <span>({{totalPost != null ? totalPost.draft : 0}})</span>
            </strong> |
            <strong class="status" [ngClass]="{'selected' : status == 'trash' }" style="cursor: pointer;"
                (click)="status = 'trash'; getData()">Cestino <span>({{totalPost != null ? totalPost.trash :
                    0}})</span></strong>
        </p>
    </div>

    <!--ADD-->
    <div class="row">
        <div *ngIf="client == 1 || client == 4" class="col-12 col-md-2">
            <button (click)="addEvent()" type="button" class="btn blu btn-block-sm w-100">AGGIUNGI</button>
        </div>
        <div *ngIf="selectedEvent.length > 0 && client == 1 && status != 'trash'" class="col-12 col-md-2 margin-sm-1">
            <button type="button" class="btn rosso btn-block-sm w-100" data-bs-toggle="modal"
                data-bs-target="#cestino">ELIMINA</button>
        </div>
        <div *ngIf="selectedEvent.length > 0 && client == 1 && status == 'trash'" class="col-12 col-md-3 margin-sm-1">
            <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
                class="btn rosso btn-block-sm w-100">Elimina
                Definitivamente</button>
        </div>
        <div class="col-12 col-md-3">
            <button (click)="exportExcel()" type="button" class="btn blu btn-block-sm w-100 margin-sm-1"
                [disabled]="listOfData.length == 0">EXPORT LISTA EVENTI</button>
        </div>
        <div *ngIf="status == 'publish'" class="col-12 col-md-3">
            <button (click)="exportIcs()" type="button" class="btn blu btn-block-sm w-100 margin-sm-1"
                [disabled]="listOfData.length == 0">SCARICA CALENDARIO</button>
        </div>
    </div>


    <!-- Modal -->
    <div *ngIf="selectedEvent?.length!=0">
        <div class="modal fade" id="cestino" tabindex="-1" aria-labelledby="cestinoLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content p-4">
                    <div class="modal-body">
                        <p>Sei sicuro di voler eliminare {{selectedEvent?.length}}
                            <span *ngIf="selectedEvent?.length == 1; else multiple">elemento</span>
                            <ng-template #multiple>elementi</ng-template>?
                        </p>
                    </div>
                    <div class="d-flex flex-row justify-content-between mb-4">
                        <button class="btn annulla pr-3 me-2" data-bs-dismiss="modal" aria-label="Close"
                            id="closebutton">
                            ANNULLA
                        </button>
                        <button type="button" demon-step-btn="hide" class="btn elimina ms-2" data-bs-dismiss="modal"
                            (click)="deleteEvent()">
                            ELIMINA
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedEvent?.length!=0">
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content p-4">
                    <div class="modal-body">
                        <p>Sei sicuro di voler eliminare definitivamente {{selectedEvent?.length}}
                            <span *ngIf="selectedEvent?.length == 1; else multiple">elemento</span>
                            <ng-template #multiple>elementi</ng-template>?
                        </p>
                    </div>
                    <div class="d-flex flex-row justify-content-between mb-4">
                        <button class="btn annulla pr-3 me-2" data-bs-dismiss="modal" aria-label="Close"
                            id="closebutton">
                            Annulla
                        </button>
                        <button type="button" demon-step-btn="hide" class="btn elimina ms-2" data-bs-dismiss="modal"
                            (click)="deleteEvent()">
                            Elimina Definitivamente
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--DATA-TABLE-->
    <div class="mt-5">
        <div class="table-responsive">
            <nz-table #basicTable [nzData]="listOfData" [nzPageIndex]="pageIndex" [nzPageSize]="pageSize"
                [nzTotal]="total" [nzFrontPagination]="false" (nzPageIndexChange)="onPageIndexChange($event)"
                [nzLoading]="!isTableLoaded">
                <thead>
                    <tr>
                        <th *ngFor="let column of columns">
                            {{ column.name }}
                        </th>
                        <th>Azione</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of basicTable.data">
                        <td *ngFor="let column of columns" [ngStyle]="{'max-width': '150px'}"
                            (click)="handleCellClick(data, column)">
                            <span *ngIf="column.type === 'date'" [ngClass]="{'green':data.subscription_event_date}">
                                {{ data[column.key] | date: 'dd-MM-YYYY HH:mm'}}
                            </span>
                            <span class="d-flex align-items-center"
                                *ngIf="column.type === 'checkbox' && isCheckboxVisible()">
                                <input type="checkbox" [(ngModel)]="data.checked" (change)="checkSelectedEvent()"
                                    [disabled]="data.hasSubscription"
                                    [matTooltip]="data.hasSubscription ? 'Impossibile cancellare l\'evento poiché ha presente degli iscritti' : ''">
                            </span>
                            <span *ngIf="column.type == 'text'" class="truncate-text centered-content"
                                [matTooltip]="getTooltipText(data, column)" [matTooltipPosition]="'below'" [ngClass]="{'clickable-row': (this.client == 1 || this.client == 4 || this.client == 2) && column.key == 'title',
                            'green':data.subscription_event_date}">
                                {{ data[column.key] }}
                            </span>

                            <span *ngIf="data.subscription_event_date && column.type =='date-text'; else notSubscribed">
                                Iscritto il {{ data.subscription_event_date | date: 'dd-MM-YYYY' }},
                                {{ data.subscription_event_date | date: 'HH:mm' }}
                                <br><small *ngIf="data.registrationNumber">Numero di iscrizione all'albo: {{
                                    data.registrationNumber }}</small>
                            </span>
                            <ng-template #notSubscribed>
                                <span *ngIf="column.type =='date-text'">
                                    {{ 'Non iscritto' }}
                                </span>
                            </ng-template>
                        </td>
                        <td>
                            <button *ngIf="isEditButtonVisible()" class="table-button blu"
                                [matTooltip]="getActionButtonTooltip(data)" (click)="infoEvent(data)">
                                <i class="fa-solid fa-pencil"></i>
                            </button>
                            <button *ngIf="isActionButtonVisible()" type="button" class="btn"
                                [disabled]="data.button_disabled"
                                [ngClass]="{'btn-light': isActionButtonLight(data), 'button': !isActionButtonLight(data)}"
                                (click)="handleActionButtonClick(data)">
                                {{ data.button_text }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </div>


</div>