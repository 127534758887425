<form nz-form [nzLayout]="'vertical'" [formGroup]="form">
    <section nz-row [nzGutter]="24" *ngIf="client == '1' || offer_id">
        <article nz-col [nzXs]="24" [nzXl]="8">
            <nz-form-item>
                <nz-form-label>Azienda</nz-form-label>
                <nz-form-control>
                    <!-- <input nz-input formControlName="company_name" placeholder="Azienda" [readonly]="true" />-->
                    <input placeholder='Azienda' nz-input formControlName="company_name" [nzAutocomplete]="auto"
                        [readonly]="offer_id != null" />
                    <nz-autocomplete #auto>
                        <nz-auto-option *ngFor="let company of companies" [nzValue]="company" [nzLabel]="company.name">
                            {{ company.name }}
                        </nz-auto-option>
                    </nz-autocomplete>
                </nz-form-control>
            </nz-form-item>
        </article>

        <article nz-col [nzXs]="24" [nzXl]="8">
            <nz-form-item>
                <nz-form-label>E-mail</nz-form-label>
                <nz-form-control>
                    <input nz-input formControlName="email" placeholder="E-mail" [readonly]="true" />
                </nz-form-control>
            </nz-form-item>
        </article>

        <article nz-col [nzXs]="24" [nzXl]="8">
            <nz-form-item>
                <nz-form-label>Telefono</nz-form-label>
                <nz-form-control>
                    <input nz-input formControlName="phone" placeholder="Telefono" [readonly]="true" />
                </nz-form-control>
            </nz-form-item>
        </article>
    </section>

    <section nz-row [nzGutter]="24">
        <article nz-col [nzXs]="24" [nzXl]="6">
            <nz-form-item>
                <nz-form-label>Sede principale</nz-form-label>
                <nz-form-control>
                    <input nz-input formControlName="address" placeholder="Sede principale"
                        [readonly]="offer_id != null" />
                </nz-form-control>
            </nz-form-item>
        </article>


        <article nz-col [nzXs]="24" [nzXl]="6">
            <nz-form-item>
                <nz-form-label>Tipologia di contratto</nz-form-label>
                <nz-form-control nzErrorTip="La tipologia di contratto è obbligatoria">
                    <nz-select class="w-100" formControlName="contract" nzPlaceHolder="Scegli..."
                        [nzDisabled]="offer_id != null">
                        <nz-option *ngFor="let type of contract_types" [nzValue]="type.id"
                            [nzLabel]="type.name"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </article>

        <article nz-col [nzXs]="24" [nzXl]="6">
            <nz-form-item>
                <nz-form-label>Range retributivo</nz-form-label>
                <nz-form-control>
                    <!-- <input nz-input formControlName="range" placeholder="Range retributivo" [readonly]="offer_id != null" /> -->
                    <nz-select class="w-100" formControlName="range" nzPlaceHolder="Scegli..."
                        [nzDisabled]="offer_id != null">
                        <nz-option *ngFor="let price_range of prices_ranges" [nzValue]="price_range"
                            [nzLabel]="price_range"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </article>

        <article nz-col [nzXs]="24" [nzXl]="6">
            <nz-form-item>
                <nz-form-label>Specializzazione</nz-form-label>
                <nz-form-control nzErrorTip="La specializzazione è obbligatoria">
                    <nz-select class="w-100" formControlName="specialization_id" nzPlaceHolder="Scegli..."
                        [nzDisabled]="offer_id != null">
                        <nz-option *ngFor="let specialization of specializations" [nzValue]="specialization.id"
                            [nzLabel]="specialization.name"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </article>
    </section>

    <nz-form-item>
        <nz-form-label>Skill richieste</nz-form-label>
        <nz-form-control>
            <textarea rows="4" formControlName="skills" nz-input [readonly]="offer_id != null"></textarea>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item>
        <nz-form-label>Esperienza richiesta</nz-form-label>
        <nz-form-control>
            <textarea rows="4" formControlName="experience" nz-input [readonly]="offer_id != null"></textarea>
        </nz-form-control>
    </nz-form-item>

    <section class="d-flex flex-row justify-content-end">
        <nz-form-item *ngIf="!offer_id">
            <nz-form-control>
                <button class="btn blu text-uppercase" nz-button nzType="primary" (click)="submitForm()">Invia</button>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="offer_id && client == '2' && !offer?.exist && offer?.validated == 1">
            <nz-form-control>
                <button class="btn blu text-uppercase" nz-button nzType="primary" (click)="openModal()">Invia la
                    tua candidatura</button>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="offer_id && client == '2' && offer?.exist && offer?.validated == 1">
            <nz-form-control>
                <button class="btn rosso text-uppercase" nz-button (click)="deleteNomination()">Ritira la tua
                    candidatura</button>
            </nz-form-control>
        </nz-form-item>
    </section>
</form>

<section class="mt-5" *ngIf="['1', '5'].includes(client!) && offer_id  && offer?.validated == 1">
    <h2>Candidature</h2>
    <app-nominations-list></app-nominations-list>
</section>

<nz-modal [(nzVisible)]="isVisible" [nzTitle]="'Aggiungi CV specifico per questa offerta'" [nzContent]="modalContent"
    [nzFooter]="modalFooter" nzClosable="false" [nzStyle]="{ width: '50%' }">

    <ng-template #modalContent>
        <div [formGroup]="form">
            <label for="curriculum" class="mb-2 blue-title">Allegati</label>
            <div>
                <nz-upload id="curriculum" class="col-12 col-xl-auto" [nzBeforeUpload]="beforeUpload"
                    (nzChange)="handleRemove($event)" [(nzFileList)]="fileList">
                    <button nz-button>
                        <span nz-icon nzType="upload"></span>
                        <span>Carica allegati</span>
                    </button>
                </nz-upload>
            </div>
            <div *ngIf="form.get('curriculum')?.hasError('required')" class="text-danger">
                Dato che non si è compilato il CV in piattaforma, caricare un CV è obbligatorio in questa fase
            </div>
        </div>
    </ng-template>

    <ng-template #modalFooter>
        <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-secondary" (click)="onCloseModal()">Chiudi</button>
            <button type="button" class="btn btn-primary" (click)="sendNomination()"
                [disabled]="form.invalid">Salva</button>
        </div>
    </ng-template>
</nz-modal>