import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EngineerService {
  route = `${environment.api}`;

  constructor(private http: HttpClient) { }


  importCsv(file: any) {
    const blob: Blob = file.file;
    const newFile = new File([blob], file.name);
    const formData: FormData = new FormData();
    formData.append('csv_file', newFile, file.name);
    return this.http.post(this.route + '/import-ordine', formData);
  }

  getStaticData(): Observable<any> {
    return this.http.get<any>(`${this.route}/static-user-data`);
  }

  setStaticData(data?: any): Observable<any> {
    return this.http.put<any>(`${this.route}/static-user-data`, data);
  }

  applyStaticData(data?: any): Observable<any> {
    return this.http.post<any>(`${this.route}/static-user-data`, data);
  }
}
