import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../../services/user.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { CertificationsService } from '../../../services/certifications.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EventService } from '../../../services/event.service';
import { ay } from 'chart.js/dist/chunks/helpers.core';

@Component({
    selector: 'app-certifications-list',
    templateUrl: './certifications-list.component.html',
    styleUrls: ['./certifications-list.component.scss']
})
export class CertificationsListComponent implements OnInit {
    currentPage = 0;
    pageSize = 5;
    data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
    pageEvent: PageEvent | undefined;
    displayedColumns: string[] = ['type', 'date', 'status', 'actions'];
    sort: Sort = { active: '', direction: '' };
    modalForm: FormGroup = new FormGroup({});
    isVisibleCreate: boolean = false;
    typeList: Array<string> = ['Certificato di Iscrizione'];
    current_user: BehaviorSubject<any> = new BehaviorSubject<any>({});
    updateIds: Array<string> = [];
    fileList: NzUploadFile[] = [
    ];
    uploading: boolean = false;
    idToUpload: string = '';
    labelStatus: boolean | any;

    constructor(
        private _certifications: CertificationsService,
        private _eventService: EventService,
        private fb: FormBuilder,
        private _user: UserService,
        private http: HttpClient
    ) { }

    ngOnInit(): void {
        this.getData();
        this.createForm();
        this._user.me().subscribe((value) => {
            this.current_user.next(value.data);
            if (value.data.role_id === 1)
                this.displayedColumns.splice(1, 0, 'registrationNumber', 'applicant');
        });
    }

    getData(status: boolean = false) {
        this.labelStatus = status;
        let params = { skip: this.currentPage * this.pageSize, take: this.pageSize, ...this.sort, storic: status };
        this._certifications.getList(params).subscribe((response: any) => {
            let data = response.data;
            console.log('data', data);

            data.forEach((element: any) => {
                element['applicant'] = element.user.name + ' ' + element.user.lastname;
                element['registrationNumber'] = element.user.registrationNumber;
            });
            this.data.next(data);
            this.records.next(response.total);
        });
    }


    handlePage(e: any) {
        this.currentPage = e.pageIndex;
        this.pageSize = e.pageSize;
        this.getData();
        return e;
    }

    onSaveModalCreate() {
        let form = this.modalForm.value;
        form['date'] = moment().format('YYYY-MM-DD HH:mm:ss');
        form['user_id'] = this.current_user.value.id;

        let body = 'L\'ingegnere ' + this.current_user.value.name + ' ' + this.current_user.value.lastname + ', con matricola: ' + this.current_user.value.registrationNumber + ', ha richiesto un certificato di tipo: ' + form.type;
        let mail = {
            'subject': 'Una nuova richiesta di caricamento certificazione è stata inoltrata',
            'body': body,
            'campaignID': '',
            'attachmentPaths': [],
            'user_id': form['user_id'],
            'approved': true
        }
        form['mail'] = mail;

        this._certifications.requestCertification(form).subscribe({
            next: (response: any) => {
                this.getData();
                this.handleCancelCreate();

                Swal.fire({
                    icon: 'success',
                    title: '<div style="font-size:18px;">' + response.message + '</div>',
                    showConfirmButton: false,
                    timer: 3000
                });
            },
            error: (error: any) => {
                this.getData();
                this.handleCancelCreate();
                Swal.fire({
                    icon: 'error',
                    title: '<div style="font-size:18px;">' + error + '</div>',
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        });
    }

    handleCancelCreate(): void {
        this.isVisibleCreate = false;
        this.createForm();
    }


    exportExcel() {
        this._eventService.exportExcelEvent({ type: "certificate" }).subscribe(
            (res: Blob) => {
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const filename = 'Certificati' + '.xlsx';
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
    }


    get dateFields() {
        return this.modalForm.get('date') as FormArray;
    }

    addInputCreate() {
        this.dateFields.push(this.fb.control('', Validators.required));
        this.dateFields.markAsDirty();
    }

    removeInputCreate(index: number) {
        if (index > -1) {
            this.dateFields.controls.splice(index, 1);
        }
        this.dateFields.markAsDirty();
    }

    createForm() {
        this.current_user.subscribe((value: any) => {
            if (value.role_id === 1)
                this.modalForm = new FormGroup({
                    file: new FormControl(null, Validators.required)
                });
            else
                this.modalForm = new FormGroup({
                    type: new FormControl(null, Validators.required)
                });
        });
    }

    uploadFile(file: NzUploadFile) {

        let body = 'Buongiorno,<br><br>le comunichiamo che il file che lei ha richiesto è disponibile.<br><br>Cordiali saluti';
        let mail = {
            'subject': 'File disponibile al download in piattaforma',
            'body': body,
            'campaignID': '',
            'attachmentPaths': [],
            'approved': false,
            'user_id': this.current_user.value.id
        };

        this._certifications.uploadFile(this.idToUpload, { file: file, mail: mail }).subscribe({
            next: () => {
                this.getData();
                this.handleCancelCreate();
                Swal.fire({
                    icon: 'success',
                    title: '<div style="font-size:18px;">File caricato con successo</div>',
                    showConfirmButton: false,
                    timer: 3000
                });
            },
            error: (error: any) => {
                Swal.fire({
                    icon: 'error',
                    title: '<div style="font-size:18px;">' + error + '</div>',
                    showConfirmButton: false,
                    timer: 3000
                });
                this.getData();
                this.handleCancelCreate();
            }
        });
    }

    formatDate(date: string) {
        return moment(date).format('DD-MM-YYYY HH:mm');
    }

    beforeUpload = (file: NzUploadFile): boolean => {
        this.uploading = false;
        this.fileList = this.fileList.concat(file);
        this.handleUpload();
        return false;
    };

    handleUpload(): void {
        let data: any = { name: null, base64: null };
        const reader = new FileReader();
        let file: any = this.fileList[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
            data.base64 = reader.result;
            data.name = file.name;
            this.uploadFile(data);
        };
        this.uploading = true;
    }

    downloadFile(id: string) {
        this._certifications.download(id).subscribe({
            next: (file: any) => {
                window.open(file.data, '_blank');
            }
        });
    }
}
