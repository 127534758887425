import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conferma-registrazione',
  templateUrl: './conferma-registrazione.component.html',
  styleUrls: ['./conferma-registrazione.component.scss']
})
export class ConfermaRegistrazioneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
