import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  constructor(private http: HttpClient) { }
  refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getTemplates(params?: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/templates`, { params });
  }

  getlistsEmails(params?: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/getlistsEmails`, { params });
  }


  getLists(params?: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/lists`, { params });
  }

  addEmailToList(params?: any): Observable<FormattedResponse<any>> {
    return this.http.put<FormattedResponse<any>>(`${environment.api}/lists/${params.list_id}`, { params });
  }

  getNewsletters(params?: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/newsletter`, { params });
  }

  getNewsletterById(id: string): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/newsletter/${id}`);
  }

  sendNewsletter(data: any): Observable<FormattedResponse<any>> {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/send-emails`, data);
  }

  sendEmail(data: any): Observable<FormattedResponse<any>> {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/send-email`, data);
  }
}
