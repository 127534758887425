<div class="m-auto mt-5 col-sm-12 col-md-10">
    <div id="dati-anagrafici" [class.other_user]="(ruolo | async) != 2">
        <div *ngIf="(ruolo | async) == 2" class="alert alert-warning d-inline-block me-3" role="alert">
            <i class="fa-solid fa-circle-info"></i>&nbsp;
            <span>Iscritto in regola</span>
        </div>
        <div *ngIf="editMail == true" class="alert alert-warning d-inline-block me-3" role="alert">
            <i class="fa-solid fa-circle-info"></i>&nbsp;
            <span>Email in attesa di conferma, controlla la tua nuova mail</span>
        </div>
        <div *ngIf="editPec == true" class="alert alert-warning d-inline-block ms-3 me-3" role="alert">
            <i class="fa-solid fa-circle-info"></i>&nbsp;
            <span>Pec in attesa di conferma, controlla la tua nuova pec</span>
        </div>
        <h2 class="mb-4">Dati anagrafici</h2>
    </div>

    <form [formGroup]="userForm" class="col-sm-10 col-md-10 example-form">
        <div class="d-flex justify-content-between">
            <div class="left">
                <p>
                    Immagine <br />
                    Profilo
                </p>
            </div>
            <div class="right col-8">
                <img width="100" src="../../../../../../assets/img/user.jpg" alt="" />
            </div>
        </div>
        <div class="d-flex row col-12">
            <div class="col-sm-6 col-md-4">
                <p class="marginText">Nome Utente</p>
            </div>
            <div class="col-sm-6 col-md-8 spaceInput mb-4 disabled">
                <input type="text" class="text disabled" matInput formControlName="username" readonly />
            </div>
        </div>
        <div class="d-flex row col-12">
            <div class="col-sm-6 col-md-4">
                <div class="d-flex justify-content-between"></div>
                <p class="marginText">Nome</p>
            </div>
            <div class="col-sm-6 col-md-8 spaceInput mb-4 disabled">
                <input type="text" class="text disabled" matInput formControlName="name" readonly />
            </div>
        </div>
        <div class="d-flex row col-12">
            <div class="col-sm-6 col-md-4">
                <p class="marginText">Cognome</p>
            </div>
            <div class="col-sm-6 col-md-8 spaceInput mb-4 disabled">
                <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                <input type="text" class="text disabled" matInput formControlName="lastname" readonly />
                <!-- </mat-form-field> -->
            </div>
        </div>

        <div class="d-flex row col-12">
            <div class="col-sm-4 col-md-4">
                <div class="d-flex justify-content-between"></div>
                <p class="marginText">E-mail</p>
            </div>
            <div class="col-sm-8 col-md-8">
                <div class="row">
                    <div class="col-10">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <input type="email" class="text" matInput formControlName="email" required />
                        </mat-form-field>
                    </div>
                    <div class="col-2" *ngIf="editMail!=true">
                        <button nz-button class="btn blu w-100" (click)="activateUpdate('email')">
                            <i class="fa-solid fa-pen"></i>
                        </button>
                    </div>
                    <div *ngIf="userForm.get('email')?.hasError('required') && userForm.get('email')?.touched"
                        class="text-danger col-12">
                        L'e-mail è obbligatoria
                    </div>
                    <div *ngIf="userForm.get('email')?.hasError('email') && userForm.get('email')?.touched"
                        class="text-danger col-12">
                        L'e-mail non è in un formato valido
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="(ruolo | async) != 3">
            <!--? Data di nascita -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Data di nascita</p>
                </div>
                <div class="col-sm-6 col-md-8 spaceInput mb-4 disabled">
                    <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                    <input type="date" class="text disabled" matInput formControlName="birthDate" readonly />
                    <!-- </mat-form-field> -->
                </div>
            </div>

            <!--? Luogo di nascita -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Luogo di nascita</p>
                </div>
                <div class="col-sm-6 col-md-8 spaceInput mb-4 disabled">
                    <!-- <mat-form-field class="example-full-width disabled" appearance="fill"> -->
                    <input type="text" class="text disabled" matInput formControlName="birthPlace" readonly />
                    <!-- </mat-form-field> -->
                </div>
            </div>

            <!--? Codice Fiscale  -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Codice Fiscale</p>
                </div>
                <div class="col-sm-6 col-md-8 spaceInput mb-4">
                    <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                    <input type="text" class="text" matInput formControlName="fiscalCode" required />
                    <!-- </mat-form-field> -->
                </div>
            </div>

            <!--? PEC  -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">PEC</p>
                </div>
                <div class="col-sm-6 col-md-8">
                    <div class="row">
                        <div class="col-10">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <input type="email" class="text" matInput formControlName="pec" required />
                            </mat-form-field>
                        </div>
                        <div class="col-2" *ngIf="editPec!=true">
                            <button nz-button class="btn blu w-100" (click)="activateUpdate('pec')">
                                <i class="fa-solid fa-pen"></i>
                            </button>
                        </div>
                        <div *ngIf="userForm.get('pec')?.hasError('required') && userForm.get('pec')?.touched"
                            class="text-danger col-12">
                            La PEC è obbligatoria
                        </div>
                        <div *ngIf="userForm.get('pec')?.hasError('email') && userForm.get('pec')?.touched"
                            class="text-danger col-12">
                            La PEC non è in un formato valido
                        </div>
                    </div>
                </div>
            </div>

            <!--? Telefono  -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Numero di telefono</p>
                </div>
                <div class="col-sm-6 col-md-8">
                    <div class="row">
                        <div class="col-10">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <input type="text" class="text" matInput formControlName="phone_residence" />
                            </mat-form-field>
                        </div>
                        <div class="col-2">
                            <button nz-button class="btn blu w-100" (click)="activateUpdate('phone_residence')">
                                <i class="fa-solid fa-pen"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!--? Residenza  -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Residenza</p>
                </div>
                <div class="col-sm-3 col-md-5">
                    <div class="row">
                        <div class="col-9">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <input type="text" class="text" placeholder="Città" matInput
                                    formControlName="city_residence" />
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <button nz-button class="btn blu w-100" (click)="activateUpdate('city_residence')">
                                <i class="fa-solid fa-pen"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <!--                <div class="col-sm-2 col-md-2">-->
                <!--                    <div class="row">-->
                <!--                        <div class="col-6">-->
                <!--                            <mat-form-field class="example-full-width" appearance="fill">-->
                <!--                                <input type="text" class="text" placeholder="Provincia" matInput-->
                <!--                                    formControlName="province" />-->
                <!--                            </mat-form-field>-->
                <!--                        </div>-->
                <!--                        <div class="col-6">-->
                <!--                            <button nz-button class="btn blu w-100" (click)="activateUpdate('province')">-->
                <!--                                <i class="fa-solid fa-pen"></i>-->
                <!--                            </button>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="col-sm-3 col-md-3">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <input type="text" class="text" placeholder="CAP" matInput
                                    formControlName="cap_residence" />
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <button nz-button class="btn blu w-100" (click)="activateUpdate('cap_residence')">
                                <i class="fa-solid fa-pen"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!--? Indirizzo  -->
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <p class="marginText">Indirizzo</p>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col-10">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <input type="text" class="text" placeholder="Via Roma" matInput
                                    formControlName="street_residence" />
                            </mat-form-field>
                        </div>
                        <div class="col-2">
                            <button nz-button class="btn blu w-100" (click)="activateUpdate('street_residence')">
                                <i class="fa-solid fa-pen"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div *ngIf="(ruolo | async) != 3">
                <h2 class="my-4">Dati professionali</h2>
                <!--? Numero di iscrizione  -->
                <div class="d-flex row col-12">
                    <div class="col-sm-6 col-md-4">
                        <p class="marginText">Numero di iscrizione albo</p>
                    </div>
                    <div class="col-sm-6 col-md-8 spaceInput mb-4">
                        <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                        <input type="text" class="text" matInput formControlName="registrationNumber" required />
                        <!-- </mat-form-field> -->
                    </div>
                </div>

                <!--? Data registrazione albo  -->
                <div class="d-flex row col-12">
                    <div class="col-sm-6 col-md-4">
                        <p class="marginText">Data registrazione albo</p>
                    </div>
                    <div class="col-sm-6 col-md-8 spaceInput mb-4 disabled">
                        <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                        <input type="date" class="text disabled" formControlName="rollRegistrationDate" matInput
                            readonly />
                        <!-- </mat-form-field> -->
                    </div>
                </div>

                <!--? Posizione  -->
                <div class="d-flex row col-12">
                    <div class="col-sm-6 col-md-4">
                        <p class="marginText">Posizione</p>
                    </div>
                    <div class="col-sm-6 col-md-8 spaceInput mb-4 disabled">
                        <input type="text" class="text disabled" matInput formControlName="position" readonly />
                    </div>
                </div>

                <!--? Settore  -->
                <div class="d-flex row col-12">
                    <div class="col-sm-6 col-md-4">
                        <p class="marginText">Settore</p>
                    </div>
                    <div class="col-sm-6 col-md-8 spaceInput mb-4 disabled">
                        <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                        <input type="text" class="text disabled" matInput formControlName="sector" readonly />
                        <!-- </mat-form-field> -->
                    </div>
                </div>

                <!--? Attuale occupazione  -->
                <div class="d-flex row col-12">
                    <div class="col-sm-6 col-md-4">
                        <p class="marginText">Attuale occupazione</p>
                    </div>
                    <div class="col-sm-6 col-md-8 mt-3 mb-3">
                        <select class="form-select" formControlName="categoria">
                            <option *ngFor="let lavoro of lavori" value="{{ lavoro.label }}">
                                {{ lavoro.label }}
                            </option>
                        </select>
                    </div>
                </div>

                <!--? Titolo di studio  -->
                <div class="d-flex row col-12">
                    <div class="col-sm-6 col-md-4">
                        <p class="marginText">Titolo di studio</p>
                    </div>
                    <div class="col-sm-6 col-md-8 spaceInput mb-4 disabled">
                        <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                        <input type="text" class="text disabled" matInput formControlName="studyTitle" readonly />
                        <!-- </mat-form-field> -->
                    </div>
                </div>

                <!--? Specialization  -->
                <div class="d-flex row col-12">
                    <div class="col-sm-6 col-md-4">
                        <p class="marginText">Specializzazione</p>
                    </div>
                    <div class="col-sm-6 col-md-8 spaceInput mb-4 disabled">
                        <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                        <input type="text" class="text disabled" matInput formControlName="specialization" readonly />
                        <!-- </mat-form-field> -->
                    </div>
                </div>

                <!--? Data di laurea  -->
                <div class="d-flex row col-12">
                    <div class="col-sm-6 col-md-4">
                        <p class="marginText">Data di laurea</p>
                    </div>
                    <div class="col-sm-6 col-md-8 spaceInput mb-4 disabled">
                        <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                        <input type="date" class="text disabled" formControlName="degreed_at" matInput readonly />
                        <!-- </mat-form-field> -->
                    </div>
                </div>
            </div>

            <h3 class="my-5">Informazioni aggiuntive</h3>
            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <div class="d-flex justify-content-between"></div>
                    <p class="marginText">Sito web</p>
                </div>
                <div class="col-sm-6 col-md-8 spaceInput mb-4">
                    <input type="text" class="text" matInput formControlName="web_site" />
                </div>
            </div>

            <div class="d-flex row col-12">
                <div class="col-sm-6 col-md-4">
                    <div class="d-flex justify-content-between"></div>
                    <p class="marginText">Bio</p>
                </div>
                <div class="col-sm-6 col-md-8">
                    <textarea class="form-control text" formControlName="bio" rows="8"></textarea>
                </div>
            </div>

            <div class="d-flex row col-12 mt-2" *ngIf="(ruolo | async) == 2">
                <div class="col-sm-6 col-md-4">
                    <div class="d-flex justify-content-between"></div>
                    <p class="marginText">Consenso alla ricezione delle e-mail di notifica da parte della Fondazione
                    </p>
                </div>
                <div class="col-sm-6 col-md-8 d-flex align-items-center">
                    <nz-switch formControlName="notification_consent"></nz-switch>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end mt-5">
            <button type="button" class="btn aggiorna" (click)="updateUser()" [disabled]="!userForm.valid">
                AGGIORNA PROFILO
            </button>
        </div>
    </form>
</div>