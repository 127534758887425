<div class="m-auto mt-5 col-11">
  <h2 class="mb-4">
    <span (click)="goTo('app/admin/engineers_list')" class="clickable">
      Lista ingegneri</span> /
    <span *ngIf="id">Anagrafica</span>
    <span *ngIf="!id">Nuova anagrafica</span>
  </h2>

  <div class="row mb-4" *ngIf="!id">
    <div class="col-12 col-md-3">
      <button (click)="getStaticData()" type="button" class="btn blu btn-block-sm w-100">APPLICA DATI STATICI</button>
    </div>
  </div>

  <div class="mb-4" *ngIf="id">
    <div>
      <span class="fw-bold me-1">Nome:</span>{{get('name')}} {{get('lastname')}}
      <div *ngIf="get('sospeso')" class="alert alert-warning d-inline-block m-0 p-1" role="alert">
        <i class="fa-solid fa-circle-info me-2"></i>
        <span>Sospeso</span>
      </div>
    </div>

    <div>
      <span class="fw-bold me-1">Posizione:</span>
      <span *ngIf="get('sector') else notDefined"> {{get('sector')}} </span> -
      <span *ngIf="get('registrationNumber') else notDefined"> {{get('registrationNumber')}} </span>
    </div>

    <div>
      <span class="fw-bold me-1">N. protocollo:</span>
      <span *ngIf="get('n_prot') else notDefined">{{get('n_prot')}}</span>
    </div>
    <div class="d-flex align-items-center mt-2">
      <button class="btn blu ms-2" matTooltip="Scarica Pdf" (click)="printPdf(id)"><i
          class="fa-regular fa-file-pdf"></i> PDF</button>
      <button *ngIf="id" class="table-button blu myspace" matTooltip="Scarica Doc" (click)="printDoc(id)">
        <i class="fa-solid fa-file-arrow-down"></i>
      </button>
    </div>
  </div>


  <nz-tabset nzType="card">
    <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab">
      <div [ngSwitch]="tab" [formGroup]="form" class="p-2">

        <div *ngSwitchCase="'Dati anagrafici'">

          <!-- DATI ANAGRAFICI -->
          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'lastname', label: 'Cognome'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'name', label: 'Nome'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'fiscalCode', label: 'Codice fiscale'}"></ng-container>
            <ng-container [ngTemplateOutlet]="radioField"
              [ngTemplateOutletContext]="{control: 'gender', label: 'Sesso', options: genders}"></ng-container>
          </div>

          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'birthPlace', label: 'Luogo di nascita'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'birthProvince', label: 'Provincia di nascita'}"></ng-container>
            <ng-container [ngTemplateOutlet]="dateField"
              [ngTemplateOutletContext]="{control: 'birthDate', label: 'Data di nascita'}"></ng-container>
          </div>

          <div class="row">
            <ng-container [ngTemplateOutlet]="selectField"
              [ngTemplateOutletContext]="{control: 'categoria', label: 'Categoria', options: jobs}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'attività', label: 'Attività'}"></ng-container>
          </div>

          <!-- SEZIONI ALBO  -->
          <nz-divider nzText="Sezioni albo" nzOrientation="left"></nz-divider>
          <div class="row">
            <ng-container [ngTemplateOutlet]="dateField" [ngTemplateOutletContext]="{control: 'deliberation', label: 'Delibera sezioni', class: 'col-4 col-lg-3'}"></ng-container>
            <ng-container [ngTemplateOutlet]="numberField" [ngTemplateOutletContext]="{control: 'registrationNumber', label: 'Posizione', class: 'col-4 col-lg-3'}"></ng-container>
            <ng-container [ngTemplateOutlet]="numberField" [ngTemplateOutletContext]="{control: 'n_prot', label: 'N. protocollo', class: 'col-4 col-lg-3'}"></ng-container>
            <ng-container [ngTemplateOutlet]="radioField" [ngTemplateOutletContext]="{control: 'nuovo_ordinamento', label: 'Nuovo ordinamento', options: general_options, class: 'col-4 col-lg-3'}"></ng-container>

            <ng-container [ngTemplateOutlet]="radioField"
              [ngTemplateOutletContext]="{control: 'sector', label: 'Sezione', options: sector_options, class: 'col-2 col-lg-1'}"></ng-container>

            <div class="col-6 col-lg-3 mt-4" *ngIf="get('sector')">
              <div *ngFor="let option of subSector()">
                <ng-container [ngTemplateOutlet]="checkboxField"
                  [ngTemplateOutletContext]="{control: option.control, label: option.label}"></ng-container>
              </div>
            </div>
          </div>

          <!-- LAUREA  -->
          <nz-divider nzText="Laurea" nzOrientation="left"></nz-divider>
          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'degree_place', label: 'Luogo'}"></ng-container>
            <ng-container [ngTemplateOutlet]="dateField"
              [ngTemplateOutletContext]="{control: 'degreed_at', label: 'Data'}"></ng-container>

            <div class="col-12 col-sm-6 mb-3" [formGroup]="form">
              <label for="specialization" class="form-label"> Specializzazione
                <span matTooltip="Seleziona una tra le opzioni presenti, oppure inseriscine una nuova">
                  <i class="fa-solid fa-circle-info me-2 clickable"></i>
                </span>
                <span *ngIf="isRequiredField('specialization')" class="text-danger">*</span>
              </label>

              <nz-select class="w-100 form-control p-0" nzBorderless nzMode="tags" formControlName="specialization"
                id="specialization" nzShowSearch [nzMaxMultipleCount]="1"
                [ngClass]="form.get('specialization')?.invalid && form.get('specialization')?.touched ? 'invalid-input' : ''">
                <nz-option *ngFor="let option of specializations" [nzLabel]="option.name"
                  [nzValue]="option.id"></nz-option>
              </nz-select>

              <ng-container [ngTemplateOutlet]="required"
                [ngTemplateOutletContext]="{control: form.get('specialization')}"></ng-container>
            </div>
          </div>

          <!-- ESAME DI STATO E ORDINE -->
          <nz-divider nzText="Esame di Stato e Ordine" nzOrientation="left"></nz-divider>
          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'exam_place_state', label: 'Luogo'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'session_examination_state', label: 'Sessione'}"></ng-container>
            <ng-container [ngTemplateOutlet]="numberField"
              [ngTemplateOutletContext]="{control: 'exam_year', label: 'Anno', range: {min: 1900, max: currentYear}}"></ng-container>
            <ng-container [ngTemplateOutlet]="radioField"
              [ngTemplateOutletContext]="{control: 'exam_law', label: 'Per legge', options: general_options}"></ng-container>
            <ng-container [ngTemplateOutlet]="dateField"
              [ngTemplateOutletContext]="{control: 'previous_subscription', label: 'Iscrizione precedente'}"></ng-container>
            <ng-container [ngTemplateOutlet]="dateField"
              [ngTemplateOutletContext]="{control: 'subscription', label: 'Iscrizione'}"></ng-container>
            <ng-container [ngTemplateOutlet]="numberField"
              [ngTemplateOutletContext]="{control: 'verbal_number', label: 'Numero verbale'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'vecchio_ordine', label: 'Provenienza'}"></ng-container>
            <ng-container [ngTemplateOutlet]="dateField"
              [ngTemplateOutletContext]="{control: 'seniority', label: 'Anzianità'}"></ng-container>
            <ng-container [ngTemplateOutlet]="dateField"
              [ngTemplateOutletContext]="{control: 'cancellation', label: 'Cancellazione'}"></ng-container>
          </div>

          <!-- RESIDENZA  -->
          <nz-divider nzText="Residenza" nzOrientation="left"></nz-divider>
          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'city_residence', label: 'Città'}"></ng-container>
            <ng-container [ngTemplateOutlet]="numberField"
              [ngTemplateOutletContext]="{control: 'cap_residence', label: 'CAP'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'street_residence', label: 'Via'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'phone_residence', label: 'Telefono'}"></ng-container>
          </div>

          <!-- POSTA  -->
          <nz-divider nzText="Posta" nzOrientation="left"></nz-divider>
          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'city_posta', label: 'Città'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'province_posta', label: 'Provincia'}"></ng-container>
            <ng-container [ngTemplateOutlet]="numberField"
              [ngTemplateOutletContext]="{control: 'cap_posta', label: 'CAP'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'street_posta', label: 'Via'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'email', label: 'E-mail'}"></ng-container>
          </div>

          <!-- POSTA BONUS  -->
          <nz-divider nzText="Posta bonus" nzOrientation="left"></nz-divider>
          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'city_posta_bonus', label: 'Città'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'prov_posta_bonus', label: 'Provincia'}"></ng-container>
            <ng-container [ngTemplateOutlet]="numberField"
              [ngTemplateOutletContext]="{control: 'cap_posta_bonus', label: 'CAP'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'street_posta_bonus', label: 'Via'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'mail_posta_bonus', label: 'E-mail'}"></ng-container>
          </div>

          <!-- ALBO  -->
          <nz-divider nzText="Albo" nzOrientation="left"></nz-divider>
          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'city_studio', label: 'Città'}"></ng-container>
            <ng-container [ngTemplateOutlet]="numberField"
              [ngTemplateOutletContext]="{control: 'cap_studio', label: 'CAP'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'street_studio', label: 'Via'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'tel_studio', label: 'Telefono'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'pec_mail', label: 'E-mail'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'pec_studio', label: 'PEC'}"></ng-container>
            <ng-container [ngTemplateOutlet]="dateField"
              [ngTemplateOutletContext]="{control: 'seniority_studio', label: 'Anzianità'}"></ng-container>
          </div>

          <!-- DOMICILIO  -->
          <nz-divider nzText="Domicilio" nzOrientation="left"></nz-divider>
          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'city_domicilio', label: 'Città'}"></ng-container>
            <ng-container [ngTemplateOutlet]="numberField"
              [ngTemplateOutletContext]="{control: 'cap_domicilio', label: 'CAP'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'street_domicilio', label: 'Via'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'tel_domicilio', label: 'Telefono'}"></ng-container>
          </div>
        </div>

        <div *ngSwitchCase="'Informazioni varie'">

          <!-- INFORMAZIONI VARIE -->
          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'web_site', label: 'Sito internet', class: 'col-12 col-md-6'}"></ng-container>
          </div>

          <div class="row">
            <ng-container [ngTemplateOutlet]="textAreaField"
              [ngTemplateOutletContext]="{control: 'note', label: 'Note', class: 'col-12 col-md-6'}"></ng-container>
          </div>

          <div class="row">
            <ng-container [ngTemplateOutlet]="radioField"
              [ngTemplateOutletContext]="{control: 'sospeso', label: 'Sospensioni', options: general_options, class: 'col-1'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textAreaField"
              [ngTemplateOutletContext]="{control: 'sospensioni', class: 'col-12 col-md-5 mt-md-4'}"></ng-container>
          </div>

          <!-- DICHIARAZIONI REQUISITI PROFESSIONALI  -->
          <nz-divider nzText="Dichiarazioni Requisiti Professionali" nzOrientation="left"></nz-divider>
          <div class="row">
            <ng-container [ngTemplateOutlet]="radioField"
              [ngTemplateOutletContext]="{control: 'dichiarazione', label: 'Cert. 7/12/48 N. 818', options: general_options}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'codice_dichiarazione', label: 'Codice'}"></ng-container>
            <ng-container [ngTemplateOutlet]="dateField"
              [ngTemplateOutletContext]="{control: 'data_delibera_494', label: 'Data delibera consiglio', options: general_options}"></ng-container>
          </div>

          <div class="row">
            <ng-container [ngTemplateOutlet]="radioField"
              [ngTemplateOutletContext]="{control: 'elenco_494', label: 'Elenco 494', options: general_options, class:  'col-3'}"></ng-container>

            <div class="col-3">
              <ng-container [ngTemplateOutlet]="checkboxField"
                [ngTemplateOutletContext]="{control: 'due', label: '2 anni'}"></ng-container>
              <ng-container [ngTemplateOutlet]="checkboxField"
                [ngTemplateOutletContext]="{control: 'cinque', label: '5 anni'}"></ng-container>
              <ng-container [ngTemplateOutlet]="checkboxField"
                [ngTemplateOutletContext]="{control: 'dieci', label: '10 anni'}"></ng-container>
            </div>
          </div>

          <!-- PEC  -->
          <nz-divider nzText="PEC" nzOrientation="left"></nz-divider>
          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'pec', label: 'PEC'}"></ng-container>
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'pec_password', label: 'Password', type: pec_password_type, icon: true}"></ng-container>

            <div class="col-2 col-sm-3 d-flex align-items-center">
              <ng-container [ngTemplateOutlet]="checkboxField"
                [ngTemplateOutletContext]="{control: 'pec_ritirata', label: 'Ritirata'}"></ng-container>
            </div>
          </div>

          <div class="row">
            <ng-container [ngTemplateOutlet]="textField"
              [ngTemplateOutletContext]="{control: 'ingpec', label: 'ingpec.eu', options: general_options}"></ng-container>
          </div>
        </div>
      </div>
    </nz-tab>
  </nz-tabset>

  <div class="d-flex justify-content-end">
    <button type="submit" class="btn btn-primary mt-4" (click)="onSubmit()">Salva</button>
  </div>
</div>

<ng-template #textField let-control=control let-label=label let-class=class let-type=type let-icon=icon>
  <div [ngClass]="class ? class : 'col-6 col-sm-3'" class="mb-3" [formGroup]="form">
    <label [for]="control" class="form-label"> {{label}} <span *ngIf="isRequiredField(control)"
        class="text-danger">*</span></label>

    <div class="d-flex">
      <input [type]="type ?? 'text'" class="form-control" [formControlName]="control" [id]="control"
        [ngClass]="{'is-invalid': form.get(control)?.invalid && form.get(control)?.touched, 'radius-right-none': icon }">

      <button *ngIf="control == 'pec_password'" class="btn blu radius-left-none" (click)="showPassword()">
        <i class="fa-regular fa-eye-slash" id="togglePassword"></i>
      </button>
    </div>

    <ng-container [ngTemplateOutlet]="required" [ngTemplateOutletContext]="{control: form.get(control)}"></ng-container>
  </div>
</ng-template>

<ng-template #numberField let-control=control let-label=label let-range=range let-class=class>
  <div [ngClass]="class ? class : 'col-6 col-sm-3'" class="mb-3" [formGroup]="form">
    <label [for]="control" class="form-label"> {{label}} <span *ngIf="isRequiredField(control)"
        class="text-danger">*</span></label>
    <input type="number" class="form-control" [formControlName]="control" [id]="control"
      [min]="range?.min ? range.min : -99999999999999" [max]="range?.max ? range.max : 99999999999999"
      [ngClass]="{'is-invalid': form.get(control)?.invalid && form.get(control)?.touched }">

    <ng-container [ngTemplateOutlet]="required" [ngTemplateOutletContext]="{control: form.get(control)}"></ng-container>
  </div>
</ng-template>

<ng-template #dateField let-control=control let-label=label let-class=class>
  <div [ngClass]="class ? class : 'col-6 col-sm-3'" class="mb-3" [formGroup]="form">
    <label [for]="control" class="form-label"> {{label}} <span *ngIf="isRequiredField(control)"
        class="text-danger">*</span></label>
    <input type="date" class="form-control" [formControlName]="control" [id]="control"
      [ngClass]="{'is-invalid': form.get(control)?.invalid && form.get(control)?.touched }">

    <ng-container [ngTemplateOutlet]="required" [ngTemplateOutletContext]="{control: form.get(control)}"></ng-container>
  </div>
</ng-template>

<ng-template #selectField let-control=control let-label=label let-options=options let-class=class>
  <div [ngClass]="class ? class : 'col-6 col-sm-3'" class="mb-3" [formGroup]="form">
    <label [for]="control" class="form-label"> {{label}} <span *ngIf="isRequiredField(control)"
        class="text-danger">*</span></label>
    <select class="form-select" [formControlName]="control" [id]="control"
      [ngClass]="{'is-invalid': form.get(control)?.invalid && form.get(control)?.touched }">
      <option *ngFor="let option of options" [value]="option.label">{{option.label }}</option>
    </select>

    <ng-container [ngTemplateOutlet]="required" [ngTemplateOutletContext]="{control: form.get(control)}"></ng-container>
  </div>
</ng-template>

<ng-template #radioField let-control=control let-label=label let-options=options let-class=class>
  <div [ngClass]="class ? class : 'col-6 col-sm-3'" class="mb-3" [formGroup]="form">
    <label [for]="control" class="form-label"> {{label}} <span *ngIf="isRequiredField(control)"
        class="text-danger">*</span></label>
    <mat-radio-group [formControlName]="control" class="d-flex flex-column me-5">
      <mat-radio-button *ngFor="let option of options" [value]="option.value">
        <span> {{option.label}} </span>
      </mat-radio-button>
    </mat-radio-group>

    <ng-container [ngTemplateOutlet]="required" [ngTemplateOutletContext]="{control: form.get(control)}"></ng-container>
  </div>
</ng-template>

<ng-template #textAreaField let-control=control let-label=label let-class=class>
  <div [ngClass]="class ? class : 'col-6 col-sm-3'" class="mb-3" [formGroup]="form">
    <label *ngIf="label" [for]="control" class="form-label"> {{label}} <span *ngIf="isRequiredField(control)"
        class="text-danger">*</span></label>
    <div>
      <textarea class="w-100 form-control" [id]="control" [formControlName]="control"></textarea>
    </div>

    <ng-container [ngTemplateOutlet]="required" [ngTemplateOutletContext]="{control: form.get(control)}"></ng-container>
  </div>
</ng-template>

<ng-template #checkboxField let-control=control let-label=label>
  <div [formGroup]="form">
    <input class="form-check-input" type="checkbox" [formControlName]="control" [id]="control">
    <span> {{label}} </span>
  </div>
</ng-template>

<ng-template #required let-control=control>
  <div *ngIf="control?.hasError('required') && control?.touched" class="text-danger">
    Il campo è richiesto.
  </div>

  <div *ngIf="(control?.hasError('max') || control?.hasError('min') || control?.hasError('email')) && control?.touched"
    class="text-danger">
    Campo non valido.
  </div>
</ng-template>

<ng-template #notDefined>
  <span>N.D.</span>
</ng-template>
