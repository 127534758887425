import { Component, EventEmitter, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-mail-details',
  templateUrl: './mail-details.component.html',
  styleUrls: ['./mail-details.component.scss']
})
export class MailDetailsComponent implements OnInit {
  public mailCreated: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(
    private location: Location
  ) { }

  ngOnInit() {
  }

  updateMailCreated(value: any) {
    this.mailCreated.emit(value);
  }

  goBack() {
    this.location.back();
  }
}
