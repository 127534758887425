<div class="table-responsive">
    <nz-table #basicTable [nzData]="listOfData" [nzPageIndex]="pageIndex" [nzPageSize]="pageSize" [nzTotal]="total"
        [nzFrontPagination]="false" (nzPageIndexChange)="changePage($event)" [nzLoading]="!isTableLoaded">
        <thead>
            <tr>
                <th *ngFor="let column of columns">{{ column.name }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of basicTable.data">
                <td *ngFor="let column of columns">
                    <ng-container [ngSwitch]="column.type">
                        <span *ngSwitchCase="'date'">
                            {{ data[column.key] | date: 'dd-MM-YYYY'}}
                        </span>
                        <span *ngSwitchCase="'name'">
                            {{ data.offer.company.name }}
                        </span>
                        <span *ngSwitchCase="'email'">
                            {{ data.offer.company.email }}
                        </span>
                        <span *ngSwitchCase="'phone'">
                            {{ data.offer.company.phone }}
                        </span>
                        <span *ngSwitchCase="'contract'">
                            {{ getContractType(data.offer.contract) }}
                        </span>
                        <span *ngSwitchCase="'specialization'">
                            {{ getSpecialization(data.offer.specialization_id) }}
                        </span>
                        <span *ngSwitchCase="'actions'">
                            <button class="btn blu" [routerLink]="'offers/' + data.offer.id">
                                <span nz-icon nzType="eye" nzTheme="outline"></span>
                            </button>
                        </span>
                        <span *ngSwitchDefault>
                            {{ data[column.key] }}
                        </span>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </nz-table>
</div>