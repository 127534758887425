import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WordpressService } from '../../services/wordpress.service';
import { UserService } from '../../services/user.service';
import Swal from 'sweetalert2';
import { DatePipe, DOCUMENT } from '@angular/common';
import * as ClassicEditor from '@ckeditor/ckeditor5-angular';
import * as moment from 'moment';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { PostsServices } from "../../services/posts.service";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-commissioni-details',
  templateUrl: './commissioni-detail-component.component.html',
  styleUrls: ['./commissioni-detail-component.component.scss']
})
export class CommissioniDetailComponentComponent implements OnInit {

  public editor = ClassicEditor;
  editorContent: string = '';
  user_wp_id: any;
  action: any;
  toDate: any;
  blob: any;
  file: any;
  fileName: any;
  fileUploaded = false;
  errorProcessing = false;
  formPost: FormGroup | any;
  // fileForm: FormGroup | any;
  path: any = this.route.snapshot.params.id;
  id: any;
  url: any;
  categoryId: any;
  tagsArray = new Array();
  tagSelect: any;
  tagSlug = false;
  title: any;
  displayedTitle: any;
  array: any = [];
  singlePost: any;
  create = false;
  restore = false
  postmodify = false;
  postEdit: any;
  sticky = false;
  showCourse = false;
  isDisabled: boolean = false;
  client: any;
  guest: any;
  today = moment().format("YYYY-MM-DD");
  pipe = new DatePipe('en-US');
  fileList: NzUploadFile[] = [
  ];
  fromFondazione: boolean = false;
  fond: boolean = false;
  original_form: any = [];
  changes: any = [];


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _wp: WordpressService,
    private http: HttpClient,
    public user: UserService,
    private datePipe: DatePipe,
    private _posts: PostsServices,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.formPost = this.fb.group({
      id: '',
      title: ['', Validators.required],
      status: ['publish'],
      category_id: '',
      description: ['', Validators.required],
      plan: '',
      attachments: this.fb.array([]),
      verbali: this.fb.array([]),
    });

  }

  ngOnInit(): void {
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
    this.title = this.route.snapshot.params.slug;
    this.displayedTitle = this.title == 'Commissioni';
    this.route.snapshot.params.slug;
    this.categoryId = 666;
    this.formPost.patchValue({ category_id: this.categoryId });
    this.url = this.router.url;
    this.fond = true;
    this.checkCreatePost();
    this.action = localStorage.getItem('action');
    this.getUserId();
  }

  setItemTitle(index: number, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.attachments.at(index).get('title_file')?.setValue(inputElement.value);
  }

  setItemDescription(index: number, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.attachments.at(index).get('description_file')?.setValue(inputElement.value);
  }




  setItemTitleVerbali(index: number, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.verbali.at(index).get('title_file')?.setValue(inputElement.value);
  }

  setItemDescriptionVerbali(index: number, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.verbali.at(index).get('description_file')?.setValue(inputElement.value);
  }



  getUserId() {
    this.user.me().subscribe(element => {
      this.user_wp_id = element.data.id;
    })
  }



  //GESTIONE FORM ARRAY//
  get attachments(): FormArray {
    return this.formPost.get('attachments') as FormArray;
  }

  get verbali(): FormArray {
    return this.formPost.get('verbali') as FormArray;
  }

  addAttachment(): void {
    const attachmentGroup = this.fb.group({
      title_file: ['', Validators.required],
      description_file: ['', Validators.required],
      fileList: [[]],
      file_url: ''
    });
    this.attachments.push(attachmentGroup);
  }

  removeAttachment(index: number): void {
    this.attachments.removeAt(index);
  }


  addAttachmentVerbali(): void {
    const attachmentGroup = this.fb.group({
      title_file: ['', Validators.required],
      description_file: ['', Validators.required],
      fileList: [[]],
      file_url: ''
    });
    this.verbali.push(attachmentGroup);
  }

  removeAttachmentVerbali(index: number): void {
    this.verbali.removeAt(index);
  }

  checkCreatePost() {
    //CODICE PER LA CREAZIONE//
    if (this.url.includes('create'))
      this.create = true;
    //CODICE PER LA MODIFICA//
    else {
      this.create = false;
      this.postmodify = true;
      this.postEdit = true;
      this.id = this.route.snapshot.params.id;
      this.user.showCommissioni({ id: this.id }).subscribe({
        next: response => {
          this.displayedTitle = response.data.name;
          this.formPost.patchValue({
            id: response.data.id,
            title: response.data.name,
            description: response.data.description,
            plan: response.data.calendar
          });
          response.data.files.forEach(async (file: any, index: number) => {
            try {
              const fileList = this.createFileList(file.file_url, file.file_name);

              if (file.verbali == 0) {
                let existingAttachment = this.attachments.controls.find((control: any) =>
                  control.value.title_file === file.name && control.value.description_file === file.description
                );

                if (existingAttachment) {
                  // Aggiungi il file alla lista esistente
                  existingAttachment?.get('fileList')?.value.push(...fileList);
                } else {
                  // Crea un nuovo gruppo di allegati
                  const attachmentGroup = this.fb.group({
                    title_file: [file.name, Validators.required],
                    description_file: [file.description, Validators.required],
                    fileList: [fileList],
                    file_url: file.file_url
                  });
                  this.attachments.push(attachmentGroup);
                }
              } else if (file.verbali == 1) {
                let existingVerbali = this.verbali.controls.find((control: any) =>
                  control.value.title_file === file.name && control.value.description_file === file.description
                );

                if (existingVerbali) {
                  // Aggiungi il file alla lista esistente
                  existingVerbali?.get('fileList')?.value.push(...fileList);
                } else {
                  // Crea un nuovo gruppo di verbali
                  const verbaliGroup = this.fb.group({
                    title_file: [file.name, Validators.required],
                    description_file: [file.description, Validators.required],
                    fileList: [fileList],
                    file_url: file.file_url
                  });
                  this.verbali.push(verbaliGroup);
                }
              }
            } catch (error) {
              console.error("Errore durante il recupero del base64 del file:", error);
            }
          });
        }
      })
    }
  }


  createFileList(file_url: string, file_name: string) {
    return [{
      uid: new Date().getTime(), // Puoi usare un timestamp come UID unico
      name: file_name,
      status: 'done',
      url: file_url
    }];
  }

  // Funzione per convertire un file in base64
  fileToBase64(fileUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
      // Effettua una richiesta HTTP per recuperare il file
      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          // Leggi il blob come dati binari
          const reader = new FileReader();
          reader.onload = () => {
            // Ottieni il base64
            const base64Data = reader.result as string;
            // Estrai solo il base64 dai dati restituiti da FileReader
            const base64 = base64Data.split(',')[1];
            resolve(base64);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
        .catch(reject);
    });
  }



  confirmModal(action: string) {
    if (action == 'create')
      this.createPost();
    else
      this.updatePost();
  }


  viewModal() {
    Swal.fire({
      title: '',
      text: "Le Iscrizioni sono iniziate. Non è più possibile apportare modifiche.",
      icon: 'error',
      showConfirmButton: false,
      timer: 3000,
    })
  }
  createPost() {
    this.user.addCommissioni(this.formPost.value).subscribe({
      next: response => {
        Swal.fire({
          icon: 'success',
          title: '<div style="font-size:18px;">' + "Commissione Salvata con successo" + '</div>',
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          this.goTo()
        });
      }
    })
  }

  updatePost() {
    this.user.putCommissioni(this.formPost.value).subscribe({
      next: response => {
        Swal.fire({
          icon: 'success',
          title: '<div style="font-size:18px;">' + response.message + '</div>',
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          this.goTo()
        });
      },
      error: error => {
        Swal.fire({
          icon: 'error',
          title: '<div style="font-size:18px;">' + error.message + '</div>',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
  }


  goTo(manualRedirect: boolean = false) {
    this.router.navigate([`app/fondazione/commissioni`]);
  }

  getBeforeUploadFn(index: number) {
    return (file: NzUploadFile): boolean => this.beforeUpload(file, index);
  }

  getBeforeUploadFnVerbali(index: number) {
    return (file: NzUploadFile): boolean => this.beforeUploadVerbali(file, index);
  }


  beforeUploadVerbali = (file: NzUploadFile, attachmentIndex: number): boolean => {
    if (file.size != null && file.size > 32000000) {
      Swal.fire({
        icon: 'error',
        title: '<div style="font-size:18px;">La dimensione del file non può superare i 32MB!</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      return false;
    }
    const verbali = this.verbali.at(attachmentIndex);
    const fileListVerbali = verbali.get('fileList')?.value || [];
    verbali.get('fileList')?.setValue(fileListVerbali.concat(file));
    this.handleUploadVerbali(attachmentIndex);
    return false;
  };

  handleUploadVerbali(attachmentIndex: number): void {
    const verbali = this.verbali.at(attachmentIndex);
    const fileListVerbali = verbali.get('fileList')?.value || [];
    let data: any[] = [];
    fileListVerbali.forEach((file: any) => {
      this.uploadLogicVerbali(data, file, attachmentIndex);
    });
  }

  uploadLogicVerbali(data: any[], file: any, attachmentIndex: number): void {
    const reader = new FileReader();
    const blob = new Blob([file]);
    reader.readAsDataURL(blob);
    reader.onload = () => {
      data.push({ name: file.name, base64: reader.result, id: typeof file.uid == 'number' ? file.uid : null });
      if (data.length === this.verbali.at(attachmentIndex).get('fileList')?.value.length) {
        // this.setFileUrlVerbali(attachmentIndex);
        this.verbali.at(attachmentIndex).get('fileList')?.setValue(data);
      }
    };
  }


  //CAPIRE COME GESTIRLO NELLA POSIZIONE GIUSTA DEL FORM ARRAY//
  beforeUpload = (file: NzUploadFile, attachmentIndex: number): boolean => {
    if (file.size != null && file.size > 32000000) {
      Swal.fire({
        icon: 'error',
        title: '<div style="font-size:18px;">La dimensione del file non può superare i 32MB!</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      return false;
    }
    const attachment = this.attachments.at(attachmentIndex);
    const fileList = attachment.get('fileList')?.value || [];
    attachment.get('fileList')?.setValue(fileList.concat(file));
    this.handleUpload(attachmentIndex);
    return false;
  };

  handleUpload(attachmentIndex: number): void {
    const attachment = this.attachments.at(attachmentIndex);
    const fileList = attachment.get('fileList')?.value || [];
    let data: any[] = [];
    fileList.forEach((file: any) => {
      this.uploadLogic(data, file, attachmentIndex);
    });
  }

  uploadLogic(data: any[], file: any, attachmentIndex: number): void {
    const reader = new FileReader();
    const blob = new Blob([file]);
    reader.readAsDataURL(blob);
    reader.onload = () => {
      data.push({ name: file.name, base64: reader.result, id: typeof file.uid == 'number' ? file.uid : null });
      if (data.length === this.attachments.at(attachmentIndex).get('fileList')?.value.length) {
        // this.setFileUrl(attachmentIndex);
        this.attachments.at(attachmentIndex).get('fileList')?.setValue(data);
      }
    };
  }

  openFile(link: string) {
    window.open(link);
  }


  handleRemove(event: any, attachmentIndex: number): void {
    const fileListControl = this.attachments.at(attachmentIndex).get('fileList');
    const fileIndex = (fileListControl?.value as NzUploadFile[]).findIndex(
      (f: NzUploadFile) => f.uid === event.file.uid
    );
    if (fileIndex !== -1) {
      const newFileList = [...fileListControl?.value];
      newFileList.splice(fileIndex, 1);
      fileListControl?.setValue(newFileList);
    }
  }


  handleRemoveVerbali(event: any, attachmentIndex: number): void {
    const fileListControl = this.verbali.at(attachmentIndex).get('fileList');
    const fileIndex = (fileListControl?.value as NzUploadFile[]).findIndex(
      (f: NzUploadFile) => f.uid === event.file.uid
    );
    if (fileIndex !== -1) {
      const newFileList = [...fileListControl?.value];
      newFileList.splice(fileIndex, 1);
      fileListControl?.setValue(newFileList);
    }
  }



  getFormattedDate(date: moment.MomentInput, format: string | undefined) {
    return moment(date).locale('it').format(format);
  }






}


