import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-recupero-psw',
  templateUrl: './recupero-psw.component.html',
  styleUrls: ['./recupero-psw.component.scss']
})
export class RecuperoPswComponent implements OnInit {

  emailform: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService
  ) {
    this.emailform = this.fb.group({
      email: [null, Validators.required]
    })
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    //console.log(this.emailform.value.email);
    if (this.emailform.value.email == null || this.onlySpaces(this.emailform.value.email)) {
      //console.log("errore");
      Swal.fire({
        icon: 'error',
        title: '<div style="font-size:18px;">e-mail non inserita</div>',
        target: '#custom-target',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
      })
    }
    else {
      this.authService.resendEmailConfirmation(this.emailform.value.email).subscribe(res => {
        // //console.log('res', res);
        localStorage.setItem('email', this.emailform.value.email);
        this.router.navigate(['/confirmation-email']);
      })
    }
  }

  onlySpaces(str: any) {
    return /^\s*$/.test(str);
  }
}
