<div class="m-auto center">
    <div class="">
        <h2 class="text-color ">Recupero Password</h2>
        <h4 class="text-color ">Inserisci l'indirizzo e-mail associato al tuo account<br>e ti invieremo
            un link per reimpostare la Password
        </h4>
        <div class="col-12 input-group center mb-3 p-2">
            <form [formGroup]="emailform" class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                    <input class="text" matInput placeholder="E-mail" formControlName="email">
                </mat-form-field>
                <div class="accedi">
                    <button type="submit" class="btn button" (click)="onSubmit();">Invia</button>
                </div>
                <h5 class="text-color ">Non hai ancora un account?</h5>
                <a href="register" class='register'>Registrati</a>
            </form>
        </div>
    </div>
</div>