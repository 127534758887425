import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { AuthRoutingModule } from './auth-routing.module';
import { CompanyRegistrationComponent } from './components/company-registration/company-registration.component';

@NgModule({
  declarations: [
    CompanyRegistrationComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    AuthRoutingModule
  ],

  bootstrap: [AuthModule]
})
export class AuthModule { }
