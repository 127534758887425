<div class="m-auto mt-5 col-10">
    <div class="row justify-content-between mb-4">
        <h2 class="col-auto">Consulenze</h2>
        <button class="btn blu col-auto" matTooltip="Imposta una consulenza" (click)="isVisibleCreate = true"
            *ngIf="current_user.value.role_id === 1">
            IMPOSTA NUOVA CONSULENZA
        </button>
        <button class="btn blu col-auto" matTooltip="Richiedi una nuova consulenza"
            (click)="isVisibleRequest.next(true)" *ngIf="current_user.value.role_id === 2">
            RICHIEDI NUOVA CONSULENZA
        </button>
    </div>




    <div class="mt-4">
        <nz-tabset>
            <nz-tab nzTitle="Consulenze richieste"><app-consulting-requests
                    [isVisibleRequest]="isVisibleRequest"></app-consulting-requests></nz-tab>
            <nz-tab *ngIf="current_user.value.role_id === 1" nzTitle="Consulenze impostate">

                <!--EXPORT EXCEL-->
                <div class="row mt-2">
                    <div class="col-12 col-md-4">
                        <button (click)="exportExcel()" type="button" class="btn blu btn-block-sm w-100"
                            [disabled]="(data|async)?.length== 0">EXPORT LISTA CONSULENZE IMPOSTATE</button>
                    </div>
                </div>

                <table mat-table class="box_super mt-4 w-100" [dataSource]="(data | async)">
                    <ng-container matColumnDef="type">
                        <th mat-header-cell mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga">
                            Tipologia </th>
                        <td mat-cell *matCellDef="let element" class="py-4">
                            {{element.consultant.type}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga">
                            Giorni disponibili </th>
                        <td mat-cell *matCellDef="let element" class="py-4">
                            {{(element.date.length>2) ?
                            (formatDates(element.date | slice:0:2))+' + altri '+(element.date.length-2):
                            (formatDates(element.date))}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="hour_slot">
                        <th mat-header-cell mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Slot
                            orario </th>
                        <td mat-cell *matCellDef="let element" class="py-4">
                            {{(element.hour_slot.length>2) ?
                            (element.hour_slot | slice:0:2)+' + altri '+(element.hour_slot.length-2) :
                            (element.hour_slot)}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="consultant_name">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Consulente </th>
                        <td mat-cell *matCellDef="let element" class="py-4">
                            {{element.consultant.name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga large"> Azione </th>
                        <td mat-cell *matCellDef="let element" class="py-4 large">
                            <button *ngIf="current_user.value.role_id === 1" class="table-button blu"
                                matTooltip="Modifica consulenze" (click)="updateMassive(element)">
                                <i class="fa-solid fa-pen"></i>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                    (page)="pageEvent = handlePage($event)" showFirstLastButtons>
                </mat-paginator>
            </nz-tab>
        </nz-tabset>
    </div>
</div>

<nz-modal [(nzVisible)]="isVisibleCreate || isVisibleUpdate" (nzOnCancel)="handleCancelCreate()" (nzOnOk)="onSave()"
    [nzStyle]="{ width: '75%' }" nzCentered>
    <ng-container *nzModalTitle>
        <h4 class="blue-title" style="margin-bottom: 0px;">Imposta la disponibilità delle consulenze
        </h4>
    </ng-container>
    <ng-container *nzModalContent>
        <form class="col-12 mt-2 mb-2 row" [formGroup]="modalForm">
            <div class="col-6 my-2">
                <label for="type" class="mb-2 blue-title">
                    Tipologia di consulenza:
                </label><br>
                <nz-select class="w-100" id="type" [formControlName]="'type'"
                    nzPlaceHolder="Seleziona la tipologia di consulenza" (ngModelChange)="getConsultantsByType($event)">
                    <nz-option *ngFor="let consulence_type of typeList" [nzValue]="consulence_type"
                        [nzLabel]="consulence_type"></nz-option>
                </nz-select>
                <div *ngIf="modalForm.get('type')?.hasError('required') && modalForm.get('type')?.touched"
                    class="text-danger">
                    La tipologia di consulenza è richiesta
                </div>
            </div>
            <div class="col-6 my-2">
                <label for="consultant_id" class="mb-2 blue-title">
                    Consulente:
                </label><br>
                <nz-select class="w-100" id="consultant_id" [formControlName]="'consultant_id'"
                    nzPlaceHolder="Seleziona il consulente" [nzDisabled]="consultantsList.value.length < 1"
                    (ngModelChange)="setConsultantEmail($event)">
                    <nz-option *ngFor="let consultant of consultantsList?.value" [nzValue]="consultant?.id"
                        [nzLabel]="consultant?.name"></nz-option>
                </nz-select>

                <div *ngIf="modalForm.get('consultant_id')?.hasError('required') && modalForm.get('consultant_id')?.touched"
                    class="text-danger">
                    Il consulente è richiesto
                </div>
            </div>
            <div class="col-6 my-2">
                <label for="consultant_email" class="mb-2 blue-title">
                    Email consulente:
                </label>
                <input nz-input id="consultant_email" type="text" [formControlName]="'consultant_email'"
                    placeholder="Scrivi l'email del consulente" [readOnly]="true" style="cursor:not-allowed" />
            </div>
            <div class="col-6 my-2">
                <label for="hour_slot" class="mb-2 blue-title">
                    Imposta gli orari disponibili:
                </label><br>
                <nz-select class="w-100" nzMode="multiple" nzPlaceHolder="Seleziona gli slot disponibili" id="hour_slot"
                    [formControlName]="'hour_slot'" [nzMaxTagCount]="2">
                    <nz-option *ngFor="let hour of hour_slots" [nzValue]="hour" [nzLabel]="hour"></nz-option>
                </nz-select>
                <div *ngIf="modalForm.get('hour_slot')?.hasError('required') && modalForm.get('hour_slot')?.touched"
                    class="text-danger">
                    Almeno uno slot orario è richiesto
                </div>
            </div>
            <div formArrayName="date" class="col-6">
                <div *ngFor="let date_controller of dateFields.controls; let i = index" class="form-group my-2">
                    <label class="mb-2 blue-title">
                        Imposta i giorni disponibili:
                    </label>
                    <div class="d-flex flex-row">
                        <nz-date-picker class="col-10" [formControlName]="i"
                            placeholder="Seleziona un giorno disponibile" nzFormat="dd-MM-yyyy"></nz-date-picker>
                        <button *ngIf="i > 0" class="btn btn-danger offset-1 col-1" (click)="removeInputCreate(i)">
                            <span nz-icon nzType="delete" nzTheme="outline"></span>
                        </button>
                    </div>
                    <div *ngIf="dateFields.controls[i]?.hasError('required') && dateFields.controls[i]?.touched"
                        class="text-danger">
                        La data è richiesta
                    </div>
                </div>
            </div>
            <div class="col-5 align-self-end my-2">
                <button class="btn blu mt-2" (click)="addInputCreate()">Aggiungi data</button>
            </div>
        </form>
    </ng-container>
    <ng-container *nzModalFooter>
        <button [disabled]="!modalForm.valid" class="btn blu me-2" (click)="onSave()">INVIA RICHIESTA</button>
    </ng-container>
</nz-modal>