import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { EventService } from 'src/app/modules/application/services/event.service';
import { NewsletterService } from 'src/app/modules/application/services/newsletter.service';

@Component({
  selector: 'app-mail-list',
  templateUrl: './mail-list.component.html',
  styleUrls: ['./mail-list.component.scss']
})
export class MailListComponent implements OnInit {
  // length!: number;
  // pageSize: number = 10;
  // pageIndex: number = 0;
  // pageSizeOptions: number[] = [10];
  // showFirstLastButtons: boolean = true;
  // displayTable = false;

  // cols = new Array();
  // colsHead: string[] = ['name', 'list', 'details'];

  public columns: { key: string, name: string, type: string }[] = [
    { key: 'subject', name: 'Oggetto', type: 'string' },
    { key: 'created_at', name: 'Data di invio', type: 'date' },
    { key: 'subscriber_list', name: 'Lista', type: 'subscriber_list' },
    { key: 'counter', name: 'Recapitate / Destinatari', type: 'counter' },
    { key: 'action', name: 'Azioni', type: 'actions' }
  ];
  public listOfData: any[] = [];
  public pageIndex = 1;
  public pageSize = 10;
  public total = 0;
  public role_id: any;
  public isTableLoaded: boolean = false;

  constructor(
    private _newsletter: NewsletterService,
    private _eventService: EventService
  ) { }

  ngOnInit() {
    this.role_id = localStorage.getItem('role');
    this.getData();

    this._newsletter.refresh.subscribe(value => {
      if (value) this.getData();
    })
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getData();
    return event;
  }

  getData() {
    // this.displayTable = false;

    this._newsletter.getNewsletters({ role_id: this.role_id }).subscribe(response => {
      // this.cols = response.data;
      // this.length = response.total!;

      this.listOfData = response.data.map((element: any) => {
        element.counter = element.delivered;
        return element;
      });
      this.total = response.total!;
      this.isTableLoaded = true;
    });
  }

  changePage(event: any) {
    this.pageIndex = event;
    this.getData();
  }

  exportExcel() {
    this._eventService.exportExcelEvent({ type: "newsletter" }).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = 'newsletter' + '.xlsx';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  datatable(event: any) {
    // * controllo la lunghezza per gestire il tasto Elimina
    let check = event.length;
    // if (check > 0) this.checkedPost = true;
    // else this.checkedPost = false;
    // this.rows = event;
  }
}
