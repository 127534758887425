import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';
import { environment } from 'src/environments/environment';
import { Consulting } from '../models/consulting.model';

@Injectable({
    providedIn: 'root'
})
export class ConsultingsService {

    constructor(private http: HttpClient) { }

    getList(params?: any) {
        return this.http.get<FormattedResponse<Consulting[]>>(`${environment.api}/consulting`, { params });
    }

    getListRequested(params?: any) {
        return this.http.get<FormattedResponse<Consulting[]>>(`${environment.api}/consulting/all/requested`, { params });
    }

    createConsulting(params?: any) {
        return this.http.post<FormattedResponse<any[]>>(`${environment.api}/consulting`, params);
    }

    delete(id?: string) {
        return this.http.delete<FormattedResponse<any[]>>(`${environment.api}/consulting/` + id);
    }

    updateByParams(params?: any) {
        return this.http.post<FormattedResponse<any[]>>(`${environment.api}/consulting/updateByParams`, params);
    }

    massiveUpdate(params?: any) {
        return this.http.post<FormattedResponse<any[]>>(`${environment.api}/consulting/massiveUpdate`, params);
    }

    getDates(params?: any) {
        return this.http.post<FormattedResponse<any[]>>(`${environment.api}/consulting/getDates`, params);
    }

    getHours(params?: any) {
        return this.http.post<FormattedResponse<any[]>>(`${environment.api}/consulting/getHours`, params);
    }

    update(id?: string, params?: any) {
        return this.http.put<FormattedResponse<any[]>>(`${environment.api}/consulting/` + id, params);
    }

    getConsultantTypes() {
        return this.http.get<FormattedResponse<any[]>>(`${environment.api}/getConsultantTypes`);
    }

    getConsultantsByType(params?: any) {
        return this.http.get<FormattedResponse<any[]>>(`${environment.api}/consultant`, { params });
    }
}
