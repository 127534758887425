<div class="mx-5 my-4">
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="pb-0 mb-0">Domanda per la iscrizione/convalida</h3>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
  </div>

  <p class="text-black">per la iscrizione/convalida di iscrizione negli elenchi dei soggetti abilitati alle verifiche in
    materia di
    sicurezza impianti di cui alla legge 5/3/90 n. 46</p>

  <label for="subtitle">si desidera:</label>

  <form [formGroup]="form_domanda">
    <div *ngFor="let chiose of scelta">
      <label for="enum_answer_{{chiose.label}}"></label>
      <input id="enum_answer_{{chiose.value}}" [value]='chiose.label' type="radio" formControlName="convalida">
      <span class="ms-1 text-black">{{chiose.label}}</span>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn aggiorna mt-3 px-4" (click)="invia()">Invia</button>
    </div>
  </form>
</div>