import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { WordpressService } from 'src/app/modules/application/services/wordpress.service';

import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    eye: any;
    loginForm: FormGroup | any;
    origin: string = '';
    idpList: any;
    spid: any;
    token_spid: any;

    constructor(
        public fb: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private wordpressService: WordpressService,
        private _auth: AuthService,
        private route: ActivatedRoute
    ) {
        this.loginForm = this.fb.group({
            username: [null, Validators.required],
            password: [null, Validators.required],
        })

        this.idpList = [
            {
                name: "SPID ITALIA",
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://spid.register.it/login/doLogin?execution=e1s1'
            },
            {
                name: 'Namirial',
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://idp.namirialtsp.com/idp'
            },
            {
                name: 'ArubaPEC S.p.A.',
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://loginspid.aruba.it'
            },
            {
                name: 'InfoCert S.p.A.',
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://identity.infocert.it'
            },
            {
                name: 'IN.TE.S.A. S.p.A.',
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://spid.intesa.it'
            },
            {
                name: 'Lepida S.p.A.',
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://id.lepida.it/idp/shibboleth'
            },
            {
                name: 'Poste Italiane SpA',
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://posteid.poste.it'
            },
            {
                name: 'Sielte S.p.A.',
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://identity.sieltecloud.it'
            },
            {
                name: 'Register.it S.p.A.',
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://spid.register.it'
            },
            {
                name: 'TI Trust Technologies srl',
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://login.id.tim.it/affwebservices/public/saml2sso'
            },
            {
                name: 'TeamSystem s.p.a.',
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://spid.teamsystem.com/idp'
            },
            {
                name: 'Intesi Group S.p.A.',
                imageSrc: '../../../../../assets/img/spid-ico-circle-bb.svg',
                entityId: 'https://idp.intesigroup.com'
            }
        ];


    }


    ngOnInit(): void {
        this.origin = this.route.snapshot.queryParams['origin'] ?? 'ordine';
        this.checkRoleUser();
        this.token_spid = this.route.snapshot.queryParams['SpidToken'] ?? false;
        if (this.token_spid) {
            this.authService.getProfile({ spid_token: this.token_spid }).subscribe(res => {
                this._auth.currentUser.next(res.data);
                localStorage.setItem('username', res.data.username);
                localStorage.setItem('role', res.data.role_id);
                localStorage.setItem('Authorization', res.data.authToken);
                localStorage.setItem('fondazione', 'false');
                if (res.data.role_id == 1)
                    this.router.navigate(['/app/admin/dashboard']);
                else if (res.data.role_id == 2)
                    this.router.navigate(['/app/client/subscriptions']);
                else if (res.data.role_id == 3)
                    this.router.navigate(['/app/guest/subscriptions']);
                else if (res.data.role_id == 4) {
                    localStorage.setItem('fondazione', 'true');
                    this.router.navigate(['/app/fondazione/dashboard']);
                }
                else if (res.data.role_id == 5)
                    this.router.navigate(['/app/company/dashboard']);
            },
                err => {
                    Swal.fire({
                        icon: 'error',
                        title: `<div style="font-size:18px;">${err}</div>`,
                        target: '#custom-target',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    })
                }
            )
        };
    }


    goToSpid() {
        this.spid = true;
        this.authService.spid({ type: 'spid' }).subscribe(res => {
            let token = res.data.data;
            var url = environment.spidDomainAPI + "/Profile?SpidToken=" + token;
            // Create a new anchor element
            window.location.href = url;
        })

    }


    changePasswordInput(input: any): any {
        input.type = input.type === 'password' ? 'text' : 'password';
        this.eye = input.type;
    }


    checkRoleUser(): any {
        let role_db = localStorage.getItem('role');
        let authorization = localStorage.getItem('Authorization')
        if (role_db && authorization) {
            if (role_db == '1')
                this.router.navigate(['/app/admin/dashboard']);
            else if (role_db == '2') {
                this.router.navigate(['/app/client/subscriptions']);
            }
            else if (role_db == '3')
                this.router.navigate(['/app/guest/subscriptions']);
            else if (role_db == '4') {
                localStorage.setItem('fondazione', 'true');
                this.router.navigate(['/app/fondazione/dashboard']);
            }
            else if (role_db == '5')
                this.router.navigate(['/app/company/dashboard']);
        } else {
            this.router.navigate(['/login'])
        }
    }

    login() {
        if (this.loginForm.valid) {
            this.authService.login(this.loginForm.get('username').value, this.loginForm.get('password').value).subscribe(
                response => {
                    // console.log(response);
                    this._auth.currentUser.next(response.data);
                    localStorage.setItem('username', this.loginForm.get('username').value);
                    localStorage.setItem('role', response.data.role_id);
                    localStorage.setItem('Authorization', response.data.authToken);
                    localStorage.setItem('fondazione', 'false');

                    if (response.data.role_id == 1)
                        this.router.navigate(['/app/admin/dashboard']);
                    else if (response.data.role_id == 2)
                        this.router.navigate(['/app/client/subscriptions']);
                    else if (response.data.role_id == 3)
                        this.router.navigate(['/app/guest/subscriptions']);
                    else if (response.data.role_id == 4) {
                        localStorage.setItem('fondazione', 'true');
                        this.router.navigate(['/app/fondazione/dashboard']);
                    }
                    else if (response.data.role_id == 5)
                        this.router.navigate(['/app/company/dashboard']);
                },
                error => {
                    Swal.fire({
                        icon: 'error',
                        title: `<div style="font-size:18px;">${error}</div>`,
                        target: '#custom-target',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    })
                }
            )
        }
    }
}
