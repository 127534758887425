import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Data } from '@angular/router';
import { NewsletterService } from 'src/app/modules/application/services/newsletter.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss']
})
export class SubscriptionListComponent implements OnInit {
  public lists: any[] = [];
  public form!: FormGroup;

  checked = false;
  loading = false;
  indeterminate = false;
  listOfData: readonly Data[] = [];
  listOfCurrentPageData: readonly Data[] = [];
  setOfCheckedId = new Set<number>();
  role_id: any;

  constructor(private _newsletter: NewsletterService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.role_id = localStorage.getItem('role');
    this.initForm();
    this.getData();
  }

  initForm = () => {
    this.form = this.formBuilder.group({
      list_id: [null, Validators.required],
      emailToAdd: null,
      emailToRemove: null
    });
  }

  getData() {
    this._newsletter.getLists({ role_id: this.role_id }).subscribe({
      next: (response) => {
        this.lists = response.data;
        if (this.role_id == 1) {
          this.form.get('list_id')?.setValue(this.lists.find(list => list.name == 'Iscritti Ordine').id);
        } else {
          this.form.get('list_id')?.setValue(this.lists.find(list => list.name == 'Iscritti Fondazione').id);
        }
        this.changeList(this.form.get('list_id')?.value);
      },
      error: (error) => {
        Swal.fire({
          icon: 'error',
          title: '<div style="font-size:18px;">Errore imprevisto</div>',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
  }

  changeList(list_id: any) {
    let list = this.lists.find(list => list.id == list_id);
    this.listOfData = list?.subscribers;
  }

  submitForm() {
    this._newsletter.addEmailToList(this.form.value).subscribe({
      next: (response) => {
        Swal.fire({
          icon: 'success',
          title: '<div style="font-size:18px;">Lista aggiornata correttamente</div>',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          this.getData();
        });
      },
      error: (error) => {
        Swal.fire({
          icon: 'error',
          title: '<div style="font-size:18px;">Errore imprevisto</div>',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    this.form.reset()
  }
}
