<div class="col-11 m-auto mt-5">

    <!-- ? Titolo e searchbar -->
    <div class="d-flex row">
        <div class="title col-sm-8 col-md-8">
            <h2><a href="/app/admin/dashboard/tematici/0">Tavoli tematici</a> / <span class="text">Iscritti</span>
            </h2>
        </div>
        <div class="search col-sm-4 col-md-4">
            <mat-form-field appearance="fill">
                <mat-label>Ricerca per titolo</mat-label>
                <input matInput [formControl]="filtroRicerca">
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <button type="button" class="btn blu me-4" (click)="admission('ammesso')">Ammetti</button>
    <button type="button" class="btn rosso" (click)="admission('non ammesso')">Non ammetti</button>

    <!-- ? Tabella -->
    <div class="mt-4">
        <table mat-table class="mt-4" [dataSource]="(tables | async)">

            <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Seleziona </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    <input class="form-check-input ms-2 radio" role="button" type="checkbox" value="" name="selection"
                        id="flexCheckDefault" [ngModel]="selectedIndex === element.user_id"
                        (change)="changeSelection($any($event), element.user_id)">
                </td>
            </ng-container>


            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Nome </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    {{element.user.name}}
                </td>
            </ng-container>


            <ng-container matColumnDef="surname">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Cognome </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    {{element.user.lastname}}
                </td>
            </ng-container>


            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Data </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    {{element?.created_at | date:"dd-MM-YYYY"}}
                </td>
            </ng-container>


            <ng-container matColumnDef="certificate">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Attestato </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    {{ !element.request_certificate ? 'Non richiesto' : element.request_certificate == 1 ? 'Richiesto' :
                    'Consegnato' }}
                </td>
            </ng-container>


            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Stato </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    {{element.admission_status}}
                </td>
            </ng-container>


            <ng-container matColumnDef="request_certificate">
                <th mat-header-cell *matHeaderCellDef class="py-3"> Richiedi attestato </th>
                <td mat-cell *matCellDef="let element" class="py-3">
                    <input type="checkbox"
                        (change)="changeStatusCertificate(element.user_id, element.thematic_id, $event)"
                        [checked]="checked(element.request_certificate)">
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="pageEvent = handlePage($event)" showFirstLastButtons>
        </mat-paginator>
    </div>


</div>