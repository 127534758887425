import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import {BehaviorSubject, ReplaySubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilsServices {
    route = `${environment.api}`;
    constructor(private http: HttpClient) { }

    onSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isExportable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    export: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    selectedIndex: ReplaySubject<any> = new ReplaySubject<any>();

    getCounters(params?: any) {
        return this.http.get<FormattedResponse<any>>(`${environment.api}/counters`, { params });
    }

    getStatsDashboard() {
        return this.http.get<any>(`${this.route}/graphs`);
    }

    getCategories(dashboard: boolean = false) {
        return this.http.get<any>(`${environment.api}/categories?dashboard=${dashboard}`);
    }

    getSpecializations() {
        return this.http.get<any>(`${environment.api}/specializations`);
    }

    setSelectedIndex(index: any){
      this.selectedIndex.next(index);
    }

    getSelectedIndex(){
      return this.selectedIndex;
    }
}
