export const config = {
    angular_editor: {
        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '20rem',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Inserisci qui il testo...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            { class: 'arial', name: 'Arial' },
            { class: 'times-new-roman', name: 'Times New Roman' },
            { class: 'calibri', name: 'Calibri' },
            { class: 'montserrat', name: 'Montserrat' }

        ],
        uploadWithCredentials: false,
        sanitize: false,
        toolbarHiddenButtons: [
            ['bold', 'italic'],
            ['fontSize']
        ]
    }
};
