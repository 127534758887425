import { NewPostComponent } from './admin/new-post/new-post.component';
import { ContainerComponent } from './admin/container/container.component';
import { ApplicationComponent } from './components/application/application.component';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { BaseComponent } from './components/base/base.component';
import { IscrizioneComponent } from './user/components/iscrizioni/iscrizione.component';
import { SettingsComponent } from './user/components/settings/settings.component';
import { ProfileComponent } from './user/components/profile/profile.component';
import { SubContainerComponent } from './user/components/subContainer/subContainer.component';
import { RoleGuard } from '../auth/components/role.guard';
import { PaymentComponent } from './user/components/payment/payment.component';
import { UtentiIscrittiComponent } from './admin/utenti-iscritti/utenti-iscritti.component';
import { TernaComponent } from './user/components/terna/terna.component';
import { StampeComponent } from './user/components/stampe/stampe.component';
import { ScadenzarioComponent } from './user/components/scadenzario/scadenzario.component';
import { AccessDocumentsComponent } from './user/components/access-documents/access-documents.component';
import { ThematicTablesComponent } from './user/components/thematic-tables/thematic-tables.component';
import { CreateThematicTablesComponent } from './user/components/create-thematic-tables/create-thematic-tables.component';
import { AdmissionThematicComponent } from './user/components/admission-thematic/admission-thematic.component';
import { EngineersListComponent } from "./user/components/engineers-list/engineers-list.component";
import { EventsListComponent } from './admin/events/events-list/events-list/events-list.component';
import { AddEventComponent } from './admin/events/add-event/add-event/add-event.component';
import { ConsultingsListComponent } from "./user/components/consultings-list/consultings-list.component";
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { NewMailComponent } from './components/newsletter/components/new-mail/new-mail.component';
import { MailDetailsComponent } from './components/newsletter/components/mail-details/mail-details.component';
import { AgoraComponent } from './components/agora/agora.component';
import { NewOfferComponent } from './components/agora/components/new-offer/new-offer.component';
import { OfferDetailsComponent } from './components/agora/components/offer-details/offer-details.component';
import { CertificationsListComponent } from './user/components/certifications-list/certifications-list.component';
import { DetailComponent } from "./user/components/terna/detail/detail.component";
import { ExternalCourseComponent } from './user/components/external-course/external-course.component';
import { RegisteredUsersComponent } from './admin/events/registered-users/registered-users/registered-users.component';
import { RapidLinksComponent } from './user/components/rapid-links/rapid-links.component';
import { UsersManagementComponent } from './admin/users-management/users-management/users-management.component';
import { SpecialCoursesDetailComponent } from './user/components/special-courses-detail/special-courses-detail/special-courses-detail.component';
import { EngineerDetailComponent } from "./user/components/engineers-list/engineer-detail/engineer-detail.component";
import { CommissioniListComponentComponent } from './admin/commissioni-list-component/commissioni-list-component.component';
import { CommissioniDetailComponentComponent } from './admin/commissioni-detail-component/commissioni-detail-component.component';


const routes: Routes = [
  {
    path: 'admin', component: ApplicationComponent, children: [
      {
        path: 'dashboard', component: BaseComponent, canActivate: [RoleGuard], data: { role: '1' }, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: DashboardComponent },
          { path: 'tematici/:id', canActivate: [RoleGuard], data: { role: '1' }, component: ThematicTablesComponent },
          { path: 'tematici/:id/create', canActivate: [RoleGuard], data: { role: '1' }, component: CreateThematicTablesComponent },
          { path: 'tematici/:id/show/:id', canActivate: [RoleGuard], data: { role: '1' }, component: CreateThematicTablesComponent },
          { path: 'tematici/:id/admission/:id', canActivate: [RoleGuard], data: { role: '1' }, component: AdmissionThematicComponent },
          { path: ':slug/:id', canActivate: [RoleGuard], data: { role: '1' }, component: ContainerComponent },
          { path: ':slug/:id/create-post', canActivate: [RoleGuard], data: { role: '1' }, component: NewPostComponent },
          { path: ':slug/:id/edit-post/:id', canActivate: [RoleGuard], data: { role: '1' }, component: NewPostComponent },
          { path: ':slug/:id/restore/:id', canActivate: [RoleGuard], data: { role: '1' }, component: NewPostComponent },
          { path: ':slug/:id/booked/:id', canActivate: [RoleGuard], data: { role: '1' }, component: UtentiIscrittiComponent },
        ]
      },
      {
        path: 'stampe', canActivate: [RoleGuard], data: { role: '1' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: StampeComponent },
        ]
      },
      {
        path: 'rapid-links', canActivate: [RoleGuard], data: { role: '1' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: RapidLinksComponent },
        ]
      },
      {


        path: 'scadenzario', canActivate: [RoleGuard], data: { role: '1' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: ScadenzarioComponent },
          { path: 'users-management', canActivate: [RoleGuard], data: { role: '1' }, component: UsersManagementComponent },
          { path: 'special-course/:id', canActivate: [RoleGuard], data: { role: '1' }, component: UsersManagementComponent },
          { path: 'detail/:id', canActivate: [RoleGuard], data: { role: '1' }, component: SpecialCoursesDetailComponent },
        ]
      },
      {
        path: 'events', canActivate: [RoleGuard], data: { role: '1' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: EventsListComponent },
          { path: 'new', canActivate: [RoleGuard], data: { role: '1' }, component: AddEventComponent },
          { path: ':event', canActivate: [RoleGuard], data: { role: '1' }, component: AddEventComponent },
          { path: 'booked/:id', canActivate: [RoleGuard], data: { role: '1' }, component: RegisteredUsersComponent },
        ]
      },
      {
        path: 'profile', canActivate: [RoleGuard], data: { role: '1' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: ProfileComponent },
        ]
      },
      {
        path: 'terna', canActivate: [RoleGuard], data: { role: '1' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: TernaComponent },
          { path: 'detail/:id', canActivate: [RoleGuard], data: { role: '1' }, component: DetailComponent },
        ]
      },
      {
        path: 'engineers_list', canActivate: [RoleGuard], data: { role: '1' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: EngineersListComponent },
          { path: 'detail', canActivate: [RoleGuard], data: { role: '1' }, component: EngineerDetailComponent },
          { path: 'detail/:id', canActivate: [RoleGuard], data: { role: '1' }, component: EngineerDetailComponent },
        ]
      },
      {
        path: 'consultings_list', canActivate: [RoleGuard], data: { role: '1' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: ConsultingsListComponent },
        ]
      },
      {
        path: 'certifications_list', canActivate: [RoleGuard], data: { role: '1' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: CertificationsListComponent },
        ]
      },
      { path: 'curriculum', loadChildren: () => import('../application/modules/resources/resources.module').then(m => m.ResourcesModule) },
      {
        path: 'newsletter', data: { role: '1' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: NewsletterComponent },
          { path: ':id', canActivate: [RoleGuard], data: { role: '1' }, component: MailDetailsComponent },
        ]
      },
      {
        path: 'agora', canActivate: [RoleGuard], data: { role: '1' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '1' }, component: AgoraComponent },
          { path: 'offers/:id', canActivate: [RoleGuard], data: { role: '1' }, component: OfferDetailsComponent },
        ]
      }
    ]
  },
  {
    path: 'company', component: ApplicationComponent, children: [
      {
        path: 'dashboard', component: BaseComponent, canActivate: [RoleGuard], data: { role: '5' }, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '5' }, component: DashboardComponent }
        ]
      },
      {
        path: 'agora', canActivate: [RoleGuard], data: { role: '5' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '5' }, component: AgoraComponent },
          { path: 'offers/:id', canActivate: [RoleGuard], data: { role: '5' }, component: OfferDetailsComponent },
        ]
      }
    ]
  },
  {
    path: 'client', component: ApplicationComponent, children: [
      {
        path: 'subscriptions', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: IscrizioneComponent },
          { path: 'tematici/:id', canActivate: [RoleGuard], data: { role: '2' }, component: ThematicTablesComponent },
          { path: 'tematici/:id/create', canActivate: [RoleGuard], data: { role: '2' }, component: CreateThematicTablesComponent },
          { path: 'tematici/:id/show/:id', canActivate: [RoleGuard], data: { role: '2' }, component: CreateThematicTablesComponent },
          { path: 'tematici/:id/payment/:id', canActivate: [RoleGuard], data: { role: '2' }, component: PaymentComponent },
          { path: ':slug/:id', canActivate: [RoleGuard], data: { role: '2' }, component: SubContainerComponent },
          { path: ':slug/:id/show/:id', canActivate: [RoleGuard], data: { role: '2' }, component: NewPostComponent },
          { path: ':slug/:id/show/:id/payment', canActivate: [RoleGuard], data: { role: '2' }, component: PaymentComponent },
        ]
      },
      {
        path: 'stampe', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: StampeComponent },
        ]
      },
      {
        path: 'scadenzario', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: ScadenzarioComponent },
          { path: 'detail/:id', canActivate: [RoleGuard], data: { role: '2' }, component: SpecialCoursesDetailComponent },
        ]
      },
      {
        path: 'external-course', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: ExternalCourseComponent },
          { path: ':id', canActivate: [RoleGuard], data: { role: '2' }, component: ExternalCourseComponent },

        ]
      },
      // {
      //     path: 'atti', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
      //         { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: AccessDocumentsComponent },
      //     ]
      // },
      {
        path: 'settings', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: SettingsComponent },
        ]
      },
      {
        path: 'profile', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: ProfileComponent },
        ]
      },
      {
        path: 'terna', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: TernaComponent },
        ]
      },
      {
        path: 'consultings_list', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: ConsultingsListComponent },
        ]
      },
      {
        path: 'events', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: EventsListComponent },
          { path: ':event', canActivate: [RoleGuard], data: { role: '2' }, component: AddEventComponent },

        ]
      },
      {
        path: 'certifications_list', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: CertificationsListComponent },
        ]
      },
      {
        path: 'curriculum', loadChildren: () => import('../application/modules/resources/resources.module').then(m => m.ResourcesModule)
      },
      {
        path: 'agora', canActivate: [RoleGuard], data: { role: '2' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '2' }, component: AgoraComponent },
          { path: 'offers/:id', canActivate: [RoleGuard], data: { role: '2' }, component: OfferDetailsComponent },
        ]
      }
    ]
  },
  {
    path: 'guest', component: ApplicationComponent, children: [
      {
        path: 'subscriptions', canActivate: [RoleGuard], data: { role: '3' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '3' }, component: IscrizioneComponent },
          { path: 'tematici/:id', canActivate: [RoleGuard], data: { role: '3' }, component: ThematicTablesComponent },
          { path: 'tematici/:id/create', canActivate: [RoleGuard], data: { role: '3' }, component: CreateThematicTablesComponent },
          { path: 'tematici/:id/show/:id', canActivate: [RoleGuard], data: { role: '3' }, component: CreateThematicTablesComponent },
          { path: ':slug/:id', canActivate: [RoleGuard], data: { role: '3' }, component: SubContainerComponent },
          { path: ':slug/:id/show/:id', canActivate: [RoleGuard], data: { role: '3' }, component: NewPostComponent },
          { path: ':slug/:id/show/:id/payment', canActivate: [RoleGuard], data: { role: '3' }, component: PaymentComponent },
        ]
      },
      {
        path: 'settings', canActivate: [RoleGuard], data: { role: '3' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '3' }, component: SettingsComponent },
        ]
      },
      {
        path: 'profile', canActivate: [RoleGuard], data: { role: '3' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '3' }, component: ProfileComponent },
        ]
      },
    ]
  },
  {
    path: 'fondazione', component: ApplicationComponent, children: [
      {
        path: 'dashboard', component: BaseComponent, canActivate: [RoleGuard], data: { role: '4' }, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '4' }, component: DashboardComponent },
          { path: ':slug/:id', canActivate: [RoleGuard], data: { role: '4' }, component: ContainerComponent },
          { path: ':slug/:id/create-post', canActivate: [RoleGuard], data: { role: '4' }, component: NewPostComponent },
          { path: ':slug/:id/edit-post/:id', canActivate: [RoleGuard], data: { role: '4' }, component: NewPostComponent },
          { path: ':slug/:id/restore/:id', canActivate: [RoleGuard], data: { role: '4' }, component: NewPostComponent },
          { path: ':slug/:id/booked/:id', canActivate: [RoleGuard], data: { role: '4' }, component: UtentiIscrittiComponent },
        ]
      },
      {
        path: 'newsletter', data: { role: '4' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '4' }, component: NewsletterComponent },
          { path: ':id', canActivate: [RoleGuard], data: { role: '4' }, component: MailDetailsComponent },
        ]
      },
      {
        path: 'commissioni', data: { role: '4' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '4' }, component: CommissioniListComponentComponent },
          { path: ':id', canActivate: [RoleGuard], data: { role: '4' }, component: CommissioniDetailComponentComponent },
        ]
      },
      // {
      //     path: 'press', canActivate: [RoleGuard], data: { role: '4' }, component: BaseComponent, children: [
      //         { path: '', canActivate: [RoleGuard], data: { role: '4' }, component: StampeComponent },
      //     ]
      // },
      {
        path: 'faq', canActivate: [RoleGuard], data: { role: '4' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '4' }, component: ScadenzarioComponent },
        ]
      },
      // {
      //     path: 'mail', canActivate: [RoleGuard], data: { role: '4' }, component: BaseComponent, children: [
      //         { path: '', canActivate: [RoleGuard], data: { role: '4' }, component: ProfileComponent },
      //     ]
      // },
      // {
      //     path: 'commission', canActivate: [RoleGuard], data: { role: '4' }, component: BaseComponent, children: [
      //         { path: '', canActivate: [RoleGuard], data: { role: '4' }, component: TernaComponent },
      //     ]
      // },
      {
        path: 'events', canActivate: [RoleGuard], data: { role: '4' }, component: BaseComponent, children: [
          { path: '', canActivate: [RoleGuard], data: { role: '4' }, component: EventsListComponent },
          { path: 'new', canActivate: [RoleGuard], data: { role: '4' }, component: AddEventComponent },
          { path: ':event', canActivate: [RoleGuard], data: { role: '4' }, component: AddEventComponent },
          { path: 'booked/:id', canActivate: [RoleGuard], data: { role: '4' }, component: RegisteredUsersComponent },
        ]
      },

      // {
      //     path: 'report', canActivate: [RoleGuard], data: { role: '4' }, component: BaseComponent, children: [
      //         { path: '', canActivate: [RoleGuard], data: { role: '4' }, component: EngineersListComponent },
      //     ]
      // },
      {
        path: 'profile', data: { role: '4' }, component: BaseComponent, children: [
          { path: '', data: { role: '4' }, component: ProfileComponent },
        ]
      },
    ]
  },
  { path: 'curriculum', loadChildren: () => import('../application/modules/resources/resources.module').then(m => m.ResourcesModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ApplicationRoutingModule { }
