<div class="bg-header fixed" [ngClass]="{'fondazione': fondazione}">
    <div class="d-flex flex-row justify-content-between">
        <div class="col-sm-9 col-xl-8">
            <nav class="navbar navbar-expand-lg">
                <div class="container-fluid">
                    <!--[ngClass]="{'fondazione-hover': fondazione}"-->
                    <a class="white p-4" [href]="header_url">
                        <img src="./assets/icons/home.svg" alt=""> {{header_text}}
                    </a>

                    <div class="collapse navbar-collapse menu" id="navbarNav" *ngIf="client != 2 || client != 3"
                        [ngClass]="{'fondazione': fondazione}">
                        <ul class="menu-items navbar-nav me-auto mb-2 mb-lg-0" [ngClass]="{'fondazione': fondazione}">
                            <li class="nav-item dropdown d-block d-md-none d-lg-none">
                                <a class="nav-link p-4 white" [href]="profile" role="button" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <!-- Modifica Utente -->
                                    Profilo
                                    <!-- <a class="ps-3 dropdown-user" [href]="profile">Modifica Utente</a>-->
                                </a>
                                <!-- <br> -->
                                <a class="nav-link p-4 white" href="#" (click)="resertToken();">Esci</a>
                            </li>

                            <li class="nav-item dropdown" *ngIf="client==1 || client == 4">
                                <a class="nav-link p-3 hover white" href="#" role="button" data-bs-toggle="dropdown"
                                    [ngClass]="{'fondazione': fondazione}" aria-expanded="false"
                                    [ngClass]="{'fondazione': fondazione}">
                                    <img src="./assets/icons/plus.svg" alt=""> Nuovo
                                </a>
                                <ul class="dropdown-menu sottomenu" width="0.3px"
                                    [ngClass]="{'fondazione': fondazione}">
                                    <li class="w-100" *ngFor="let category of routes_fine">
                                        <span>
                                            <a [href]="category.url + '/create-post'"
                                                class="nav-link  p-3 align-middle">
                                                <span class="d-sm-inline text-white">
                                                    {{ category.name }}
                                                </span>
                                            </a>
                                        </span>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        <div class="col-5 menu">
            <nav class="navbar navbar-expand-lg">
                <app-notifications></app-notifications>
                <div class="container-fluid">
                    <div class="collapse navbar-collapse">
                        <ul class="menu-items navbar-nav me-auto mb-2 mb-lg-0" [ngClass]="{'fondazione': fondazione}">
                            <li class="nav-item dropdown">
                                <a id="user-name" class="w-100 nav-link pad white" href="#" role="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="w-100">Ciao, {{name}}&nbsp;&nbsp;<i class="fa-solid fa-bars"></i></div>
                                    <!-- <img src="./assets/icons/avatar.svg" alt=""> -->
                                </a>
                                <ul class="dropdown-menu sottomenuUtente" [ngClass]="{'fondazione': fondazione}">
                                    <!-- <li class="white ps-3"> Ciao, {{name}} </li>
                  <br> -->
                                    <a class="ms-3 dropdown-user" [href]="profile">
                                        <li class="mx-3" *ngIf="client == 2 || client == 3">Profilo</li>
                                    </a>
                                    <a class="ms-3 dropdown-user" href="#" (click)="resertToken();">
                                        <li class="mx-3">Esci</li>
                                    </a>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        <div class="col-3 spaceMenu">

            <li class="nav-item dropdown" *ngIf="client != 2 || client != 3">
                <a class="nav-link pr-4 hover white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false" [ngClass]="{'fondazione': fondazione}">
                    <img src="./assets/icons/plus.svg" alt="">
                </a>
                <ul class="dropdown-menu sottomenu" width="0.3px" [ngClass]="{'fondazione': fondazione}">
                    <li class="w-100" *ngFor="let category of routes_fine">
                        <span *ngIf="category.slug != 'senza-categoria'">
                            <a [href]="category.url + '/create-post'" class="nav-link  p-3 align-middle">
                                <span class="d-sm-inline text-white">
                                    {{ category.name }}
                                </span>
                            </a>
                        </span>
                    </li>
                </ul>
            </li>
            <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon mt-4 mr-3">
          <img src="../../../assets/icons/hamburger-menu.png" width="35px" alt="">
        </span>
      </button> -->

            <app-notifications></app-notifications>
        </div>

    </div>
</div>