import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-angular';
import * as moment from 'moment';
import {config} from '../../../../../../environments/config';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {ExternalCourseService} from "../../../../../shared/services/external-course.service";

@Component({
  selector: 'app-external-course',
  templateUrl: './external-course.component.html',
  styleUrls: ['./external-course.component.scss']
})
export class ExternalCourseComponent implements OnInit {
  public editor = ClassicEditor;
  editorConfig: AngularEditorConfig = config.angular_editor;
  formPost: FormGroup;
  courseId: any;
  fileList: NzUploadFile[] = [];
  types: any[] = [];

  constructor(
    public user: UserService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _externalCourse: ExternalCourseService
  ) {
    this.formPost = this.fb.group({
      external_course_type_id: [null, Validators.required],
      description: null,
      start_date: [null, Validators.required],
      end_date: [null, Validators.compose([Validators.required, this.dateValidation])],
      attachmentsFile: null,
      duration: [{value: null, disabled: true}],
    });
  }

  ngOnInit(): void {
    this.courseId = this.route.snapshot.paramMap.get('id');
    this.getExternalCourseTypes();

    this.formPost.get('external_course_type_id')?.valueChanges.subscribe({
      next: value => {
        let type = this.types.find(item => item.id == value);
        if(type) {
          let hour_string = type.title.split("- ");
          this.formPost.patchValue({duration: (hour_string[1].slice(0, 2))})
        }
      }
    })

    if(this.courseId) this.getData();
  }

  getData() {
    this._externalCourse.getExternalCourse(this.courseId).subscribe({
      next: response => {
        this.fileList = response.data.files;
        this.formPost.patchValue(response.data);
      }
    })
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    this.handleUpload();
    return false;
  };

  handleRemove = (file: any): void => {
    let data: any[] = [];
    file.fileList.forEach((file: any) => {
      this.uploadLogic(data, file)
    });
  };

  handleUpload(): void {
    let data: any[] = [];
    this.fileList.forEach((file: any) => {
      this.uploadLogic(data, file)
    });
  }

  uploadLogic(data: any[], file: any) {
    const reader = new FileReader();
    const blob = new Blob([file]);
    reader.readAsDataURL(blob);
    const uuidPattern = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[1-5][a-fA-F0-9]{3}-[89abAB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}$/;
    const isUUID: boolean = uuidPattern.test(file.uid);

    reader.onload = () => {
      data.push({ name: file.name, base64: reader.result, id: isUUID ? file.uid : null });

      if (data.length === this.fileList.length) {
        this.formPost.patchValue({ attachmentsFile: data });
      }
    };
  }


  goTo() {
    this.router.navigate(['app/client/scadenzario']);
  }

  createPost() {
    this.formatDates();

    if (this.courseId != null) {
      this._externalCourse.updateExternalCourse(this.formPost.value, this.courseId).subscribe({
        next: response => {
          this.successResponse(response.message);
        }
      })
    } else {
      this._externalCourse.createExternalCourse(this.formPost.value).subscribe({
        next: response => {
          this.successResponse(response.message);
        }
      })
    }
  }

  private formatDates() {
    this.formPost.patchValue({
      start_date: moment(this.formPost.value.start_date).toISOString(true),
      end_date: moment(this.formPost.value.end_date).toISOString(true),
    });
  }

  private successResponse(message: string) {
    Swal.fire({
      icon: 'success',
      title: '<div style="font-size:18px;">' + message + '</div>',
      showConfirmButton: false,
      timer: 3000,
    }).then(() => {
      this.goTo()
    });
  }

  deleteCourse() {
    this.user.deleteExrternalCourse({ id: this.formPost.get('id')?.value }).subscribe((res: any) => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">Eliminazione avvenuta con successo</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this.goTo();
    });
  }

  private getExternalCourseTypes() {
    this._externalCourse.getExternalCourseTypes().subscribe({
      next: response => {
        this.types = response.data;
      }
    })
  }

  dateValidation: ValidatorFn = (): ValidationErrors | null => {
    let start_date = this.formPost?.get('start_date')?.value;
    let end_date = this.formPost?.get('end_date')?.value;

    if (end_date && start_date && moment(end_date).unix() < moment(start_date).unix())
      return { dateTimeRange: true };

    return null;
  }
}
