import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';
import { RapidLinkService } from '../../../services/rapid-link.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, finalize } from 'rxjs/operators';

@Component({
    selector: 'app-rapid-links',
    templateUrl: './rapid-links.component.html',
    styleUrls: ['./rapid-links.component.scss']
})
export class RapidLinksComponent implements OnInit {
    modalForm: FormGroup = new FormGroup({});
    isVisibleCreate: boolean = false;
    isVisibleUpdate: boolean = false;
    filtroRicerca: FormControl = new FormControl();
    filter: any;
    selectedLink = new Array();
    selectedRecord : any = null;

    columns = [
        { key: 'check', name: 'check', type: 'check', sortable: false },
        { key: 'name', name: 'Nome link visibile', sortable: true },
        { key: 'url', name: 'URL link' }
    ];

    data_count = 0;
    data: any = [];
    table_config = {
        pagination: { index: 0, size: 10 },
        sorting: { key: 'created_at', direction: 'desc' },
        filters: { search: null }
    }

    constructor(
        private _rapidlinks: RapidLinkService,
        private _user: UserService
    ) { }

    ngOnInit(): void {
        this.getData();

        this.modalForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            url: new FormControl(null, Validators.compose([Validators.required, Validators.pattern("https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)")])),
            section: new FormControl(null)
        });

        this.filtroRicerca.valueChanges.pipe(debounceTime(1000)).subscribe((value: any) => {
            this.table_config.filters.search = value;
            this.table_config.pagination.index = 0
            this.getData();
        });
    }

    getData() {
        this._rapidlinks.getList(this.table_config).subscribe((response: any) => {
            this.data = response.data;
            this.data_count = response.total;
        });
    }

    deleteLink(): void {
        const selectedRowsIds = this.selectedLink.map((row: any) => row.id);

        this._rapidlinks.deleteMultipleLink(selectedRowsIds)
            .pipe(finalize(() => {
                this.getData();
                this.selectedLink = [];
            }))
            .subscribe({
                next: response => {
                    this.successResponse(response.message);
                },
                error: response => {
                    this.errorResponse(response);
                }
            });
    }


    onRowCheck(data: any) {
        if (data.checked) {
            let element = this.data.find((item: any) => item.id == data.id);
            this.selectedLink.push(element)
        }
        else {
            let index = this.selectedLink.findIndex((item: any) => item.id == data.id);
            this.selectedLink.splice(index, 1);
        }
    }

    onPageChange(data: any) {
        this.table_config.pagination = data;
        this.getData();
    }

    onSortChange(data: any) {
        this.table_config.sorting = data;
        this.getData();
    }

    onActionClick($event: any) {
        switch ($event.action){
            case 'edit':
                this.selectedRecord = $event.data.id;
                this.isVisibleUpdate = true;
                this.modalForm.patchValue($event.data);
            break;
        }
    }


    onSave() {
        this._rapidlinks.createLink(this.modalForm.value)
            .pipe(finalize(() => {
                this.getData();
                this.handleCancelCreate();
            }))
            .subscribe({
                next: response => {
                    this.successResponse(response.message);
                },
                error: response => {
                    this.errorResponse(response);
                }
            });
    }

    onUpdate() {
        this._rapidlinks.updateLink(this.selectedRecord, this.modalForm.value)
            .pipe(finalize(() => {
                this.getData();
                this.handleCancelCreate();
            }))
            .subscribe({
                next: response => {
                    this.successResponse(response.message);
                },
                error: response => {
                    this.errorResponse(response);
                }
            });
    }

    successResponse(message: any){
        Swal.fire({
            icon: 'success',
            title: '<div style="font-size:18px;">' + message + '</div>',
            showConfirmButton: false,
            timer: 3000,
        });
    }

    errorResponse(message: any){
        Swal.fire({
            icon: 'error',
            title: '<div style="font-size:18px;">' + message + '</div>',
            showConfirmButton: false,
            timer: 3000,
        });
    }

    handleCancelCreate(): void {
        this.isVisibleCreate = false;
        this.isVisibleUpdate = false;
        this.modalForm.reset();
    }
}
