import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NotificationsServices } from 'src/app/modules/application/services/notifications.service';
import { UserService } from 'src/app/modules/application/services/user.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

    constructor(
        public user: UserService,
        public notifications: NotificationsServices,
        private router: Router,
    ) { }
    toogle = false;
    toRead: any[] | undefined;
    readed: any[] | undefined;
    notificationsArray = new Array();
    notificationsToRead = new Array();

    array: any[] | undefined;
    user_id: any;
    count: number | undefined;
    idNotification: any;
    show_notification_number = 10;
    show_not_read_notification_number = 10;
    notificationsToReadCount = 0;
    notificationsArrayCount = 0;

    ngOnInit() {
        // this.getUserId();
        this.getNotify();
    }

    /**
     * Recupero l'id dello user
     */
    // getUserId() {
    //   this.user.me().subscribe(element => {
    //     this.user_id = element.data.id;
    //     this.getNotify();
    //   })
    // }

    getNotify() {
        this.notifications.getNotification(this.show_notification_number, this.show_not_read_notification_number).subscribe(response => {
            this.count = response.count;
            this.notificationsToRead = response.data.notification_not_read
            this.notificationsToReadCount = response.data.notification_not_read_count
            this.notificationsArray = response.data.notification_read;
            this.notificationsArrayCount = response.data.notification_read_count;
        });
    }

    /**
     * prendo l'id della notifica che mi serve per renderla letta al click
     */
    getElement(element: any) {
        this.idNotification = element.id;
        this.readNotification(element);
    }

    /**
     * cambio il valore della notifica cliccata in letta
     */
    readNotification(element: any) {
        this.notifications.readNotification(1, this.idNotification).subscribe(() => {
            this.getNotify();
            let url = this.router.url;
            let role_path = '';
            if (url.includes('/app/admin/')) role_path = '/app/admin/';
            else if (url.includes('/app/client/')) role_path = '/app/client/';
            else if (url.includes('/app/guest/')) role_path = '/app/guest/';
            else if (url.includes('/app/fondazione/')) role_path = '/app/fondazione/';
            else if (url.includes('/app/company/')) role_path = '/app/company/';
            window.location.href = role_path + element.path;
        });
    }

    /**
    *
    * @param event prendo il valore del toogle se true o false
    */
    getToogle(event: any) {
        this.toogle = event.target.checked;
        if (this.toogle) this.toRead;
        else this.readed;
    }
}
