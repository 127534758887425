import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiNotifications } from '../models/notifiche.model';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';
@Injectable({
    providedIn: 'root'
})


export class NotificationsServices {
    route = `${environment.api}`;
    private notificationsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(private http: HttpClient) { }
    getNotifications() {
        return this.notificationsSubject.asObservable();
    }

    setNotifications(notifications: any) {
        this.notificationsSubject.next(notifications);
    }

    /**
     *
     * @returns recupero le notifiche associate
     * @param show_notification_number numero di notifiche da mostrare
     * @param show_not_read_notification_number numero di notifiche non lette da mostrare
     */
    getNotification(show_notification_number: any, show_not_read_notification_number: any): Observable<any> {
        return this.http.get<FormattedResponse<ApiNotifications[]>>(`${environment.api}/getNotification?show_notification_number=${show_notification_number}&show_not_read_notification_number=${show_not_read_notification_number}`,);
    }

    readNotification(read_flag: any, id: any): Observable<any> {
        return this.http.post<any>(`${environment.api}/readNotification`, { read_flag, id });
    }

}
