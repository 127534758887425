import { DatePipe, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { DatatableService } from '../../../services/datatable.service';
import { UserService } from '../../../services/user.service';
import { WordpressService } from '../../../services/wordpress.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  today = moment().format("YYYY-MM-DD");
  pipe = new DatePipe('en-US');
  url: any;
  categoryId: any;
  title: any;
  id: any;
  wp_id: any;
  user_wp_id: any;
  titleCourse: any;
  date: any;
  duration: any;
  cfp: any;

  mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
  anni = ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035'];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public user: UserService,
    private _wp: WordpressService,
    public datatableService: DatatableService,
    @Inject(DOCUMENT) private document: Document,

  ) { }

  ngOnInit() {
    this.title = this.route.snapshot.params.slug;
    this.categoryId = this.route.snapshot.url[1].path;
    this.url = this.router.url;
    this.getSingleCourse();
    this.getUserId();
  }

  getUserId() {
    this.user.me().subscribe(element => {
      this.user_wp_id = element.data.id;
      //console.log('id utente', this.user_wp_id);
    })
  }


  getSingleCourse() {
    this.id = this.route.snapshot.params.id;
    this.wp_id = this.route.snapshot.params.wp_id;

    if (!this.wp_id) {
      this.user.getTable({ id: this.id }).subscribe(response => {
        //console.log('Table: ', response);

        if (response.data != null) {
          let startdata = this.pipe.transform(response.data.start_date, 'YYYY-MM-dd');

          this.titleCourse = response.data.title,
            this.date = startdata;
          this.duration = response.data.duration;
          this.cfp = response.data.cfp;
        }
      })

    } else {
      //console.log('sono dentro il corso normale');

      this._wp.getSinglePost(this.id, this.wp_id).subscribe(response => {
        let startdata = this.pipe.transform(response.data.start_date, 'YYYY-MM-dd');
        var html = response.data.title;
        var div = document.createElement("div");
        var divContent = document.createElement("div");
        div.innerHTML = html;
        divContent.innerHTML = response.data.content;
        var titleDecoded = div.textContent || div.innerText || "";

        this.titleCourse = titleDecoded;
        this.date = startdata;
        this.duration = response.data.duration;
        this.cfp = response.data.cfp;
      })
    }


  }


  subscribe() {
    if (!this.wp_id) {
      this.datatableService.subscriptionThematic(this.user_wp_id, this.id).subscribe(res => {
        Swal.fire({
          icon: 'success',
          title: '<div style="font-size:18px;">Iscrizione effettuata</div>',
          showConfirmButton: false,
          timer: 3000,
        });
        this.goTo()
      })
    } else {
      this.datatableService.subscription(this.id).subscribe(res => {
        Swal.fire({
          icon: 'success',
          title: '<div style="font-size:18px;">Iscrizione effettuata</div>',
          showConfirmButton: false,
          timer: 3000,
        });
        this.goTo()
      })
    }

  }

  goTo() {
    if (!this.wp_id) {
      this.router.navigate([`app/client/subscriptions/tematici/0`]);
      if (this.url.includes('app/admin/dashboard/')) this.document.location.href = `${environment.dashboard}/tematici/0`;
      else if (this.url.includes('app/client/subscriptions')) this.document.location.href = `${environment.subscriptions}/tematici/0`;
      else if (this.url.includes('app/guest/subscriptions')) this.document.location.href = `${environment.guest_subscriptions}/tematici/0`;
    } else {
      if (this.url.includes('app/admin/dashboard/')) this.document.location.href = `${environment.dashboard}/${this.title}/${this.categoryId}`;
      else if (this.url.includes('app/client/subscriptions')) this.document.location.href = `${environment.subscriptions}/${this.title}/${this.categoryId}`;
      else if (this.url.includes('app/guest/subscriptions')) this.document.location.href = `${environment.guest_subscriptions}/${this.title}/${this.categoryId}`;
    }

  }

}
