<section class="d-flex flex-row justify-content-between" *ngIf="newsletter_id">
  <h4 class="mb-4">{{ form.get('name')?.value }}</h4>
  <span>Inviate: {{ newsletter?.delivered ?? 0 }}/{{ newsletter?.count }}</span>
</section>

<form nz-form [nzLayout]="'vertical'" [formGroup]="form">
  <section nz-row [nzGutter]="24">
    <article nz-col [nzXs]="24" [nzXl]="8">
      <nz-form-item>
        <nz-form-label>Oggetto</nz-form-label>
        <nz-form-control nzErrorTip="L'oggetto è obbligatorio">
          <input nz-input formControlName="subject" placeholder="Oggetto" [readonly]="newsletter_id != null" />
        </nz-form-control>
      </nz-form-item>
    </article>

    <article nz-col [nzXs]="24" [nzXl]="8">
      <nz-form-item>
        <nz-form-label>Lista</nz-form-label>
        <nz-form-control nzErrorTip="La lista è obbligatoria">
          <nz-select class="w-100" formControlName="list_id" nzPlaceHolder="Scegli..."
            [nzDisabled]="newsletter_id != null">
            <nz-option *ngFor="let list of lists" [nzValue]="list.id" [nzLabel]="list.name"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </article>

    <article nz-col [nzXs]="24" [nzXl]="8">
      <nz-form-item>
        <nz-form-label>Template</nz-form-label>
        <nz-form-control nzErrorTip="Il template è obbligatorio">
          <nz-select formControlName="template_id" nzPlaceHolder="Scegli..." [nzDisabled]="newsletter_id != null">
            <nz-option *ngFor="let template of templates" [nzValue]="template.id" [nzLabel]="template.name"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </article>
  </section>

  <nz-form-item>
    <nz-form-label>Corpo</nz-form-label>
    <nz-form-control>
      <angular-editor formControlName="body" [config]="editorConfig"></angular-editor>
    </nz-form-control>
  </nz-form-item>

  <div *ngIf="newsletter_id &&(records | async)>0 ">
    <h5>Email Presenti in Lista</h5>
    <table mat-table class="box_super full-width" [dataSource]="(mailNewsletter | async)">

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Email </th>
        <td mat-cell *matCellDef="let element" class="py-4">
          {{element.email}}
        </td>
      </ng-container>


      <ng-container matColumnDef="success">
        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Stato </th>
        <td mat-cell *matCellDef="let element" class="py-4">
          <p *ngIf="element?.success==1">Inoltrata</p>
          <p *ngIf="element?.success==0">Non Inoltrata</p>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="pageEvent = handlePage($event)" showFirstLastButtons>
    </mat-paginator>
  </div>

  <section class="d-flex flex-row justify-content-end" *ngIf="!newsletter_id">
    <nz-form-item>
      <nz-form-control>
        <button class="btn blu" nz-button nzType="primary" (click)="submitForm()">Invia</button>
      </nz-form-control>
    </nz-form-item>
  </section>
</form>