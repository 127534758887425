import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe, DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';
import { WordpressService } from '../../../services/wordpress.service';
import { Sort } from "@angular/material/sort";
import { PostsServices } from "../../../services/posts.service";
import * as moment from 'moment';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
@Component({
  selector: 'app-subContainer',
  templateUrl: './subContainer.component.html',
  styleUrls: ['./subContainer.component.scss']
})

export class SubContainerComponent implements OnInit {

  cols = new Array();
  publish = new Array();

  colsHead: string[] = ['title', 'seats', 'cfp', 'duration', 'request_attestato', 'free', 'status', 'action'];

  subCount: any;

  path: any;
  url: any;
  userId: any;
  rows = new Array();
  categories = new Array();
  total: any;
  displayTable = false;
  pipe = new DatePipe('en-US');
  status = ['publish', 'draft', 'trash'];
  // * Paginator
  length!: number;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10];
  showFirstLastButtons: boolean = true;
  search = '';
  searchField: FormControl = new FormControl();
  searchDelay: number = 2000;
  ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  sorting: Sort = { active: 'title', direction: 'desc' };


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _post: WordpressService,
    private _user: UserService,
    private _posts: PostsServices,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.checkPath();
    // this.getCategory();
    this.getData();
    this.getCounters();

    this.ruolo.next(localStorage.getItem('role'));

    this.searchField.valueChanges.pipe(
      debounceTime(this.searchDelay)
    ).subscribe(value => {
      this.search = value;
      this.pageIndex = 0;
      this.getData()
    });


    this._user.me().subscribe(res => {
      this.userId = res.data.id
    })
    this.url = this.router.url;
    if (this.url.includes('/comunicazioni')) this.colsHead = ['title', 'start_date'];
    if (this.url.includes('/tavoli-tematici')) this.colsHead = ['title', 'thematic_date', 'status', 'action'];
    if (this.url.includes('/seminari')) {
      let index = this.colsHead.indexOf('request_attestato');
      this.colsHead.splice(index, 1);
    }
  }

  getCounters() {
    this._posts.getCounters(this.route.snapshot.params.id).subscribe({
      next: response => {
        this.total = response.data.publish;
      }
    })
  }

  getData() {
    this._posts.getPosts(this.route.snapshot.params.id, false, 'publish', this.search, this.sorting?.active, this.sorting?.direction, this.pageIndex * this.pageSize, this.pageSize).pipe(
      map((response: any) => {
        return {
          data: response.data.map((el: any) => {
            if (this.route.snapshot.params.id == 1) {
              el.start_date = el.publish_on_site ? this.pipe.transform(el.publish_on_site, 'dd-MM-YYYY HH:mm') : this.pipe.transform(el.created_at, 'dd-MM-YYYY HH:mm');
            }
            if (this.route.snapshot.params.id == 6) {
              el.thematic_date = this.pipe.transform(el.created_at, 'dd-MM-YYYY HH:mm');
              el.button_text = el.action == 'already-subscribed' ? 'Disiscriviti' : 'Iscriviti';
            }

            if (
              this.route.snapshot.params.id == 2 ||
              this.route.snapshot.params.id == 3 ||
              this.route.snapshot.params.id == 4
            ) {
              if (el.registration_allowed) {
                el.seats = el.subscribed + '/' + el.total_seats + ' (' + el.reserved_seats + ' in riserva)';
              } else {
                el.seats = '- / -';
              }
              //el.start_date = this.pipe.transform(el.start_date, 'dd-MM-YYYY');
              el.button_text = this.getActionColumn(el).text;
              el.button_disabled = this.getActionColumn(el).disabled;
              el.tooltip = this.getActionColumn(el).tooltip;
            }
            return el;
          }),
          count: response.count,
          total: response.total,
        };
      })
    )
      .subscribe({
        next: (result: any) => {
          this.cols = result.data;
          this.length = result.count;
          this.total = result.total;
        },
      });


    // this.displayTable = false;
    // let username = localStorage.getItem('username');
    //
    // const data = {
    //   category: this.route.snapshot.params.id,
    //   orderBy: this.sorting?.active ?? 'date',
    //   orderDir: this.sorting?.direction ?? 'desc',
    //   offset: 0,
    //   page: (this.pageIndex + 1),
    //   status: this.status,
    //   filter: 'null',
    //   username: username,
    //   userId: 0
    // }
    //
    // if (search) data.filter = search;
    //
    // this._post.getAllPost(data).subscribe(r => {
    //
    //   // //console.log(r.data[0].subscriptionsCount);
    //   this.cols = r.data;
    //   this.length = r.count ?? 0;
    //   this.displayTable = true;
    //   let arr = this.cols.length - 1;
    //   this.subCount = r?.data[arr]?.total_element_already_sub;
    //
    //   this.cols.forEach(el => {
    //
    //     var html = el.title;
    //     var div = document.createElement("div");
    //     div.innerHTML = html;
    //     var titleDecoded = div.textContent || div.innerText || "";
    //     el.title = titleDecoded;
    //
    //     this.publish.push(el.status);
    //   })
    //
    // });
    // // //console.log(this.cols);

  }

  getActionColumn(element: any) {
    let start = moment(element.start_date);
    let end = moment(element.end_date);
    let event = moment(element.event_start_date);
    let now = moment();

    //Se l'iscrizione non è possibile al corso/convegno/seminario specifico
    if (!element.registration_allowed) {
      return { disabled: true, text: '', tooltip: false };
    }
    //Se la data di inizio iscrizione è futura mostriamo il bottone Iscrizioni non aperte disabilitato
    else if (element.action == 'waiting') {
      return { disabled: true, text: 'In attesa', tooltip: false };
    }
    else if (start > now) {
      return { disabled: true, text: 'Iscrizioni non aperte', tooltip: false };
    }
    //Se l'ingegnere si è iscritto mostriamo il bottone Disiscriviti e accanto il tooltip; il termine massimo per disiscriversi è un giorno prima della data di inizio dell'evento
    else if (element.action == 'already-subscribed') {
      if (now <= moment(event.subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'))) return { disabled: false, text: 'Disiscriviti', tooltip: true };
      else return { disabled: true, text: 'Iscritto', tooltip: true };
    }
    //Se la data corrente si  trova tra quella di inizio iscrizione e quella di fine, mostriamo il bottone Iscriviti
    else if (start < now && end > now && element.action != 'not-admitted') {
      return { disabled: false, text: 'Iscriviti', tooltip: false };
    }
    //Se la data di fine iscrizone è passata, e l'ingegnere non si è iscritto, mostriamo una label Iscrizioni chiuse
    else if (end < now && element.action == 'subscribe') {
      return { disabled: true, text: 'Iscrizioni chiuse', tooltip: false };
    }
    //Quando l'utente si iscrive e non viene ammesso, mostriamo comunque il tooltip con le informazioni dell'iscrizione e il bottone disabilitato Non ammesso
    else if (element.action == 'not-admitted') {
      return { disabled: true, text: 'Non ammesso', tooltip: false };
    }
    else {
      return { disabled: true, text: 'Iscriviti', tooltip: false };
    }
  }

  checkPath() {
    // -> lo snapshot.params mi prende il parametro slug del path
    this.path = this.route.snapshot.params.slug;
  }

  // getCategory() {

  //   let url = this.router.url;
  //   this._post.getCategories().subscribe(value => {
  //     if (value) {
  //       this.categories = value;
  //       this.categories.forEach(el => {
  //         if (this.path == el.slug) {
  //           this.total = el.count;
  //         };
  //       })
  //     }
  //   });
  // }

  goTo(path: string): void {
    this.url = this.router.url;
    this.router.navigate([`${this.url}/${path}`]);
  }

  datatable(event: any) {
    this.rows = event;
  }

  alert(): void {
    const bottone = '<div class="d-flex justify-content-between">' +
      '<button type="button" class="btn" style="background-color: lightgray;color: #063e64;text-transform: uppercase;"> ANNULLA </button>' +
      '<button type="button" class="btn" style="background-color: red;color: #fff;text-transform: uppercase;">ELIMINA</button>' +
      '</div>';
    Swal.fire({
      title: '<div style="font-size:16px; text-align:center">Eliminare la comunicazione?</div>' + this.rows.length + bottone,
      target: '#custom-target',
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timerProgressBar: true
    })
  }

  messageError(): void {
    if (this.rows.length == 0)
      Swal.fire({
        icon: 'error',
        title: '<div style="font-size:16px; text-align:center">Non hai selezionato nessun elemento',
        target: '#custom-target',
        toast: true,
        position: 'top',
        timer: 2500,
        showConfirmButton: false,
        timerProgressBar: true
      })
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getData();
    return event;
  }

  indexSubs() {

    let username = localStorage.getItem('username');
    const data = {
      category: this.route.snapshot.params.id,
      orderBy: 'title',
      orderDir: 'desc',
      offset: 0,
      page: (this.pageIndex + 1),
      status: this.status,
      filter: 'null',
      username: username,
      userId: this.userId,
    }
    // //console.log('dataIndex');

    this._post.getAllPost(data).subscribe(r => {
      // //console.log(r.data[0].subscriptionsCount);
      this.cols = r.data;
      this.length = r.count ?? 0;
      this.displayTable = true;
      let arr = this.cols.length - 1;
      this.subCount = r?.data[arr]?.total_element_already_sub;

      this.cols.forEach(el => {

        var html = el.title;
        var div = document.createElement("div");
        div.innerHTML = html;
        var titleDecoded = div.textContent || div.innerText || "";
        el.title = titleDecoded;

        this.publish.push(el.status);
      })

    });
  }

  reloadData() {
    this.ngOnInit();
  }

  onSortChange($event: any) {
    this.sorting = $event;
    this.getData();
  }
}
