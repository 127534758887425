import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ExternalCourseService } from 'src/app/shared/services/external-course.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';


@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss']
})
export class UsersManagementComponent implements OnInit, AfterViewInit {

  managementForm: FormGroup
  client: any
  types: any[] = []
  fileList: NzUploadFile[] = [];
  uploading: boolean = false;
  special_course_id: string = ''
  data = []
  courseTitle: string = ''
  isDetailPage: boolean = false



  private getExternalCourseTypes() {
    this._externalCourse.getExternalCourseTypes().subscribe({
      next: response => {
        this.types = response.data;
      }
    })
  }

  private formatDates() {
    this.managementForm.patchValue({
      start_date: moment(this.managementForm.value.start_date).toISOString(true),
      end_date: moment(this.managementForm.value.end_date).toISOString(true),
    }, { emitEvent: false });
  }



  constructor(
    private fb: FormBuilder,
    private location: Location,
    private _externalCourse: ExternalCourseService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.managementForm = this.fb.group({
      external_course_type_id: [null, Validators.required],
      start_date: [new Date(), Validators.required],
      end_date: ["", Validators.required],
      file: [null, Validators.required]
    }, {
      validator: this.dateTimeRangeValidator('start_date', 'end_date')
    })

    this.route.url.subscribe((segments) => {
      // Verifica se la parola "detail" è presente tra i segmenti dell'URL
      this.isDetailPage = segments.some((segment) => segment.path === 'detail');
    });


  }


  dateTimeRangeValidator(startControlName: string, endControlName: string) {
    return (group: FormGroup) => {
      const startControl = group.get(startControlName);
      const endControl = group.get(endControlName);

      if (startControl && endControl && startControl.value && endControl.value) {
        const startTime = new Date(startControl.value);
        const endTime = new Date(endControl.value);

        if (startTime > endTime) {
          endControl.setErrors({ dateTimeRange: true });
        } else {
          endControl.setErrors(null);
        }
      }
    };
  }

  ngOnInit(): void {
    this.special_course_id = this.route.snapshot.params.id;
    this.client = localStorage.getItem('role')
    this.getExternalCourseTypes()
  }

  ngAfterViewInit(): void {
    if (this.special_course_id) {
      this.getData();
    }
  }

  goBack() {
    this.location.back();
  }


  getData(): void {
    this._externalCourse.getSpecialCoursesInscriptions(this.special_course_id).subscribe({
      next: res => {
        this.data = res.data
        this.courseTitle = res.data.external_course_types.title

        this.managementForm.patchValue(this.data)
      },
      error: err => {
      }
    })
  }
  onSubmit() {
    if (this.managementForm.valid) {
      this.formatDates()
      this._externalCourse.createCourseByCsvUser(this.managementForm.value)
        .pipe(finalize(() => this.handleRedirect()))
        .subscribe({
          next: () => {
            Swal.fire({
              icon: 'success',
              title: '<div style="font-size:18px;">Lista inserita correttamente</div>',
              showConfirmButton: false,
              timer: 3000,
            });

          },
          error: response => {
            Swal.fire({
              icon: 'error',
              title: '<div style="font-size:18px;">Problema nell\' inserimento della lista</div>',
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
    }
  }

  onUpdate() {
    if (this.managementForm.valid) {
      this.formatDates()
      this._externalCourse.updateSpecialCourse(this.special_course_id, this.managementForm.value)
        .pipe(finalize(() => this.handleRedirect()))
        .subscribe({
          next: () => {
            Swal.fire({
              icon: 'success',
              title: '<div style="font-size:18px;">Lista aggiornata correttamente</div>',
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
    }
  }

  private handleRedirect() {
    this.router.navigate([`/app/admin/scadenzario`]);
  }


  beforeUpload = (file: NzUploadFile): boolean => {
    this.uploading = false;
    this.fileList = [];
    this.fileList = this.fileList.concat(file);
    this.handleUpload()
    return false;
  };


  handleUpload(): void {
    let data: any = [];
    this.fileList.forEach((file: any) => {
      data.push({
        file: file
      });
    });
    this.managementForm.patchValue({ file: data[0].file });
    this.uploading = true;
  }

  handleRemove = (): void => {
    this.managementForm.patchValue({ file: [] });
  };
}
