import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';
import { WordpressService } from '../../../services/wordpress.service';

@Component({
  selector: 'app-thematic-tables',
  templateUrl: './thematic-tables.component.html',
  styleUrls: ['./thematic-tables.component.scss']
})
export class ThematicTablesComponent implements OnInit {

  displayedColumns: string[] = [];
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  currentPage = 0;
  pageSize = 5;
  filtroVariabile: any;
  filter: any;
  pageEvent: PageEvent | undefined;
  tables: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  scadenze: any;
  ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  user_id: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedIndex: number | undefined;
  searchDelay: number = 1000;
  filtroRicerca: FormControl = new FormControl();


  constructor(
    private userService: UserService,
    private _wp: WordpressService
  ) { }

  ngOnInit(): void {
    this.getUserId();
    this.ruolo.next(localStorage.getItem('role'));
    if (this.ruolo.value == 1) this.displayedColumns = ['check', 'titolo', 'posti', 'cfp', 'durata', 'data', 'azione'];
    else this.displayedColumns = ['titles', 'posti', 'cfp', 'durata', 'data', 'prenota'];
    this.getData();

    this.filtroRicerca.valueChanges.pipe(debounceTime(this.searchDelay)).subscribe((value: any) => {
      this.filter = value;
      this.getData();
    });
  }

  getUserId() {
    this.userService.me().subscribe(element => {
      this.user_id.next(element.data.id);
    })
  }

  getData() {
    let params = this.filter ? { start: this.currentPage * this.pageSize, length: this.pageSize, like_search: this.filter } : { start: this.currentPage * this.pageSize, length: this.pageSize };
    this.userService.getThematicTable(params).subscribe(response => {
      this.tables.next(response.data);
      this.records.next(response.total);
    });
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getData();
    return e;
  }

  changeSelection(event: { target: { checked: any; }; }, index: number | undefined) {
    this.selectedIndex = event.target.checked ? index : undefined;
  }


  removeElement() {
    this.userService.deleteThematicTable({ id: this.selectedIndex }).subscribe(response => {

      if (response.code == 200) {
        Swal.fire({
          icon: 'success',
          title: '<div style="font-size:18px;">Eliminato con successo</div>',
          showConfirmButton: false,
          timer: 3000,
        });
        this.ngOnInit();
      }
    })
  }

  unsubscribe(id: any) {
    this._wp.unsubscribeThematic(this.user_id.value, id).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">prenotazione annullata</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this.getData()
    })
  }

}
