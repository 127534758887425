import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UtilsServices } from 'src/app/shared/services/utils.service';
import { PostsServices } from "../../services/posts.service";
import { UserService } from '../../services/user.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    allCategories: Array<any> = [];
    stickyPost: Array<any> = [];
    pipe = new DatePipe('en-US');
    client = localStorage.getItem('role');
    isCompany: boolean = false;
    specializations: any = [];
    specialists: any[] = [];
    project_url = environment.project;
    user: any = {};

    constructor(
        public router: Router,
        private route: ActivatedRoute,
        public _utils: UtilsServices,
        private _posts: PostsServices,
        private _users: UserService,
        private _auth: AuthService
    ) { }

    ngOnInit() {
        let url = this.router.url;
        this.isCompany = url.includes('/app/company');
        if (this.isCompany) {
            this.getUserBySpecializationCounters();
            this._utils.getSpecializations().subscribe((response: any) => {
                this.specializations = response.data;
            });
            this._auth.currentUser.subscribe(value => this.user = value);
        } else {
            this.getCategories();
            this.getStickyPosts();
        }
    }

    getCategories() {
        this._utils.getCategories(true).subscribe({
            next: response => {
                this.allCategories = response.data.map((category: any) => {
                    category.icon = category.icon.replace("-white", "");
                    return category;
                });
            }
        })
    }

    getStickyPosts() {
        this._posts.getPosts(1, true).subscribe({
            next: response => {
                response.data.forEach((el: any) => {
                    this.stickyPost.push(el);
                    var html = el.title;
                    var div = document.createElement("div");
                    div.innerHTML = html;
                    var titleDecoded = div.textContent || div.innerText || "";
                    el.title = titleDecoded;
                    const time = el.date;
                    const myFormattedDate = this.pipe.transform(time, 'dd-MM-YYYY');
                    el.date = myFormattedDate;
                });

                this.stickyPost = this.stickyPost.slice(0, 8);
            }
        })
    }

    getUserBySpecializationCounters() {
        this._users.getUserBySpecializationCounters().subscribe((result: any) => {
            this.specialists = result.data;
        });
    }

    getSpecialization(id: any) {
        let specialization = this.specializations?.find((specialization: any) => {
            return specialization.id == id;
        });

        return specialization?.name ?? '---';
    }
}
