import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilsServices {
    route = `${environment.api}`;

    constructor(private http: HttpClient) { }

    getCategories(dashboard: boolean = false) {
      return this.http.get<any>(`${environment.api}/categories?dashboard=${dashboard}`);
    }
}
