import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'app-offer-details',
    templateUrl: './offer-details.component.html',
    styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent implements OnInit {
    public mailCreated: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    url: any;
    displayedTitle: string = '';

    constructor(
        private router: Router,
        private location: Location
    ) { }

    ngOnInit() {
        this.url = this.router.url;
    }

    goBack() {
        this.location.back();
    }

    setTitle($event: string) {
        this.displayedTitle = $event;
    }
}
