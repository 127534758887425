<nz-table #table [nzData]="data" [nzTotal]="data_count" [nzShowPagination]="true" [nzPageSize]="pageSize"
    [nzPageIndex]="pagination.index + 1" [nzFrontPagination]="false" (nzPageIndexChange)="onPageChange($event)">

    <thead>
        <tr>
            <th *ngFor="let column of columns">
                <div [ngClass]="{'clickable': column.sortable}" (click)="onSortChange(column)">
                    <span *ngIf="column.type != 'check'">{{ column.name }}</span>

                    <span *ngIf="column.sortable && sort.key == column.key" [ngClass]="{'ms-2': sort.direction}">
                        <span *ngIf="sort.direction == 'asc'">
                            <i class="fa-solid fa-arrow-up"></i>
                        </span>
                        <span *ngIf="sort.direction == 'desc'">
                            <i class="fa-solid fa-arrow-down"></i>
                        </span>
                    </span>
                </div>
            </th>

            <th *ngIf="actions.length > 0 || row_actions">
                <div>
                    <span>{{label_azioni != null ? label_azioni : 'AZIONI'}}</span>
                </div>
            </th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let data of table.data" [ngClass]="{'clickable': clickable}" (click)="onRowClick(data)">
            <td *ngFor="let column of columns">

                <div [ngSwitch]="column.type">
                    <div *ngSwitchCase="'date'">
                        {{data[column.key] | date: 'dd-MM-YYYY'}}
                    </div>

                    <div *ngSwitchCase="'datetime'">
                        {{data[column.key] | date: 'dd-MM-YYYY HH:mm'}}
                    </div>

                    <div class="d-flex align-items-center" *ngSwitchCase="'check'">
                        <input *ngIf="data[column.key]" type="checkbox"
                            (click)="onRowCheck($event, data.id); $event.stopPropagation()">
                    </div>

                    <div *ngSwitchDefault>
                        <div *ngIf="data[column.key] && data[column.key].length > 90; else normalColumn">
                            {{data[column.key].substring(0, 90)}}...
                        </div>

                        <ng-template #normalColumn>
                            {{data[column.key]}}
                        </ng-template>
                    </div>
                </div>
            </td>

            <td *ngIf="actions.length > 0">
                <div *ngFor="let action of actions; last as last; first as first"
                    (click)="onActionClick(action, $event, data)">
                    <ng-container [ngTemplateOutlet]="actionsColumn"
                        [ngTemplateOutletContext]="{action: action, data: data, last: last}">
                    </ng-container>
                </div>
            </td>

            <td *ngIf="row_actions">
                <div *ngFor="let action of data.actions; last as last; first as first"
                    (click)="onActionClick(action, $event, data)">
                    <ng-container [ngTemplateOutlet]="actionsColumn"
                        [ngTemplateOutletContext]="{action: action, data: data, last: last}">
                    </ng-container>
                </div>
            </td>
        </tr>
    </tbody>
</nz-table>


<ng-template #actionsColumn let-action='action' let-data='data' let-last='last'>
    <div [ngSwitch]="action" [ngClass]="{'me-1': !last}">

        <span *ngSwitchCase="'edit'" class="action">
            <i class="fa-solid fa-pen-to-square"></i>
        </span>

        <span *ngSwitchCase="'download'" class="action">
            <i class="fa-solid fa-file-arrow-down"></i>
        </span>

        <span *ngSwitchCase="'upload'" class="action">
            <i class="fa-solid fa-file-arrow-up"></i>
        </span>

        <span *ngSwitchCase="'detail'" class="action">
            <i class="fa-solid fa-info"></i>
        </span>

        <span *ngSwitchCase="'confirm'" class="action">
            <i class="fa-solid fa-check"></i>
        </span>

        <span *ngSwitchCase="'delete'" class="action red">
            <i class="fa-solid fa-trash"></i>
        </span>

        <span *ngSwitchDefault>
            <span [matTooltip]="action.tooltip">
                <button class="btn blu" [disabled]="action.disabled">
                    {{action.text}}
                </button>
            </span>
        </span>

    </div>
</ng-template>