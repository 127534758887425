import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-elenchiDlgs81Dialog',
  templateUrl: './elenchiDlgs81Dialog.component.html',
  styleUrls: ['./elenchiDlgs81Dialog.component.scss']
})
export class ElenchiDlgs81DialogComponent implements OnInit {
  tipo: any;
  filter_status: any = false;

  form_dlgs81: FormGroup = this.formBuilder.group({
    convalida: [null],
    amminAppartenenza: [null],
    qualifica: [null],
    periodoDiSvolgimento: [null],
    periodoSettoreCostruzioni: [null],
    datoreLavoro: [null],
    periodoLavoro: [null],
    tipoLavoro: [null]
  });

  scelta_1 = [
    { value: 1, label: "Allega attestazioni da parte di datori di lavoro o committenti comprovanti l'espletamento di attività lavorativa, per almeno un anno (nel caso di laurea specialistica) o per almeno due anni (nel caso di laurea di primo livello) nel settore delle costruzioni." },
    { value: 2, label: "Allega autocertificazione comprovante l'espletamento di attività lavorativa, per almeno un anno (nel caso di laurea specialistica) o per almeno due anni (nel caso di laurea di primo livello) nel settore delle costruzioni." },
    { value: 3, label: "Allega copia autenticata dell'attestato di partecipazione a corso di 120 ore in materia di sicurezza." },
    { value: 4, label: "Dichiara di essere esonerato dalla frequenza del corso propedeutico alla sicurezza, in quanto:" }
  ]

  scelta_2 = [
    { value: 1, label: "almeno un anno nel settore delle costruzioni (nel caso di laurea magistrale o specialistica)" },
    { value: 2, label: "almeno due anni nel settore delle costruzioni (nel caso di laurea di primo livello)" }
  ]

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  invia() {
    if (!this.form_dlgs81.value.convalida || !this.form_dlgs81.value.periodoSettoreCostruzioni || !this.form_dlgs81.value.datoreLavoro || !this.form_dlgs81.value.periodoLavoro || !this.form_dlgs81.value.tipoLavoro) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Swal.fire({
        icon: 'warning',
        text: 'Compilare i campi'
      })
    } else {
      this.getDocument(this.tipo);
    }
  }

  getDocument(tipo: any) {
    this.userService.getDocument(tipo, this.form_dlgs81.value.convalida, this.form_dlgs81.value.amminAppartenenza, this.form_dlgs81.value.qualifica, this.form_dlgs81.value.periodoDiSvolgimento, this.form_dlgs81.value.periodoSettoreCostruzioni, this.form_dlgs81.value.datoreLavoro, this.form_dlgs81.value.periodoLavoro, this.form_dlgs81.value.tipoLavoro).subscribe((r: Blob) => {
      const blobFile: Blob = new Blob([r], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(blobFile);
      window.open(fileURL, '_blank');
    })
  }



  setFilter(filtro: any) {
    //console.log(filtro);
    if (filtro == 4) {
      this.filter_status = true;
    } else {
      this.filter_status = false;
    }
  }

}
