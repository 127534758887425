import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { BaseComponent } from 'src/app/components/base/base.component';
import { EventService } from 'src/app/modules/application/services/event.service';
import { NewsletterService } from 'src/app/modules/application/services/newsletter.service';
import { OffersService } from 'src/app/shared/services/offers.service';
import { UtilsServices } from 'src/app/shared/services/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss']
})
export class OffersListComponent extends BaseComponent implements OnInit {
  // length!: number;
  // pageSize: number = 10;
  // pageIndex: number = 0;
  // pageSizeOptions: number[] = [10];
  // showFirstLastButtons: boolean = true;
  // displayTable = false;

  // cols = new Array();
  // colsHead: string[] = ['name', 'list', 'details'];
  @ViewChild('openModal') openModalButton!: ElementRef;
  @ViewChild('closeModal') closeModalButton!: ElementRef;

  client = localStorage.getItem('role');
  specialization_id = null;
  specializations: any[] = [];
  offers_with_no_feedback: any;
  form = this.fb.group({
    search: null,
    specialization_id: null
  })

  public columns: { key: string, name: string, type: string }[] = [
    { key: 'company.name', name: 'Azienda', type: 'name' },
    // { key: 'company.email', name: 'E-mail', type: 'email' },
    // { key: 'company.phone', name: 'Telefono', type: 'phone' },
    { key: 'specialization_id', name: 'Specializzazione', type: 'specialization' },
    { key: 'contract', name: 'Contratto', type: 'contract' },
    { key: 'nominations_count', name: 'Numero candidati', type: 'number' },
    { key: 'created_at', name: 'Creata il', type: 'date' },
    { key: 'validated', name: 'Stato', type: 'status' },
    { key: 'action', name: 'Azioni', type: 'actions' }
  ];
  public listOfData: any[] = [];
  public pageIndex = 1;
  public pageSize = 10;
  public total = 0;
  public isTableLoaded: boolean = false;

  constructor(
    private _offers: OffersService,
    private _utils: UtilsServices,
    private _eventService: EventService,
    private fb: FormBuilder
  ) {
    super();

    if (this.client == '2') this.columns.splice(this.columns.findIndex(column => column.key == 'validated'), 1);
  }

  ngOnInit() {
    this.getData();
    if (this.client == '2') {
      this.getFeedback();
    }
    this._utils.getSpecializations().subscribe(response => {
      this.specializations = response.data;
    });

    this._offers.refresh.subscribe(value => {
      if (value) {
        this.getData();
      }
    });
  }

  getFeedback() {
    this._offers.getFeedback().subscribe(res => {
      this.offers_with_no_feedback = res.data;
      if (this.offers_with_no_feedback.length > 0) {
        this.openModalButton.nativeElement.click();
      }
    })
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getData();
    return event;
  }

  onSpecializationChange() {
    this.pageIndex = 1;
    this.getData();
  }

  getData() {
    let params: any = { skip: (this.pageIndex - 1) * this.pageSize, take: this.pageSize };
    if (this.form.get('search')?.value) params.search = this.form.get('search')?.value;
    if (this.form.get('specialization_id')?.value && this.form.get('specialization_id')?.value != 'null') params.specialization_id = this.form.get('specialization_id')?.value;

    this._offers.getOffers(params).subscribe(response => {

      this.listOfData = response.data;

      this.total = response.total!;
      this.isTableLoaded = true;
    });
  }

  changePage(event: any) {
    this.pageIndex = event;
    this.getData();
  }


  sendFeedback(row: any, feedback: any) {
    this._offers.storeFeedback({ id: row.id, feedback: feedback }).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">' + "Feedback inoltrato con  successo" + '</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this.closeModalButton.nativeElement.click();
      this.getFeedback();
    })
  }


  exportExcel() {
    this._eventService.exportExcelEvent({ type: "offers" }).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = 'Offerte' + '.xlsx';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  datatable(event: any) {
    // * controllo la lunghezza per gestire il tasto Elimina
    let check = event.length;
    // if (check > 0) this.checkedPost = true;
    // else this.checkedPost = false;
    // this.rows = event;
  }

  getContractType(type: string) {
    let contract = this.contract_types.find(c_type => {
      return c_type.id == type;
    });

    return contract.name;
  }

  getSpecialization(id: any) {
    let specialization = this.specializations.find(specialization => {
      return specialization.id == id;
    });

    return specialization?.name ?? '---';
  }


  validateOffer(offer_id: string, validated: boolean) {
    this._offers.validateOffer({ offer_id, validated }).subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: 'Offerta ' + (validated == true ? 'validata' : 'rigettata'),
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        this.getData();
      })
    });
  }

  deleteOffer(offer_id: string) {
    this._offers.deleteOffer(offer_id).subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: 'Offerta rigettata',
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        this.getData();
      })
    });
  }
}
