<div class="m-auto col-11 mt-5">

    <h2 *ngIf="client == 1 && !isDetailPage"><span (click)="goBack()" class="clickable"><i
                class="fa-solid fa-arrow-left"></i></span> Gestione corsisti - {{((courseTitle != undefined &&
        courseTitle.length > 30)? (courseTitle | slice:0:30)+'...':(courseTitle)) + ', del ' +
        (managementForm.get('start_date')?.value | date: 'dd-MM-YYYY')}}</h2>

    <h2 *ngIf="client == 2 || isDetailPage"><span (click)="goBack()" class="clickable"><i
                class="fa-solid fa-arrow-left"></i></span> Dettaglio corso - {{((courseTitle != undefined &&
        courseTitle.length > 30)? (courseTitle | slice:0:30)+'...':(courseTitle)) + ', del ' +
        (managementForm.get('start_date')?.value | date: 'dd-MM-YYYY')}}</h2>


    <div [formGroup]="managementForm">
        <div class="row">
            <div class="col-12">
                <label class="form-label">Titolo</label>
                <p *ngIf="client == 2 || isDetailPage; else title">
                    {{courseTitle}}
                </p>
                <ng-template #title>
                    <nz-form-control>
                        <nz-select class="w-100" id="type" formControlName="external_course_type_id"
                            nzPlaceHolder="Seleziona la tipologia di corso">
                            <nz-option *ngFor="let type of types" [nzValue]="type.id"
                                [nzLabel]="type.title"></nz-option>
                        </nz-select>
                    </nz-form-control>
                    <div *ngIf="managementForm.get('external_course_type_id')?.hasError('required') && managementForm.get('external_course_type_id')?.touched"
                        class="text-danger">
                        Titolo è obbligatorio
                    </div>
                </ng-template>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <label class="form-label" nzFor="data">Data di inizio</label>
                <p *ngIf="client == 2 || isDetailPage; else startDate">
                    {{ managementForm.get('start_date')?.value | date: 'dd-MM-YYYY' }}
                </p>
                <ng-template #startDate>
                    <nz-form-control>
                        <nz-date-picker [nzFormat]="'dd-MM-yyyy'" [nzPlaceHolder]="'Seleziona data'" class="w-100"
                            formControlName="start_date">
                        </nz-date-picker>
                    </nz-form-control>
                    <div *ngIf="managementForm.get('start_date')?.hasError('required') && managementForm.get('start_date')?.touched"
                        class="text-danger">
                        Data di inizio è obbligatoria
                    </div>
                </ng-template>

            </div>

            <div class="col-12 col-md-6 mt-2">
                <label class="form-label" nzFor="data">Data di fine</label>
                <p *ngIf="client == 2 || isDetailPage; else endDate">
                    {{ managementForm.get('end_date')?.value | date: 'dd-MM-YYYY' }}
                </p>
                <ng-template #endDate>
                    <nz-form-control>
                        <nz-date-picker [nzFormat]="'dd-MM-yyyy'" class="w-100" [nzPlaceHolder]="'Seleziona data'"
                            formControlName="end_date">
                        </nz-date-picker>
                    </nz-form-control>
                    <div *ngIf="managementForm.get('end_date')?.hasError('required') && managementForm.get('end_date')?.touched"
                        class="text-danger">
                        Data di fine è obbligatoria
                    </div>
                    <div *ngIf="managementForm.get('end_date')?.hasError('dateTimeRange') && managementForm.get('end_date')?.touched"
                        class="text-danger">
                        La data di fine non può essere inferiore a quella di inizio
                    </div>
                </ng-template>
            </div>
        </div>


        <div class="mt-5" *ngIf="client == 1 && !isDetailPage">
            <p class="fs-7 mt-3 mt-sm-0"><span style="color: red;">*</span>Caricare il file csv contenente la
                lista dei corsisti</p>

            <nz-form-item>
                <nz-form-label>Carica corsisti</nz-form-label>
                <nz-form-control>
                    <section class="d-flex flex-row justify-content-start">
                        <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzMultiple]="true"
                            [nzAccept]="'.csv'" [nzBeforeUpload]="beforeUpload" (nzChange)="handleRemove()">
                            <button nz-button>
                                <span nz-icon nzType="upload"></span>
                                Seleziona file
                            </button>
                        </nz-upload>
                    </section>
                </nz-form-control>

            </nz-form-item>
        </div>




        <div class="mt-4 d-flex justify-content-end mt-2">
            <div *ngIf="!isDetailPage">
                <button class="me-1 btn grey space ng-star-inserted" (click)="goBack()">Annulla</button>
            </div>
            <div *ngIf="client == 1">
                <div *ngIf="!special_course_id">
                    <button [disabled]="!managementForm.valid" class="btn blu" (click)="onSubmit()">Salva</button>
                </div>
                <div *ngIf="special_course_id && !isDetailPage">
                    <button [disabled]="!managementForm.valid" class="btn blu" (click)="onUpdate()">Modifica</button>
                </div>
            </div>
        </div>
    </div>

</div>
