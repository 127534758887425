<div class="m-auto mt-5 col-11">
  <h2 class="mb-4">Bacheca</h2>
  <p class="size mb-5" *ngIf="!isCompany">In sintesi</p>

  <div *ngIf="isCompany">
    Benvenuta, {{user?.name}} in Agorà del lavoro dell'ordine degli ingegneri di Catania. Da questa sezione sarà
    possibile:<br><br>
    <ul>
      <li>Accedere a un'ampia rete di professionisti, ottimizzando la ricerca del candidato ideale in modo semplice ed
        efficiente.</li>
      <li>Filtrare sia le offerte di lavoro che le candidature per agevolare l'individuazione delle opportunità più
        adatte alle vostre esigenze.</li>
    </ul><br>
    Sfruttate al meglio queste opportunità messe in atto dall'Ordine degli Ingegneri di Catania
  </div>
  <div class="d-flex row col-sm-4 col-md-10 col-lg-10 mb-5 mobile gap-3 gap-xl-0">
    <div class="d-flex flex-row col-12 col-md-3" *ngFor="let category of allCategories">
      <a [href]="router.url + '/' + category.slug + '/' + category.id" *ngIf="category.slug != 'faq'" style="width:95%">
        <div class="card center">
          <div class="card-body">
            <img [src]="category.icon" alt="">
            <p class="size p-2">{{category.name}}</p>
            <p class="size_number">{{category.count}}</p>
          </div>
        </div>
      </a>
    </div>
  </div>
  <hr>

  <div *ngIf="!isCompany; else isCompanyTemplate">
    <h3 class="mb-4">Comunicazioni in evidenza</h3>
    <div *ngIf="stickyPost!.length > 0; else noComunication">
      <div class="d-flex row col-sm-4 col-md-10 col-lg-10 mb-5 gap-3 gap-xl-0"
        [ngClass]="stickyPost!.length > 4 ? 'responsive' : '' ">
        <div class="space d-flex flex-row col-12 col-md-3" *ngFor="let news of stickyPost">
          <a [routerLink]="'comunicazioni/1/' + (client == '1' ? 'edit-post/' : 'show/')  + news.id" style="width:95%">
            <div class="card center">
              <div class="card-body">
                <p class="size p-2" [innerHtml]="news.title"></p>
                <p class="hour p-2">{{news.date}}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <ng-template #isCompanyTemplate class="row">
    <h3 class="mb-4">Specialisti presenti in numero maggiore in piattaforma</h3>
    <div *ngIf="specialists!.length > 0">
      <div class="d-flex row col-sm-4 col-md-10 col-lg-10 mb-5 gap-3 gap-xl-0"
        [ngClass]="specialists!.length > 4 ? 'responsive' : '' ">
        <div class="space d-flex flex-row col-12 col-md-3" *ngFor="let specialist of specialists">
          <div class="card center" style="width:95%">
            <div class="card-body text-center d-flex flex-column aling-items-between">
              <p class="size p-2 mb-auto">{{specialist.specialization}}</p>
              <p class="hour p-2 fs-1">{{specialist.count}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <button class="btn blu text-uppercase" nz-button nzType="primary" (click)="_utils.setSelectedIndex(2)"
      [routerLink]="['/app/company/agora']">Inserisci una nuova offerta di lavoro</button>
  </ng-template>

  <ng-template #noComunication>
    <div class="d-flex flex-row col-sm-4 col-md-10 col-lg-10 mb-5">
      <div class="space">
        <p class="size p-2">
          <em>Nessuna comunicazione in evidenza</em>
        </p>
      </div>
    </div>
  </ng-template>


  <!-- <div>
      <canvas id="myChart"></canvas>
    </div> -->

</div>