<div>
  <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" nzPlacement="bottomRight">
    <nz-badge [nzCount]="count">
      <a class="head-example"></a>
      <img class="image" src="../../../assets/icons/notifications.png" alt="" width="30px">
    </nz-badge>
  </a>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>

      <ng-container>
        <div class="rectangle scroll">
          <nz-tabset>
            <nz-tab nzTitle="Tutte">
              <div class="row align-items-start cursor" *ngFor="let item of notificationsArray">
                <div class="col-12 my-2">
                  <div class="item row" (click)="getElement(item)">
                    <div class="col-1 pt-1">
                      <span [ngClass]="item.is_read != 1 ? 'blue' : ''"></span>
                    </div>
                    <div class="col-10 pt-1">
                      <li>
                        <h6>{{item.text}}</h6>
                        <p class="desc">{{item.description}}</p>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              <div class="show-more" *ngIf="notificationsArrayCount > show_notification_number"
                   (click)="show_notification_number = show_notification_number+10; getNotify()">
                Mostra di più
              </div>
              <div class="mt-4 nothingToRead" *ngIf="(notificationsArray.length == 0)">
                <span class="ms-3 mb-4 font-text">
                  Nessuna notifica</span>
                <br>
              </div>
            </nz-tab>


            <nz-tab nzTitle="Non lette">
              <div class="row align-items-start cursor " *ngFor="let item of notificationsToRead">
                <div class="col-12 my-2" *ngIf="(item.is_read == null)">
                  <div class="item row" (click)="getElement(item)">
                    <div class="col-1 pt-1">
                      <span [ngClass]="item.is_read != 1 ? 'blue' : ''"></span>
                    </div>
                    <div class="col-10 pt-1">
                      <li class="" [routerLink]="item.path">
                        <h6>{{item.text}}</h6>
                        <p class="desc">{{item.description}}</p>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
              <div class="show-more" *ngIf="notificationsToReadCount > show_not_read_notification_number"
                   (click)="show_not_read_notification_number = show_not_read_notification_number+10; getNotify()">
                Mostra di più
              </div>
              <div class="mt-4 nothingToRead" *ngIf="(notificationsToRead.length == 0)">
                <span class="ms-3 mb-4 font-text">
                  Nessuna notifica da leggere</span>
                <br>
              </div>
            </nz-tab>
          </nz-tabset>
        </div>
      </ng-container>
    </ul>
  </nz-dropdown-menu>
</div>
