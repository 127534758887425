<div class="m-auto mt-5 col-11">

  <div class="d-flex justify-content-between">
    <h2 class="mb-4">Lista ingegneri</h2>

    <div *ngIf="filtersApplied" class="alert alert-warning" role="alert">
      <i class="fa-solid fa-circle-info me-2"></i>
      <span>Stai visualizzando una lista filtrata</span>
    </div>
  </div>


  <div class="mt-4">
    <!--    <div class="mt-4 row" [formGroup]="filters">-->
    <!--      <div class="col">-->
    <!--        <input type="text" class="form-control" formControlName="registrationNumber"-->
    <!--          placeholder="Cerca per n. matricola...">-->
    <!--      </div>-->
    <!--      <div class="col">-->
    <!--        <input type="text" class="form-control" formControlName="name" placeholder="Cerca per nome...">-->
    <!--      </div>-->
    <!--      <div class="col">-->
    <!--        <input type="text" class="form-control" formControlName="lastname" placeholder="Cerca per cognome...">-->
    <!--      </div>-->
    <!--    </div>-->


    <!--EXPORT EXCEL-->
    <div class="row mt-2">
      <div class="col-12 col-md-3 mt-2">
        <button (click)="exportExcel()" type="button" class="btn blu btn-block-sm w-100"
          [disabled]="(data|async)?.length== 0">EXPORT LISTA INGEGNERI</button>
      </div>
      <div class="col-12 col-md-3 mt-2">
        <button (click)="showModal()" type="button" class="btn blu btn-block-sm w-100">IMPORT LISTA
          INGEGNERI</button>
      </div>
      <div class="col-12 col-md-3 mt-2">
        <button (click)="addEngineer()" type="button" class="btn blu btn-block-sm w-100">AGGIUNGI INGEGNERE</button>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-md-3 mt-2">
        <button (click)="onQueryBuilderOpen()" type="button" class="btn blu btn-block-sm w-100">RICERCA
          AVANZATA</button>
      </div>
      <div class="col-12 col-md-3 mt-2">
        <button (click)="onStaticDataOpen()" type="button" class="btn blu btn-block-sm w-100">DEFINISCI DATI
          STATICI</button>
      </div>
      <div class="col-12 col-md-3 mt-2" *ngIf="selected_rows.length > 0">
        <button (click)="applyStaticData()" type="button" class="btn blu btn-block-sm w-100">APPLICA DATI
          STATICI</button>
      </div>
    </div>

    <table mat-table class="box_super mt-4" [dataSource]="(data | async)" matSort
      (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"></th>
        <td mat-cell *matCellDef="let element" class="py-4 pe-4">
          <mat-checkbox (click)="onCheck(element)"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="registrationNumber">
        <th mat-sort-header mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> N. Matricola </th>
        <td mat-cell *matCellDef="let element" class="py-4">
          {{element.registrationNumber}}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastname">
        <th mat-sort-header mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Cognome </th>
        <td mat-cell *matCellDef="let element" class="py-4">
          {{element.lastname}}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-sort-header mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Nome </th>
        <td mat-cell *matCellDef="let element" class="py-4">
          {{element.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="sector">
        <th mat-sort-header mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Settore </th>
        <td mat-cell *matCellDef="let element" class="py-4">
          {{element.sector}}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> E-mail </th>
        <td mat-cell *matCellDef="let element" class="py-4">
          {{element.email}}
        </td>
      </ng-container>

      <ng-container matColumnDef="sospeso">
        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga"> Stato </th>
        <td mat-cell *matCellDef="let element" class="py-4">
          {{ element.sospeso == 0 ? 'Attivo' : 'Sospeso' }}
        </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="py-3 colore-riga testo-riga large"> Azione </th>
        <td mat-cell *matCellDef="let element" class="py-4 large">
          <button class="table-button blu" matTooltip="Scarica il CV" (click)="printCv(element.resource_id)">
            <i class="fa-solid fa-file-arrow-down"></i>
          </button>

          <button *ngIf="element.videocurriculum_request" class="table-button blu"
            matTooltip="Carica il videocurriculum" data-bs-toggle="modal" data-bs-target="#videocurriculumModal"
            (click)="selected_user = element">
            <i class="fa-solid fa-file-arrow-up"></i>
          </button>

          <button class="table-button blu" [matTooltip]="element.sospeso ? 'Attiva account' : 'Sospendi account'"
            (click)="changeStatus(element.id)">
            <i class="fa-solid" [ngClass]="element.sospeso ? 'fa-check' : 'fa-xmark'"></i>
          </button>

          <button class="table-button blu" [matTooltip]="'Anagrafica'"
            (click)="goTo('app/admin/engineers_list/detail/' + element.id)">
            <i class="fa-solid fa-user"></i>
          </button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="(records | async)" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="pageEvent = handlePage($event)" [pageIndex]="currentPage" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>

<!--IMPORT CSV-->
<nz-modal [(nzVisible)]="isVisible" nzTitle="Carica lista ingegneri" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()"
  [nzOkDisabled]="!form.valid">
  <ng-container *nzModalContent [formGroup]="form">
    <div class="row">
      <div class="col-12 mt-3">
        <nz-upload [(nzFileList)]="fileList" [nzMultiple]="false" [nzBeforeUpload]="beforeUpload" [nzAccept]="'.xlsx'">
          <button nz-button>
            <span nz-icon nzType="upload"></span>
            Seleziona file <span class="text-danger">*</span>
          </button>
        </nz-upload>
        <div *ngIf="form.get('file')!.invalid && (form.get('file')!.dirty || form.get('file')!.touched)">
          <div *ngIf="form.get('file')!.hasError('required')" class="text-danger">Il file è
            richiesto.</div>
          <div *ngIf="form.get('file')!.hasError('pattern')" class="text-danger">Il file è
            richiesto.</div>
        </div>
      </div>

      <div class="col-12 mt-3 mt-xl-0 text-xl-end text-md-end">
        <!-- Bottone di upload -->
        <button class="btn btn-out ms-auto d-none d-sm-block" nz-button [nzType]="'primary'" (click)="handleUpload()"
          [disabled]="fileList.length === 0">
          {{ uploading ? 'Caricato' : 'Carica File' }}
        </button>

        <!-- Gruppo di icona di upload e testo (visualizzato solo su schermi più piccoli di sm) -->
        <div class="d-flex d-sm-none align-items-center">
          <!-- Icona di upload -->
          <button class="btn btn-out d-flex justify-content-center" nz-button [nzType]="'primary'"
            (click)="handleUpload()" [disabled]="fileList.length === 0">
            <i class="fas fa-upload"></i>
          </button>

          <!-- Testo "Carica File" -->
          <div class="ms-2 me-2">
            {{ uploading ? 'Caricato' : 'Carica File' }}
          </div>
        </div>
        <p class="fs-7 mt-3 mt-sm-0">Caricare il file prima di salvare<span style="color: red;">*</span></p>
      </div>
    </div>
  </ng-container>
</nz-modal>


<div class="modal fade" id="videocurriculumModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Carica videocurriculum</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="onCloseModal()"></button>
      </div>

      <div class="modal-body">
        <div class="col-12 mt-2 mb-2">
          <label for="link" class="mb-2">
            Inserisci il link del videocurriculum di {{selected_user?.lastname}} {{selected_user?.name}}
            ({{selected_user?.registrationNumber}}):
          </label>
          <input id="link" type="text" class="form-control" [formControl]="link" placeholder="Link"
            [ngClass]="{'is-invalid': link?.hasError('linkError')}">
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onCloseModal()">Chiudi</button>
        <button type="button" class="btn btn-primary" (click)="onSaveModal()" data-bs-dismiss="modal"
          [disabled]="!link.value || link.invalid">Salva</button>
      </div>

    </div>
  </div>
</div>

<nz-modal [(nzVisible)]="isVisibleQueryBuilder" [nzStyle]="{ width: '75%' }" nzCentered nzClosable="false"
  [nzTitle]="title" [nzContent]="content" [nzFooter]="footer">

  <ng-template #title>
    <h4 class="blue-title" style="margin-bottom: 0px;">Impostazione dei criteri di ricerca</h4>
  </ng-template>

  <ng-template #content>
    <div [formGroup]="queryBuilderForm">
      <div formArrayName="formArray">
        <div *ngFor="let item of formArray.controls; let i = index; let first = first">
          <div [formGroupName]="i" class="row mb-4">

            <div class="col-2" *ngIf="formArray.controls.length > 1">
              <nz-radio-group formControlName="clause" *ngIf="!first">
                <label class="fw-normal" *ngFor="let option of clauseOptions" nz-radio [nzValue]="option.value">{{
                  option.label }}</label>
              </nz-radio-group>
            </div>

            <div class="col-3">
              <nz-select class="w-100 form-control p-0" nzBorderless nzAllowClear nzShowSearch formControlName="control"
                (ngModelChange)="onSelectChange(i)" nzPlaceHolder="Scegli il campo da filtrare"
                [ngClass]="item.get('control')?.invalid && item.get('control')?.touched ? 'input-error': ''">
                <nz-option-group [nzLabel]="option.key" *ngFor="let option of queryBuilderFieldsList | keyvalue">
                  <nz-option *ngFor="let opt of option.value" [nzValue]="opt.control" [nzLabel]="opt.label"></nz-option>
                </nz-option-group>
              </nz-select>
            </div>

            <div class="col-2">
              <nz-select class="w-100 form-control p-0" nzBorderless formControlName="symbol"
                *ngIf="item.get('control')?.value"
                [ngClass]="item.get('symbol')?.invalid && item.get('symbol')?.touched ? 'input-error': ''">
                <nz-option *ngFor="let option of getSymbols(i)" [nzValue]="option.value"
                  [nzLabel]="option.value"></nz-option>
              </nz-select>
            </div>

            <div class="col-3" [ngSwitch]="getFormArrayElement(i)?.type">
              <div *ngSwitchCase="'text'">
                <input nz-input type="text" formControlName="value"
                  [ngClass]="item.get('value')?.invalid && item.get('value')?.touched ? 'input-error': ''">
              </div>
              <div *ngSwitchCase="'date'">
                <nz-date-picker nzPlaceHolder="dd-mm-yyyy" class="w-100" nzFormat="dd-MM-yyyy" formControlName="value"
                  [ngClass]="item.get('value')?.invalid && item.get('value')?.touched ? 'input-error': ''">
                </nz-date-picker>
              </div>
              <div *ngSwitchCase="'number'">
                <input type="number" class="form-control" formControlName="value"
                  [ngClass]="item.get('value')?.invalid && item.get('value')?.touched ? 'input-error': ''">
              </div>
              <div *ngSwitchCase="'multi-select'">
                <nz-select class="w-100 form-control p-0" nzBorderless nzMode="tags" formControlName="value"
                  nzShowSearch [nzMaxMultipleCount]="1"
                  [ngClass]="item.get('value')?.invalid && item.get('value')?.touched ? 'input-error': ''">
                  <nz-option *ngFor="let option of specializations" [nzLabel]="option.name"
                    [nzValue]="option.name"></nz-option>
                </nz-select>
              </div>
              <div *ngSwitchDefault>
                <nz-select class="w-100 form-control p-0" nzBorderless formControlName="value"
                  *ngIf="item.get('control')?.value"
                  [ngClass]="item.get('value')?.invalid && item.get('value')?.touched ? 'input-error': ''">
                  <nz-option *ngFor="let option of getFormArrayElement(i)?.values" [nzValue]="option.value"
                    [nzLabel]="option.label"></nz-option>
                </nz-select>
              </div>
            </div>

            <div class="col-2">
              <button (click)="addItem()" class="btn blu icons me-2">
                <i class="fa-solid fa-plus"></i>
              </button>
              <button (click)="removeItem(i)" class="btn rosso icons">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #footer>
    <div class="d-flex justify-content-end">
      <button nz-button class="btn blu me-2" (click)="onQueryBuilderSubmit()">
        ESEGUI
      </button>
      <button nz-button class="btn rosso me-2" (click)="onQueryBuilderReset()">
        RESET
      </button>
      <button nz-button class="btn colored-border" (click)="onQueryBuilderClose()">
        CHIUDI
      </button>
    </div>
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="isVisibleStaticData" [nzStyle]="{ width: '75%' }" nzCentered nzClosable="false"
  [nzTitle]="staticTitle" [nzContent]="staticContent" [nzFooter]="staticFooter">

  <ng-template #staticTitle>
    <h4 class="blue-title" style="margin-bottom: 0px;">Definizione dei dati statici</h4>
  </ng-template>

  <ng-template #staticContent>
    <div [formGroup]="staticDataForm">
      <div formArrayName="staticDataFormArray">
        <div *ngFor="let item of staticDataFormArray.controls; let i = index; let first = first">
          <div [formGroupName]="i" class="row mb-4">

            <div class="col-5">
              <nz-select class="w-100 form-control p-0" nzBorderless nzAllowClear nzShowSearch formControlName="control"
                (ngModelChange)="onStaticDataSelectChange(i)" nzPlaceHolder="Scegli il campo"
                [ngClass]="item.get('control')?.invalid && item.get('control')?.touched ? 'input-error': ''">
                <nz-option-group [nzLabel]="option.key" *ngFor="let option of staticDataFieldsList | keyvalue">
                  <nz-option *ngFor="let opt of option.value" [nzValue]="opt.control" [nzLabel]="opt.label"></nz-option>
                </nz-option-group>
              </nz-select>
            </div>

            <div class="col-5" [ngSwitch]="getStaticDataFormArrayElement(i)?.type">
              <div *ngSwitchCase="'text'">
                <input nz-input type="text" formControlName="value"
                  [ngClass]="item.get('value')?.invalid && item.get('value')?.touched ? 'input-error': ''">
              </div>
              <div *ngSwitchCase="'date'">
                <nz-date-picker nzPlaceHolder="dd-mm-yyyy" class="w-100" nzFormat="dd-MM-yyyy" formControlName="value"
                  [ngClass]="item.get('value')?.invalid && item.get('value')?.touched ? 'input-error': ''">
                </nz-date-picker>
              </div>
              <div *ngSwitchCase="'number'">
                <input type="number" class="form-control" formControlName="value"
                  [ngClass]="item.get('value')?.invalid && item.get('value')?.touched ? 'input-error': ''">
              </div>
              <div *ngSwitchCase="'multi-select'">
                <nz-select class="w-100 form-control p-0" nzBorderless nzMode="tags" formControlName="value"
                  nzShowSearch [nzMaxMultipleCount]="1"
                  [ngClass]="item.get('value')?.invalid && item.get('value')?.touched ? 'input-error': ''">
                  <nz-option *ngFor="let option of specializations" [nzLabel]="option.name"
                    [nzValue]="option.name"></nz-option>
                </nz-select>
              </div>
              <div *ngSwitchDefault>
                <nz-select class="w-100 form-control p-0" nzBorderless formControlName="value"
                  *ngIf="item.get('control')?.value"
                  [ngClass]="item.get('value')?.invalid && item.get('value')?.touched ? 'input-error': ''">
                  <nz-option *ngFor="let option of getStaticDataFormArrayElement(i)?.values" [nzValue]="option.value"
                    [nzLabel]="option.label"></nz-option>
                </nz-select>
              </div>
            </div>

            <div class="col-2">
              <button (click)="addItemStaticData()" class="btn blu icons me-2">
                <i class="fa-solid fa-plus"></i>
              </button>
              <button (click)="removeItemStaticData(i)" class="btn rosso icons">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #staticFooter>
    <div class="d-flex justify-content-end">
      <button nz-button class="btn blu me-2" (click)="onStaticDataSubmit()">
        SALVA
      </button>
      <button nz-button class="btn rosso me-2" (click)="onStaticDataReset()">
        RESET
      </button>
      <button nz-button class="btn colored-border" (click)="onStaticDataClose()">
        CHIUDI
      </button>
    </div>
  </ng-template>
</nz-modal>