<div class="mx-5 my-4">
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="pb-0 mb-0">Richiesta di iscrizione</h3>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
  </div>
  <p class="text-black">Richiesta Inclusione negli elenchi del Ministero dell’Interno</p>

  <p class="text-black">In possesso dell’attestazione di frequenza con esito positivo del Corso base di specializzazione in materia di Prevenzione Incendi ai fini dell’iscrizione negli elenchi del Ministero dell’Interno</p> 

  <form [formGroup]="form_previncendi" class="mt-3">


    <div class="my-3">

      <p class="text-black">dichiarare:</p>

      <!-- <label for="qualifica" class="mt-2">Conseguito presso</label>
      <input type="text"  class="col-12 form-control" formControlName="iscrizioneElMin"> -->

      <!--? Conseguito presso  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">Conseguito presso</p>
        </div>
        <div class="col-sm-6 col-md-8">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" class="text" matInput formControlName="iscrizioneElMin" required>
          </mat-form-field>
        </div>
      </div>

      <!--? Data conseguimento  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">Data conseguimento</p>
        </div>
        <div class="col-sm-6 col-md-8">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="date" class="text" matInput formControlName="periodoSvolgimento" required>
          </mat-form-field>
        </div>
      </div>
      
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn aggiorna mt-3 px-4" (click)="invia()">Invia</button>
    </div>
  </form>
</div>