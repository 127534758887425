<div class="m-auto mt-5 col-11">
    <h2 class="mb-4">Agorà del lavoro</h2>
    <!-- <p class="size mb-5">In sintesi</p> -->

    <div class="d-flex flex-row col-sm-4 col-md-12 mb-5 responsive">
        <nz-tabset nzSize="large" class="col-12"  [nzSelectedIndex]="selectedIndex">
            <nz-tab nzTitle="Aziende" *ngIf="client == '1'">
                <app-companies-list></app-companies-list>
            </nz-tab>
            <nz-tab nzTitle="Offerte">
                <app-offers-list></app-offers-list>
            </nz-tab>
            <nz-tab nzTitle="Nuova offerta" *ngIf="['1', '5'].includes(client!)">
                <app-new-offer></app-new-offer>
            </nz-tab>
            <nz-tab nzTitle="Le mie offerte" *ngIf="client == '2'">
                <app-offers-history></app-offers-history>
            </nz-tab>
        </nz-tabset>
    </div>
</div>
