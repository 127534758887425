import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Company } from '../models/company.model';
import { FormattedResponse } from '../models/formatted-response';
import { Mode } from '../models/utils.model';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  constructor(private http: HttpClient) { }
  offer: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  mode: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.SHOW);
  refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getOffers(params?: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/offers`, { params });
  }

  getOfferById(id: string, params?: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/offers/${id}`, { params });
  }

  createOffer(offer: any): Observable<FormattedResponse<any>> {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/offers`, offer);
  }

  updateOffer(offerId: string, offer: any): Observable<FormattedResponse<any>> {
    return this.http.put<FormattedResponse<any>>(`${environment.api}/offers/${offerId}`, offer);
  }

  validateOffer(params?: any): Observable<FormattedResponse<any>> {
    return this.http.put<FormattedResponse<any>>(`${environment.api}/offers/validate`, params);
  }

  deleteOffer(offerId: any): Observable<FormattedResponse<null>> {
    return this.http.delete<FormattedResponse<null>>(`${environment.api}/offers/${offerId}`);
  }

  sendNomination(nomination: any) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/nominations`, nomination);
  }

  deleteNomination(id: any) {
    return this.http.delete<FormattedResponse<any>>(`${environment.api}/nominations/${id}`);
  }

  getNominations(params?: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/nominations`, { params });
  }


  //API PER SISTEMA DI FEEDBACK//
  storeFeedback(nomination: any) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/storeFeedback`, nomination);
  }

  getFeedback(params?: any): Observable<FormattedResponse<any>> {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/notificationAgora`, { params });
  }
}
