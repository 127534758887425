<!--COURSE-DETAIL-->
<app-users-management></app-users-management>

<!--REGISTERED-USER-DATATABLE-->
<div class="m-auto col-11 mt-5">

    <div class="mt-4">
        <h5>Iscritti</h5>
        <nz-datatable [columns]="registered_users_columns" [data]="registered_users_data"
            [data_count]="registered_users_count" (action_clicked)="onActionClick($event)"
            [row_actions]="role_id == 1 ? true : false" (sort_changed)="onSortChange($event)"
            (page_changed)="onPageChange($event)">
        </nz-datatable>
    </div>

    <div class="mt-4 d-flex justify-content-end mt-2">
        <div>
            <button class="me-1 btn grey space ng-star-inserted" (click)="goBack()">Annulla</button>
        </div>
    </div>

    <nz-modal [(nzVisible)]="isVisibleModal" [nzStyle]="{ width: '50%' }" nzCentered (nzOnCancel)="handleCancelCreate()"
        [nzTitle]="'Carica attestato'" [nzContent]="content" [nzFooter]="footer">
    </nz-modal>

    <ng-template #footer>
        <button type="button" class="btn grey" data-bs-dismiss="modal" (click)="_initAllegaFile()">ANNULLA</button>
        <button type="button" class="ms-1 btn blu" (click)="uploadFile()" data-bs-dismiss="modal"
            [disabled]="fileList.length == 0">CARICA</button>
    </ng-template>


    <!-- Modal CARICA ATTESTATO -->

    <ng-template #content>
        <label class="color me-2">Allegato</label>
        <br>
        <nz-form-item class="mt-3">
            <nz-form-control>
                <section class="d-flex flex-row justify-content-start">
                    <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzMultiple]="false"
                        [nzBeforeUpload]="beforeUpload">
                        <button nz-button class="color">
                            <span nz-icon nzType="upload"></span>
                            Aggiungi file
                        </button>
                    </nz-upload>
                </section>
            </nz-form-control>
        </nz-form-item>
    </ng-template>
</div>