import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }
  transform(objArr: any) {
    // //console.log('obj array looks like this: ', objArr);
    let mappingObject: any = {};
    switch (objArr) {
      case 'author':
        return (mappingObject = 'Autore');
      case 'title':
        return (mappingObject = 'Titolo');
      case 'tag':
        return (mappingObject = 'Tag');
      case 'date':
        return (mappingObject = 'Data inizio evento');
      case 'start_date':
        return (mappingObject = 'Data inizio evento');
      case 'seats':
        return (mappingObject = 'iscritti / totali');
      case 'cfp':
        return (mappingObject = 'CFP');
      case 'duration':
        return (mappingObject = 'Durata');
      case 'subscribe':
        // return mappingObject = 'Iscriviti' -> da riattivare quando ci sarà il pagamento
        return (mappingObject = 'Iscriviti');
      case 'already subscribed':
        return (mappingObject = 'Iscritto');
      case 'preparation':
        return (mappingObject = 'In preparazione');
      case 'name':
        return (mappingObject = 'Nome');
      case 'lastname':
        return (mappingObject = 'Cognome');
      case 'sub_date':
        return (mappingObject = 'Data');
      case 'sub_status':
        return (mappingObject = 'Ammessi');
      case 'request_certificate':
        return (mappingObject = 'Attestato');
      case 'request_attestato':
        return (mappingObject = 'Richiedi Attestato');
      case 'accetta':
        return (mappingObject = 'Consegna attestato');
      case 'waiting':
        return (mappingObject = 'In attesa');
      case 'terne':
        return (mappingObject = 'Richieste');
      case 'path':
        return (mappingObject = 'File');
      case 'list':
        return (mappingObject = 'Lista');
      case 'comunication_date':
        return (mappingObject = 'Data comunicazione');;
      case 'created_at':
        return (mappingObject = 'Data di creazione');
      case 'event_start_date':
        return (mappingObject = 'Data Evento');
      case 'data_scadenza':
        return (mappingObject = 'Data Scadenza');
      case 'registrationNumber':
        return (mappingObject = 'N. Matricola');
      case 'fiscalCode':
        return (mappingObject = 'Codice fiscale');
      case 'free':
        return (mappingObject = 'Gratuito');
      default:
        return;
    }
  }
}
