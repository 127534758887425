import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { EventService } from 'src/app/modules/application/services/event.service';
import { NewsletterService } from 'src/app/modules/application/services/newsletter.service';
//import { CompaniesService } from 'src/app/shared/services/companies.service';
import { CompaniesService } from 'src/app/modules/application/services/companies.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import Swal from 'sweetalert2';
import { OffersService } from 'src/app/shared/services/offers.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ResourcesServices } from 'src/app/shared/services/resources.service';


@Component({
  selector: 'app-nominations-list',
  templateUrl: './nominations-list.component.html',
  styleUrls: ['./nominations-list.component.scss']
})
export class NominationsListComponent implements OnInit {
  public columns: { key: string, name: string, type: string }[] = [
    { key: 'registrationNumber', name: 'Cod. Iscrizione', type: 'string' },
    { key: 'name', name: 'Nominativo', type: 'name' },
    { key: 'email', name: 'E-mail', type: 'string' },
    { key: 'telephone', name: 'Telefono', type: 'string' },
    { key: 'specialization', name: 'Specializzazione', type: 'string' },
    { key: 'actions', name: 'Azioni', type: 'actions' },
  ];
  public listOfData: any[] = [];
  public pageIndex = 1;
  public pageSize = 10;
  public total = 0;
  public isTableLoaded: boolean = false;
  public search: FormControl = new FormControl(null);
  searchDelay: number = 500;

  constructor(
    private _offers: OffersService,
    private _auth: AuthService,
    private _eventService: EventService,
    private route: ActivatedRoute,
    private _resource: ResourcesServices,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getData();

    this.search.valueChanges.pipe(debounceTime(this.searchDelay))
      .subscribe((value) => {
        this.getData();
      });
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getData();
    return event;
  }

  getData() {
    // this.displayTable = false;
    let params: any = { skip: 0, take: this.pageSize, offer_id: this.route.snapshot.params['id'] };
    if (this.search.value) params.search = this.search.value;

    this._offers.getNominations(params).subscribe(response => {
      this.listOfData = response.data;
      this.total = response.total!;
      this.isTableLoaded = true;
    });
  }

  changePage(event: any) {
    this.pageIndex = event;
    this.getData();
  }

  exportExcel() {
    this._eventService.exportExcelEvent({ type: "newsletter" }).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = 'newsletter' + '.xlsx';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  printCv(row: any, type: string) {
    switch (type) {
      case 'cv': {
        if (row?.cv_url) {
          window.open(row?.cv_url, '_blank');
        } else if (row?.user?.resource?.id) {
          this._resource.printCV(row?.user?.resource?.id).subscribe((blob: any) => {
            const blobFile: Blob = new Blob([blob], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(blobFile);
            window.open(fileURL, '_blank');
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '<div style="font-size:18px;">File del CV non trovato</div>',
            showConfirmButton: false,
            timer: 3000,
          });
        }
        break;
      }
      case 'video': {
        window.open(row?.user?.resource?.videocurriculum_request?.link, '_blank');
        break;
      }
    }
  }

  datatable(event: any) {
    // * controllo la lunghezza per gestire il tasto Elimina
    let check = event.length;
    // if (check > 0) this.checkedPost = true;
    // else this.checkedPost = false;
    // this.rows = event;
  }
}
