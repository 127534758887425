import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { UserService } from 'src/app/modules/application/services/user.service';
import { BehaviorSubject } from 'rxjs';
import * as moment from "moment/moment";
@Component({
  selector: 'app-registrati',
  templateUrl: './registrati.component.html',
  styleUrls: ['./registrati.component.scss']
})
export class RegistratiComponent implements OnInit {
  isPasswordShowed: boolean = false;
  isConfirmPasswordShowed: boolean = false;
  registerForm: FormGroup | any;
  //ruoli: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService
  ) {

    this.registerForm = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.pattern("([-'a-zA-ZÀ-ÖØ-öø-ÿ]{1,}(\\s{1})?){1,}")])],
      lastname: [null, Validators.compose([Validators.required, Validators.pattern("([-'a-zA-ZÀ-ÖØ-öø-ÿ]{1,}(\\s{1})?){1,}")])],
      username: [null, Validators.compose([Validators.required, Validators.pattern("(?=[a-zA-Z0-9._]{8,20}$)(?!.*[_.]{2})[^_.].*[^_.]")])],
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
      password: [null, Validators.compose([Validators.required, Validators.pattern(/(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.,_]).{8,}$/)])],
      password_confirmation: [null, Validators.compose([Validators.required, this.passwordValidation])],
      origin: [null, Validators.required],
      fiscalcode: [null, [Validators.required, this.isValidFiscalCode]],
      role_id: [3, Validators.required]
    })
  }

  ngOnInit(): void {
    this.registerForm.get('origin').setValue(this.route.snapshot.queryParams['origin'] ?? 'ordine');
    //this.getRoles();
  }

  isValidFiscalCode(control: any): { [key: string]: any } | null {
    const fiscalcodeRegex = /^[A-Za-z]{6}\d{2}[A-Za-z]\d{2}[A-Za-z]\d{3}[A-Za-z]$/;
    const isValid = fiscalcodeRegex.test(control.value);

    return isValid ? null : { 'invalidFiscalCode': { value: control.value } };
  }


  changePasswordInput(input: any, type: string): any {
    input.type = input.type === 'password' ? 'text' : 'password';

    if (type == 'password') this.isPasswordShowed = input.type == 'text';
    else this.isConfirmPasswordShowed = input.type == 'text';
  }

  // getRoles() {
  //   this.userService.getRoles().subscribe(response => {
  //     this.ruoli.next(response.data);
  //   })
  // }

  onSubmit() {
    this.authService.register(this.registerForm.value).subscribe({
      next: response => {
        localStorage.setItem('email', response.data.email);
        this.router.navigate(['/confirmation-register']);
      },
      error: response => {
        let errors = JSON.parse(response);

        for (let key in errors) {
          this.registerForm.get(key).setErrors({ validationError: errors[key] })
        }

        Swal.fire({
          icon: 'error',
          title: '<div style="font-size:16px; text-align:center"> Registrazione non avvenuta </div>',
          target: '#custom-target',
          toast: true,
          position: 'top',
          timer: 2500,
          showConfirmButton: false,
          timerProgressBar: true
        })
      },
    });
  }

  passwordValidation: ValidatorFn = (): ValidationErrors | null => {
    let password = this.registerForm?.get('password')?.value;
    let password_confirmation = this.registerForm?.get('password_confirmation')?.value;

    if (password != password_confirmation)
      return { error: true };

    return null;
  }
}
