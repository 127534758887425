<form [formGroup]="form">
  <!-- * Ruolo -->
  <div class="d-flex flex-row justify-content-between mx-0 px-0">
    <h5 class="mx-2 px-1 my-3 fs-18">{{ config.title }}</h5>

    <!-- <h5 class="cursor-pointer mt-3" [routerLink]="">
      <span class="badge bg-secondary">Consulta CV</span>
    </h5> -->
  </div>

  <div class="row d-flex flex-row flex-wrap px-0 mx-0">
    <ng-container *ngFor="let field of config.fields">
      <ng-container *ngIf="!field.visibleConditions || field.conditions()">
        <ng-container [ngSwitch]="field.type">
          <!-- * Input text -->
          <ng-container *ngSwitchCase="'text'">
            <div class="mb-3" [ngClass]="field.column">
              <label [for]="field.key" class="form-label">{{ field.label }} <span class="text-danger"
                  *ngIf="field.validators?.includes('required'); else optional">*</span></label>
              <input type="text" class="form-control" [formControlName]="field.key" [id]="field.key"
                [attr.aria-describedby]="field.key" [readOnly]="field.disabled"
                [placeholder]="field.placeholder ? field.placeholder : ''"
                [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched }">
              <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched" class="text-danger">
                {{field.label}} richiesto.
              </div>
            </div>
          </ng-container>

          <!-- * Input password -->
          <ng-container *ngSwitchCase="'password'">
            <div class="mb-3" [ngClass]="field.column">
              <label [for]="field.key" class="form-label">{{ field.label }} <span class="text-danger"
                  *ngIf="field.validators?.includes('required'); else optional">*</span> </label>
              <input type="password" class="form-control" [formControlName]="field.key" [id]="field.key"
                [attr.aria-describedby]="field.key" [readOnly]="field.disabled"
                [placeholder]="field.placeholder ? field.placeholder : ''"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&]).{8,}$">
              <div class="col-10" *ngIf="!field.key.includes('confirm')">
                <span class="fs-10">La password deve contenere almeno una lettera minuscola, una maiuscola, un carattere
                  speciale, e la lunghezza deve essere almeno 8 caratteri.</span>
              </div>
              <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched" class="text-danger">
                {{field.label}} non valida.
              </div>

            </div>
          </ng-container>


          <!-- * Input Date -->
          <ng-container *ngSwitchCase="'date'">
            <div class="mb-3" [ngClass]="field.column">
              <label [for]="field.key" class="form-label">{{ field.label }} <span class="text-danger"
                  *ngIf="field.validators?.includes('required'); else optional">*</span> </label>
              <input type="date" class="form-control" [formControlName]="field.key" [id]="field.key"
                [attr.aria-describedby]="field.key" [readOnly]="field.disabled"
                [placeholder]="field.placeholder ? field.placeholder : ''" [min]="field.min ? field.min : null"
                [max]="field.max ? field.max : null"
                [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched }">
              <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched" class="text-danger">
                {{field.label}} richiesto.
              </div>
            </div>
          </ng-container>

          <!-- * Input Number -->
          <ng-container *ngSwitchCase="'number'">
            <div class="mb-3" [ngClass]="field.column">
              <label [for]="field.key" class="form-label">{{ field.label }} <span class="text-danger"
                  *ngIf="field.validators?.includes('required'); else optional">*</span> </label>
              <input type="number" class="form-control" [formControlName]="field.key" [id]="field.key"
                [attr.aria-describedby]="field.key" [readOnly]="field.disabled"
                [placeholder]="field.placeholder ? field.placeholder : ''"
                [min]="field.min ? field.min : -99999999999999" [max]="field.max ? field.max : 99999999999999"
                [step]="field.step ? field.step : null">
            </div>
          </ng-container>

          <!-- * Input Currency -->
          <ng-container *ngSwitchCase="'currency'">
            <div [ngClass]="field.column">
              <label [for]="field.key" class="form-label">{{ field.label }} <span class="text-danger"
                  *ngIf="field.validators?.includes('required'); else optional">*</span> </label>
              <div class="mb-3 input-group">
                <span class="input-group-text">€</span>
                <input type="text" class="form-control" [formControlName]="field.key" [id]="field.key"
                  [attr.aria-describedby]="field.key" [readOnly]="field.disabled"
                  [placeholder]="field.placeholder ? field.placeholder : ''" [min]="field.min ? field.min : null"
                  [max]="field.max ? field.max : null" [step]="field.step ? field.step : null">
              </div>
            </div>
          </ng-container>

          <!-- * Input Percentage -->
          <ng-container *ngSwitchCase="'percentage'">
            <div [ngClass]="field.column">
              <label [for]="field.key" class="form-label">{{ field.label }} <span class="text-danger"
                  *ngIf="field.validators?.includes('required'); else optional">*</span> </label>
              <div class="mb-3 input-group">
                <span class="input-group-text">%</span>
                <input type="text" class="form-control" [formControlName]="field.key" [id]="field.key"
                  [attr.aria-describedby]="field.key" [readOnly]="field.disabled"
                  [placeholder]="field.placeholder ? field.placeholder : ''" [min]="field.min ? field.min : null"
                  [max]="field.max ? field.max : null" [step]="field.step ? field.step : null">
              </div>
            </div>
          </ng-container>

          <!-- * Select -->
          <ng-container *ngSwitchCase="'select'">
            <div class="mb-3" [ngClass]="field.column">
              <label [for]="field.key" class="form-label">{{ field.label }} <span class="text-danger"
                  *ngIf="field.validators?.includes('required'); else optional">*</span> </label>
              <select class="form-select" [formControlName]="field.key">
                <option *ngFor="let option of field.options" [value]="option.value" [disabled]="field.disabled">{{
                  option.label }}</option>
              </select>
              <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched" class="text-danger">
                {{field.label}} richiesto.
              </div>
            </div>
          </ng-container>

          <!-- * Checkbox -->
          <ng-container *ngSwitchCase="'checkbox'">
            <div class="mb-3" [ngClass]="field.column">
              <div class="form-check mt-4 pt-3">
                <input class="form-check-input" type="checkbox" [formControlName]="field.key" [id]="field.key">
                <label class="form-check-label" [for]="field.key">{{field.label}}</label>
              </div>
            </div>
          </ng-container>

          <!-- * Textarea -->
          <ng-container *ngSwitchCase="'textarea'">
            <div class="mb-3" [ngClass]="field.column">
              <label [for]="field.key" class="form-label">{{ field.label }} <span class="text-danger"
                  *ngIf="field.validators?.includes('required'); else optional">*</span> </label>
              <textarea [class]="field.class" [id]="field.key" [formControlName]="field.key" [readOnly]="field.disabled"
                [attr.aria-describedby]=" field.key"></textarea>
            </div>
          </ng-container>


          <!-- * Switch -->
          <ng-container *ngSwitchCase="'switch'">
            <div class="mb-3" [ngClass]="field.column">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                <label class="form-check-label" for="flexSwitchCheckDefault">{{field.label}}</label>
              </div>
            </div>
          </ng-container>

          <!-- * Autocomplete by Ng-zorro -->
          <ng-container *ngSwitchCase="'ng-auto'">
            <div class="mb-3" [ngClass]="field.column">
              <label [for]="field.key" class="form-label">{{field.label}} <span class="text-danger"
                  *ngIf="field.validators?.includes('required'); else optional">*</span></label>
              <input type="text" class="form-control" [nzAutocomplete]="qualificationAuto" [formControlName]="field.key"
                (input)="field.input ? field.input(ngzorroauto.value) : null" #ngzorroauto
                placeholder="{{field.placeholder}}"
                [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched }">
              <nz-autocomplete #qualificationAuto>
                <nz-auto-option #optionValue *ngFor="let option of field.options" [nzLabel]="option.name"
                  [nzValue]="option">
                  {{ option.name}}
                </nz-auto-option>
              </nz-autocomplete>
            </div>
          </ng-container>

          <!-- * Autocomplete by Material -->
          <ng-container *ngSwitchCase="'material-auto'">
            <div class="mb-3" [ngClass]="field.column">

              <label [for]="field.key" class="form-label">{{field.label}} <span class="text-danger"
                  *ngIf="field.validators?.includes('required'); else optional">*</span></label>
              <input type="text" class="custom-input form-control" #materialauto [matAutocomplete]="auto"
                [formControlName]="field.key"
                [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched }"
                (input)="field.input ? field.input(materialauto.value) : null">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let item of field.options" [value]="item">
                  {{item.name}}
                </mat-option>
              </mat-autocomplete>
            </div>
          </ng-container>



          <!--* Multi-select Autocomplete-->
          <ng-container *ngSwitchCase="'multi-select-autocomplete'">
            <div class="mb-3" [ngClass]="field?.column!" *ngIf="!field?.hidden">
              <div>
                <label [for]="field?.key" class="form-label">{{ field?.label }} <span class="text-danger"
                    *ngIf="field.validators?.includes('required'); else optional">*</span></label>
                <input class="form-control custom-input" [placeholder]="field.placeholder ? field.placeholder : ''"
                  #itemInput #autocompleteTrigger="matAutocompleteTrigger"
                  (keyup)="onkeyUpChangeAutoComplete(itemInput.value,field)" [formControlName]="field.key"
                  [matAutocomplete]="auto"
                  [ngClass]="{'is-invalid': form.get(field.key)?.invalid && form.get(field.key)?.touched}" />
                <!-- Visualizza i valori selezionati internamente -->
                <div *ngIf="selectedItem.length > 0" class="selected-values-container">
                  <span *ngFor="let selectedOption of selectedItemLabel" class="selected-item">
                    {{ selectedOption }}
                    <span class="trash-icon" (click)="remove(selectedOption,'professional_role_id')" nz-icon
                      nzType="delete" nzTheme="outline"></span>
                  </span>
                </div>
                <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayFnMulti">
                  <mat-option *ngFor="let option of field.filteredOptions" [value]="option"
                    (click)="add(option,field.key)">
                    <mat-checkbox color="primary" style="padding: 0 12px"
                      [checked]="selectedItem.indexOf(option.value) >= 0"></mat-checkbox>
                    {{option.label}}
                  </mat-option>
                </mat-autocomplete>
              </div>
              <div *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched" class="text-danger">
                {{ field.label }} ri<span class="text-danger" *ngIf="field.validators?.includes('required')" ; else
                  #optional>*</span> chiesto.
              </div>
            </div>
          </ng-container>


          <!-- * Empty -->
          <ng-container *ngSwitchCase="'empty'">
            <div class="mb-3" [ngClass]="field?.column!">
            </div>
          </ng-container>

        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</form>

<ng-template #optional>(opzionale)</ng-template>