import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RapidLinkService {

    constructor(private http: HttpClient) { }

    getList(params?: any) {
        return this.http.post<FormattedResponse<any[]>>(`${environment.api}/get-rapid-link`, params);
    }

    createLink(params?: any) {
        return this.http.post<FormattedResponse<any>>(`${environment.api}/rapid-link`, params);
    }

    updateLink(id: string, params: any) {
        return this.http.put<FormattedResponse<any>>(`${environment.api}/rapid-link/${id}`, params);
    }

    deleteMultipleLink(ids: number[]) {
        return this.http.post<FormattedResponse<any>>(`${environment.api}/rapid-link/delete`, {ids});
    }
}
