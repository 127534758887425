import {Component, Input, OnInit} from '@angular/core';
import { UtilsServices } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-agora',
  templateUrl: './agora.component.html',
  styleUrls: ['./agora.component.scss']
})
export class AgoraComponent implements OnInit {
  client = localStorage.getItem('role');
  selectedIndex = null;

  constructor(
    private _utils: UtilsServices
  ) { }

  ngOnInit() {
    this._utils.getSelectedIndex().subscribe({
      next: value => {
        this.selectedIndex = value;
      }
    })
  }

}
