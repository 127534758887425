import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-create-thematic-tables',
  templateUrl: './create-thematic-tables.component.html',
  styleUrls: ['./create-thematic-tables.component.scss']
})
export class CreateThematicTablesComponent implements OnInit {

  id: any;
  table: any;
  table_page: BehaviorSubject<any> = new BehaviorSubject<any>(null);;
  ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  formThematic: FormGroup = this.formBuilder.group({
    id: [null],
    title: [null, Validators.required],
    start_date: [moment().format('YYYY-MM-DD'), Validators.required],
    end_date: [moment().format('YYYY-MM-DD'), Validators.required],
    total_seats: [null, Validators.required],
    cfp: [null],
    duration: [null],
  });

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private dateAdapter: DateAdapter<Date>,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.dateAdapter.setLocale('it-IT');
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.ruolo.next(localStorage.getItem('role'));

    this.getElementById();
  }

  getElementById() {
    this.userService.getTable({ id: this.id }).subscribe(response => {
      this.table = response.data;
      this.table_page.next(response.data);
      //console.log('Table: ', this.table_page.value);

      if (response.data != null) {
        let datainizio = new Date(response.data.start_date);
        let datafine = new Date(response.data.end_date);
        let d_inizio = moment(datainizio).format("YYYY-MM-DD");
        let d_fine = moment(datafine).format("YYYY-MM-DD");
        this.formThematic.patchValue({
          id: response.data.id,
          title: response.data.title,
          start_date: d_inizio,
          end_date: d_fine,
          total_seats: response.data.total_seats,
          cfp: response.data.cfp,
          duration: response.data.duration,
        })
      }
    })
  }

  getFormattedDate(date: moment.MomentInput, format: string | undefined) {
    return moment(date).locale('it').format(format);
  }

  add() {
    if (this.table != null) {
      this.userService.editThematicTable(this.formThematic.value).subscribe(res => {
        if (res.code == 200) {
          Swal.fire({
            icon: 'success',
            title: '<div style="font-size:18px;">Modificato con successo</div>',
            showConfirmButton: false,
            timer: 3000,
          });
          if (this.ruolo.value == 1) this.router.navigate([`app/admin/dashboard/tematici/0`]);
          else if (this.ruolo.value == 2) this.router.navigate([`app/client/subscriptions/tematici/0`]);
          else if (this.ruolo.value == 3) this.router.navigate([`app/guest/subscriptions/tematici/0`]);

        }
      })
    } else {
      this.formThematic.patchValue({
        start_date: this.getFormattedDate(this.formThematic.value.start_date, 'YYYY-MM-DD'),
        end_date: this.getFormattedDate(this.formThematic.value.end_date, 'YYYY-MM-DD'),
      });

      this.userService.addThematicTable(this.formThematic.value).subscribe(response => {
        if (response.code == 200) {
          Swal.fire({
            icon: 'success',
            title: '<div style="font-size:18px;">Creato con successo</div>',
            showConfirmButton: false,
            timer: 3000,
          });

          if (this.ruolo.value == 1) this.router.navigate([`app/admin/dashboard/tematici/0`]);
          else if (this.ruolo.value == 2) this.router.navigate([`app/client/subscriptions/tematici/0`]);
          else if (this.ruolo.value == 3) this.router.navigate([`app/guest/subscriptions/tematici/0`]);
        }
      })
    }
  }

}
