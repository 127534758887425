import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  form_domanda: FormGroup = this.formBuilder.group({
    convalida: [null, Validators.required],
  });

  scelta = [
    { value: 1, label: "L'iscrizione" },
    { value: 2, label: "La convalida dell'iscrizione" }
  ]

  // public title: string;
  // public message: string;
  data: any
  tipo: any

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit() {
    //console.log('MODALDATA', this.tipo);
  }

  invia() {
    if (!this.form_domanda.value.convalida) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Swal.fire({
        icon: 'warning',
        text: 'Compilare i campi'
      })
    } else {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">File scaricato</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this.bsModalRef.hide();
      this.getDocument(this.tipo);
    }

  }

  getDocument(tipo: any) {
    this.userService.getDocument(tipo, this.form_domanda.value.convalida).subscribe((r: Blob) => {
      const blobFile: Blob = new Blob([r], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(blobFile);
      window.open(fileURL, '_blank');
    })
  }

}
