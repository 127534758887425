import { Component, OnInit, ChangeDetectorRef, EventEmitter, Output, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzSiderComponent } from 'ng-zorro-antd/layout';
import { WordpressService } from 'src/app/modules/application/services/wordpress.service';
import { UtilsServices } from "../../modules/application/services/utils.service";
import { UserService } from "../../modules/application/services/user.service";
import { AuthService } from 'src/app/modules/application/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Output() public sidebarStatus: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @ViewChild('sider') sider!: NzSiderComponent;

  activeRoute: string = '/app/admin/dashboard';
  routes_fine = new Array();
  categories = new Array();
  client: any;
  url: any;
  resource_id = null;
  isCollapsed: boolean = false;
  fondazione: boolean = false;
  isMobile: boolean = false;

  routes_admin = [
    { name: 'Bacheca', url: '/app/admin/dashboard', icon: '../../../../../assets/sidebar/dashboard.svg', children: [] },
    // { name: 'Modulistica', url: '/app/admin/stampe', icon: '../../../../../assets/sidebar/doc-pdf.svg' },
    { name: 'Scadenzario', url: '/app/admin/scadenzario', icon: '../../../../../assets/sidebar/calendar2-date.svg' },
    { name: 'Link Utili', url: '/app/admin/rapid-links', icon: '../../../../../assets/icons/link.png' },
    { name: 'Terna', url: '/app/admin/terna', icon: '../../../../../assets/sidebar/engineer.png' },
    { name: 'Newsletter', url: '/app/admin/newsletter', icon: '../../../../../assets/sidebar/e-mail.png' },
    { name: 'Eventi', url: '/app/admin/events', icon: '../../../../../assets/sidebar/events.png' },
    { name: 'Consulenze', url: '/app/admin/consultings_list', icon: '../../../../../assets/sidebar/people.png' },
    { name: 'Certificati', url: '/app/admin/certifications_list', icon: '../../../../../assets/sidebar/curriculum.svg' },
    { name: 'Lista ingegneri', url: '/app/admin/engineers_list', icon: '../../../../../assets/sidebar/engineers_list.png' },
    { name: 'Agorà del lavoro', url: '/app/admin/agora', icon: '../../../../../assets/sidebar/gears.png' },
    { name: 'Profilo', url: '/app/admin/profile', icon: '../../../../../assets/sidebar/profile.svg' },
  ]

  routes_client = [
    { name: 'Bacheca', url: '/app/client/subscriptions', icon: '../../../../../assets/sidebar/subscriptions.svg', children: [] },
    { name: 'Modulistica', url: '/app/client/stampe', icon: '../../../../../assets/sidebar/doc-pdf.svg' },
    { name: 'Scadenzario', url: '/app/client/scadenzario', icon: '../../../../../assets/sidebar/calendar2-date.svg' },
    // { name: 'Accesso atti', url: '/app/client/atti', icon: '../../../../../assets/sidebar/access.svg' },
    { name: 'Terna', url: '/app/client/terna', icon: '../../../../../assets/sidebar/engineer.png' },
    { name: 'Eventi', url: '/app/client/events', icon: '../../../../../assets/sidebar/events.png' },
    { name: 'Consulenze', url: '/app/client/consultings_list', icon: '../../../../../assets/sidebar/people.png' },
    { name: 'Agorà del lavoro', url: '/app/client/agora', icon: '../../../../../assets/sidebar/gears.png' },
    { name: 'Certificati', url: '/app/client/certifications_list', icon: '../../../../../assets/sidebar/curriculum.svg' },
    { name: 'Profilo', url: '/app/client/profile', icon: '../../../../../assets/sidebar/profile.svg' },
  ]

  routes_fond = [
    { name: 'Bacheca', url: '/app/fondazione/dashboard', icon: '../../../../../assets/sidebar/dashboard.svg', children: [] },
    // { name: 'Rassegna Stampa Fondazione', url: '/app/fondazione/press', icon: '../../../../../assets/sidebar/news.png' },
    // { name: 'F.A.Q. Fondazione', url: '/app/fondazione/faq', icon: '../../../../../assets/sidebar/faq.png' },
    // { name: 'Lista E-mail Fondazione', url: '/app/fondazione/mail', icon: '../../../../../assets/sidebar/mail.png' },
    // { name: 'Commissioni', url: '/app/fondazione/commission', icon: '../../../../../assets/sidebar/people.png' },
    { name: 'Eventi', url: '/app/fondazione/events', icon: '../../../../../assets/sidebar/events.png' },
    { name: 'Newsletter', url: '/app/fondazione/newsletter', icon: '../../../../../assets/sidebar/e-mail.png' },
    { name: 'Commissioni', url: '/app/fondazione/commissioni', icon: '../../../../../assets/sidebar/candidati.png' },
    // { name: 'Verbali', url: '/app/fondazione/report', icon: '../../../../../assets/sidebar/document.png' },
    { name: 'Profilo', url: '/app/fondazione/profile', icon: '../../../../../assets/sidebar/profile.svg' },
  ]

  routes_guest = [
    { name: 'Bacheca', url: '/app/guest/subscriptions', icon: '../../../../../assets/sidebar/subscriptions.svg', children: [] },
    { name: 'Profilo', url: '/app/guest/profile', icon: '../../../../../assets/sidebar/profile.svg' },
  ]

  routes_company = [
    { name: 'Bacheca', url: '/app/company/dashboard', icon: '../../../../../assets/sidebar/dashboard.svg', children: [] },
    { name: 'Agorà del lavoro', url: '/app/company/agora', icon: '../../../../../assets/sidebar/gears.png' },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _post: WordpressService,
    private cdr: ChangeDetectorRef,
    private _auth: AuthService,
    private _utils: UtilsServices,
    public _user: UserService,
  ) {
    this.getCategories();
  }

  ngOnInit(): void {
    this.fondazione = localStorage.getItem('fondazione') == 'true' ? true : false;
    this.checkIfMobile();
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  isActiveRoute(url: string) {
    if (this.router.url == '/app/admin/dashboard') return url == '/app/admin/dashboard'
    else if (this.router.url == '/app/client/subscriptions') return url == '/app/client/subscriptions'
    else if (this.router.url == '/app/guest/subscriptions') return url == '/app/guest/subscriptions'
    else if (this.router.url == '/app/fondazione/dashboard') return url == '/app/fondazione/dashboard'
    else if (this.router.url == '/app/company/dashboard') return url == '/app/company/dashboard'
    else if (url != '/app/admin/dashboard' && url != '/app/client/subscriptions' && url != '/app/guest/subscriptions' && url != '/app/fondazione/dashboard') return this.router.url.includes(url);
    else return false;
  }

  logout() {
    localStorage.removeItem('Authorization');
    this.router.navigate(['/auth/login']);
  }

  getCategories() {
    this._utils.getCategories().subscribe({
      next: response => {
        this.client = localStorage.getItem('role');
        if (this.client == 1) this.url = '/app/admin/dashboard'
        else if (this.client == 2) this.url = '/app/client/subscriptions';
        else if (this.client == 3) this.url = '/app/guest/subscriptions';
        else if (this.client == 4) this.url = '/app/fondazione/dashboard';
        else if (this.client == 5) this.url = '/app/company/dashboard';

        if (this.fondazione) document.querySelector(".ant-layout-sider-trigger")!.classList.add('fondazione')
        else document.querySelector(".ant-layout-sider-trigger")!.classList.add('ordine')

        this.categories = response.data.map((category: any) => {
          category.url = this.url + '/' + category.slug + '/' + category.id;
          return category;
        });

        this._user.me().subscribe(value => {
          this._auth.currentUser.next(value.data);
          console.log(this.client);
          if (this.client == 2)
            this.routes_client.splice(5, 0, { name: 'Il mio CV', url: `/app/client/curriculum/${value.data.resource.id}`, icon: '../../../../../assets/sidebar/curriculum.svg' });
        })

        this.activeRoute = this.router.url;

        this.client = localStorage.getItem('role');
        if (this.client == 1) this.routes_fine = this.routes_admin;
        else if (this.client == 2) this.routes_fine = this.routes_client;
        else if (this.client == 3) this.routes_fine = this.routes_guest;
        else if (this.client == 4) this.routes_fine = this.routes_fond;
        else if (this.client == 5) this.routes_fine = this.routes_company;

        this.routes_fine.splice(1, 0, ...this.categories);
      }
    });
  }

  nzCollapseOnChange(event: any) {
    localStorage.setItem('isSidebarCollapsed', event);
    this.isCollapsed = event;
    this.sidebarStatus.emit(event);
  }

  // check if the view is in mobile mode
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth < 768; // You can adjust the breakpoint according to your design
    if (this.isMobile) {
      localStorage.setItem('isSidebarCollapsed', 'true');
    } else {
      localStorage.setItem('isSidebarCollapsed', 'false');
    }
    this.nzCollapseOnChange(this.isMobile);
  }
}
