<div class="m-auto mt-5 mb-3 col-11">
    <form [formGroup]="formThematic">
        <h2 *ngIf="(ruolo | async) == 1">Aggiungi <span class="capitalize">Tavoli tematici</span></h2>
        <h2 *ngIf="(ruolo | async) != 1" class="capitalize">{{(table_page | async)?.title}}</h2>
        <div class="col-11 mt-5">
            <label class="form-label">Titolo</label>
            <input type="text" class="form-control" formControlName="title" *ngIf="(ruolo | async) == 1">
            <input type="text" class="form-control" formControlName="title" *ngIf="(ruolo | async) != 1" readonly>
        </div>


        <div class="input-group mb-3 mt-4 d-flex justify-content-between spc">
            <div class="col-sm-2 col-md-1">
                <label for="basic-url" class="form-label">Posti</label>
                <input type="number" class="form-control" formControlName="total_seats" *ngIf="(ruolo | async) == 1">
                <input type="number" class="form-control" formControlName="total_seats" *ngIf="(ruolo | async) != 1"
                    readonly>
            </div>

            <div class="col-sm-2 col-md-1">
                <label for="basic-url" class="form-label">CFP</label>
                <input type="number" class="form-control" formControlName="cfp" *ngIf="(ruolo | async) == 1">
                <input type="number" class="form-control" formControlName="cfp" *ngIf="(ruolo | async) != 1" readonly>
            </div>

            <div class="col-sm-3 col-md-1">
                <label for="basic-url" class="form-label">Durata</label>
                <input type="number" class="form-control" formControlName="duration" *ngIf="(ruolo | async) == 1">
                <input type="number" class="form-control" formControlName="duration" *ngIf="(ruolo | async) != 1"
                    readonly>
            </div>

            <div class="col-sm-6 col-md-2">
                <label for="basic-url" class="form-label">Data inizio</label>
                <input type="date" class="form-control" formControlName="start_date" *ngIf="(ruolo | async) == 1">
                <input type="date" class="form-control" formControlName="start_date" *ngIf="(ruolo | async) != 1"
                    readonly>
            </div>

            <div class="col-sm-6 col-md-2">
                <label for="basic-url" class="form-label">Data fine</label>
                <input type="date" class="form-control" formControlName="end_date" *ngIf="(ruolo | async) == 1">
                <input type="date" class="form-control" formControlName="end_date" *ngIf="(ruolo | async) != 1"
                    readonly>
            </div>
        </div>

        <hr>
        <div class="d-flex justify-content-end mt-4">
            <button type="button" class="btn grey space me-2" [routerLink]="'/app/admin/dashboard/tematici/0'"
                *ngIf="(ruolo | async) == 1"> ANNULLA</button>
            <button type="button" class="btn grey space me-2" [routerLink]="'/app/client/subscriptions/tematici/0'"
                *ngIf="(ruolo | async) == 2"> Indietro</button>
            <button type="button" class="btn grey space me-2" [routerLink]="'/app/guest/subscriptions/tematici/0'"
                *ngIf="(ruolo | async) == 3"> Indietro</button>
            <button type="button" class="btn blu " (click)="add()" *ngIf="(ruolo | async) == 1">SALVA</button>
        </div>

    </form>
</div>