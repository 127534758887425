<div class="row justify-content-between">
    <div class="col-12 col-md-3 mt-3">
        <button [disabled]="listOfData.length == 0" (click)="exportExcel()" type="button"
            class="btn blu btn-block-sm w-100">EXPORT LISTA AZIENDE</button>
    </div>
    <div class="search col-12 col-xl-auto">
        <mat-form-field appearance="fill">
            <mat-label>Ricerca</mat-label>
            <input matInput [(ngModel)]="search" (ngModelChange)="getData()">
            <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
    </div>
</div>

<!--EXPORT EXCEL-->
<div class="row mt-2 mb-1">

</div>

<div class="table-responsive">
    <nz-table #basicTable [nzData]="listOfData" [nzPageIndex]="pageIndex" [nzPageSize]="pageSize" [nzTotal]="total"
        [nzFrontPagination]="false" (nzPageIndexChange)="changePage($event)" [nzLoading]="!isTableLoaded">
        <thead>
            <tr>
                <th *ngFor="let column of columns">{{ column.name }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of basicTable.data">
                <td *ngFor="let column of columns">
                    <ng-container [ngSwitch]="column.type">
                        <span *ngSwitchCase="'date'">
                            {{ data[column.key] | date: 'dd-MM-YYYY'}}
                        </span>
                        <span *ngSwitchCase="'subscriber_list'">
                            {{ data.subscriber_list.name }}
                        </span>
                        <span *ngSwitchCase="'counter'">
                            {{ data.counter }} / {{ data.subscriber_list.subscribers.length }}
                        </span>
                        <span *ngSwitchCase="'actions'">
                            <div class="d-flex flex-row" style="gap:0.5rem">
                                <button class="btn blu" (click)="confirmCompany(data['id'], true)"
                                    *ngIf="data['validated'] == null">
                                    <span nz-icon nzType="check" nzTheme="outline"></span>
                                </button>
                                <button class="btn rosso ms-2" (click)="confirmCompany(data['id'], false)"
                                    *ngIf="data['validated'] == null">
                                    <span nz-icon nzType="close" nzTheme="outline"></span>
                                </button>
                            </div>
                        </span>
                        <span *ngSwitchDefault>
                            {{ data[column.key] }}
                        </span>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </nz-table>
</div>