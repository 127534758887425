import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-elenchiPrevincendiDialog',
  templateUrl: './elenchiPrevincendiDialog.component.html',
  styleUrls: ['./elenchiPrevincendiDialog.component.scss']
})
export class ElenchiPrevincendiDialogComponent implements OnInit {
  tipo: any;
  filter_status: any = false;

  form_previncendi: FormGroup = this.formBuilder.group({
    iscrizioneElMin: null,
    periodoSvolgimento: null
  });

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  invia() {
    if (!this.form_previncendi.value.iscrizioneElMin || !this.form_previncendi.value.periodoSvolgimento) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Swal.fire({
        icon: 'warning',
        text: 'Compilare i campi'
      })
    } else {
      this.getDocument(this.tipo);
    }
  }

  getDocument(tipo: any) {
    this.userService.getDocument(tipo, null, null, null, null, null, null, null, null, this.form_previncendi.value.iscrizioneElMin, this.form_previncendi.value.periodoSvolgimento).subscribe((r: Blob) => {
      const blobFile: Blob = new Blob([r], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(blobFile);
      window.open(fileURL, '_blank');
    })
  }
}
