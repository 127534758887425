<!-- <app-dataTable [cols]="cols" [colsHead]="colsHead" (datatable)="datatable($event)">
</app-dataTable>

<mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
  *ngIf="length != 0; else noUsers" [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
  [pageIndex]="pageIndex" aria-label="Select page">
</mat-paginator>

<ng-template #noUsers></ng-template> -->


<!--ADD-->
<div class="row">
  <div class="col-12 col-md-3">
    <button (click)="exportExcel()" type="button" class="btn blu btn-block-sm  w-100"
      [disabled]="listOfData.length == 0">EXPORT LISTA NEWSLETTER</button>
  </div>
</div>



<div class="table-responsive">
  <nz-table #basicTable [nzData]="listOfData" [nzPageIndex]="pageIndex" [nzPageSize]="pageSize" [nzTotal]="total"
    [nzFrontPagination]="false" (nzPageIndexChange)="changePage($event)" [nzLoading]="!isTableLoaded">
    <thead>
      <tr>
        <th *ngFor="let column of columns">{{ column.name }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td *ngFor="let column of columns">
          <ng-container [ngSwitch]="column.type">
            <span *ngSwitchCase="'date'">
              {{ data[column.key] | date: 'dd-MM-YYYY'}}
            </span>
            <span *ngSwitchCase="'subscriber_list'">
              {{ data.list_name }}
            </span>
            <span *ngSwitchCase="'counter'">
              {{ data.counter }} / {{ data.count }}
            </span>
            <span *ngSwitchCase="'actions'">
              <button class="btn blu" [routerLink]="data.id">
                <span nz-icon nzType="eye" nzTheme="outline"></span>
                <!-- <span class="ms-2">Visualizza dettaglio</span> -->
              </button>
            </span>
            <span *ngSwitchDefault>
              {{ data[column.key] }}
            </span>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>