
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LoginComponent } from '../modules/auth/components/login/login.component';
import { RegistratiComponent } from './../modules/auth/components/registrati/registrati.component';
import { RecuperoPswComponent } from './../modules/auth/components/recupero-psw/recupero-psw.component';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './loader/loader.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageErrorComponent } from '../modules/auth/components/message-error/message-error.component';
import { MatExpansionModule, MatExpansionPanelDescription } from '@angular/material/expansion';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DataTableComponent } from './dataTable/dataTable.component';
import { HeadersComponent } from './headers/headers.component';
import { ProfileComponent } from '../modules/application/user/components/profile/profile.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NewPostComponent } from '../modules/application/admin/new-post/new-post.component';
import { MatSelectModule } from '@angular/material/select';
import { TranslatePipe } from './translatePipe/translate.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PaymentComponent } from '../modules/application/user/components/payment/payment.component';
import { UtentiIscrittiComponent } from '../modules/application/admin/utenti-iscritti/utenti-iscritti.component';
import { TernaComponent } from '../modules/application/user/components/terna/terna.component';
import { MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BaseComponent } from './base/base.component';
import { NgZorroAntdModule } from './ng-zorro-antd/ng-zorro-antd.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CustomFormComponent } from './custom-form/custom-form.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AlboComponent } from './albo/albo.component';
import { RouterModule } from '@angular/router';
import { NzDatatableComponent } from "./nz-datatable/nz-datatable.component";
import { DetailComponent } from "../modules/application/user/components/terna/detail/detail.component";
import { NotificationsComponent } from './notifications/notifications.component';
import { MatTabsModule } from '@angular/material/tabs';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [
        AlboComponent,
        LoginComponent,
        RecuperoPswComponent,
        RegistratiComponent,
        LoaderComponent,
        MessageErrorComponent,
        SidebarComponent,
        HeadersComponent,
        ProfileComponent,
        DataTableComponent,
        NewPostComponent,
        NotificationsComponent,
        TranslatePipe,
        PaymentComponent,
        UtentiIscrittiComponent,
        TernaComponent,
        BaseComponent,
        CustomFormComponent,
        AlboComponent,
        NzDatatableComponent,
        DetailComponent
    ],
    imports: [
        AngularEditorModule,
        CommonModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatIconModule,
        // MatButtonModule,
        FormsModule,
        // MatSidenavModule,
        MatTabsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatSelectModule,
        CKEditorModule,
        MatNativeDateModule,
        MatDatepickerModule,
        NgZorroAntdModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        AngularEditorModule,
        MatSortModule,
        MatTooltipModule,
        AngularEditorModule,
        RouterModule
    ],
    exports: [
        AlboComponent,
        LoginComponent,
        RecuperoPswComponent,
        RegistratiComponent,
        LoaderComponent,
        MessageErrorComponent,
        SidebarComponent,
        HeadersComponent,
        ProfileComponent,
        DataTableComponent,
        MatTabsModule,
        MatPaginatorModule,
        NewPostComponent,
        NotificationsComponent,
        PaymentComponent,
        UtentiIscrittiComponent,
        TernaComponent,
        MatNativeDateModule,
        MatDatepickerModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatExpansionModule,
        MatTableModule,
        MatCheckboxModule,
        MatSelectModule,
        BaseComponent,
        NgZorroAntdModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        CustomFormComponent,
        NzDatatableComponent,
        AngularEditorModule,
        AlboComponent,
    ],
    providers: [],
    bootstrap: [],

    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
