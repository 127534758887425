import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';
import { config } from '../../../../../../../environments/config';
import { OffersService } from 'src/app/shared/services/offers.service';
import { BaseComponent } from 'src/app/components/base/base.component';
import { AuthService } from 'src/app/modules/application/services/auth.service';
import { CompaniesService } from 'src/app/modules/application/services/companies.service';
import { UtilsServices } from 'src/app/shared/services/utils.service';
import { debounceTime } from 'rxjs/operators';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
    selector: 'app-new-offer',
    templateUrl: './new-offer.component.html',
    styleUrls: ['./new-offer.component.scss']
})
export class NewOfferComponent extends BaseComponent implements OnInit {
    @Output() displayedTitle: EventEmitter<string> = new EventEmitter<string>();
    public offer: any = null;
    public offer_id: string | null = null;
    public lists: any[] = [];
    public templates: any[] = [];
    public specializations: any[] = [];
    companies: any[] = [];
    isVisible: boolean = false;
    fileList: any[] = [];

    form!: FormGroup;
    body: any;
    editorConfig: AngularEditorConfig = config.angular_editor;

    client = localStorage.getItem('role');
    prices_ranges: any[] = [
        '€500 - €1.000',
        '€1.000 - €1.500',
        '€1.500 - €2.000',
        '€2.000 - €2.500',
        '€2.500 - €3.000',
        '€3.000 - €3.500',
        '€3.500 - €4.000',
        '€4.000 - €4.500',
        '€4.500 - €5.000',
        'Importo > €5.000'
    ];

    constructor(
        private formBuilder: FormBuilder,
        private _offers: OffersService,
        private _utils: UtilsServices,
        private _auth: AuthService,
        private router: ActivatedRoute,
        private _router: Router,
        private _companies: CompaniesService
    ) {
        super();
    }

    ngOnInit() {
        this.initForm();

        this.offer_id = this.router.snapshot.params['id'];

        this._utils.getSpecializations().subscribe(response => {
            this.specializations = response.data;
        })

        if (this.offer_id) {
            let params = this.client == '2' ? { user_id: true } : null;
            this._offers.getOfferById(this.offer_id, params).subscribe({
                next: (response) => {
                    this.offer = response.data;
                    let contract_name = '';
                    this.contract_types.map((el) => {
                        if (el.id === this.offer.contract)
                            contract_name = el.name;
                    });
                    this.displayedTitle.emit('Specializzazione: ' + this.offer.specialization.name + ', con contratto: ' + contract_name);
                    this.initForm(response.data);
                }
            });
        }

        this.form.get('company_name')?.valueChanges.subscribe((value: any) => {
            this.form.patchValue({
                company_id: value.id,
                email: value.email,
                phone: value.phone
            });
        });

    }

    initForm = (data?: any) => {
        this._auth.currentUser?.subscribe((value: any) => {
            this.form = this.formBuilder.group({
                company_id: data ? data.company.id : null,
                company_name: data ? data.company.name : null,
                phone: data ? data.company.phone : null,
                email: data ? data.company.email : null,
                address: [data ? data.address : null, Validators.required],
                skills: [data ? data.skills : null, Validators.required],
                experience: [data ? data.experience : null, Validators.required],
                contract: [data ? data.contract : null, Validators.required],
                range: [data ? data?.range : null, Validators.required],
                specialization_id: [data ? data.specialization_id : null, Validators.required],
                curriculum: [null, (value?.role_id === 2 && !value?.resource?.id) ? Validators.required : null],
                cv_name: data ? data.cv_name : null,
                cv_url: data ? data.cv_url : null
            });

            // initialize the company_name array
            this.onCompaniesChange('');
            this.form.get('company_name')?.valueChanges
                .pipe(debounceTime(1000))
                .subscribe((value) => {
                    this.onCompaniesChange(value);
                });

        });
    }

    submitForm(): void {
        if (this.form.valid) {
            this.form.value.company_id = this.form.get('company_name')?.value.id;
            this._offers.createOffer(this.form.value).subscribe({
                next: (response) => {
                    Swal.fire({
                        icon: 'success',
                        title: '<div style="font-size:18px;">Offerta creata correttamente</div>',
                        showConfirmButton: false,
                        timer: 3000,
                    }).then(() => {
                        this._offers.refresh.next(true);

                        let url: any = this._router.url;
                        if (url.includes('/app/company/')) this._utils.setSelectedIndex(0);
                        else this._utils.setSelectedIndex(1);
                    });
                },
                error: (error) => {
                    Swal.fire({
                        icon: 'error',
                        title: `<div style="font-size:18px;">${error}</div>`,
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            })
        } else {
            Object.values(this.form.controls).forEach((control: any) => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({ onlySelf: true });
                }
            });
        }
    }

    sendNomination() {
        this._offers.sendNomination({ offer_id: this.offer_id, user_id: this._auth.currentUser.value.id, curriculum: this.form.get('curriculum')?.value }).subscribe({
            next: (response) => {
                Swal.fire({
                    icon: 'success',
                    title: '<div style="font-size:18px;">Candidatura inviata correttamente</div>',
                    showConfirmButton: false,
                    timer: 3000,
                }).then(() => {
                    this._offers.refresh.next(true);
                    this.onCloseModal();
                    this.ngOnInit();
                });
            }
        });
    }

    deleteNomination() {
        this._offers.deleteNomination(this.offer_id).subscribe({
            next: (response) => {
                Swal.fire({
                    icon: 'success',
                    title: '<div style="font-size:18px;">Candidatura ritirata correttamente</div>',
                    showConfirmButton: false,
                    timer: 3000,
                }).then(() => {
                    this._offers.refresh.next(true);
                    this.ngOnInit();
                });
            }
        });
    }

    onCompaniesChange(search: any) {
        this._companies.getAgoraCompanies({ search: search })
            .subscribe(response => {
                this.companies = response.data;
            });
    }

    compareFun = (o1: any | string, o2: any): boolean => {
        if (o1) {
            return typeof o1 === 'string' ? o1 === o2.name : o1.id === o2.id;
        } else {
            return false;
        }
    };

    beforeUpload = (file: NzUploadFile): boolean => {
        this.fileList = this.fileList.concat(file);
        this.handleUpload();
        return false;
    };

    handleRemove = (info: NzUploadChangeParam): void => {
        if (info.file.status === 'removed') {
            this.fileList = [];
            this.form.patchValue({ curriculum: null });
        }
    };

    handleUpload(): void {
        let data: any[] = [];
        this.fileList.forEach((file: any) => {
            this.uploadLogic(data, file)
        });
    }

    uploadLogic(data: any[], file: any) {
        const reader = new FileReader();
        const blob = new Blob([file]);
        reader.readAsDataURL(blob);
        reader.onload = () => {
            data.push({ name: file.name, base64: reader.result, id: typeof file.uid == 'number' ? file.uid : null });

            if (data.length === 1) {
                this.form.patchValue({ curriculum: data });
            }
        };
    }

    onCloseModal() {
        this.isVisible = false;
    }

    openModal() {
        this.isVisible = true;
    }
}
