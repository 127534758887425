<div class="row justify-content-end">
  <div class="search col-12 col-xl-auto">
    <mat-form-field appearance="fill">
      <mat-label>Ricerca</mat-label>
      <input matInput [formControl]="search">
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
  </div>
  <!-- <button (click)="exportExcel()" type="button" class="btn blu btn-block-sm  w-100"
      [disabled]="listOfData.length == 0">LISTA NEWSLETTER</button> -->
</div>

<div class="table-responsive">
  <nz-table #basicTable [nzData]="listOfData" [nzPageIndex]="pageIndex" [nzPageSize]="pageSize" [nzTotal]="total"
    [nzFrontPagination]="false" (nzPageIndexChange)="changePage($event)" [nzLoading]="!isTableLoaded">
    <thead>
      <tr>
        <th *ngFor="let column of columns">{{ column.name }}</th>
        <!-- Aggiungi la nuova colonna "Contattato" -->
        <th>Contattato</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td *ngFor="let column of columns">
          <ng-container [ngSwitch]="column.type">
            <span *ngSwitchCase="'date'">
              {{ data.user[column.key] | date: 'dd-MM-YYYY'}}
            </span>
            <span *ngSwitchCase="'name'">
              {{ data.user.name }} {{ data.user.lastName }}
            </span>
            <span *ngSwitchCase="'actions'">
              <button class="table-button blu" matTooltip="Scarica il CV" (click)="printCv(data, 'cv')">
                <i class="fa-solid fa-file-arrow-down"></i>
              </button>
              <button *ngIf="data?.user?.resource?.videocurriculum_request" class="table-button blu"
                matTooltip="Apri il videocurriculum" (click)="printCv(data, 'video')">
                <i class="fa-solid fa-video"></i>
              </button>
            </span>
            <span *ngSwitchDefault>
              {{ data.user[column.key] }}
            </span>
          </ng-container>
        </td>
        <!-- Aggiungi la cella per la nuova colonna "Contattato" -->
        <td>
          {{ data.feedback === 1 ? 'Si' : 'No' }}
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>