import { Injectable } from '@angular/core';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';
import { Certification } from '../models/certification.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CertificationsService {

    constructor(private http: HttpClient) { }

    getList(params?: any) {
        return this.http.get<FormattedResponse<Certification[]>>(`${environment.api}/certificationRequested`, { params });
    }

    requestCertification(params?: any) {
        return this.http.post<FormattedResponse<any>>(`${environment.api}/certificationRequested`, params);
    }

    uploadFile(id?: string, params?: any) {
        return this.http.put<FormattedResponse<any>>(`${environment.api}/certificationRequested/uploadFile/` + id, params);
    }

    download(id?: string, data?: any) {
        const headers = new HttpHeaders({
            'toast': 'hidden',
            'Content-Type': 'application/json',
            'customHeader': 'blob'
        });

        const params = new HttpParams({ fromObject: data });

        return this.http.get(`${environment.api}/certificationRequested/download/` + id, {
            headers: headers,
            params: params
        });
    }
}
