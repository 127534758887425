import { DatePipe, DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router, ActivatedRoute } from '@angular/router';
import { E } from 'chart.js/dist/chunks/helpers.core';
import { debounceTime, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { WordpressService } from '../../services/wordpress.service';
import { Sort } from '@angular/material/sort';
import { UtilsServices } from '../../services/utils.service';

import { PostsServices } from '../../services/posts.service';
import { EventService } from '../../services/event.service';
import { DataTableComponent } from 'src/app/components/dataTable/dataTable.component';
import * as moment from 'moment';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {

  @ViewChild('datatablechild') datatablechild!: DataTableComponent;
  activeStatus = 'publish';
  cols = new Array();
  publish = new Array();
  colsHead: string[] = ['select', 'title', 'seats', 'created_at', 'event_start_date', 'edit'];
  path: any;
  url: any;
  idPost: any;
  rows: any[] = [];
  permanentlyDelete = false;
  total: any;
  trash: number = 0;
  draft: any;
  totalpost: any;
  pipe = new DatePipe('en-US');
  status = 'publish';
  length!: number;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10];
  showFirstLastButtons: boolean = true;
  searched: any = null;
  searchField: FormControl = new FormControl();
  searchDelay: number = 2000;
  sorting: Sort = { active: '', direction: '' };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _post: PostsServices,
    private utils: UtilsServices,
    private _eventService: EventService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.checkPath();
    this.getData();
    this.getCounters();

    this.searchField.valueChanges
      .pipe(debounceTime(this.searchDelay))
      .subscribe((value) => {
        this.searched = value;
        this.pageIndex = 0;
        this.getData();
      });

    this.url = this.router.url;
    //  * Setta i titoli delle colonne
    // if (this.url.includes('/comunicazioni')) this.colsHead = ['select', 'title', 'created_at', 'edit'];
    if (this.url.includes('/comunicazioni') || this.url.includes('/bandi')) this.colsHead = ['select', 'title', 'created_at', 'data_scadenza', 'edit'];
    if (this.url.includes('/faq') || this.url.includes('tavoli-tematici') || this.url.includes('attivita-aggregative') || this.url.includes('convenzioni')) this.colsHead = ['select', 'title', 'created_at', 'edit'];
    if (this.url.includes('video-istituzionali') || this.url.includes('tecnica-e-ricostruzione') || this.url.includes('rassegna-stampa')) this.colsHead = ['select', 'title', 'edit'];
  }

  getCounters() {
    this._post.getCounters(this.route.snapshot.params.id).subscribe({
      next: (response) => {
        this.totalpost = response.data;
      },
    });
  }

  getData() {
    this._post
      .getPosts(
        this.route.snapshot.params.id,
        null,
        this.status,
        this.searched,
        this.sorting?.active,
        this.sorting?.direction,
        this.pageIndex * this.pageSize,
        this.pageSize
      )
      .pipe(
        map((response: any) => {
          return {
            data: response.data.map((el: any) => {
              el.event_start_date = moment(el.event_start_date).format('DD-MM-YYYY HH:mm');

              if (this.route.snapshot.params.id == 1 || this.route.snapshot.params.id == 2 || this.route.snapshot.params.id == 3 || this.route.snapshot.params.id == 4) {
                el.created_at = moment(el.created_at).format('DD-MM-YYYY HH:mm');
              }

              if (this.url.includes('/faq') || this.url.includes('tavoli-tematici') || this.url.includes('attivita-aggregative') || this.url.includes('bandi') || this.url.includes('convenzioni')) {
                el.created_at = moment(el.created_at).format('DD-MM-YYYY HH:mm');
                el.data_scadenza != null ? el.data_scadenza = moment(el.data_scadenza).format('DD-MM-YYYY HH:mm') : el.data_scadenza = 'Data non presente';
              }

              if (this.url.includes('comunicazioni')) el.data_scadenza != null ? el.data_scadenza = moment(el.data_scadenza).format('DD-MM-YYYY HH:mm') : el.data_scadenza = 'Data non presente';

              if (
                this.route.snapshot.params.id == 2 ||
                this.route.snapshot.params.id == 3 ||
                this.route.snapshot.params.id == 4
              ) {
                if (el.registration_allowed) {
                  el.seats = el.subscribed + '/' + el.total_seats + ' (' + el.reserved_seats + ' in riserva)';
                } else {
                  el.seats = '- / -';
                }
                el.start_date = moment(el.event_start_date).format('DD-MM-YYYY');
              }
              return el;
            }),
            count: response.count,
            total: response.total,
          };
        })
      )
      .subscribe({
        next: (result) => {
          this.cols = result.data;
          this.length = result.count;
          this.total = result.total;

          this.cols.forEach((el) => {
            var html = el.title;
            var div = document.createElement('div');
            div.innerHTML = html;
            var titleDecoded = div.textContent || div.innerText || '';
            el.title = titleDecoded;
            const myFormattedDate = moment(el.date).format('DD-MM-YYYY');
            el.date = myFormattedDate;
          });
        },
      });

  }

  checkPath() {
    // -> lo snapshot.params mi prende il parametro slug del path
    this.path = this.route.snapshot.params.slug.split('-').join(' ');
    if (this.path == 'attivita aggregative') this.path = "attività aggregative";
  }

  exportExcel() {
    this._eventService.exportExcelEvent({ status: this.status, category_id: this.route.snapshot.params.id, type: "post" }).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = this.path + '_' + this.translateStatus(this.status) + '.xlsx';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  translateStatus(status: string): string {
    if (status == 'publish') {
      return 'pubblicati'
    } if (status == 'draft') {
      return 'bozze';
    } if (status == 'trash') {
      return 'cestino'
    } else {
      return '';
    }
  }

  goTo(path: string): void {
    this.url = this.router.url;
    this.router.navigate([`${this.url}/${path}`]);
  }

  datatable(event: any) {
    this.rows = event;
  }

  getElement(element: any) {
    this.idPost = element;
  }

  deletePost() {
    this._post.delete(this.rows.map(item => item.id), this.status == 'trash').subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">Eliminazione avvenuta con successo</div>',
        showConfirmButton: false,
        timer: 3000,
      });

      this.getData();
      this.getCounters();
      this.rows = []
      this.datatablechild.reloadDataEvent()
    });
  }

  alert(): void {
    const bottone =
      '<div class="d-flex justify-content-between">' +
      '<button type="button" class="btn" style="background-color: lightgray;color: #063e64;text-transform: uppercase;"> ANNULLA </button>' +
      '<button type="button" class="btn" style="background-color: red;color: #fff;text-transform: uppercase;">ELIMINA</button>' +
      '</div>';
    Swal.fire({
      title:
        '<div style="font-size:16px; text-align:center">Eliminare la comunicazione?</div>' +
        this.rows.length +
        bottone,
      target: '#custom-target',
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timerProgressBar: true,
    });
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getData();
    return event;
  }

  onSortChange($event: any) {
    this.sorting = $event;
    this.getData();
  }

  onStatusChange(status: any) {
    this.activeStatus = status;
    this.status = status;
    this.getData();
    this.rows = [];
    this.datatablechild.reloadDataEvent()
  }
}
