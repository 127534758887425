import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { ConsultingsService } from 'src/app/modules/application/services/consultings.service';
import { UserService } from 'src/app/modules/application/services/user.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { EventService } from 'src/app/modules/application/services/event.service';

@Component({
    selector: 'app-consulting-requests',
    templateUrl: './consulting-requests.component.html',
    styleUrls: ['./consulting-requests.component.scss']
})
export class ConsultingRequestsComponent implements OnInit {

    moment = moment;
    @Input() isVisibleRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    currentPage = 0;
    pageSize = 5;
    data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
    pageEvent: PageEvent | undefined;
    displayedColumns: string[] = ['type', 'date', 'hour_slot', 'consultant_name', 'status'];
    sort: Sort = { active: '', direction: '' };
    typeList: Array<string> = [];
    modalDates: Array<string> = [];
    modalHours: Array<string> = [];
    current_user: BehaviorSubject<any> = new BehaviorSubject<any>({});
    isVisibleRequestValue: boolean = false;
    modalForm: FormGroup = this.createModalForm();

    constructor(
        private _consultings: ConsultingsService,
        private _user: UserService,
        private _eventService: EventService
    ) { }

    ngOnInit(): void {
        this.getData();
        this._user.me().subscribe((value) => {
            this.current_user.next(value.data);
            if (value.data.role_id === 1)
                this.displayedColumns.push('user_id', 'actions');
        });
        this.isVisibleRequest.subscribe((value: boolean) => this.isVisibleRequestValue = value);
    }

    getData() {
        let params = { skip: this.currentPage * this.pageSize, take: this.pageSize, ...this.sort };
        this._consultings.getListRequested(params).subscribe(response => {
            this.data.next(response.data);
            this.records.next(response.total);
        });

        this._consultings.getConsultantTypes().subscribe(response => {
            this.typeList = response.data.map(el => el.type);
        });
    }

    handlePage(e: any) {
        this.currentPage = e.pageIndex;
        this.pageSize = e.pageSize;
        this.getData();
        return e;
    }

    onSaveModalCreate() {
        let element = this.modalForm.value;
        element['status'] = 'In attesa di approvazione';
        element['user_id'] = this.current_user.value.id;
        this._consultings.updateByParams(this.modalForm.value).subscribe({
            next: () => {
                Swal.fire({
                    icon: 'success',
                    title: '<div style="font-size:18px;">Consulenza richiesta con successo</div>',
                    showConfirmButton: false,
                    timer: 3000
                });
                this.getData();
                this.handleCancelCreate();
            }
        });
    }

    handleCancelCreate(): void {
        this.isVisibleRequest.next(false);
        this.modalDates = [];
        this.modalHours = [];
        this.modalForm = this.createModalForm();
    }

    sendToConsultant(el: any) {
        let element = el;
        element['status'] = 'Approvata';
        let body = 'Tipo consulenza: ' + el.consultant.type + '<br>Data dell\'incontro: ' + el.date + '<br>Ora dell\'incontro: ' + el.hour_slot + '<br>Stato richiesta: ' + el.status;
        let mail = {
            'subject': 'Una nuova richiesta di consulenza è stata inoltrata',
            'body': body,
            'campaignID': '',
            'attachmentPaths': [],
            'user_id': element['user_id'],
            'approved': true
        }
        element['mail'] = mail;
        this._consultings.update(element.id, element).subscribe({
            next: () => {
                this.getData();
                Swal.fire({
                    icon: 'success',
                    title: '<div style="font-size:18px;">Richiesta approvata!</div>',
                    showConfirmButton: false,
                    timer: 3000
                });
            },
            error: () => {
                this.getData();
                Swal.fire({
                    icon: 'error',
                    title: '<div style="font-size:18px;">Errore nella modifica della richiesta</div>',
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        });
    }

    getDates() {
        this.modalHours = [];
        this._consultings.getDates(this.modalForm.value).subscribe((value: any) => {
            this.modalDates = value.data.map((value: any) => value.date);
        });
    }

    getHours() {
        this._consultings.getHours(this.modalForm.value).subscribe((value: any) => {
            this.modalHours = value.data.map((value: any) => value.hour_slot);
        });
    }

    exportExcel() {
        this._eventService.exportExcelEvent({ type: "request_consultations" }).subscribe(
            (res: Blob) => {
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const filename = 'Consulenze_richieste' + '.xlsx';
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
    }

    createModalForm(): FormGroup {
        return new FormGroup({
            type: new FormControl('', Validators.required),
            date: new FormControl('', Validators.required),
            hour_slot: new FormControl('', Validators.required)
        });
    }

    reject(el: any) {
        let element = el;
        element['status'] = 'Disponibile';
        element['user_id'] = null;

        let body = 'Gentile utente,<br><br>le comunichiamo che la sua richiesta di consulenza è stata rigettata. Per ulteriori chiarimenti, la preghiamo di contattare la segreteria della fondazione.<br><br>Cordiali saluti';
        let mail = {
            'subject': 'Richiesta di consulenza rigettata',
            'body': body,
            'campaignID': '',
            'attachmentPaths': [],
            'user_id': element['user']['id'],
            'approved': false
        };
        element['mail'] = mail;

        this._consultings.update(element.id, element).subscribe({
            next: () => {

                this.getData();
                Swal.fire({
                    icon: 'info',
                    title: '<div style="font-size:18px;">Richiesta rigettata!</div>',
                    showConfirmButton: false,
                    timer: 3000
                });
            },
            error: () => {
                this.getData();
                Swal.fire({
                    icon: 'error',
                    title: '<div style="font-size:18px;">Errore nella modifica della richiesta</div>',
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        });
    }
}
