import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WordpressService } from '../../services/wordpress.service';
import { UserService } from '../../services/user.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { DatePipe, DOCUMENT } from '@angular/common';
import * as ClassicEditor from '@ckeditor/ckeditor5-angular';
import * as moment from 'moment';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { config } from '../../../../../environments/config';
import { PostsServices } from "../../services/posts.service";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.scss']
})
export class NewPostComponent implements OnInit {

  public editor = ClassicEditor;
  Editor: any;
  status = [
    { name: 'publish', it: 'Pubblicato' },
    { name: 'draft', it: 'Bozza' },
    // { name: 'trash', it: 'Cestinato' }
  ];


  fondazione = [
    { label: 'Si', value: true },
    { label: 'No', value: false },
  ]

  telegram = [
    { label: 'Si', value: true },
    { label: 'No', value: false },
  ]

  request_certificate = [
    { label: 'Si', value: true },
    { label: 'No', value: false },
  ]

  publish_on_site = [
    { label: 'Subito', value: true },
    { label: 'Programma', value: false },
  ]

  registration_allowed = [
    { label: 'Si', value: true },
    { label: 'No', value: false },
  ]

  editorContent: string = '';

  user_wp_id: any;
  action: any;
  toDate: any;
  blob: any;
  file: any;
  fileName: any;
  fileUploaded = false;
  errorProcessing = false;
  formPost: FormGroup | any;
  // fileForm: FormGroup | any;
  path: any = this.route.snapshot.params.id;
  id: any;
  url: any;
  categoryId: any;
  tagsArray = new Array();
  tagSelect: any;
  tagSlug = false;
  title: any;
  displayedTitle: any;
  array: any = [];
  singlePost: any;
  create = false;
  restore = false
  postmodify = false;
  postEdit: any;
  sticky = false;
  showCourse = false;
  isDisabled: boolean = false;
  client: any;
  guest: any;
  today = moment().format("YYYY-MM-DD");
  pipe = new DatePipe('en-US');
  editorConfig: AngularEditorConfig = config.angular_editor;
  fileList: NzUploadFile[] = [
  ];
  fromFondazione: boolean = false;
  fond: boolean = false;
  original_form: any = [];
  changes: any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _wp: WordpressService,
    private http: HttpClient,
    public user: UserService,
    private datePipe: DatePipe,
    private _posts: PostsServices,
    @Inject(DOCUMENT) private document: Document,
  ) {

    this.formPost = this.fb.group({
      id: null,
      title: [null, Validators.compose([Validators.required])],
      status: 'publish',
      author: null,
      total_seats: [0], //Max Iscritti
      reserved: [0], //Posti Riservati
      cfp: [0],
      duration: [0], //Durata in ore
      content: [null],
      sticky: this.sticky,
      data_scadenza: null,
      start_date: [null], //Apertura Iscrizione
      end_date: [null], //Chiusura Iscrizioni
      event_date: [null], //Data Evento
      publish: new FormControl(new Date()),
      publish_on_site: [true], //Pubblicazione sul sito Evento subito o programmato
      category_id: null,
      sub: null,
      choise: [false],
      sendViaWhatsapp: [false],
      sendViaTelegram: [false],
      sendViaMail: [false],
      is_enable_certificate: [false],
      registration_allowed: [true],
      free: [true],
      // attachmentsFile: this.fb.array([])
      attachmentsFile: '',
      video: [null],
      pdf: [null]
    });
  }

  ngOnInit(): void {
    this.toDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
    this.title = this.route.snapshot.params.slug;

    this.displayedTitle = this.title == 'attivita-aggregative' ? "attività aggregative" : this.title.split('-').join(' ');
    this.route.snapshot.params.slug;
    this.categoryId = Number(this.route.snapshot.url[1].path);
    this.formPost.patchValue({ category_id: this.categoryId });
    this.url = this.router.url;

    if (this.url.includes('app/client/subscriptions') || this.url.includes('app/guest/subscriptions')) {
      this.client = true;
    }
    if (this.url.includes('app/fondazione/dashboard')) {
      this.fond = true;
    }

    this.checkCreatePost();
    this.action = localStorage.getItem('action');
    this.getUserId();
    if (this.url.includes('create-post'))
      this.dinamicValidators();

    //per corsi/convegni/seminari, al cambiamento di un campo ne comunichiamo la modifica via mail
    if ((this.categoryId == 2 || this.categoryId == 3 || this.categoryId == 4) && this.url.includes('edit-post')) {
      this.formPost.valueChanges.subscribe({
        next: () => {
          Object.keys(this.formPost.value).forEach((key: any) => {
            let new_value = this.formPost.get(key).value;
            let old_value: string = this.original_form[key];

            if (key == 'start_date' || key == 'end_date' || key == 'event_date') {
              new_value = moment(this.formPost.get(key).value).format('DD-MM-YYYY HH:mm');
              old_value = moment(this.original_form[key]).format('DD-MM-YYYY HH:mm');
            } else if (key == 'free') {
              new_value = this.formPost.get(key).value ? 'Si' : 'No';
              old_value = this.original_form[key] ? 'Si' : 'No';
            }

            if (this.formPost.get(key).dirty && new_value != old_value && key != 'status' && key != 'attachmentsFile') {
              let index = this.changes.findIndex((item: any) => item.key == key);
              if (index != -1) this.changes.splice(index, 1);

              this.changes.push({ key: key, old: old_value, new: new_value });
            }
          })
        }
      })
    }
  }

  getUserId() {
    this.user.me().subscribe(element => {
      this.user_wp_id = element.data.id;
    })
  }

  dinamicValidators() {
    if (this.title != 'convenzioni' && this.title != 'comunicazioni' && this.title != 'faq' && this.title != 'tavoli-tematici' && this.title != 'attivita-aggregative' && this.title != 'bandi' && this.title != 'video-istituzionali' && this.title != 'tecnica-e-ricostruzione' && this.title != 'rassegna-stampa' && this.formPost.get('registration_allowed')?.value === true) {
      this.formPost.get('total_seats').setValidators([Validators.required])
      this.formPost.get('total_seats').updateValueAndValidity();

      this.formPost.get('reserved').setValidators([Validators.required, this.reservedSeatsValidation])
      this.formPost.get('reserved').updateValueAndValidity();

      this.formPost.get('cfp').setValidators([Validators.required])
      this.formPost.get('cfp').updateValueAndValidity();

      this.formPost.get('duration').setValidators([Validators.required])
      this.formPost.get('duration').updateValueAndValidity();

      this.formPost.get('start_date').setValidators([Validators.required, this.dateValidation])
      this.formPost.get('start_date').updateValueAndValidity();

      this.formPost.get('end_date').setValidators([Validators.required, this.dateValidation])
      this.formPost.get('end_date').updateValueAndValidity();

      this.formPost.get('event_date').setValidators([Validators.required, this.eventDateValidation])
      this.formPost.get('event_date').updateValueAndValidity();
    }
    if ((this.title === 'corsi' || this.title === 'convegni' || this.title === 'seminari') && this.formPost.get('registration_allowed')?.value === false) { // in questo caso vanno tolti i validatori dei campi nascosti
      this.formPost.get('total_seats').setValidators([])
      this.formPost.get('total_seats').updateValueAndValidity();

      this.formPost.get('reserved').setValidators([])
      this.formPost.get('reserved').updateValueAndValidity();

      this.formPost.get('start_date').setValidators([])
      this.formPost.get('start_date').updateValueAndValidity();

      this.formPost.get('end_date').setValidators([])
      this.formPost.get('end_date').updateValueAndValidity();

    }
    if (this.title != 'video-istituzionali' && this.title != 'tecnica-e-ricostruzione' && this.title != 'rassegna-stampa') {
      this.formPost.get('content').setValidators([Validators.required])
      this.formPost.get('content').updateValueAndValidity();
    }
    if (this.title === 'video-istituzionali') {
      this.formPost.get('video').setValidators([Validators.required])
      this.formPost.get('video').updateValueAndValidity();
    }
    if (this.title === 'tecnica-e-ricostruzione' || this.title === 'rassegna-stampa') {
      this.formPost.get('pdf').setValidators([Validators.required])
      this.formPost.get('pdf').updateValueAndValidity();
    }
  }

  checkCreatePost() {
    if (this.url.includes('create-post')) this.create = true;
    else {
      if (this.url.includes('restore') || this.url.includes('subscriptions')) {
        this.showCourse = true;
        this.create = false;
        this.restore = true;
        this.postEdit = false;
        this.postmodify = false;
        this.id = this.route.snapshot.params.id;
        this._posts.getPost(this.id).subscribe({
          next: response => {
            this.fileList = response.data ? response.data.files : [];
            let startdata = this.pipe.transform(response.data.start_date, 'YYYY-MM-dd');
            let enddata = this.pipe.transform(response.data.end_date, 'YYYY-MM-dd');
            var html = response.data.title;
            var div = document.createElement("div");
            var divContent = document.createElement("div");
            div.innerHTML = html;
            divContent.innerHTML = response.data.content;
            var contentDecoded = divContent.textContent || divContent.innerText || "";
            var titleDecoded = div.textContent || div.innerText || "";

            this.formPost.patchValue({
              id: response.data.id,
              title: titleDecoded,
              author: response.data.author_name,
              status: 'publish', //response.data.status, //'publish', // * Da sistemare BE
              total_seats: response.data.total_seats,
              reserved: response.data.reserved_seats,
              cfp: response.data.cfp,
              data_scadenza: response.data.data_scadenza,
              duration: response.data.duration,
              content: response.data.content,
              sticky: response.data.sticky,
              sendViaWhatsapp: response.data.sendViaWhatsapp ? true : false,
              start_date: response.data.start_date,
              end_date: response.data.end_date,
              category_id: this.categoryId,
              tags: response.data.tags,
              choise: response.data.fondazione ? true : false,
              sendViaTelegram: response.data.send_telegram ? true : false,
              sendViaMail: response.data.sendMail ? true : false,
              publish: response.data.publish_on_site,
              publish_on_site: response.data.publish_on_site ? false : true,
              event_date: response.data.event_start_date,
              free: response.data.free ? true : false,
              is_enable_certificate: response.data.is_enable_certificate ? true : false,
              registration_allowed: response.data.registration_allowed ? true : false
            }, { emitEvent: false });
            this.dinamicValidators();
          }
        })

      }
      else {
        this.create = false;
        this.postmodify = true;
        this.postEdit = true;
        this.showCourse = false;
        this.id = this.route.snapshot.params.id;

        this._posts.getPost(this.id).subscribe({
          next: response => {
            this.fileList = response.data ? response.data.files.map((file: any) => ({ ...file, uid: file.id })) : [];

            this.handleUpload()
            let startdata = this.pipe.transform(response.data.start_date, 'YYYY-MM-dd');
            let enddata = this.pipe.transform(response.data.end_date, 'YYYY-MM-dd');
            var html = response.data.title;
            var div = document.createElement("div");
            var divContent = document.createElement("div");
            div.innerHTML = html;
            divContent.innerHTML = response.data.content;
            var contentDecoded = divContent.textContent || divContent.innerText || "";
            var titleDecoded = div.textContent || div.innerText || "";

            this.formPost.patchValue({
              id: response.data.id,
              title: titleDecoded,
              author: response.data.author_name,
              status: response.data.status,
              total_seats: response.data.total_seats,
              reserved: response.data.reserved_seats,
              cfp: response.data.cfp,
              duration: response.data.duration,
              content: response.data.content,
              sticky: response.data.sticky,
              data_scadenza: response.data.data_scadenza,
              start_date: response.data.start_date,
              end_date: response.data.end_date,
              category_id: this.categoryId,
              sendViaWhatsapp: response.data.sendViaWhatsapp ? true : false,
              tags: response.data.tags,
              choise: response.data.fondazione ? true : false,
              sendViaTelegram: response.data.send_telegram ? true : false,
              sendViaMail: response.data.sendMail ? true : false,
              publish: response.data.publish_on_site,
              publish_on_site: response.data.publish_on_site ? false : true,
              event_date: response.data.event_start_date,
              free: response.data.free ? true : false,
              is_enable_certificate: response.data.is_enable_certificate ? true : false,
              registration_allowed: response.data.registration_allowed ? true : false
            }, { emitEvent: false })

            switch (this.title) {
              case 'comunicazioni': case 'corsi': case 'convegni': case 'seminari': case 'tavoli-tematici': case 'attivita-aggregative': case 'bandi': case 'video-istituzionali': case 'tecnica-e-ricostruzione': case 'rassegna-stampa': case 'convenzioni': {
                this.displayedTitle = this.formPost.value.title;
                break;
              }
            }
            // if (!this.url.includes('comunicazioni') && !this.url.includes('tavoli-tematici') && !this.url.includes('attivita-aggregative') && !this.url.includes('convenzioni') && !this.url.includes('video-istituzionali') && !this.url.includes('tecnica-e-ricostruzione') && !this.url.includes('rassegna-stampa') && moment(response.data.start_date) < moment())
            //   this.showCourse = true;

            this.fromFondazione = response.data.fromFondazione;
            this.original_form = this.formPost.value;

            if (moment(response.data.start_date) < moment()) this.formPost.get('total_seats').disable()

            this.dinamicValidators();
          }
        })
      }
    }

    if (this.url.includes('app/fondazione') && !this.url.includes('comunicazioni') && !this.url.includes('rassegna-stampa') && !this.url.includes('video-istituzionali') && this.url.includes('create-post')) {
      this.formPost.patchValue({
        status: 'draft'
      }, { emitEvent: false })
    }
  }

  stickyPost(event: any) {
    if (this.showCourse) event.target.checked = !event.target.checked
    else this.sticky = event.target.checked;
  }

  confirmModal(action: string) {
    // Swal.fire({
    //   title: '',
    //   text: "Sei certo di voler procedere al salvataggio? Tieni presente che una volta avviate le iscrizioni, non sarai più in grado di apportare modifiche.",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   reverseButtons: true,
    //   confirmButtonText: 'Conferma',
    //   cancelButtonText: 'Chiudi'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    if (action == 'create') this.createPost();
    else this.updatePost();
    //   }
    // });
  }


  viewModal() {
    Swal.fire({
      title: '',
      text: "Le Iscrizioni sono iniziate. Non è più possibile apportare modifiche.",
      icon: 'error',
      showConfirmButton: false,
      timer: 3000,
    })
  }
  createPost() {
    const currentUrl = this.router.url;
    console.log('Current URL:', currentUrl);
    if (currentUrl.includes('fondazione')) {
      this.formPost.value.fondazione = true;
    } else {
      this.formPost.value.fondazione = false;
    }
    this.formatDates();
    this._posts.createPost(this.formPost.value).subscribe({
      next: response => {
        Swal.fire({
          icon: 'success',
          title: '<div style="font-size:18px;">' + response.message + '</div>',
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          this.goTo()
        });
      }
    })
  }

  updatePost() {
    this.formatDates();
    this._posts.updatePost({ ...this.formPost.value, changes: this.changes }, this.route.snapshot.params.id).subscribe({
      next: response => {
        Swal.fire({
          icon: 'success',
          title: '<div style="font-size:18px;">' + response.message + '</div>',
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          this.goTo()
        });
      },
      error: error => {
        Swal.fire({
          icon: 'error',
          title: '<div style="font-size:18px;">' + error.message + '</div>',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
  }

  deletePermanently() {
    this._posts.delete([this.formPost.get('id').value], true).subscribe((res: any) => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">Eliminazione avvenuta con successo</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this.goTo();
    });
  }

  goTo(manualRedirect: boolean = false) {
    if (this.formPost.dirty && manualRedirect) {
      Swal.fire({
        title: '',
        text: "Uscire dalla pagina? Le modifiche apportate verranno perse",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        reverseButtons: true,
        confirmButtonText: 'Esci',
        cancelButtonText: 'Annulla'
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.url.includes('app/admin/dashboard/')) this.document.location.href = `${environment.dashboard}/${this.title}/${this.categoryId}`;
          else if (this.url.includes('app/client/subscriptions')) this.document.location.href = `${environment.subscriptions}/${this.title}/${this.categoryId}`;
          else if (this.url.includes('app/guest/subscriptions')) this.document.location.href = `${environment.guest_subscriptions}/${this.title}/${this.categoryId}`;
          else if (this.url.includes('app/fondazione/dashboard')) this.router.navigate([`app/fondazione/dashboard/${this.title}/${this.categoryId}`]);
        }
      });
    } else {
      if (this.url.includes('app/admin/dashboard/')) this.document.location.href = `${environment.dashboard}/${this.title}/${this.categoryId}`;
      else if (this.url.includes('app/client/subscriptions')) this.document.location.href = `${environment.subscriptions}/${this.title}/${this.categoryId}`;
      else if (this.url.includes('app/guest/subscriptions')) this.document.location.href = `${environment.guest_subscriptions}/${this.title}/${this.categoryId}`;
      else if (this.url.includes('app/fondazione/dashboard')) this.router.navigate([`app/fondazione/dashboard/${this.title}/${this.categoryId}`]);
    }
  }

  unsubscribe() {
    this._wp.unsubscribe(this.id).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">Disiscrizione effettuata con successo</div>',
        showConfirmButton: false,
        timer: 3000,
      });

      this.goTo();
    })
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    if (file.size != null && file.size > 32000000) {
      Swal.fire({
        icon: 'error',
        title: '<div style="font-size:18px;">La dimensione del file non può superare i 32MB!</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      return false;
    }
    this.fileList = this.fileList.concat(file);
    this.handleUpload();
    return false;
  };

  handleUpload(): void {
    let data: any[] = [];
    this.fileList.forEach((file: any) => {
      this.uploadLogic(data, file)
    });
  }


  uploadLogic(data: any[], file: any) {
    const reader = new FileReader();
    const blob = new Blob([file]);
    reader.readAsDataURL(blob);
    reader.onload = () => {
      data.push({ name: file.name, base64: reader.result, id: typeof file.uid == 'number' ? file.uid : null });
      if (data.length === this.fileList.length) {
        if (this.title === 'tecnica-e-ricostruzione' || this.title === 'rassegna-stampa') {
          this.formPost.patchValue({ pdf: data });
        } else if (this.title === 'video-istituzionali') {
          this.formPost.patchValue({ video: data });
        } else {
          this.formPost.patchValue({ attachmentsFile: data });
        }
      }
    };
  }


  handleRemove = (file: any): void => {
    let data: any[] = [];
    if (file.fileList.length > 0) {
      file.fileList.forEach((file: any) => {
        this.uploadLogic(data, file)
      });
    } else if (file.fileList.length == 0) {
      if (this.title === 'tecnica-e-ricostruzione' || this.title === 'rassegna-stampa') {
        this.formPost.patchValue({ pdf: [] });
      } else if (this.title === 'video-istituzionali') {
        this.formPost.patchValue({ video: [] });
      } else {
        this.formPost.patchValue({ attachmentsFile: [] });
      }
    }

  };


  getFormattedDate(date: moment.MomentInput, format: string | undefined) {
    return moment(date).locale('it').format(format);
  }

  get attachmentsFile() {
    return this.formPost.controls["attachmentsFile"] as FormArray;
  }

  private formatDates() {
    this.formPost.patchValue({
      publish: moment(this.formPost.value.publish).toISOString(true),
      start_date: moment(this.formPost.value.start_date).toISOString(true),
      end_date: moment(this.formPost.value.end_date).toISOString(true),
      event_date: moment(this.formPost.value.event_date).toISOString(true),
    }, { emitEvent: false });
  }

  dateValidation: ValidatorFn = (): ValidationErrors | null => {
    let start_date = this.formPost.get('start_date')?.value;
    let end_date = this.formPost.get('end_date')?.value;

    if (end_date && start_date && moment(end_date).unix() < moment(start_date).unix())
      return { dateValidationError: true };

    return null;
  }

  eventDateValidation: ValidatorFn = (): ValidationErrors | null => {
    let end_date = this.formPost.get('end_date')?.value;
    let event_date = this.formPost.get('event_date')?.value;

    if (end_date && event_date && moment(event_date).unix() < moment(end_date).unix())
      return { eventDateValidation: true };

    return null;
  }

  reservedSeatsValidation: ValidatorFn = (value): ValidationErrors | null => {
    let new_value = value.value;
    let old_value = this.original_form.reserved;

    if (new_value && old_value && new_value < old_value) return { reservedSeatsValidation: true };
    return null;
  }
}


