import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { debounceTime, finalize } from 'rxjs/operators';
import { EventService } from 'src/app/modules/application/services/event.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent implements OnInit, AfterViewInit {
  filters: FormGroup;

  public listOfData: any[] = [];
  public pageIndex = 1;
  public pageSize = 10;
  public total = 0;
  public totalPost: any
  status = 'publish';
  public isTableLoaded: boolean = false;
  url: string = ''
  selectedEvent = new Array()
  client: any

  // Definizione delle colonne
  columns = [
    { name: 'Titolo', key: 'title', type: 'text' },
    { name: 'Data di inizio', key: 'start_date', type: 'date' },
    { name: 'Data di fine', key: 'end_date', type: 'date' },
  ];


  constructor(
    private router: Router,
    private _eventService: EventService,
    private fb: FormBuilder) {

    this.filters = this.fb.group({
      search: null,
      start_date: null,
      end_date: null
    })
  }

  ngOnInit(): void {
    this.client = localStorage.getItem('role');
    this.getData()
    this.getCounters()
  }

  ngAfterViewInit(): void {
    this.filters.valueChanges.pipe(debounceTime(1000)).subscribe({
      next: value => {
        this.pageIndex = 1
        this.getData();
      }
    })
    // Aggiunge la colonna 'checkbox' solo se il cliente è 1
    if (this.client == 1) {
      this.columns.unshift({ name: '', key: 'checkbox', type: 'checkbox' });
    }

    if(this.client == 2){
      this.columns.push({ name: 'Stato', key: 'subscription_event_date', type: 'date-text' });
    }
  }

  exportIcs() {
    this._eventService.exportIcs().subscribe((icsData: string) => {
      this.downloadIcs(icsData);
    });
  }

  private downloadIcs(icsData: string) {
    const blob = new Blob([icsData], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'events.ics');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onPageIndexChange(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.getData();
  }

  checkSelectedEvent() {
    this.selectedEvent = this.listOfData.filter(data => data.checked);
  }

  resetFilter() {
    this.filters.reset()
  }

  isClickableRow(data: any): boolean {
    return (this.client == 1 || this.client == 4) && data.key == 'title';
  }

  getTooltipText(data: any, column: any): string {
    if ((this.client == 1 || this.client == 4) && column.key == 'title') {
      return 'Clicca qui per vedere la lista degli iscritti';
    } else if (this.client == 2 && column.key == 'title') {
      return 'Clicca qui per vedere il riepilogo';
    } else {
      return '';
    }
  }


  handleCellClick(data: any, column: any): void {
    if ((this.client == 1 || this.client == 4) && column.key == 'title') {
      this.getPath(data);
    }
    else if (this.client == 2 && column.key == 'title') {
      this.getPath(data, 'client')
    }
  }

  isCheckboxVisible(): boolean {
    return this.client == 1 || this.client == 4;
  }

  isEditButtonVisible(): boolean {
    return this.client != 2;
  }

  getActionButtonTooltip(data: any): string {
    return this.client == 1 ? 'Modifica' : 'Dettaglio';
  }

  isActionButtonVisible(): boolean {
    return this.client == 2;
  }

  isActionButtonLight(data: any): boolean {
    return data.button_text == 'Iscrizioni chiuse';
  }

  handleActionButtonClick(data: any): void {
    if (data.button_text == 'Iscriviti') {
      this.subscribe(data.id);
    } else {
      this.unsubscribe(data.id);
    }
  }


  subscribe(id: any) {
    Swal.fire({
      title: '',
      text: "Sei certo di voler procedere all'iscrizione?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Chiudi'
    }).then((result) => {
      if (result.isConfirmed) {
        this._eventService.subscription(id).subscribe({
          next: response => {
            Swal.fire({
              icon: 'success',
              title: '<div style="font-size:18px;">Iscrizione effettuata</div>',
              showConfirmButton: false,
              timer: 3000,
            });

            this.getData();
          },
          error: response => {
            Swal.fire({
              icon: 'error',
              title: '<div style="font-size:18px;">Non è possibile effettuare l\'iscrizione.</div>',
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
      }
    });
  }

  // *Controlla il path del componenete
  getPath(element: any, type?: string) {
    this.url = this.router.url;
    if (type == 'client') {
      this.goTo(element?.id);
    } else {
      this.goTo('/booked' + '/' + element?.id);
    }
  }

  goTo(path: string): void {
    this.url = this.router.url;
    this.router.navigate([`${this.url}/${path}`]);
  }


  getActionColumn(element: any) {
    let start = moment(element.start_date);
    let end = moment(element.end_date);
    let now = moment();
    //Se la data di inizio iscrizione è futura mostriamo il bottone Iscrizioni non aperte disabilitato
    if (start > now) {
      return { disabled: true, text: 'Iscrizioni non aperte', tooltip: false };
    }
    //Se la data corrente si  trova tra quella di inizio iscrizione e quella di fine, mostriamo il bottone Iscriviti
    else if (start < now && end > now && element.action != 'ammesso') {
      return { disabled: false, text: 'Iscriviti', tooltip: false };
    }

    else if (start < now && element.end_date == null && element.action != 'ammesso') {
      return { disabled: false, text: 'Iscriviti', tooltip: false };
    }

    //Se la data di fine iscrzione è passata, e l'ingegnere non si è iscritto, mostriamo una label Iscrizioni chiuse
    else if (end < now && element.action != 'ammesso') {
      return { disabled: true, text: 'Iscrizioni chiuse', tooltip: false };
    }
    else {
      return { disabled: false, text: 'Disiscriviti', tooltip: false };
    }
  }



  unsubscribe(id: any) {
    Swal.fire({
      title: '',
      text: "Sei certo di voler procedere alla disiscrizione?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Chiudi'
    }).then((result) => {
      if (result.isConfirmed) {
        this._eventService.unsubscribe(id).subscribe((res) => {
          Swal.fire({
            icon: 'success',
            title: '<div style="font-size:18px;">Disiscrizione effettuata con successo</div>',
            showConfirmButton: false,
            timer: 3000,
          });
          this.getData();
        });
      }
    });
  }

  getData() {
    this.selectedEvent = []
    this.isTableLoaded = false;
    let start_date = this.filters.get('start_date')?.value;
    let end_date = this.filters.get('end_date')?.value;
    let format_start_date = start_date != null ? moment(start_date).format('DD-MM-YYYY') : null;
    let format_end_date = end_date != null ? moment(end_date).format('DD-MM-YYYY') : null;
    let params = { skip: (this.pageIndex - 1) * this.pageSize, take: this.pageSize, search: this.filters.get('search')?.value, start_date: format_start_date, end_date: format_end_date, status: this.status };
    this._eventService.getAllEvent(params).subscribe(response => {
      this.listOfData = response.data;
      this.total = response.total ?? 0;
      this.isTableLoaded = true;
      response.data.forEach((item: any) => {
        item.button_text = this.getActionColumn(item).text;
        item.button_disabled = this.getActionColumn(item).disabled;
      });
    });
  }

  deleteEvent(): void {
    let selectedRows = this.listOfData.filter(data => data.checked);
    const selectedRowsIds = selectedRows.map((row: any) => row.id);

    this._eventService.deleteEvent(selectedRowsIds, this.status == 'trash')
      .pipe(finalize(() => {
        // Questo blocco di codice sarà eseguito al completamento della chiamata HTTP
        this.getData();
        this.getCounters();
        this.selectedEvent = [];
      }))
      .subscribe({
        next(response) {
          Swal.fire({
            icon: 'success',
            title: 'Evento eliminato con successo',
          });
        },
        error(error) {
          Swal.fire({
            icon: 'error',
            title: "Errore durante l'eliminazione",
            text: error,
          });
        }
      });
  }



  addEvent(): void {
    this.url = this.router.url;
    this.router.navigate([`${this.url}/new`]);
  }

  formatDateAndTime(dateTime: string): string {
    return moment(dateTime).format('DD-MM-YYYY HH:mm');
  }



  formatHour(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  getCounters() {
    this._eventService.getCounters().subscribe({
      next: (response) => {
        this.totalPost = response.data;
      },
    });
  }


  infoEvent(event: any) {
    this.url = this.router.url;
    this.router.navigate([`${this.url}/${event.id}`])
  }


  exportExcel() {
    this._eventService.exportExcelEvent({ type: "event", status: this.status }).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = 'eventi' + '_' + this.translateStatus(this.status) + '.xlsx';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  translateStatus(status: string): string {
    if (status == 'publish') {
      return 'pubblicati'
    } if (status == 'draft') {
      return 'bozze';
    } if (status == 'trash') {
      return 'cestino'
    } else {
      return '';
    }
  }

}
