import { UserService } from './../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    userForm: FormGroup | any;
    userId: any;

    lavori = [
        { label: 'Libero Professionista' },
        { label: 'Docente' },
        { label: 'Docente Universitario' },
        { label: 'Dipendente Pubblico' },
        { label: 'Dipendente Privato' },
        { label: 'Pensionato' },
    ];

    ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    editMail: any;
    editPec: any;

    constructor(
        public user: UserService,
        public fb: FormBuilder,
        private dateAdapter: DateAdapter<Date>
    ) {

    }

    ngOnInit() {
        this.ruolo.next(localStorage.getItem('role'));
        if (this.ruolo.getValue() == 3) {
            this.userForm = this.fb.group({
                id: null,
                name: [
                    { value: null, disabled: true },
                    Validators.compose([
                        Validators.required,
                        Validators.pattern("([-'a-zA-ZÀ-ÖØ-öø-ÿ]{1,}(\\s{1})?){1,}"),
                    ]),
                ],
                lastname: [
                    { value: null, disabled: true },
                    Validators.compose([
                        Validators.required,
                        Validators.pattern("([-'a-zA-ZÀ-ÖØ-öø-ÿ]{1,}(\\s{1})?){1,}"),
                    ]),
                ],
                username: [
                    { value: null, disabled: true },
                    Validators.compose([
                        Validators.required,
                        Validators.pattern("([-'a-zA-ZÀ-ÖØ-öø-ÿ]{1,}(\\s{1})?){1,}"),
                    ]),
                ],
                birthDate: [{ value: moment().format('YYYY-MM-DD'), disabled: true }],
                email: [{ value: null, disabled: true }, [Validators.email]],
                birthPlace: [{ value: null, disabled: true }],
                fiscalCode: [{ value: null, disabled: true }],
                pec: [{ value: null, disabled: true }, [Validators.email]],
                phone_residence: [{ value: null, disabled: true }, null],
                city_residence: [{ value: null, disabled: true }, null],
                street_residence: [{ value: null, disabled: true }, null],
                cap_residence: [{ value: null, disabled: true }, null],
                registrationNumber: [{ value: null, disabled: true }],
                rollRegistrationDate: [
                    { value: moment().format('YYYY-MM-DD'), disabled: true }
                ],
                categoria: [null],
                position: [{ value: null, disabled: true }],
                sector: [{ value: null, disabled: true }],
                studyTitle: [{ value: null, disabled: true }],
                degreed_at: [{ value: null, disabled: true }],
                specialization: [{ value: null, disabled: true }],
                web_site: { value: null, disabled: false },
                bio: { value: null, disabled: false },
                image: null,
                notification_consent: [false],
            });
        } else {
            this.userForm = this.fb.group({
                id: null,
                name: [
                    { value: null, disabled: true },
                    Validators.compose([
                        Validators.required,
                        Validators.pattern("([-'a-zA-ZÀ-ÖØ-öø-ÿ]{1,}(\\s{1})?){1,}"),
                    ]),
                ],
                lastname: [
                    { value: null, disabled: true },
                    Validators.compose([
                        Validators.required,
                        Validators.pattern("([-'a-zA-ZÀ-ÖØ-öø-ÿ]{1,}(\\s{1})?){1,}"),
                    ]),
                ],
                username: [
                    { value: null, disabled: true },
                    Validators.compose([
                        Validators.required,
                        Validators.pattern("([-'a-zA-ZÀ-ÖØ-öø-ÿ]{1,}(\\s{1})?){1,}"),
                    ]),
                ],
                birthDate: [{ value: moment().format('YYYY-MM-DD'), disabled: true }, Validators.required],
                email: [{ value: null, disabled: true }, [Validators.required, Validators.email]],
                birthPlace: [{ value: null, disabled: true }, null],
                fiscalCode: [{ value: null, disabled: false }, Validators.required],
                pec: [{ value: null, disabled: true }, Validators.email],
                phone_residence: [{ value: null, disabled: true }, null],
                city_residence: [{ value: null, disabled: true }, null],
                street_residence: [{ value: null, disabled: true }, null],
                cap_residence: [{ value: null, disabled: true }, null],
                // roll: [null, Validators.required],
                registrationNumber: [{ value: null, disabled: false }, Validators.required],
                rollRegistrationDate: [
                    { value: moment().format('YYYY-MM-DD'), disabled: true },
                    null,
                ],
                categoria: [null, null],
                position: [{ value: null, disabled: true }, null],
                sector: [{ value: null, disabled: true }, null],
                studyTitle: [{ value: null, disabled: true }, null],
                degreed_at: [{ value: null, disabled: true }, null],
                specialization: [{ value: null, disabled: true }, null],
                web_site: { value: null, disabled: false },
                bio: { value: null, disabled: false },
                image: null,
                notification_consent: [false, null],
            });
        }

        this.dateAdapter.setLocale('it-IT');
        this.getUser();
    }

    getFormattedDate(date: moment.MomentInput, format: string | undefined) {
        return moment(date).locale('it').format(format);
    }

    getUser() {
        this.user.me().subscribe((element) => {
            this.editMail = element.data.edit_mail;
            this.editPec = element.data.edit_mail_pec;
            this.userForm.patchValue({
                id: element.data.id,
                name: element.data.name,
                lastname: element.data.lastname,
                username: element.data.username,
                email: element.data.email,
                birthDate: element.data.birthDate,
                birthPlace: element.data.birthPlace,
                fiscalCode: element.data.fiscalCode,
                pec: element.data.pec,
                phone_residence: element.data.phone_residence,
                city_residence: element.data.city_residence,
                street_residence: element.data.street_residence,
                cap_residence: element.data.cap_residence,
                registrationNumber: element.data.registrationNumber,
                rollRegistrationDate: element.data.rollRegistrationDate,
                categoria: element.data.categoria,
                position: element.data.position,
                sector: element.data.sector,
                studyTitle: element.data.studyTitle,
                web_site: element.data.web_site,
                bio: element.data.bio,
                image: element.data.image_profile_url,
                degreed_at: element.data.degreed_at,
                specialization: element.data.specialization,
                notification_consent: element.data.notification_consent
            });
        });
    }

    updateUser() {
        if (this.userForm.value.rollRegistrationDate != null) {
            this.userForm.patchValue({
                rollRegistrationDate: this.getFormattedDate(this.userForm.value.rollRegistrationDate, 'YYYY-MM-DD'),
            });
        }

        this.userForm.get('email').enable();
        this.userForm.get('pec').enable();
        if (!this.userForm.valid) {
            Swal.fire({
                icon: 'error',
                title: '<div style="font-size:18px;">Compila correttamente tutti i campi obbligatori prima di aggiornare il profilo</div>',
                showConfirmButton: false,
                timer: 5000,
            });
            return;
        }
        this.user.update(this.userForm.value).subscribe({
            next: (response) => {
                this.user.setUser(this.userForm.value);
                if (response.code == 200) {
                    Swal.fire({
                        icon: 'success',
                        title: '<div style="font-size:18px;">Profilo aggiornato</div>',
                        showConfirmButton: false,
                        timer: 1000,
                    });
                    this.ngOnInit();
                }
            },
            error: (error) => {
                Swal.fire({
                    icon: 'error',
                    title: '<div style="font-size:18px;">' + error + '</div>',
                    showConfirmButton: false,
                    timer: 5000,
                });
            }
        });
    }

    activateUpdate(formControlName: string) {
        if (this.userForm.get(formControlName).disabled)
            this.userForm.get(formControlName).enable();
        else
            this.userForm.get(formControlName).disable();
    }
}
