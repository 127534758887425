
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlboComponent } from './components/albo/albo.component';
import { AuthGuard } from './modules/auth/components/auth.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('../app/modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'app', loadChildren: () => import('../app/modules/application/application.module').then(m => m.ApplicationModule), canActivate: [AuthGuard] },
  { path: 'albo', component: AlboComponent },
  { path: '*', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
