import { Component, OnInit } from '@angular/core';
import { DatatableService } from '../../services/datatable.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { UserService } from '../../services/user.service';
import { PostsServices } from "../../services/posts.service";
import { EventService } from '../../services/event.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-utenti-iscritti',
  templateUrl: './utenti-iscritti.component.html',
  styleUrls: ['./utenti-iscritti.component.scss']
})
export class UtentiIscrittiComponent implements OnInit {

  postId: any;
  url: any;
  intPostId: any;
  subsUsers: any;
  cols = new Array();
  test: any;
  rows = new Array();
  showCheckbox = false;
  ammesso = 'ammesso';
  nonAmmesso = 'non ammesso';
  page: any;
  search_key: any = '';
  searchField: FormControl = new FormControl();
  searchDelay: number = 2000;
  pipe = new DatePipe('en-US');
  length!: number;
  page_length: any;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10];
  showFirstLastButtons: boolean = true;
  id: any;
  wp_id: any;
  titleCourse: any;
  checkedPost: any;
  free: boolean = true;
  categoryId: any;

  //  *sono stati tolti i seguenti campi della colonna = 'sub_status', 'accetta'
  colsHead: string[] = ['select', 'registrationNumber', 'name', 'lastname', 'fiscalCode', 'request_certificate', 'status', 'action'];
  constructor(
    private datatableService: DatatableService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private _posts: PostsServices,
    private _eventService: EventService,
    private location: Location
  ) { }

  ngOnInit() {
    this.url = this.router.url;
    this.categoryId = Number(this.route.snapshot.url[1].path);
    if (this.url.includes('tavoli-tematici')) this.colsHead = ['registrationNumber', 'name', 'lastname', 'fiscalCode'];
    if (this.url.includes('seminari')) {
      let index = this.colsHead.indexOf('request_certificate');
      this.colsHead.splice(index, 1);
    }

    this.postId = this.route.snapshot.paramMap.get('id');
    this.intPostId = Number(this.postId);
    this.getSubs(null, null);
    this.getSingleCourse();

    this.searchField.valueChanges.pipe(
      debounceTime(this.searchDelay)
    ).subscribe(value => {
      this.search_key = value;
      this.getSubs(value);

    });
  }

  getSingleCourse() {
    this.id = this.route.snapshot.params.id;
    this.wp_id = this.route.snapshot.params.wp_id;

    this._posts.getPost(this.id).subscribe({
      next: response => {
        this.free = response.data.free
        this.titleCourse = response.data.title;
      }
    })
  }


  getSubs(search?: any, status?: any) {
    this.page = this.pageIndex + 1;
    this.page_length = this.pageSize;

    this.datatableService.subUsers(this.intPostId, this.page, this.page_length, this.search_key).subscribe((r: any) => {
      // if (search) data.filter = search;
      this.length = r.total ?? 0;
      this.cols = r.data;

      this.cols.forEach(res => {
        const time = res.sub_date;
        const myFormattedDate = this.pipe.transform(time, 'dd-MM-YYYY');
        res.date = myFormattedDate;
        // if (res.sub_status == 'waiting') res.sub_status = '⏱';
        if (res.sub_status == 'waiting') res.sub_status = 'In attesa';
        if (res.sub_status == 'ammesso') res.sub_status = 'Ammesso';
        if (res.sub_status == 'non ammesso') res.sub_status = 'Rigettato';

        if (res.request_certificate == null) res.request_certificate = 'Non richiesto';
        if (res.request_certificate == 1) res.request_certificate = 'Richiesto';
        if (res.request_certificate == 0) res.request_certificate = 'Consegnato';


      })
    })
  }

  changeStatusCertificate(event: any) {
    this.userService.changeStatusCertificate().subscribe(res => {
    })
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getSubs(this.search_key, null);
    return event;
  }


  datatable(event: any) {
    this.rows = event;
  }

  reloadData() {
    this.ngOnInit();
  }

  acceptedUser() {
    this.datatableService.subsManagement(this.rows, this.intPostId, this.ammesso).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">Utente ammesso/i</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this.getSubs(null, null);
      this.reloadData();
      this.rows.splice(0, this.rows.length);
    })
  }

  deniedUser() {
    this.datatableService.subsManagement(this.rows, this.intPostId, this.nonAmmesso).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">Operazione conclusa con successo</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this.getSubs(null, null);
      this.rows = [];
    })
  }

  admitUser() {
    this.datatableService.subsManagement(this.rows, this.intPostId, this.ammesso).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">Operazione conclusa con successo</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this.getSubs(null, null);
      this.rows = [];
    })
  }

  exportExcel() {
    this._eventService.exportExcelEvent({ status: '', post_id: this.postId, category_id: this.route.snapshot.url[1].path, type: "signed_up" }).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = 'Iscritti' + '.xlsx';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  goBack() {
    this.location.back();
  }

}
