import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-admission-thematic',
  templateUrl: './admission-thematic.component.html',
  styleUrls: ['./admission-thematic.component.scss']
})
export class AdmissionThematicComponent implements OnInit {

  displayedColumns: string[] = ['check', 'name', 'surname', 'date', 'certificate', 'status', 'request_certificate'];
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  currentPage = 0;
  pageSize = 5;
  filtroVariabile: any;
  filter: any;
  pageEvent: PageEvent | undefined;
  tables: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  user_id: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedIndex: number | undefined;
  searchDelay: number = 1000;
  filtroRicerca: FormControl = new FormControl();
  id: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];

    this.getData();
  }

  getData() {
    let params = this.filter ? { start: this.currentPage * this.pageSize, length: this.pageSize, like_search: this.filter, thematic_id: this.id } : { start: this.currentPage * this.pageSize, length: this.pageSize, thematic_id: this.id };
    this.userService.getAdmissionThematicTable(params).subscribe(response => {
      if (!response.request_certificate) response.request_certificate = 'Non richiesto';
      if (response.request_certificate == 1) response.request_certificate = 'Richiesto';
      if (response.request_certificate == 2) response.request_certificate = 'Consegnato';
      this.tables.next(response.data);
      this.records.next(response.total);
      //console.log('dati: ', this.tables.value);
    });
  }

  changeSelection(event: { target: { checked: any; }; }, index: number | undefined) {
    this.selectedIndex = event.target.checked ? index : undefined;
    //console.log('indice', this.selectedIndex);
  }

  admission(status: string) {
    let params = { status: status, user_id: this.selectedIndex, thematic_id: this.id }
    this.userService.updateStatus(params).subscribe(response => {
      //console.log(response);
      this.getData();
    })
  }

  changeStatusCertificate(user_id: any, thematic_id: any, event: any) {
    let number = 0;
    if (event.target.checked == true) {
      number = 2;
    } else {
      number = 1;
    }
    //console.log('number', number);
    this.userService.changeStatusCertificateThematic({ thematic_id: thematic_id, user_id: user_id, status_request: number }).subscribe(res => {
      this.getData();
    })

  }

  checked(el: any): boolean {
    //console.log(el)
    if (el == 2) return true;
    return false;
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getData();
    return e;
  }

}
