import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { EventService } from 'src/app/modules/application/services/event.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { config } from '../../../../../../../environments/config';
import { finalize } from 'rxjs/operators';
import * as moment from "moment/moment";



@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss']
})
export class AddEventComponent implements OnInit, AfterViewInit {

  eventForm: FormGroup
  radioValue: boolean = false
  eventId: string | null = ''
  client: any

  editorConfig: AngularEditorConfig = config.angular_editor;


  constructor(
    private fb: FormBuilder,
    private location: Location,
    private _eventService: EventService,
    private _activatedRoute: ActivatedRoute,
    private router: Router,

  ) {
    this.eventForm = this.fb.group({
      title: [null, Validators.required],
      description: [null, Validators.required],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],
      choise: [false, Validators.required],
      sendViaMail: [false, Validators.required],
      status: ['draft', Validators.required]
    }, {
      validator: this.dateTimeRangeValidator('start_date', 'end_date')
    })

  }

  dateTimeRangeValidator(startControlName: string, endControlName: string) {
    return (group: FormGroup) => {
      const startControl = group.get(startControlName);
      const endControl = group.get(endControlName);

      if (startControl && endControl && startControl.value && endControl.value) {
        const startTime = new Date(startControl.value);
        const endTime = new Date(endControl.value);

        if (startTime > endTime) {
          endControl.setErrors({ dateTimeRange: true });
        } else {
          endControl.setErrors(null);
        }
      }
    };
  }

  ngOnInit(): void {
    this.client = localStorage.getItem('role');
    // Ottenere l'ID dell'evento dalla route
    this.eventId = this._activatedRoute.snapshot.paramMap.get('event');
    if (this.eventId) {
      this.getData(this.eventId)
    }
  }


  ngAfterViewInit(): void {
    if (this.client == 2 || this.client == 4 && this.eventId) {
      this.eventForm.disable()
      this.editorConfig.editable = false
    } else {
      this.editorConfig.editable = true

    }
  }

  getData(eventId: string) {
    this._eventService.getEventById(eventId).subscribe((res) => {
      // Patchare il form con i dati ottenuti
      this.eventForm.patchValue({
        title: res.data.title,
        description: res.data.description,
        start_date: res.data.start_date,
        end_date: res.data.end_date,
        status: res.data.status
      });
    });
  }

  exportIcs() {
    this._eventService.exportIcsById(this.eventId).subscribe((icsData: string) => {
      this.downloadIcs(icsData);
    });
  }

  private downloadIcs(icsData: string) {
    const blob = new Blob([icsData], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', 'event.ics');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onSubmit() {
    if (this.eventForm.valid) {
      if (this.eventId) {
        this.formatDates();

        this._eventService.updateEvent(this.eventId, this.eventForm.value)
          .pipe(finalize(() => this.handleRedirect()))
          .subscribe({
            next(response) {
              Swal.fire({
                icon: 'success',
                title: 'Evento aggiornato con successo',
              });
            },
            error(error) {
              Swal.fire({
                icon: 'error',
                title: "Errore durante la modifica",
                text: error,
              });
            }
          });

        this.getData(this.eventId);
      } else {
        this.formatDates();

        this._eventService.postEvent(this.eventForm.value)
          .pipe(finalize(() => this.handleRedirect()))
          .subscribe({
            next(response) {
              Swal.fire({
                icon: 'success',
                title: 'Evento aggiunto con successo',
              });
            },
            error(error) {
              Swal.fire({
                icon: 'error',
                title: "Errore durante la creazione",
                text: error,
              });
            }
          });
      }
    }
  }

  // Metodo per gestire il reindirizzamento
  private handleRedirect() {
    if (this.client == 4) {
      this.router.navigate([`/app/fondazione/events`]);
    } else {
      this.router.navigate([`/app/admin/events`]);
    }
  }


  goBack() {
    this.location.back();
  }

  private formatDates() {
    this.eventForm.patchValue({
      start_date: moment(this.eventForm.value.start_date).toISOString(true),
      end_date: moment(this.eventForm.value.end_date).toISOString(true),
    }, { emitEvent: false });
  }
}
