<div class="m-auto col-11 mt-5">
     <h2>{{ courseId ? 'Modifica Corso' : 'Aggiungi Corso' }}</h2>


     <form [formGroup]="formPost">
          <div class="row">
               <div class="col-12">
                    <label class="form-label">Titolo</label>
                    <nz-form-control>
                      <nz-select class="w-100" id="type" formControlName="external_course_type_id" nzPlaceHolder="Seleziona la tipologia di corso">
                        <nz-option *ngFor="let type of types" [nzValue]="type.id"
                                   [nzLabel]="type.title"></nz-option>
                      </nz-select>
                    </nz-form-control>
                    <div *ngIf="formPost.get('external_course_type_id')?.hasError('required') && formPost.get('external_course_type_id')?.touched"
                         class="text-danger">
                         Titolo è obbligatorio
                    </div>
               </div>
               <div class="col-12 col-md-4 mt-2">
                    <label class="form-label" nzFor="data">Data e ora di inizio</label>
                    <nz-form-control>
                         <nz-date-picker [nzFormat]="'dd-MM-YYYY HH:mm'" [nzPlaceHolder]="'Seleziona data e ora'"
                              class="w-100" nzShowTime formControlName="start_date"></nz-date-picker>
                    </nz-form-control>
                    <div *ngIf="formPost.get('start_date')?.hasError('required') && formPost.get('start_date')?.touched"
                         class="text-danger">
                         Data e ora di inizio è obbligatoria
                    </div>
               </div>

               <div class="col-12 col-md-4 mt-2">
                    <label class="form-label" nzFor="data">Data e ora di fine</label>
                    <nz-form-control>
                         <nz-date-picker [nzFormat]="'dd-MM-YYYY HH:mm'" class="w-100"
                              [nzPlaceHolder]="'Seleziona data e ora'" nzShowTime
                              formControlName="end_date"></nz-date-picker>
                    </nz-form-control>
                    <div *ngIf="formPost.get('end_date')?.hasError('required') && formPost.get('end_date')?.touched"
                         class="text-danger">
                         Data e ora di fine è obbligatoria
                    </div>
                    <div *ngIf="formPost.get('end_date')?.hasError('dateTimeRange') && formPost.get('end_date')?.touched"
                         class="text-danger">
                         La data e l'ora di fine non possono essere inferiori a quelle di inizio
                    </div>
               </div>

              <div class="col-12 col-md-4 mt-2">
                  <label class="form-label" nzFor="data">Durata in ore</label>
                  <input nz-input type="number" class="form-control" aria-describedby="basic-addon1" formControlName="duration"
                         oninput="this.value = this.value.replace(/[^0-9: ]/g, '').replace(/( .*?),(.* )?/, '$1')">

                  <div *ngIf="formPost.get('duration')?.hasError('required') && formPost.get('duration')?.touched"
                       class="text-danger">
                      Durata è obbligatoria
                  </div>
              </div>

               <div class="col-12 mt-2">
                    <label class="form-label">Descrizione</label>
                    <nz-form-control>
                         <angular-editor id="descrizione" name="descrizione" formControlName="description"
                              [config]="editorConfig"></angular-editor>
                    </nz-form-control>
                    <div *ngIf="formPost.get('description')?.hasError('required') && formPost.get('description')?.touched"
                         class="text-danger">
                         Descrizione è obbligatoria
                    </div>
               </div>
          </div>

          <nz-form-item class="space mt-3">
               <nz-form-label>Allegati</nz-form-label>
               <nz-form-control>
                    <section class="d-flex flex-row justify-content-start">
                         <nz-upload class="col-12 col-xl-auto"
                              [(nzFileList)]="fileList" [nzMultiple]="true" [nzBeforeUpload]="beforeUpload" (nzChange)="handleRemove($event)">
                              <button nz-button>
                                   <span nz-icon nzType="upload"></span>
                                   Seleziona file
                              </button>
                         </nz-upload>
                    </section>
               </nz-form-control>
          </nz-form-item>

          <div class="mt-4 d-flex justify-content-end mt-2">
               <div>
                    <button class="me-1 btn grey space ng-star-inserted" (click)="goTo()">INDIETRO</button>
               </div>
               <div>
                    <button [disabled]="!formPost.valid" class="btn blu" (click)="createPost()">SALVA</button>
               </div>
          </div>
     </form>
</div>
