export const environment = {
  production: false,
  project: 'https://dev-portaleing.darwintech.it',
  dashboard: 'https://dev-portaleing.darwintech.it/app/admin/dashboard',
  subscriptions: 'https://dev-portaleing.darwintech.it/app/client/subscriptions',
  guest_subscriptions: 'https://dev-portaleing.darwintech.it/app/guest/subscriptions',
  api: 'https://dev-portaleing.darwintech.it/services/api',
  // * In questo modo puntiamo direttamente al BE in produzione
  // api: 'https://dev-portaleing.darwintech.it/services/api',
  storageBE: 'https://dev-portaleing.darwintech.it/services/storage',
  spidDomainAPI: "https://pub.fastid.it"
};
