<div class="m-auto mt-5 col-11">
  <div class="title" *ngIf="create; else edit">
    <h2>
      <span (click)="goTo(true)" class="clickable"><i class="fa-solid fa-arrow-left"></i></span> Aggiungi <span
        class="capitalize">{{displayedTitle}}</span>
    </h2>
  </div>
  <ng-template #edit>
    <h2 *ngIf="postmodify; else trash">
      <span (click)="goTo(true)" class="clickable"><i class="fa-solid fa-arrow-left"></i></span> Modifica
      <span>{{(displayedTitle.length>50)? (displayedTitle | slice:0:50)+'...':(displayedTitle)}}</span>
    </h2>
    <!--        <div *ngIf="!client && showCourse && !url.includes('faq') && !url.includes('tavoli-tematici') && !url.includes('attivita-aggregative') && !url.includes('bandi') && !url.includes('convenzioni')"-->
    <!--            class="alert alert-danger" role="alert" style="width:46%">-->
    <!--            Le Iscrizioni sono iniziate. Non è più possibile apportare modifiche.-->
    <!--        </div>-->

    <div *ngIf="fromFondazione && !fond" class="alert alert-warning d-inline-block" role="alert">
      <i class="fa-solid fa-circle-info me-3"></i>
      <span>Creato dalla Fondazione</span>
    </div>
    <ng-template #trash>
      <h2 *ngIf="showCourse == false; else riepilogo">Ripristina <span class="capitalize">{{title}}</span></h2>
      <ng-template #riepilogo>
        <h2>
          <span (click)="goTo(true)" class="clickable"><i class="fa-solid fa-arrow-left"></i></span> Riepilogo
        </h2>
      </ng-template>
    </ng-template>
  </ng-template>
  <form action="" [formGroup]="formPost">
    <div class="input-group mb-3 d-flex justify-content-between">
      <div class="col">
        <label for="basic-url" class="form-label">Titolo</label>
        <!-- <textarea   class="form-control" rows="3" formControlName="title"
                    readonly></textarea> -->
        <p *ngIf="showCourse && !url.includes('faq')  && !url.includes('bandi'); else titolo" rows="3">
          {{formPost.get('title')?.value}}</p>
        <ng-template #titolo>
          <textarea class="form-control" rows="3" formControlName="title"></textarea>
        </ng-template>
        <div
          *ngIf="formPost.controls['title'].invalid && (formPost.controls['title'].dirty || formPost.controls['title'].touched)"
          class="m-0 p-0">
          <em class="input-form-error">* Titolo non
            valido</em>
        </div>
      </div>
    </div>


    <div class="input-group mb-3 d-flex justify-content-between spc"
      *ngIf="title != 'comunicazioni' && title != 'faq' && title != 'tavoli-tematici' && title != 'attivita-aggregative' && title != 'bandi' && title != 'convenzioni' && title != 'video-istituzionali' && title != 'tecnica-e-ricostruzione' && title != 'rassegna-stampa'">
      <div class="col-sm-2" *ngIf="formPost.get('registration_allowed')?.value">
        <label for="basic-url" class="form-label">Max Iscritti</label>
        <!-- <input *ngIf="showCourse == true; else posti"  type="number" class="form-control"
                    aria-describedby="basic-addon1" formControlName="total_seats" readonly> -->
        <p *ngIf="showCourse; else Iscritti" rows="3">{{formPost.get('total_seats')?.value}}</p>
        <ng-template #Iscritti>
          <input type="number" class="form-control" aria-describedby="basic-addon1" formControlName="total_seats"
            oninput="this.value = this.value.replace(/[^0-9: ]/g, '').replace(/( .*?),(.* )?/, '$1')">
          <div
            *ngIf="formPost.controls['total_seats'].invalid && (formPost.controls['total_seats'].dirty || formPost.controls['total_seats'].touched)"
            class="m-0 p-0">
            <em class="input-form-error">*Non valido</em>
          </div>
        </ng-template>
      </div>
      <div class="col-sm-2" *ngIf="formPost.get('registration_allowed')?.value">
        <label for="basic-url" class="form-label">Posti Riservati</label>
        <p *ngIf="showCourse; else posti" rows="3">{{formPost.get('reserved')?.value}}</p>
        <ng-template #posti>
          <input type="number" class="form-control" aria-describedby="basic-addon1" formControlName="reserved"
            oninput="this.value = this.value.replace(/[^0-9: ]/g, '').replace(/( .*?),(.* )?/, '$1')">
          <div
            *ngIf="formPost.controls['reserved'].invalid && (formPost.controls['reserved'].dirty || formPost.controls['reserved'].touched)"
            class="m-0 p-0">
            <em class="input-form-error">*Non valido</em>
            <em *ngIf="formPost.get('reserved').hasError('reservedSeatsValidation')" class="input-form-error">: non può
              essere inferiore a quello inserito precedentemente</em>
          </div>
        </ng-template>
      </div>
      <div class="col-sm-2">
        <label for="basic-url" class="form-label">CFP</label>
        <!-- <input *ngIf="showCourse == true; else cfp"  type="number" class="form-control"
                    aria-describedby="basic-addon1" formControlName="cfp" readonly> -->
        <p *ngIf="showCourse; else cfp" rows="3">{{formPost.get('cfp')?.value}}</p>
        <ng-template #cfp>
          <input type="number" class="form-control" aria-describedby="basic-addon1" formControlName="cfp"
            oninput="this.value = this.value.replace(/[^0-9: ]/g, '').replace(/( .*?),(.* )?/, '$1')">
          <div
            *ngIf="formPost.controls['cfp'].invalid && (formPost.controls['cfp'].dirty || formPost.controls['cfp'].touched)"
            class="m-0 p-0">
            <em class="input-form-error">*Non
              valido</em>
          </div>
        </ng-template>
      </div>
      <div class="col-sm-2">
        <label for="basic-url" class="form-label">Durata in ore</label>
        <!-- <input *ngIf="showCourse == true; else durata"  type="number" class="form-control"
                    aria-describedby="basic-addon1" formControlName="duration" readonly> -->
        <p *ngIf="showCourse; else durata" rows="3">{{formPost.get('duration')?.value}}</p>
        <ng-template #durata>
          <input type="number" class="form-control" aria-describedby="basic-addon1" formControlName="duration"
            oninput="this.value = this.value.replace(/[^0-9: ]/g, '').replace(/( .*?),(.* )?/, '$1')">
          <div
            *ngIf="formPost.controls['duration'].invalid && (formPost.controls['duration'].dirty || formPost.controls['duration'].touched)"
            class="m-0 p-0">
            <em class="input-form-error">*Non
              valido</em>
          </div>
        </ng-template>
      </div>

      <!-- Serve a mantenere il layout corretto nel caso in cui Max iscritti e Posti riservati siano nascosti -->
      <div class="col-sm-2" *ngIf="!formPost.get('registration_allowed')?.value"></div>
      <div class="col-sm-2" *ngIf="!formPost.get('registration_allowed')?.value"></div>

    </div>
    <div class="input-group mb-3 d-flex justify-content-between spc"
      *ngIf="title != 'comunicazioni' && title != 'faq'  && title != 'tavoli-tematici' && title != 'attivita-aggregative' && title != 'bandi' && title != 'convenzioni' && title != 'video-istituzionali' && title != 'tecnica-e-ricostruzione' && title != 'rassegna-stampa'">
      <div class="col-sm-2" *ngIf="formPost.get('registration_allowed')?.value">
        <label for="basic-url" class="form-label">Apertura Iscrizioni</label>
        <p *ngIf="showCourse; else datainizio" rows="3">
          {{formPost.get('start_date')?.value | date:'dd-MM-YYYY HH:mm' }}
        </p>
        <ng-template #datainizio>
          <!-- * Da Admin vedo solo gli input per l'add o l'upload -->
          <br>
          <nz-date-picker nzShowTime nzAllowClear="false" formControlName="start_date"
            nzFormat="dd-MM-yyyy HH:mm"></nz-date-picker>
          <div
            *ngIf="(formPost.controls['start_date'].invalid && (formPost.controls['start_date'].dirty || formPost.controls['start_date'].touched))"
            class="m-0 p-0">
            <em class="input-form-error">*Data di inizio non valida</em>
          </div>
        </ng-template>
      </div>
      <div class="col-sm-2" *ngIf="formPost.get('registration_allowed')?.value">
        <label for="basic-url" class="form-label">Chiusura Iscrizioni</label>

        <!-- * Da utente vedo solo il testo del corso -->
        <p *ngIf="showCourse; else datafine" rows="3">
          {{formPost.get('end_date')?.value | date:'dd-MM-YYYY HH:mm' }}
        </p>
        <ng-template #datafine>
          <!-- * Da Admin vedo solo gli input per l'add o l'upload -->
          <br>
          <nz-date-picker nzAllowClear="false" nzShowTime formControlName="end_date"
            nzFormat="dd-MM-yyyy HH:mm"></nz-date-picker>
          <!-- <input type="datetime-local" class="form-control" aria-describedby="basic-addon1"
                        formControlName="end_date"> -->
          <div
            *ngIf="formPost.controls['end_date'].invalid && (formPost.controls['end_date'].dirty || formPost.controls['end_date'].touched)"
            class="m-0 p-0">
            <em class="input-form-error">*Data di fine non
              valida</em>
          </div>
        </ng-template>
      </div>
      <div class="col-sm-2">
        <div *ngIf="showCourse; else dataEvento" rows="3">
          <!-- * Da utente vedo solo il testo del corso -->
          <p *ngIf="formPost.get('event_date')?.value">
            <label for="basic-url" class="form-label">Data Evento</label>
            <br>
            {{formPost.get('event_date')?.value | date:'dd-MM-YYYY HH:mm' }}
          </p>

        </div>
        <ng-template #dataEvento>
          <label for="basic-url" class="form-label">Data Evento</label>
          <!-- * Da Admin vedo solo gli input per l'add o l'upload -->
          <br>
          <nz-date-picker nzAllowClear="false" nzShowTime formControlName="event_date"
            nzFormat="dd-MM-yyyy HH:mm"></nz-date-picker>

          <div
            *ngIf="formPost.controls['event_date'].invalid && (formPost.controls['event_date'].dirty || formPost.controls['event_date'].touched)"
            class="m-0 p-0">
            <em class="input-form-error">*Data evento non valida</em>
          </div>
        </ng-template>
      </div>

      <!-- Serve a mantenere il layout corretto nel caso in cui Apertura iscrizioni e Chiusura iscrizioni siano nascosti -->
      <div class="col-sm-2" *ngIf="!formPost.get('registration_allowed')?.value"></div>
      <div class="col-sm-2" *ngIf="!formPost.get('registration_allowed')?.value"></div>

      <div class="col-sm-2"></div>
    </div>

    <div class="row">
      <div class="col-sm-10 col-xl-6" *ngIf="categoryId == 2">
        <div class="d-flex justify-content-start align-items-center my-2" *ngIf="!showCourse ">
          <label for="basic-url" class="form-label pe-4 d-block mb-0 pb-0">Gratuito</label>
          <div *ngFor="let choice of fondazione">
            <input id="enum_answer_{{choice.value}}" [value]='choice.value' type="radio" formControlName="free">
            <span class="ms-1 me-2 text-black">{{choice.label}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 mb-3"
      *ngIf="title != 'video-istituzionali' && title != 'tecnica-e-ricostruzione' && title != 'rassegna-stampa'">

      <!-- * Da utente vedo solo il testo del corso -->
      <label for="exampleFormControl" class="form-label">Contenuto</label>
      <p *ngIf="showCourse && !url.includes('faq')  && !url.includes('bandi'); else contenuto" rows="3" class="justify"
        [innerHTML]="formPost.get('content')?.value">
      </p>

      <ng-template #contenuto>
        <angular-editor formControlName="content" [config]="editorConfig"></angular-editor>
      </ng-template>
    </div>
    <div class="d-flex align-items-center row">
      <!-- * Da utente vedo solo il testo del corso -->
      <hr
        *ngIf="showCourse && !client  && !url.includes('restore') && !url.includes('faq') && !url.includes('tavoli-tematici') && !url.includes('attivita-aggregative') && !url.includes('bandi') && !url.includes('convenzioni')"
        class="line">
      <!-- <div *ngIf="client">
                <label class="form-label pe-4">Autore:</label> <span>{{formPost.get('author')?.value}}</span>
            </div> -->

      <div class="col-sm-4 col-md-4 m-2 ms-0" *ngIf="title != 'faq' && title != 'bandi' && title != 'bandi'">
        <label for="formFile" class="form-label pe-4" *ngIf="!showCourse || url.includes('restore')">
          Stato
        </label>

        <div class="btn-group">
          <nz-select *ngIf="showCourse; else stato" class="pad d-none" formControlName="status" id="status">
            <nz-option *ngFor="let value of status" [nzValue]="value.name" [nzLabel]="value.it"></nz-option>
          </nz-select>

          <ng-template #stato>
            <nz-select class="pad" formControlName="status" name="" id=""
              [nzDisabled]="fond && !url.includes('comunicazioni') && !url.includes('rassegna-stampa') && !url.includes('video-istituzionali')">
              <nz-option *ngFor="let value of status" [nzValue]="value.name" [nzLabel]="value.it"></nz-option>
            </nz-select>
          </ng-template>
          <nz-select *ngIf="url.includes('restore')" class="pad" formControlName="status" name="" id="">
            <nz-option *ngFor="let value of status" [nzValue]="value.name" [nzLabel]="value.it"></nz-option>
          </nz-select>
        </div>
      </div>
      <div class="col-sm-3 col-md-3 m-2 d-flex"
        *ngIf="title == 'comunicazioni' && !client && !url.includes('restore') && title != 'faq' && title != 'bandi' && title != 'video-istituzionali' && title != 'tecnica-e-ricostruzione' && title != 'rassegna-stampa'">
        <input type="checkbox" for="formFile" class="form-label pos me-2" formControlName="sticky"
          (change)="stickyPost($event)">
        <span>Metti in evidenza</span>
      </div>
    </div>



    <nz-form-item
      *ngIf="title != 'faq' && title != 'tavoli-tematici' && title != 'attivita-aggregative' && title != 'convenzioni' && title != 'video-istituzionali' && title != 'tecnica-e-ricostruzione' && title != 'rassegna-stampa'">
      <nz-form-control>
        <nz-form-label>Allegati</nz-form-label>
        <section class="d-flex flex-row justify-content-start"
          *ngIf="!client && !showCourse || url.includes('edit-post') && url.includes('bandi'); else noFiles">
          <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzMultiple]="true"
            [nzBeforeUpload]="beforeUpload" (nzChange)="handleRemove($event)">
            <button nz-button>
              <span nz-icon nzType="upload"></span>
              Seleziona file
            </button>
          </nz-upload>
        </section>

        <ng-template #noFiles>
          <div>
            <section class="d-flex flex-row justify-content-start">
              <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload"
                [nzShowUploadList]="{showRemoveIcon: false}">
              </nz-upload>
            </section>
          </div>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="title=='comunicazioni' || title =='bandi'">
      <div class="row">
        <nz-form-control class="col-2">
          <nz-form-label for="basic-url">Data Scadenza</nz-form-label>
          <!-- * Da Admin vedo solo gli input per l'add o l'upload -->
          <nz-date-picker nzAllowClear="false" nzShowTime formControlName="data_scadenza"
            nzFormat="dd-MM-yyyy HH:mm"></nz-date-picker>
          <div
            *ngIf="formPost.controls['data_scadenza'].invalid && (formPost.controls['data_scadenza'].dirty || formPost.controls['data_scadenza'].touched)"
            class="m-0 p-0">
            <em class="input-form-error">*Data Scadenza non valida</em>
          </div>
        </nz-form-control>
      </div>

    </nz-form-item>


    <nz-form-item *ngIf="title == 'attivita-aggregative'">
      <nz-form-label>Immagine</nz-form-label>
      <nz-form-control>
        <section class="d-flex flex-row justify-content-start" *ngIf="!client && !showCourse; else noFiles">
          <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzMultiple]="false"
            [nzBeforeUpload]="beforeUpload" (nzChange)="handleRemove($event)" nzAccept="image/png, image/jpeg"
            [nzLimit]="1">
            <button nz-button [disabled]="fileList.length == 1">
              <span nz-icon nzType="upload"></span>
              Seleziona file
            </button>
          </nz-upload>
        </section>

        <ng-template #noFiles>
          <!-- ! Cosa vede l'ingegnere o ospite -->
          <!-- <i>Nessun allegato</i> -->
          <section class="d-flex flex-row justify-content-start">
            <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload"
              [nzShowUploadList]="{showRemoveIcon: false}">
            </nz-upload>
          </section>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="title == 'video-istituzionali'">
      <nz-form-label>Video</nz-form-label>
      <nz-form-control>
        <section class="d-flex flex-row justify-content-start" *ngIf="!client && !showCourse; else noFiles">
          <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzMultiple]="false"
            [nzBeforeUpload]="beforeUpload" (nzChange)="handleRemove($event)"
            nzAccept="video/mp4, video/webm, video/ogg" [nzLimit]="1">
            <button nz-button [disabled]="fileList.length == 1">
              <span nz-icon nzType="upload"></span>
              Seleziona file
            </button>
          </nz-upload>
        </section>

        <ng-template #noFiles>
          <section class="d-flex flex-row justify-content-start">
            <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload"
              [nzShowUploadList]="{showRemoveIcon: false}">
            </nz-upload>
          </section>
        </ng-template>
      </nz-form-control>
      <div *ngIf="formPost.get('video')?.hasError('required') && formPost.get('video')?.touched" class="text-danger">
        Il video da caricare è obbligatorio
      </div>
    </nz-form-item>

    <nz-form-item *ngIf="title == 'tecnica-e-ricostruzione' || title == 'rassegna-stampa'">
      <nz-form-label>File PDF</nz-form-label>
      <nz-form-control>
        <section class="d-flex flex-row justify-content-start" *ngIf="!client && !showCourse; else noFiles">
          <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzMultiple]="false"
            [nzBeforeUpload]="beforeUpload" (nzChange)="handleRemove($event)" nzAccept="application/pdf" [nzLimit]="1">
            <button nz-button [disabled]="fileList.length == 1">
              <span nz-icon nzType="upload"></span>
              Seleziona file
            </button>
          </nz-upload>
        </section>

        <ng-template #noFiles>
          <section class="d-flex flex-row justify-content-start">
            <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload"
              [nzShowUploadList]="{showRemoveIcon: false}">
            </nz-upload>
          </section>
        </ng-template>
      </nz-form-control>
      <div *ngIf="formPost.get('pdf')?.hasError('required') && formPost.get('pdf')?.touched" class="text-danger">
        Il PDF da caricare è obbligatorio
      </div>
    </nz-form-item>

    <!-- <div class="d-flex justify-content-between pt-5">
            <div class="position left">
                <label *ngIf="showCourse == true; else allegato" for="formFile"
                    class="form-label pe-4 d-none">ALLEGATI</label>
                <ng-template #allegato>
                    <div class="d-flex flex-row">
                        <label for="formFile" class="form-label pe-4 d-block">Allegati</label>

                        <button class="btn blu" mat-raised-button color="primary" (click)="addNewFile()">
                            AGGIUNGI FILE
                        </button>
                    </div>

                    <ng-container>
                        <ng-container formArrayName="attachmentsFile">
                            <ng-container *ngFor="let fileA of attachmentsFile.controls; let i = index">
                                <div [formGroupName]="i">
                                    <input type="file" style="display:none" id="file{{i}}" class="my-2"
                                        #fileImagesElement (change)="fileChangeEvent($event, i);" accept=".pdf" />
                                    <button type="button" class="btn blu my-2" (click)="clickBrowse(i)">
                                        Inserisci un file
                                    </button>
                                    <span class="mx-2">{{attachmentsFile.value[i].fileName != null ?
                                        attachmentsFile.value[i].fileName : "Seleziona un file" }}</span>
                                    <button type="button" class="btn mx-2" (click)="removeFile(i)">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-template>

            </div>
        </div> -->
    <!-- * Pubblica sul sito subito o programmato -->
    <div class="row"
      *ngIf="!showCourse && !client && title != 'faq' && title != 'tavoli-tematici' && title != 'attivita-aggregative' && title != 'bandi' && title != 'convenzioni'">
      <div class="col-4">
        <div class="d-flex justify-content-start align-items-center my-2">
          <label for="basic-url" class="form-label pe-4 d-block mb-0 pb-0">Pubblica sul sito</label>
          <div *ngFor="let publish of publish_on_site" class="d-flex">
            <input id="enum_answer_{{publish.value}}" [value]='publish.value' type="radio"
              [ngClass]="{'readonly': url.includes('edit-post')}" formControlName="publish_on_site">
            <span class="ms-1 me-2 text-black">{{publish.label}}</span>
          </div>
        </div>
      </div>
      <div class="col-sm-10 col-xl-6" *ngIf="!showCourse">
        <div *ngIf="!formPost.get('publish_on_site').value">
          <!-- * Da Admin vedo solo gli input per l'add o l'upload   -->
          <div *ngIf="!url.includes('restore')">
            <nz-date-picker nzAllowClear="false" nzShowTime [nzShowToday]="true" formControlName="publish"
              nzFormat="dd-MM-yyyy HH:mm" [nzDisabled]="url.includes('edit-post')"></nz-date-picker>
          </div>
        </div>
      </div>
    </div>

    <!-- * Pubblica su Fondazione -->
    <div class="mt-3 d-flex justify-content-start align-items-center my-2"
      *ngIf="!showCourse && title != 'faq' && title != 'tavoli-tematici' && title != 'attivita-aggregative' && title != 'bandi' && title != 'convenzioni' && !fond && !fromFondazione && title != 'tecnica-e-ricostruzione'&& title != 'rassegna-stampa'&& title != 'video-istituzionali'">
      <label class="form-label pe-4 d-block mb-0 pb-0">Pubblica sul portale della Fondazione</label>
      <div *ngFor="let choice of fondazione">
        <input id="enum_answer_{{choice.value}}" [value]='choice.value' type="radio" formControlName="choise">
        <span class="ms-1 me-2 text-black">{{choice.label}}</span>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-start align-items-center my-2"
      *ngIf="!showCourse && title != 'faq' && title != 'tavoli-tematici' && title != 'attivita-aggregative'&& title != 'bandi' && title != 'convenzioni' && title != 'video-istituzionali' && this.formPost.get('id').value==null">
      <label for="basic-url" class="form-label pe-4 d-block mb-0 pb-0">Invia comunicazione via e-mail</label>
      <div *ngFor="let choice of fondazione" class="d-flex">
        <input id="enum_answer_{{choice.value}}" [value]='choice.value' type="radio"
          [ngClass]="{'readonly': url.includes('edit-post')}" formControlName="sendViaMail">
        <span class="ms-1 me-2 text-black">{{choice.label}}</span>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-start align-items-center my-2"
      *ngIf="!showCourse && (title == 'corsi' || title == 'convegni')">
      <label for="basic-url" class="form-label pe-4 d-block mb-0 pb-0">Abilita richiesta certificato</label>
      <div *ngFor="let choice of request_certificate" class="d-flex">
        <input id="enum_answer_{{choice.value}}" [value]='choice.value' type="radio"
          formControlName="is_enable_certificate">
        <span class="ms-1 me-2 text-black">{{choice.label}}</span>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-start align-items-center my-2"
      *ngIf="!showCourse && (title == 'corsi' || title == 'convegni' || title == 'seminari')">
      <label for="basic-url" class="form-label pe-4 d-block mb-0 pb-0">Consenti le iscrizioni</label>
      <div *ngFor="let choice of registration_allowed" class="d-flex">
        <input id="enum_answer_{{choice.value}}" [value]='choice.value' type="radio" (change)="dinamicValidators()"
          [ngClass]="{'readonly': url.includes('edit-post')}" formControlName="registration_allowed">
        <span class="ms-1 me-2 text-black">{{choice.label}}</span>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-start align-items-center"
      *ngIf="!showCourse && categoryId==1 && title != 'faq' && title != 'tavoli-tematici' && title != 'attivita-aggregative' && title != 'bandi' && title != 'convenzioni' && !this.fond">
      <label class="form-label pe-4 d-block mb-0 pb-0">Invia a Telegram</label>
      <div *ngFor="let sendMsg of telegram">
        <input id="enum_answer_{{sendMsg.value}}" [value]='sendMsg.value' type="radio"
          [ngClass]="{'readonly': url.includes('edit-post')}" formControlName="sendViaTelegram">
        <span class="ms-1 me-2 text-black">{{sendMsg.label}}</span>
      </div>
    </div>


    <div class="mt-4 d-flex justify-content-start align-items-center"
      *ngIf="!showCourse && categoryId==1 && title != 'faq' && title != 'tavoli-tematici' && title != 'attivita-aggregative' && title != 'bandi' && title != 'convenzioni' && !this.fond">
      <label class="form-label pe-4 d-block mb-0 pb-0">Invia su Whatsapp</label>
      <div *ngFor="let sendMsg of telegram">
        <input id="enum_answer_{{sendMsg.value}}" [value]='sendMsg.value' type="radio"
          [ngClass]="{'readonly': url.includes('edit-post')}" formControlName="sendViaWhatsapp">
        <span class="ms-1 me-2 text-black">{{sendMsg.label}}</span>
      </div>
    </div>

    <hr>
    <div class="d-flex justify-content-between right">
      <div>
        <button *ngIf="showCourse; else annulla" type="button" class="btn grey space" (click)="goTo(true)">
          INDIETRO</button>
        <!--                <button-->
        <!--                    *ngIf="url.includes('edit-post')"-->
        <!--                    type=" button" class="btn blu" (click)=" updatePost()">MODIFICA</button>-->
        <button *ngIf="formPost.value.sub == 'subscribe'" type="button" class="btn blu" (click)="unsubscribe()">
          disiscriviti </button>
        <button *ngIf="url.includes('restore')" type="button" class="btn blu" (click)="updatePost()">RIPRISTINA</button>

        <!-- <button *ngIf="url.includes('restore')" type="button" class="btn rosso ms-3"
                    (click)="deletePermanently()">Elimina
                    Definitivamente</button> -->
        <ng-template #annulla>
          <button type="button" class="btn grey space" (click)="goTo(true)"> INDIETRO</button>
          <button type="button" class="btn blu "
            (click)="!url.includes('corsi') && !url.includes('convegni') && !url.includes('seminari') ? createPost() : confirmModal('create')"
            [disabled]="!formPost.valid" *ngIf="create; else modify">AGGIUNGI</button>
          <ng-template #modify>
            <button *ngIf="postEdit" type="button" class="btn blu" [disabled]="!formPost.valid"
              (click)="!url.includes('corsi') && !url.includes('convegni') && !url.includes('seminari') ? updatePost() : confirmModal('update')">MODIFICA</button>

          </ng-template>
        </ng-template>
      </div>
    </div>
  </form>
</div>