<div class="mx-5 my-4">
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="pb-0 mb-0">Manifestazione di interesse</h3>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
  </div>
  <p class="text-black">Manifestazione di interesse per richiesta nominativi di ingegneri</p>

  <form [formGroup]="form_manifestazioni" class="mt-3">


    <div class="my-3">

      <p class="text-black">dichiarare:</p>

      <!--? Ente  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">Ente</p>
        </div>
        <div class="col-sm-6 col-md-8">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" class="text" matInput formControlName="ente" required>
          </mat-form-field>
        </div>
      </div>

      <!--? Oggetto  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">Oggetto</p>
        </div>
        <div class="col-sm-6 col-md-8">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" class="text" matInput formControlName="oggetto" required>
          </mat-form-field>
        </div>
      </div>

    </div>
    
    <div class="d-flex justify-content-end">
      <button class="btn aggiorna mt-3 px-4" (click)="invia()">Invia</button>
    </div>

  </form>
</div>