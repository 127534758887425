<div class="m-auto mt-5 col-11">
    <h2 class="mb-4">Terna</h2>
    <div class="">
        <div *ngIf="admin" class="d-flex my-2">
            <p class="me-3">
                <strong [ngClass]="{'active': activeStatus === false}" class="status" (click)="getData(false);">Attive
                    ({{data_count}})</strong>
            </p>
            <p class="">
                <strong [ngClass]="{'active': activeStatus === true}" class="status" (click)="getData(true);">Scadute
                    ({{data_expired}})</strong>
            </p>
        </div>


        <div *ngIf="!client" class="col-5 row">
            <div class="col-12 col-md-4 mt-sm-1">
                <button type="button" class="btn blu btn-block-sm w-100" (click)="showModal(); insert = true;">
                    AGGIUNGI
                </button>
            </div>
            <div class="col-12 col-md-8 mt-sm-1">
                <button (click)="exportExcel()" type="button" class="btn blu btn-block-sm w-100"
                    [disabled]="data.length == 0">EXPORT LISTA TERNA</button>
            </div>
        </div>
    </div>

    <nz-datatable [columns]="columns" [data]="data"
        [data_count]="table_config.closed == false ? data_count : data_expired" [row_actions]="true"
        [clickable]="!client" [default_sort]="table_config.sorting" (page_changed)="onPageChange($event)"
        (sort_changed)="onSortChange($event)" (action_clicked)="onActionClick($event)"
        (row_clicked)="onRowClick($event)">
    </nz-datatable>

    <nz-modal [(nzVisible)]="isVisible" [nzTitle]="!client ? adminModalTitle : userModalTitle"
        [nzContent]="!client ? adminModalContent : userModalContent"
        [nzFooter]="!client ? adminModalFooter : userModalFooter" nzClosable="false" [nzStyle]="{ width: '50%' }">

        <!-- MODALI AMMINISTRATORE -->
        <ng-template #adminModalTitle>
            <div *ngIf="readOnly else modifyTerna">
                <h3>Visualizza la Terna</h3>
            </div>

            <ng-template #modifyTerna>
                <h3 *ngIf="insert; else update">Inserisci una nuova Terna</h3>
                <ng-template #update>
                    <h3>Modifica la Terna</h3>
                </ng-template>
            </ng-template>
        </ng-template>

        <ng-template #adminModalContent>
            <div *ngIf="readOnly else modifyTerna">
                <div>
                    <label class="mb-2 blue-title">Titolo</label>
                    <div class="mb-4">{{terna_form.get('title')!.value}}</div>
                </div>

                <div>
                    <label class="mb-2 blue-title">Descrizione</label>
                    <div class="mb-4">{{terna_form.get('description')!.value}}</div>
                </div>

                <div>
                    <label class="mb-2 blue-title">Data di chiusura</label>
                    <div class="mb-4">{{terna_form.get('end_date')!.value | date: 'dd-MM-YYYY'}}</div>
                </div>

                <div>
                    <div class="mb-4">
                        <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList"
                            [nzShowUploadList]="{showRemoveIcon: false}" [nzMultiple]="true"
                            [nzBeforeUpload]="beforeUpload" (nzChange)="handleRemove($event)">
                            <label class="mb-2 blue-title">Allegati</label>
                        </nz-upload>
                    </div>
                </div>
            </div>

            <ng-template #modifyTerna>
                <div [formGroup]="terna_form">
                    <label for="title" class="mb-2 blue-title">Titolo</label>
                    <input id="title" nz-input type="text" class="mb-4" formControlName="title" />

                    <label for="description" class="mb-2 blue-title">Descrizione</label>
                    <textarea id="description" nz-input rows="4" class="mb-4" formControlName="description"></textarea>

                    <label for="end_date" class="mb-2 blue-title">Data di chiusura</label>
                    <nz-date-picker id="end_date" [nzDisabledDate]="disabledDate" class="w-100 mb-4"
                        nzFormat="dd-MM-yyyy" formControlName="end_date" nzPlaceHolder=" "
                        nzAllowClear="false"></nz-date-picker>

                    <label for="attachment" class="mb-2 blue-title">Allegati</label>
                    <div>
                        <nz-upload id="attachment" class="col-12 col-xl-auto" [(nzFileList)]="fileList"
                            [nzMultiple]="true" [nzBeforeUpload]="beforeUpload" (nzChange)="handleRemove($event)">
                            <button nz-button>
                                <span nz-icon nzType="upload"></span>
                                <span>Carica allegati</span>
                            </button>
                        </nz-upload>
                    </div>
                </div>
            </ng-template>

        </ng-template>

        <ng-template #adminModalFooter>
            <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-secondary" (click)="onCloseModal()">Chiudi</button>
                <button *ngIf="!readOnly" type="button" class="btn btn-primary" (click)="onSaveModal()"
                    [disabled]="terna_form.invalid || fileList.length < 1">Salva</button>
            </div>
        </ng-template>


        <!-- MODALI INGEGNERE -->
        <ng-template #userModalTitle>
            <div *ngIf="readOnly else uploadFile">
                <h3>Visualizza gli allegati</h3>
            </div>

            <ng-template #uploadFile>
                <div *ngIf="!confirm else confirmTitle">
                    <h3>Partecipa alla Terna: {{terna_form.value.title}}</h3>
                </div>
            </ng-template>

            <ng-template #confirmTitle>
                <h3>Hai ottenuto l'incarico?</h3>
            </ng-template>
        </ng-template>

        <ng-template #userModalContent>
            <div *ngIf="readOnly; else modifyFile">
                <div class="mb-4">
                    <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="userFileList"
                        [nzShowUploadList]="{showRemoveIcon: false}" [nzMultiple]="true" [nzBeforeUpload]="beforeUpload"
                        (nzChange)="handleRemove($event)">
                        <label class="mb-2 blue-title">Allegati</label>
                    </nz-upload>
                </div>
            </div>

            <ng-template #modifyFile>
                <div *ngIf="!confirm; else confirmContent">
                    <div class="mb-4">
                        <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList"
                            [nzShowUploadList]="{showRemoveIcon: false}" [nzMultiple]="true"
                            [nzBeforeUpload]="beforeUpload" (nzChange)="handleRemove($event)">
                            <label class="mb-2 blue-title">Allegati</label>
                        </nz-upload>
                    </div>

                    <div class="mb-4">
                        <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="userFileList" [nzMultiple]="true"
                            [nzBeforeUpload]="userBeforeUpload" (nzChange)="userHandleRemove($event)">
                            <span class="mb-2">
                                Scaricare gli allegati e ricaricarli compilati per partecipare.

                                <div>
                                    <button nz-button class="mt-2">
                                        <span nz-icon nzType="upload"></span>
                                        <span>Carica gli allegati</span>
                                    </button>
                                </div>
                            </span>
                        </nz-upload>
                    </div>
                </div>
            </ng-template>

            <ng-template #confirmContent>
                <div class="mb-4">
                    <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="userFileList"
                        [nzShowUploadList]="{showRemoveIcon: false}" [nzMultiple]="true" [nzBeforeUpload]="beforeUpload"
                        (nzChange)="handleRemove($event)">
                        <label class="mb-2 blue-title">Allegati</label>
                    </nz-upload>
                </div>

                <div>
                    Vuoi confermare di aver ottenuto l'incarico?
                </div>
            </ng-template>
        </ng-template>

        <ng-template #userModalFooter>
            <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-secondary" (click)="onCloseModal()">Chiudi</button>
                <button *ngIf="!readOnly && !confirm" type="button" class="btn btn-primary" (click)="onSaveModal()"
                    [disabled]="userFileList.length < 1">Partecipa</button>
                <div *ngIf="userFileList.length >=1 && confirm">
                    <button type="button" class="btn btn-primary me-2" (click)="confirmTerna(false)"
                        [disabled]="userFileList.length < 1">Non
                        confermare</button>
                    <button type="button" class="btn btn-primary" (click)="confirmTerna(true)"
                        [disabled]="userFileList.length < 1">Conferma</button>
                </div>
            </div>
        </ng-template>

    </nz-modal>

</div>