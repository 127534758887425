<div class="m-auto col-11 mt-5">
    <h2 class="mb-4">
        <span (click)="goTo('app/admin/events')" class="clickable">Eventi</span> / Iscritti
    </h2>

    <h4>Titolo dell'evento: {{event.title}}</h4>

    <!--FILTER-->
    <div [formGroup]="filters">
        <div class="row mt-2 mb-3">
            <div class="col-12 col-md-4">
                <label class="mb-1" for="search">Ricerca</label>
                <nz-input-group>
                    <input nz-input placeholder="Cerca iscritti..." formControlName="search" />
                </nz-input-group>
            </div>
            <div class="mt-4 col-12 col-md-2">
                <button type="button" (click)="resetFilter()" class="btn blu btn-block-md w-100">Reset</button>
            </div>
        </div>
    </div>

    <!--ADD-->
    <div class="row">
        <div class="col-12 col-md-3">
            <button (click)="exportExcel()" type="button" class="btn blu btn-block-sm w-100 margin-sm-1"
                [disabled]="data.length == 0">EXPORT LISTA ISCRITTI</button>
        </div>
    </div>

    <!--DATA-TABLE-->
    <div class="mt-2">
        <nz-datatable [columns]="columns" [data]="data" [data_count]="data_count" [default_sort]="table_config.sorting"
            (page_changed)="onPageChange($event)" (sort_changed)="onSortChange($event)">
        </nz-datatable>
    </div>

</div>
