<!-- <div class="container-fluid fixed">
    <div class="row flex-nowrap">
        <div class="sidebar ">
            <div class="scroll d-flex flex-column align-items-center align-items-sm-start text-white min-vh-100">
                <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                </a>
                <ul class="col-12 nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                    id="menu">

                    <li *ngFor="let route of routes_fine" class="col-12">
                        <div class="d-flex flex-row justify-content-between"
                            [ngClass]="{'activeLink': activeRoute == route.url && route.name != 'Profilo'}">
                            <a [href]="route.url" class="nav-link p-3 align-middle">
                                <div [ngClass]="route.name == 'Profilo' ? 'w-100 d-block d-lg-none' : ''">
                                    <img [src]="route.icon" alt="" style="width:20px">
                                    <span class="ms-1 d-none d-sm-inline text-white">{{ route.name }}</span>
                                </div>
                            </a>
                            <a *ngIf="route.name != 'Profilo'" [href]="'#' + route.name" data-bs-toggle="collapse">
                                <i class="arrow fa-solid fa-caret-left" *ngIf="activeRoute == route.url"></i>
                            </a>
                        </div>

                        <ul class="col-12 collapse nav flex-column"
                            [ngClass]="{'show activeGroup': checkSidebarItemsByRole(route.name)}" [id]="route.name"
                            data-bs-parent="#menu">
                            <li class="w-100" *ngFor="let category of categories">
                                <span>
                                    <a [href]="url + '/' + category.slug + '/' + category.id"
                                        class="nav-link p-3 align-middle">
                                        <img class="d-block d-md-none d-lg-none" width="25px" [src]="category.icon"
                                            alt="">
                                        <span class="d-none d-sm-inline text-white"> {{ category.name }} </span>
                                    </a>
                                    <a *ngIf="category" [href]="'#' + category.name" data-bs-toggle="collapse">
                                        <i class="arrow fa-solid fa-caret-left"
                                            *ngIf="activeRoute == category.link">!</i>
                                    </a>
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li class="w-100 d-block d-lg-none">
                        <a class="nav-link white" href="#" (click)="resertToken();">
                            <img width="20px" src="../../../assets/icons/logout-50.png" alt="logout">
                            <span class="d-none d-sm-inline text-white ps-1">
                                Esci
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div> -->

<nz-sider *ngIf="fondazione" nzCollapsible nzWidth="18rem" [nzCollapsed]="isCollapsed" [nzCollapsedWidth]="60"
    (nzCollapsedChange)="nzCollapseOnChange($event)" [ngClass]="{'fondazione': fondazione}">
    <ul nz-menu [nzInlineCollapsed]="isCollapsed" nzMode="inline" nzTheme="dark" class="fondazione">
        <ng-container *ngFor="let route of routes_fine" class="fondazione">
            <a [href]="route.url" [ngClass]="{'d-block d-xl-none': route.name == 'Profilo'}">
                <li nz-menu-item [nzSelected]="isActiveRoute(route.url)" class="fondazione">
                    <i class="arrow fa-solid fa-caret-left">!</i>
                    <span class="text-white" nz-icon>
                        <img [src]="route.icon" alt="" style="width:20px">
                    </span>
                    <span class="fw-bold text-white d-xl-block" [ngClass]="{'d-none': isCollapsed}">
                        {{ route.name }}
                    </span>
                </li>
            </a>
        </ng-container>
        <a href="javascript:void(0)" (click)="logout();">
            <li nz-menu-item class="d-block d-xl-none">
                <span nz-icon>
                  <i style="font-size:20px; color: white" class="fa-solid fa-arrow-right-from-bracket"></i>
                </span>
                <span class="fw-bold text-white d-xl-block" [ngClass]="{'d-none': isCollapsed}">Esci</span>
            </li>
        </a>
    </ul>
</nz-sider>

<nz-sider *ngIf="!fondazione" nzCollapsible nzWidth="18rem" [nzCollapsed]="isCollapsed" [nzCollapsedWidth]="60"
          (nzCollapsedChange)="nzCollapseOnChange($event)">
  <ul nz-menu [nzInlineCollapsed]="isCollapsed" nzMode="inline" nzTheme="dark">
    <ng-container *ngFor="let route of routes_fine">
      <a [href]="route.url" [ngClass]="{'d-block d-xl-none': route.name == 'Profilo'}">
        <li nz-menu-item [nzSelected]="isActiveRoute(route.url)">
          <i class="arrow fa-solid fa-caret-left">!</i>
          <span class="text-white" nz-icon>
                        <img [src]="route.icon" alt="" style="width:20px">
                    </span>
          <span class="fw-bold text-white d-xl-block" [ngClass]="{'d-none': isCollapsed}">
                        {{ route.name }}
                    </span>
        </li>
      </a>
    </ng-container>
    <a href="javascript:void(0)" (click)="logout();">
      <li nz-menu-item class="d-block d-xl-none">
                <span nz-icon>
                  <i style="font-size:20px; color: white" class="fa-solid fa-arrow-right-from-bracket"></i>
                </span>
        <span class="fw-bold text-white d-xl-block" [ngClass]="{'d-none': isCollapsed}">Esci</span>
      </li>
    </a>
  </ul>
</nz-sider>
