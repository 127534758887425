import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Configuration } from './models/configuration.model';
import { Field } from './models/field.model';

@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent implements OnInit {
  @Input() config!: Configuration;

  form: FormGroup = new FormGroup({});
  today = moment().format('YYYY-MM-DD');

  selectedItem = new Array()
  selectedItemLabel = new Array()

  constructor() { }

  ngOnInit() {
    this.generateForm();
  }

  generateForm() {
    this.config.fields.forEach(field => {
      let validators = [];
      if (field.validators?.includes('required')) validators.push(Validators.required);
      if (field.validators?.includes('email')) validators.push(Validators.email);
      if (field.min == 'now') field.min = this.today;
      if (field.max == 'now') field.max = this.today;

      this.form.addControl(field.key, new FormControl(null, validators));

      if (field.disabled) this.form.controls[field.key].disable();
    });
  }


  add(event: any, control: string): void {
    if (!this.selectedItem.includes(event.value)) {
      this.selectedItem.push(event.value)
      this.selectedItemLabel.push(event.label)
    } else {
      this.selectedItem.splice(event, 1)
      this.selectedItemLabel.splice(event.label, 1)
    }
    this.form.get(control)?.patchValue(this.selectedItem)
  }

  remove(selectedOption: any, control: string) {
    const index = this.selectedItemLabel.indexOf(selectedOption);
    if (index !== -1) {
      this.selectedItemLabel.splice(index, 1);
      this.selectedItem.splice(index, 1)
    }
    this.form.get(control)?.patchValue(this.selectedItem)
  }

  displayFn(item: any): string {
    if (typeof item === 'string') {
      return item;
    } else if (item && item.name) {
      return item.name;
    }
    return '';
  }

  onkeyUpChangeAutoComplete(search: string, field: Field) {    
    field.filteredOptions = field.options?.filter(element => element.label?.toLowerCase().includes(search.toLowerCase()))
  }


  displayFnMulti(item: any): string {
    return item.label ? item.label : ''
  }

  get formValue() {
    return this.form;
  }

  set formValue(form: any) {
    this.form.patchValue(form);
  }
}
