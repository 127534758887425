import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';

const AUTH_API = `${environment.api}/auth`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser: ReplaySubject<any> = new ReplaySubject<any>();

  constructor(private http: HttpClient) {

  }

  register(data: any): Observable<any> {
    return this.http.post<any>(`${AUTH_API}/register`, data);
  }

  registerCompany(data: any): Observable<any> {
    return this.http.post<any>(`${AUTH_API}/agora/companies`, data);
  }

  validateAgoraCompany(params?: any): Observable<FormattedResponse<any[]>> {
    return this.http.put<FormattedResponse<any[]>>(`${environment.api}/agora/companies/validate`, params);
  }

  deleteAgoraCompany(params?: any): Observable<FormattedResponse<any[]>> {
    return this.http.delete<FormattedResponse<any[]>>(`${environment.api}/agora/companies/${params.company_id}`);
  }

  getRole(username: any): Observable<any> {
    return this.http.get<any>(`${AUTH_API}/getRole?username=${username}`);
  }

  resendEmailConfirmation(email: any): Observable<any> {
    return this.http.get<any>(`${AUTH_API}/generateCode?email=${email}`);
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${AUTH_API}/login`, { username, password });
  }


  // spid(params: any): Observable<any> {
  //   return this.http.post<any>(`https://dev-portaleing.darwintech.it/services/api/auth/spid`,params);
  // }

  spid(params: any): Observable<any> {
    return this.http.post<any>(`${AUTH_API}/spid`, params);
  }

  getProfile(params: any): Observable<any> {
    return this.http.post<any>(`${AUTH_API}/getProfile`, params);
  }





  me(): Observable<any> {
    return this.http.get<any>(`${AUTH_API}/me`);
  }
}
