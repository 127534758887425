import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  route = `${environment.api}`;

  constructor(private http: HttpClient) {

  }

  getAllEvent(params?: any) {
    return this.http.get<any>(`${environment.api}/events`, { params });
  }

  getCounters() {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/eventCounters`,);
  }

  getEventById(id: string) {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/events/${id}`);
  }

  postEvent(event: any) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/events`, event);
  }

  updateEvent(id: string, event: any) {
    return this.http.put<FormattedResponse<any>>(`${environment.api}/events/${id}`, event);
  }

  deleteEvent(id: number[], force: boolean) {
    const body = { id: id, force: force };
    return this.http.delete<FormattedResponse<any>>(`${environment.api}/events/delete`, { body: body });
  }


  exportExcelEvent(params: any): Observable<Blob> {
    return this.http.post(`${environment.api}/excel`, params, {
      responseType: 'blob'
    });
  }

  exportIcs() {
    return this.http.get(`${environment.api}/events/export/ics`, { responseType: 'text' });
  }

  exportIcsById(eventId: string | null) {
    const url = `${environment.api}/events/export/ics/${eventId}`;
    return this.http.get(url, { responseType: 'text' });
  }

  /**
 *
 * @param event_id
 * @returns sottoscrive l'utente ad un determinato evento
 */
  subscription(event_id: string): Observable<any> {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/event/subscribe`, { event_id });
  }

  /**
 *
 * @param event_id
 * @returns passo il parametro event_id per annullare l'iscrizione all'evento
 */
  unsubscribe(event_id: string): Observable<any> {
    return this.http.delete<FormattedResponse<any>>(`${environment.api}/event/unsubscribe?event_id=${event_id}`);
  }

  getEventUsers(id: any, params?: any) {
    return this.http.post<FormattedResponse<any>>(`${environment.api}/event/getEventUsers/${id}`, params);
  }



}
