<div class="m-auto mt-5 col-11">
    <h2 class="mb-4">
        <span (click)="goTo('app/admin/terna')" class="clickable"><i class="fa-solid fa-arrow-left"></i> Terna</span> /
        {{terna?.title}}
    </h2>

    <div class="buttonspace">
        <button type="button" class="btn blu me-4" *ngIf="data.length > 0" (click)="exportExcel()"> EXPORT LISTA
            PARTECIPANTI</button>
        <button *ngIf="selected_rows.length > 0" type="button" class="btn blu"
            (click)="approveTernaUsers(true)">AMMETTI</button>
        <button *ngIf="selected_rows.length > 0" type="button" class="btn rosso ms-2"
            (click)="approveTernaUsers(false)">RIGETTA</button>
    </div>

    <nz-datatable [columns]="columns" [data]="data" [data_count]="data_count" [actions]="['download']"
        [default_sort]="table_config.sorting" [label_azioni]="label_azioni" (page_changed)="onPageChange($event)"
        (sort_changed)="onSortChange($event)" (action_clicked)="onActionClick($event)"
        (selected_row)="onRowCheck($event)">
    </nz-datatable>
</div>

<nz-modal [(nzVisible)]="isVisible" [nzTitle]="title" [nzContent]="content" [nzFooter]="footer" nzClosable="false">

    <ng-template #title>
        <h3>Visualizza gli allegati</h3>
    </ng-template>

    <ng-template #content>
        <div class="mb-4">
            <nz-upload class="col-12 col-xl-auto" [(nzFileList)]="fileList" [nzShowUploadList]="{showRemoveIcon: false}"
                [nzMultiple]="true">
                <label class="mb-2 blue-title">Allegati</label>
            </nz-upload>
        </div>
    </ng-template>

    <ng-template #footer>
        <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-secondary" (click)="isVisible = false">Chiudi</button>
        </div>
    </ng-template>

</nz-modal>