import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UserService } from 'src/app/modules/application/services/user.service';

@Component({
    selector: 'app-access-documents-history',
    templateUrl: './access-documents-history.component.html',
    styleUrls: ['./access-documents-history.component.scss']
})
export class AccessDocumentsHistoryComponent implements OnInit {
    @Input() refreshData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    filtroRicerca: FormControl = new FormControl();
    columns = [
        { key: 'name', name: 'Nome', sortable: true },
        { key: 'lastname', name: 'Cognome', sortable: true },
        { key: 'email_content', name: 'Testo', sortable: false },
        { key: 'created_at', name: 'Data di creazione', type: 'datetime', sortable: true }
    ];

    data = [];
    data_count = 0;

    table_config = {
        filter: '',
        pagination: { index: 0, size: 10 },
        sorting: { key: 'created_at', direction: 'desc' }
    }

    client = true;

    constructor(public _user: UserService) { }

    ngOnInit(): void {
        this.refreshData.subscribe((value: boolean) => {
            if (value) {
                this.refreshData.next(false);
                this.getData();
            }
        });

        this.filtroRicerca.valueChanges.pipe(debounceTime(1000)).subscribe((value: any) => {
            this.table_config.filter = value;
            this.table_config.pagination.index = 0
            this.getData();
        });
    }

    getData() {
        this._user.getAccessDocumentsHistory(this.table_config).subscribe({
            next: (response: any) => {
                this.data = response.data;
                this.data_count = response.total ?? 0;
            }
        })
    }

    /**
     * Al cambio di paginazione
     * @param data
     */
    onPageChange(data: any) {
        this.table_config.pagination = data;
        this.getData();
    }

    /**
     * Al cambio di sorting
     * @param data
     */
    onSortChange(data: any) {
        this.table_config.sorting = data;
        this.table_config.pagination.index = 0;
        this.getData();
    }
}
