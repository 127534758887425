import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WordpressService } from '../../services/wordpress.service';
import { LoadingService } from "../../../../services/loading.service";
import { delay } from "rxjs/operators";

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {
  allCategories = new Array();
  comunication: any;
  loading: boolean = false;

  constructor(
    private router: Router,
    public _posts: WordpressService,
    private _loader: LoadingService
  ) { }

  ngOnInit() {
    this._loader.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => this.loading = loading);

    let url = this.router.url;

    // this._posts.getAllCategory(url).subscribe(res => {
    //   this._posts.setCategories(res.data);
    // })
  }

  changeSize(event: any) {
    if (event) document.getElementById('inner')!.style.marginLeft = '4rem';
    else document.getElementById('inner')!.style.marginLeft = '18rem';
  }
}
