import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UserService } from '../../services/user.service';
import { WordpressService } from '../../services/wordpress.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-commissioni-list',
  templateUrl: './commissioni-list-component.component.html',
  styleUrls: ['./commissioni-list-component.component.html']
})
export class CommissioniListComponentComponent implements OnInit {

  displayedColumns: string[] = [];
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  currentPage = 0;
  pageSize = 5;
  filtroVariabile: any;
  filter: any;
  pageEvent: PageEvent | undefined;
  tables: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  scadenze: any;
  ruolo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  user_id: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  searchDelay: number = 1000;
  filtroRicerca: FormControl = new FormControl();
  selectedIndex: number[] = [];


  constructor(
    private userService: UserService,
    private router: Router,
    private _wp: WordpressService
  ) { }

  ngOnInit(): void {
    this.getUserId();
    this.ruolo.next(localStorage.getItem('role'));
    this.displayedColumns = ['title', 'description', 'data', 'action'];
    this.getData();

    this.filtroRicerca.valueChanges.pipe(debounceTime(this.searchDelay)).subscribe((value: any) => {
      this.filter = value;
      this.getData();
    });
  }

  getUserId() {
    this.userService.me().subscribe(element => {
      this.user_id.next(element.data.id);
    })
  }

  getData() {
    let params = this.filter ? { start: this.currentPage * this.pageSize, length: this.pageSize, like_search: this.filter } : { start: this.currentPage * this.pageSize, length: this.pageSize };
    this.userService.getCommissioni(params).subscribe(response => {
      this.tables.next(response.data);
      this.records.next(response.total);
    });
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getData();
    return e;
  }

  goTo(id: any) {
    this.router.navigate(['/app/fondazione/commissioni/' + id]);
  }

  changeSelection(event: any, id: number) {
    if (event.target.checked) {
      this.selectedIndex.push(id);
    } else {
      const index = this.selectedIndex.indexOf(id);
      if (index !== -1) {
        this.selectedIndex.splice(index, 1);
      }
    }
  }


  removeElement() {
    this.userService.deleteCommisioni({ array_id: this.selectedIndex }).subscribe(response => {
      if (response.code == 200) {
        Swal.fire({
          icon: 'success',
          title: '<div style="font-size:18px;">Eliminato con successo</div>',
          showConfirmButton: false,
          timer: 3000,
        });
        this.ngOnInit();
      }
    })
  }
}
