import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Company } from 'src/app/shared/models/company.model';
import { FormattedResponse } from 'src/app/shared/models/formatted-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(private http: HttpClient) { }
  company: BehaviorSubject<Company | null> = new BehaviorSubject<Company | null>(null);

  getCompanies(params?: any): Observable<FormattedResponse<Company[]>> {
    return this.http.get<FormattedResponse<Company[]>>(`${environment.api}/companies`, { params });
  }

  getAgoraCompanies(params?: any): Observable<FormattedResponse<Company[]>> {
    return this.http.get<FormattedResponse<Company[]>>(`${environment.api}/agora/companies`, { params });
  }

  getCompanyById(id: string): Observable<FormattedResponse<Company>> {
    return this.http.get<FormattedResponse<Company>>(`${environment.api}/companies/${id}`);
  }

  createCompany(company: Company): Observable<FormattedResponse<Company>> {
    return this.http.post<FormattedResponse<Company>>(`${environment.api}/companies`, company);
  }

  updateCompany(companyid: string, company: Company): Observable<FormattedResponse<Company>> {
    return this.http.put<FormattedResponse<Company>>(`${environment.api}/companies/${companyid}`, company);
  }

  deleteCompany(companyid: Company['id']): Observable<FormattedResponse<null>> {
    return this.http.delete<FormattedResponse<null>>(`${environment.api}/companies/${companyid}`);
  }

  filterTechnology(technology: string): Observable<FormattedResponse<Company>> {
    return this.http.put<FormattedResponse<Company>>(`${environment.api}/companies/search/${technology}`, { technology });
  }

  searchCompany(data: any) {
    return this.http.get<FormattedResponse<Company[]>>(`${environment.api}/companies/list?query=${data}`);
  }

  searchRegions(data?: any) {
    return this.http.get<FormattedResponse<Company[]>>(`${environment.api}/regions?query=${data}`);
  }

  searchDataOffices(data?: any, id?: any) {
    return this.http.get<FormattedResponse<Company[]>>(`${environment.api}/company_office_locations`, { params: { query: data, id } });
  }

  getSector() {
    return this.http.get<FormattedResponse<any>>(`${environment.api}/sector`);
  }
}
