import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';

import { BaseComponent } from 'src/app/components/base/base.component';
import { OffersService } from 'src/app/shared/services/offers.service';
import { UtilsServices } from 'src/app/shared/services/utils.service';
import { EventService } from 'src/app/modules/application/services/event.service';

@Component({
    selector: 'app-offers-history',
    templateUrl: './offers-history.component.html',
    styleUrls: ['./offers-history.component.scss']
})
export class OffersHistoryComponent extends BaseComponent implements OnInit {

    client = localStorage.getItem('role');
    specialization_id = null;
    specializations: any[] = [];

    form = this.fb.group({
        search: null,
        specialization_id: null
    })

    public columns: { key: string, name: string, type: string }[] = [
        { key: 'offer.company.name', name: 'Azienda', type: 'name' },
        // { key: 'company.email', name: 'E-mail', type: 'email' },
        // { key: 'company.phone', name: 'Telefono', type: 'phone' },
        { key: 'offer.specialization_id', name: 'Specializzazione', type: 'specialization' },
        { key: 'offer.contract', name: 'Contratto', type: 'contract' },
        { key: 'created_at', name: 'Inviata il', type: 'date' },
        { key: 'action', name: 'Azioni', type: 'actions' }
    ];
    public listOfData: any[] = [];
    public pageIndex = 1;
    public pageSize = 10;
    public total = 0;
    public isTableLoaded: boolean = false;

    constructor(
        private _offers: OffersService,
        private _utils: UtilsServices,
        private _eventService: EventService,
        private fb: FormBuilder
    ) {
        super();
    }

    ngOnInit() {
        this.getData();

        this._utils.getSpecializations().subscribe(response => {
            this.specializations = response.data;
        });

        this._offers.refresh.subscribe(value => {
            if (value) this.getData();
        });
    }

    handlePageEvent(event: PageEvent) {
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.getData();
        return event;
    }

    getData() {
        let params: any = { skip: 0, take: this.pageSize };
        if (this.form.get('search')?.value) params.search = this.form.get('search')?.value;
        if (this.form.get('specialization_id')?.value && this.form.get('specialization_id')?.value != 'null') params.specialization_id = this.form.get('specialization_id')?.value;

        this._offers.getNominations(params).subscribe(response => {
            // this.cols = response.data;
            // this.length = response.total!;

            this.listOfData = response.data;

            this.total = response.total!;
            this.isTableLoaded = true;
        });
    }

    changePage(event: any) {
        this.pageIndex = event;
        this.getData();
    }

    exportExcel() {
        this._eventService.exportExcelEvent({ type: "offers" }).subscribe(
            (res: Blob) => {
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const filename = 'Offerte' + '.xlsx';
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
    }

    datatable(event: any) {
        // * controllo la lunghezza per gestire il tasto Elimina
        let check = event.length;
        // if (check > 0) this.checkedPost = true;
        // else this.checkedPost = false;
        // this.rows = event;
    }

    getContractType(type: string) {
        let contract = this.contract_types.find(c_type => {
            return c_type.id == type;
        });

        return contract.name;
    }

    getSpecialization(id: any) {
        let specialization = this.specializations.find(specialization => {
            return specialization.id == id;
        });

        return specialization?.name ?? '---';
    }
}
