<div class="m-auto mt-5 col-11">
  <div class="d-flex row">
    <div class="title  col-sm-8 col-md-8">
      <h2><span (click)="goBack()" class="clickable"><i class="fa-solid fa-arrow-left"></i></span><a
          href="/app/admin/dashboard"> Bacheca</a> / <span class="text">Iscritti</span></h2>
    </div>
    <div class="search col-sm-4 col-md-4">
      <mat-form-field appearance="fill">
        <mat-label>Ricerca Iscritti</mat-label>
        <input matInput [formControl]="searchField">
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>


  <div class="mb-4">
    <h4 *ngIf="url.includes('corsi')">Corso: {{titleCourse}}</h4>
    <h4 *ngIf="url.includes('tavoli-tematici')">Tavolo tematico: {{titleCourse}}</h4>

    <div class="buttonspace">
      <!-- <button type="button" class="btn blu me-4 spaceResponsive" (click)="acceptedUser()">Ammetti</button> -->
      <button *ngIf="!free && rows.length > 0" type="button" class="btn blu me-4 "
        (click)="admitUser()">Ammetti</button>
      <button *ngIf="rows.length > 0" type="button" class="btn rosso me-4 " (click)="deniedUser()">Rigetta</button>
      <button type="button" class="btn blu me-4" (click)="exportExcel()"> EXPORT LISTA ISCRITTI</button>
    </div>
  </div>


  <!-- DataTable -->
  <div>
    <app-dataTable [cols]="cols" [free]="free" [colsHead]="colsHead" (corsi)="datatable($event)"
      (reloadData)="reloadData()">
    </app-dataTable>
    <mat-paginator (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
      *ngIf="length != 0; else noData" [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex" aria-label="Select page">
    </mat-paginator>
    <ng-template #noData>
    </ng-template>
  </div>
</div>