type FieldList = {
  [key: string]: FieldListItem[]
}

type FieldListItem = {
  label: string;
  control: string;
  type: string;
  values?: any;
}


const jobs = [
  {
    label: 'Libero Professionista',
    value: 'Libero Professionista'
  },
  {
    label: 'Docente',
    value: 'Docente'
  },
  {
    label: 'Docente Universitario',
    value: 'Docente Universitario'
  },
  {
    label: 'Dipendente Pubblico',
    value: 'Dipendente Pubblico'
  },
  {
    label: 'Dipendente Privato',
    value: 'Dipendente Privato'
  },
  {
    label: 'Pensionato',
    value: 'Pensionato'
  },
];

const genders = [
  {
    value: 'M',
    label: 'M'
  },
  {
    value: 'F',
    label: 'F'
  }
];

const general_options = [
  {
    label: "Sì",
    value: true
  },
  {
    label: "No",
    value: false
  }
]

const clause_options = [
  {
    label: "AND",
    value: 'where'
  },
  {
    label: "OR",
    value: 'orWhere'
  }
]

const sector_options = [
  {
    value: 'A',
    label: 'A',
    sub_sectors: [
      {
        label: 'Aa civile ed ambientale',
        control: 'Aa'
      },
      {
        label: 'Ab industriale',
        control: 'Ab'
      },
      {
        label: 'Ac dell\'informazione',
        control: 'Ac'
      }
    ]
  },
  {
    value: 'B',
    label: 'B',
    sub_sectors: [
      {
        label: 'Ba civile ed ambientale',
        control: 'Ba'
      },
      {
        label: 'Bb industriale',
        control: 'Bb'
      },
      {
        label: 'Bc dell\'informazione',
        control: 'Bc'
      }
    ]
  }
];

const queryBuilderFieldsList: FieldList = {
  "Dati anagrafici": [
    {
      label: 'Cognome',
      control: 'lastname',
      type: 'text'
    },
    {
      label: 'Nome',
      control: 'name',
      type: 'text'
    },
    {
      label: 'Codice fiscale',
      control: 'fiscalCode',
      type: 'text'
    },
    {
      label: 'Sesso',
      control: 'gender',
      type: 'radio',
      values: genders
    },
    {
      label: 'Luogo di nascita',
      control: 'birthPlace',
      type: 'text'
    },
    {
      label: 'Provincia di nascita',
      control: 'birthProvince',
      type: 'text'
    },
    {
      label: 'Data di nascita',
      control: 'birthDate',
      type: 'date'
    },
    // {
    //   label: 'Categoria',
    //   control: 'categoria',
    //   type: 'select',
    //   values: jobs
    // },
    // {
    //   label: 'Attività',
    //   control: 'attività',
    //   type: 'text'
    // }
  ],
  "Sezioni albo": [
    {
      label: 'Delibera sezioni',
      control: 'deliberation',
      type: 'date'
    },
    {
      label: 'Posizione',
      control: 'registrationNumber',
      type: 'number'
    },
    {
      label: 'Nuovo ordinamento',
      control: 'nuovo_ordinamento',
      type: 'radio',
      values: general_options
    },
    {
      label: 'Sezione',
      control: 'sector',
      type: 'radio',
      values: sector_options
    },
  ],
  "Laurea": [
    {
      label: 'Luogo',
      control: 'degree_place',
      type: 'text'
    },
    {
      label: 'Data',
      control: 'degreed_at',
      type: 'date'
    },
    {
      label: 'Specializzazione',
      control: 'specialization',
      type: 'multi-select',
      values: []
    }
  ],
  "Esame di Stato e Ordine": [
    {
      label: 'Luogo',
      control: 'exam_place_state',
      type: 'text'
    },
    {
      label: 'Sessione',
      control: 'session_examination_state',
      type: 'text'
    },
    {
      label: 'Anno',
      control: 'exam_year',
      type: 'number'
    },
    // {
    //   label: 'Per legge',
    //   control: 'exam_law',
    //   type: 'radio',
    //   values: general_options
    // },
    // {
    //   label: 'Iscrizione precedente',
    //   control: 'previous_subscription',
    //   type: 'date'
    // },
    {
      label: 'Iscrizione',
      control: 'subscription',
      type: 'date'
    },
    {
      label: 'Numero verbale',
      control: 'verbal_number',
      type: 'number'
    },
    {
      label: 'Provenienza',
      control: 'vecchio_ordine',
      type: 'text'
    },
    // {
    //   label: 'Anzianità',
    //   control: 'seniority',
    //   type: 'date'
    // },
    // {
    //   label: 'Cancellazione',
    //   control: 'cancellation',
    //   type: 'date'
    // },
  ],
  "Residenza": [
    // {
    //   label: 'Città',
    //   control: 'city_residence',
    //   type: 'text'
    // },
    // {
    //   label: 'CAP',
    //   control: 'cap_residence',
    //   type: 'number'
    // },
    // {
    //   label: 'Via',
    //   control: 'street_residence',
    //   type: 'text'
    // },
    // {
    //   label: 'Telefono',
    //   control: 'phone_residence',
    //   type: 'text'
    // }
  ],
  "Posta": [
    // {
    //   label: 'Città',
    //   control: 'city_posta',
    //   type: 'text'
    // },
    // {
    //   label: 'Provincia',
    //   control: 'province_posta',
    //   type: 'text'
    // },
    // {
    //   label: 'CAP',
    //   control: 'cap_posta',
    //   type: 'number'
    // },
    // {
    //   label: 'Via',
    //   control: 'street_posta',
    //   type: 'text'
    // },
    // {
    //   label: 'E-mail',
    //   control: 'email',
    //   type: 'text'
    // }
  ],
  "Posta bonus": [
    // {
    //   label: 'Città',
    //   control: 'city_posta_bonus',
    //   type: 'text'
    // },
    // {
    //   label: 'Provincia',
    //   control: 'prov_posta_bonus',
    //   type: 'text'
    // },
    // {
    //   label: 'CAP',
    //   control: 'cap_posta_bonus',
    //   type: 'number'
    // },
    // {
    //   label: 'Via',
    //   control: 'street_posta_bonus',
    //   type: 'text'
    // },
    // {
    //   label: 'E-mail',
    //   control: 'mail_posta_bonus',
    //   type: 'text'
    // }
  ],
  "Albo": [
    // {
    //   label: 'Città',
    //   control: 'city_studio',
    //   type: 'text'
    // },
    // {
    //   label: 'CAP',
    //   control: 'cap_studio',
    //   type: 'number'
    // },
    // {
    //   label: 'Via',
    //   control: 'street_studio',
    //   type: 'text'
    // },
    // {
    //   label: 'Telefono',
    //   control: 'tel_studio',
    //   type: 'text'
    // },
    // {
    //   label: 'E-mail',
    //   control: 'pec_mail',
    //   type: 'text'
    // },
    // {
    //   label: 'PEC',
    //   control: 'pec_studio',
    //   type: 'text'
    // },
  ],
  "Domicilio": [
    {
      label: 'Città',
      control: 'city_domicilio',
      type: 'text'
    },
    {
      label: 'CAP',
      control: 'cap_domicilio',
      type: 'number'
    },
    {
      label: 'Via',
      control: 'street_domicilio',
      type: 'text'
    },
    {
      label: 'Telefono',
      control: 'tel_domicilio',
      type: 'text'
    }
  ],
  "Informazioni varie": [
    // {
    //   label: 'Sito internet',
    //   control: 'web_site',
    //   type: 'text'
    // },
    // {
    //   label: 'Note',
    //   control: 'note',
    //   type: 'text'
    // },
    // {
    //   label: 'Sospeso',
    //   control: 'sospeso',
    //   type: 'radio',
    //   values: general_options
    // },
    // {
    //   label: 'Sospensioni',
    //   control: 'sospensioni',
    //   type: 'text'
    // }
  ],
  "Dichiarazioni Requisiti Professionali": [
    {
      label: 'Cert. 7/12/48 N. 818',
      control: 'dichiarazione',
      type: 'radio',
      values: general_options
    },
    {
      label: 'Codice',
      control: 'codice_dichiarazione',
      type: 'text'
    },
    {
      label: 'Data delibera consiglio',
      control: 'data_delibera_494',
      type: 'date'
    },
    {
      label: 'Elenco 494',
      control: 'elenco_494',
      type: 'radio',
      values: general_options
    },
  ],
  "PEC": [
    // {
    //   label: 'PEC',
    //   control: 'pec',
    //   type: 'text'
    // },
    // {
    //   label: 'Password',
    //   control: 'pec_password',
    //   type: 'text'
    // },
    // {
    //   label: 'ingpec.eu',
    //   control: 'ingpec',
    //   type: 'text'
    // },
    // {
    //   label: 'PEC ritirata',
    //   control: 'pec_ritirata',
    //   type: 'radio',
    //   values: general_options
    // }
  ],
}

const symbols = [
  {
    value: "=",
    type: ['text', 'date', 'radio', 'select', 'multi-select']
  },
  {
    value: "LIKE",
    type: ['text', 'multi-select']
  },
  {
    value: "<",
    type: ['date', 'number']
  },
  {
    value: ">",
    type: ['date', 'number']
  },
  {
    value: "<=",
    type: ['date', 'number']
  },
  {
    value: ">=",
    type: ['date', 'number']
  }
]

export function getJobs() {
  return jobs;
}

export function getGenders() {
  return genders;
}

export function getFields() {
  return queryBuilderFieldsList;
}

export function getFieldsStaticData() {
  let fields = JSON.parse(JSON.stringify(queryBuilderFieldsList));
  let deleteFields = [
    {
      from: 'Dati anagrafici',
      label: 'Cognome'
    },
    {
      from: 'Dati anagrafici',
      label: 'Nome'
    },
    {
      from: 'Dati anagrafici',
      label: 'Codice fiscale'
    },
    {
      from: 'Sezioni albo',
      label: 'Posizione'
    },
    {
      from: 'Esame di Stato e Ordine',
      label: 'Numero verbale'
    },
    {
      from: 'Residenza',
      label: 'Telefono'
    },
    {
      from: 'Posta',
      label: 'E-mail'
    },
    {
      from: 'Posta bonus',
      label: 'E-mail'
    },
    {
      from: 'Albo',
      label: 'Telefono'
    },
    {
      from: 'Albo',
      label: 'E-mail'
    },
    {
      from: 'Albo',
      label: 'PEC'
    },
    {
      from: 'Domicilio',
      label: 'Telefono'
    },
    {
      from: 'Informazioni varie',
      label: 'Sito internet'
    },
    {
      from: 'Informazioni varie',
      label: 'Note'
    },
    {
      from: 'Dichiarazioni Requisiti Professionali',
      label: 'Codice'
    },
    {
      from: 'PEC',
      label: 'PEC'
    },
    {
      from: 'PEC',
      label: 'Password'
    },
    {
      from: 'PEC',
      label: 'ingpec.eu'
    }
  ]

  deleteFields.forEach((item: any) => {
    let index = fields[item.from].findIndex((i: any) => i.label == item.label);
    delete fields[item.from][index]
    fields[item.from] = fields[item.from].filter((n: any) => n)
  })

  return fields;
}

export function getSymbols() {
  return symbols;
}

export function getGeneralOptions() {
  return general_options;
}

export function getSectorOptions() {
  return sector_options;
}

export function getClauseOptions() {
  return clause_options;
}
