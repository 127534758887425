import { ApplicationComponent } from './components/application/application.component';
import { CommonModule, DatePipe } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { ApplicationRoutingModule } from './application-routing.module';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { BaseComponent } from './components/base/base.component';
import { IscrizioneComponent } from './user/components/iscrizioni/iscrizione.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ContainerComponent } from './admin/container/container.component';
import { SubContainerComponent } from './user/components/subContainer/subContainer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StampeComponent } from './user/components/stampe/stampe.component';
import { DialogComponent } from './user/components/dialog/dialog.component';
import { ElenchiDlgs81DialogComponent } from './user/components/elenchiDlgs81Dialog/elenchiDlgs81Dialog.component';
import { ElenchiPrevincendiDialogComponent } from './user/components/elenchiPrevincendiDialog/elenchiPrevincendiDialog.component';
import { ManifestazioneInteresseDialogComponent } from './user/components/manifestazioneInteresseDialog/manifestazioneInteresseDialog.component';
import { VariazioneAlboDialogComponent } from './user/components/variazioneAlboDialog/variazioneAlboDialog.component';
import { ScadenzarioComponent } from './user/components/scadenzario/scadenzario.component';
import { AccessDocumentsComponent } from './user/components/access-documents/access-documents.component';
import { ThematicTablesComponent } from './user/components/thematic-tables/thematic-tables.component';
import { CreateThematicTablesComponent } from './user/components/create-thematic-tables/create-thematic-tables.component';
import { AdmissionThematicComponent } from './user/components/admission-thematic/admission-thematic.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { EngineersListComponent } from './user/components/engineers-list/engineers-list.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSortModule } from "@angular/material/sort";
import { EventsListComponent } from './admin/events/events-list/events-list/events-list.component';
import { AddEventComponent } from './admin/events/add-event/add-event/add-event.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { SubscriptionListComponent } from './components/newsletter/components/subscription-list/subscription-list.component';
import { MailListComponent } from './components/newsletter/components/mail-list/mail-list.component';
import { NewMailComponent } from './components/newsletter/components/new-mail/new-mail.component';
import { MailDetailsComponent } from './components/newsletter/components/mail-details/mail-details.component';
import { ConsultingsListComponent } from './user/components/consultings-list/consultings-list.component';
import { ConsultingRequestsComponent } from './user/components/consultings-list/consulting-requests/consulting-requests.component';
import { AgoraComponent } from './components/agora/agora.component';
import { CompaniesListComponent } from './components/agora/components/companies-list/companies-list.component';
import { NewOfferComponent } from './components/agora/components/new-offer/new-offer.component';
import { OffersListComponent } from './components/agora/components/offers-list/offers-list.component';
import { OfferDetailsComponent } from './components/agora/components/offer-details/offer-details.component';
import { CertificationsListComponent } from './user/components/certifications-list/certifications-list.component';
import { NominationsListComponent } from './components/agora/components/nominations-list/nominations-list.component';
import { ExternalCourseComponent } from './user/components/external-course/external-course.component';
import { RegisteredUsersComponent } from './admin/events/registered-users/registered-users/registered-users.component';
import { RapidLinksComponent } from './user/components/rapid-links/rapid-links.component';
import { UsersManagementComponent } from './admin/users-management/users-management/users-management.component';
import { SpecialCoursesDetailComponent } from './user/components/special-courses-detail/special-courses-detail/special-courses-detail.component';
import { AccessDocumentsHistoryComponent } from './user/components/access-documents/access-documents-history/access-documents-history.component';
import { OffersHistoryComponent } from './components/agora/components/offers-history/offers-history.component';
import { EngineerDetailComponent } from './user/components/engineers-list/engineer-detail/engineer-detail.component';
import { MatRadioModule } from "@angular/material/radio";
import { NZ_DATE_LOCALE } from "ng-zorro-antd/i18n";
import { it } from "date-fns/locale";
import { CommissioniListComponentComponent } from './admin/commissioni-list-component/commissioni-list-component.component';
import { CommissioniDetailComponentComponent } from './admin/commissioni-detail-component/commissioni-detail-component.component';

@NgModule({
  declarations: [
    ApplicationComponent,
    DashboardComponent,
    BaseComponent,
    IscrizioneComponent,
    ContainerComponent,
    SubContainerComponent,
    StampeComponent,
    DialogComponent,
    ElenchiDlgs81DialogComponent,
    ElenchiPrevincendiDialogComponent,
    ManifestazioneInteresseDialogComponent,
    VariazioneAlboDialogComponent,
    ScadenzarioComponent,
    AccessDocumentsComponent,
    ThematicTablesComponent,
    CreateThematicTablesComponent,
    AdmissionThematicComponent,
    EngineersListComponent,
    EventsListComponent,
    AddEventComponent,
    NewsletterComponent,
    SubscriptionListComponent,
    MailListComponent,
    NewMailComponent,
    MailDetailsComponent,
    ConsultingsListComponent,
    ConsultingRequestsComponent,
    AgoraComponent,
    CompaniesListComponent,
    NewOfferComponent,
    OffersListComponent,
    OfferDetailsComponent,
    CertificationsListComponent,
    NominationsListComponent,
    ExternalCourseComponent,
    RegisteredUsersComponent,
    RapidLinksComponent,
    UsersManagementComponent,
    SpecialCoursesDetailComponent,
    AccessDocumentsHistoryComponent,
    OffersHistoryComponent,
    EngineerDetailComponent,
    CommissioniListComponentComponent,
    CommissioniDetailComponentComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ApplicationRoutingModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    MatTooltipModule,
    MatSortModule,
    MatRadioModule
  ],
  providers: [
    DatePipe,
    {
      provide: NZ_DATE_LOCALE,
      useValue: it
    }
  ]
})
export class ApplicationModule { }
