import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { EventService } from 'src/app/modules/application/services/event.service';
import { NewsletterService } from 'src/app/modules/application/services/newsletter.service';
//import { CompaniesService } from 'src/app/shared/services/companies.service';
import { CompaniesService } from 'src/app/modules/application/services/companies.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit {
  public columns: { key: string, name: string, type: string }[] = [
    { key: 'vat_number', name: 'Partita IVA', type: 'string' },
    { key: 'name', name: 'Ragione sociale', type: 'string' },
    { key: 'email', name: 'E-mail', type: 'string' },
    { key: 'phone', name: 'Telefono', type: 'string' },
    { key: 'chief', name: 'Rappr. legale', type: 'string' },
    { key: 'sector', name: 'Settore', type: 'string' },
    { key: 'action', name: 'Azioni', type: 'actions' }
  ];
  public listOfData: any[] = [];
  public pageIndex = 1;
  public pageSize = 10;
  public total = 0;
  public isTableLoaded: boolean = false;
  public search = null;

  constructor(
    private _companies: CompaniesService,
    private _auth: AuthService,
    private _eventService: EventService
  ) { }

  ngOnInit() {
    this.getData();
  }

  handlePageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getData();
    return event;
  }

  getData() {
    // this.displayTable = false;
    let params: any = { skip: this.pageIndex - 1, take: this.pageSize };

    if (this.search) {
      params.skip = 0
      params.search = this.search;
    }

    this._companies.getAgoraCompanies(params).subscribe(response => {
      // this.cols = response.data;
      // this.length = response.total!;

      this.listOfData = response.data;
      this.total = response.total!;
      this.isTableLoaded = true;
    });
  }

  changePage(event: any) {
    this.pageIndex = event;
    this.getData();
  }

  exportExcel() {
    this._eventService.exportExcelEvent({ type: "companies" }).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = 'Aziende' + '.xlsx';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  datatable(event: any) {
    // * controllo la lunghezza per gestire il tasto Elimina
    let check = event.length;
    // if (check > 0) this.checkedPost = true;
    // else this.checkedPost = false;
    // this.rows = event;
  }

  confirmCompany(company_id: string, validated: boolean) {
    this._auth.validateAgoraCompany({ company_id, validated }).subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: 'Azienda ' + (validated == true ? 'validata' : 'rigettata'),
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        this.getData();
      })
    });
  }

  deleteCompany(company_id: string) {
    this._auth.deleteAgoraCompany({ company_id }).subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: 'Azienda rigettata',
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        this.getData();
      })
    });
  }
}
