<div class="col-11 m-auto mt-5">
  <h2 class="mt-4">Richiesta di accesso agli atti</h2>
  <nz-tabset>
    <nz-tab nzTitle="Invia nuova richiesta">
      <form [formGroup]="formDocuments">
        <div class="row">
          <div class="col-6">
            <label for="basic-url" class="form-label">Nome</label>
            <input type="text" class="form-control" placeholder="Nome" aria-describedby="name" formControlName="name" />
          </div>
          <div class="col-6">
            <label for="basic-url" class="form-label">Cognome</label>
            <input type="text" class="form-control" placeholder="Cognome" aria-describedby="lastname"
              formControlName="lastname" />
          </div>
          <div class="mt-2">
            <label for="basic-url" class="form-label">Testo</label>
            <textarea class="form-control"
              placeholder="Con la presente si richiede l'accesso agli atti della procedura [XXXXX] per le seguenti motivazioni"
              rows="3" id="floatingTextarea" formControlName="email_content"></textarea>
          </div>

          <div class="d-flex justify-content-end mt-4">
            <button class="btn blu px-4" [disabled]="formDocuments.invalid" (click)="sendMail()">Invia</button>
          </div>

        </div>
      </form>
    </nz-tab>
    <nz-tab nzTitle="Storico richieste">
      <app-access-documents-history [refreshData]="refreshData"></app-access-documents-history>
    </nz-tab>
  </nz-tabset>
</div>