import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

const AUTH_API = `${environment.api}/auth`;
const post = `${environment.api}/posts`;
const API_URL = `${environment.api}`;

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private http: HttpClient) {

    }

    getUser() {
        return this.userSubject.asObservable();
    }

    setUser(nameUser: any) {
        this.userSubject.next(nameUser);
    }

    me(): Observable<any> {
        return this.http.get<any>(`${AUTH_API}/me`);
    }

    update(data: any): Observable<any> {
        return this.http.put<any>(`${AUTH_API}/update`, data);
    }

    uploadFile(user_id: any, path: any, fileName: any): Observable<any> {
        return this.http.put<any>(`${post}/terneSubscribe`, { user_id, path, fileName });
    }

    getTerna(page: any, page_length: any): Observable<any> {
        return this.http.get<any>(`${post}/getPdf?page=${page}&page_length=${page_length}`);
    }

    getTerneManagement(user_id: any, action: any): Observable<any> {
        return this.http.put<any>(`${post}/terneManagement`, { user_id, action });
    }

    sendRequestCertificate(post_id?: any): Observable<any> {
        return this.http.put<any>(`${post}/sendRequestCertificate`, { post_id });
    }

    sendSpecialCourseRequestCertificate(special_course_id?: any): Observable<any> {
        return this.http.put<any>(`${post}/sendSpecialCourseRequestCertificate`, { special_course_id });
    }

    changeStatusCertificate(params?: any): Observable<any> {
        return this.http.put<any>(`${post}/changeStatusCertificate`, params);
    }

    changeStatusCertificateThematic(params?: any): Observable<any> {
        return this.http.put<any>(`${post}/changeStatusCertificateThematic`, params);
    }

    accessDocument(params?: any): Observable<any> {
        return this.http.post<any>(`${post}/accessDocuments`, params);
    }

    getThematicTable(params?: any): Observable<any> {
        return this.http.get<any>(`${post}/getThematicTable`, { params });
    }

    getAdmissionThematicTable(params?: any): Observable<any> {
        return this.http.get<any>(`${post}/tableAdmission`, { params });
    }

    updateStatus(params?: any): Observable<any> {
        return this.http.put<any>(`${post}/updateStatus`, { params });
    }

    getTable(params?: any): Observable<any> {
        return this.http.get<any>(`${post}/getTable`, { params });
    }

    addThematicTable(params?: any): Observable<any> {
        return this.http.post<any>(`${post}/addThematicTable`, params);
    }

    editThematicTable(params?: any): Observable<any> {
        return this.http.put<any>(`${post}/editThematicTable`, params);
    }

    deleteThematicTable(params?: any): Observable<any> {
        return this.http.delete<any>(`${post}/deleteThematicTable`, { params });
    }

    getDocument(type: any, dati?: any, ammin?: any, qualify?: any, period?: any, costruzioni?: any, datoreLavoro?: any, periodoLavoro?: any, tipoLavoro?: any, iscrizioneElMin?: any, dataConseguimento?: any, ente?: any, oggetto?: any, comunicazioni?: any, mailSegreteria?: any, indirizzo_posta?: any, cap_posta?: any, residenza_posta?: any, provincia_posta?: any, landlinePhone?: any, fax?: any): Observable<any> {
        return this.http.get<any>(`${post}/print/pdf?type=${type}&convalida=${dati}&amminAppartenenza=${ammin}&qualifica=${qualify}&periodoDiSvolgimento=${period}&periodoSettoreCostruzioni=${costruzioni}&datoreLavoro=${datoreLavoro}&periodoLavoro=${periodoLavoro}&tipoLavoro=${tipoLavoro}&iscrizioneElMin=${iscrizioneElMin}&periodoSvolgimento=${dataConseguimento}&ente=${ente}&oggetto=${oggetto}&comunicazioni=${comunicazioni}&mailSegreteria=${mailSegreteria}&indirizzo_posta=${indirizzo_posta}&cap_posta=${cap_posta}&residenza_posta=${residenza_posta}&provincia_posta=${provincia_posta}&landlinePhone=${landlinePhone}&fax=${fax}`, {
            'headers': {
                'toast': 'hidden',
                'Content-Type': 'application/json', 'customHeader': 'blob'
            }, 'responseType': "blob" as "json"
        });
    }

    getRoles(): Observable<any> {
        return this.http.get<any>(`${AUTH_API}/roles`);
    }

    getUserSubscriptions(params?: any): Observable<any> {
        return this.http.get<any>(`${AUTH_API}/userSubscriptions?index=${params.pagination.index}&size=${params.pagination.size}&key=${params.sorting.key}&direction=${params.sorting.direction}`);
    }

    getUsersList(params?: any): Observable<any> {
        return this.http.get<any>(`${API_URL}/engineer/list`, { params });
    }

    getEngineersList(params?: any): Observable<any> {
        return this.http.post<any>(`${API_URL}/engineer/list`, params);
    }

    changeStatus(id: string): Observable<any> {
        return this.http.patch<any>(`${API_URL}/engineer/${id}/status`, { params: [] });
    }

    getAlboList(params?: any): Observable<any> {
        return this.http.get<any>(`${API_URL}/albo`, { params: { filters: JSON.stringify(params) } });
    }

    saveEngineerVideoCv(resource_id: string, params?: any): Observable<any> {
        return this.http.post<any>(`${API_URL}/engineer/${resource_id}/videoCv`, params);
    }

    //ELIMINO UN CORSO//
    deleteExrternalCourse(params?: any): Observable<any> {
        return this.http.delete<any>(`${API_URL}/externalcourse`, { params });
    }

    getHeaderInfo(): Observable<any> {
        return this.http.get<any>(`${API_URL}/headerInfo`);
    }

    getAccessDocumentsHistory(params?: any): Observable<any> {
        return this.http.get<any>(`${post}/accessDocuments?skip=${params.pagination.index}&take=${params.pagination.size}&order_by=${params.sorting.key}&order=${params.sorting.direction}&filter=${params.filter}`);
    }

    getUserBySpecializationCounters(): Observable<any> {
        return this.http.get<any>(`${API_URL}/getUserBySpecializationCounters`);
    }

    getUserDetail(id: string): Observable<any> {
        return this.http.get<any>(`${API_URL}/engineer/${id}`);
    }


    printPdf(params: any): Observable<any> {
        return this.http.post<any>(`${API_URL}/printengineerpdf`, params);
    }

    printDoc(params: any): Observable<any> {
        return this.http.post<any>(`${API_URL}/printenginnerdoc`, params);
    }



    saveEngineerData(id: string, data: any): Observable<any> {
        return this.http.post<any>(`${API_URL}/engineer_data`, { data, id });
    }

    printEngineerPDF(id: string) {
        return this.http.post<any>(`${API_URL}/printengineerpdf`, { id });
    }


    getCommissioni(params: any) {
        return this.http.get<any>(`${API_URL}/commissioni`, { params });
    }

    showCommissioni(params: any) {
        return this.http.get<any>(`${API_URL}/commissioni/show`, { params });
    }

    addCommissioni(params: any) {
        return this.http.post<any>(`${API_URL}/commissioni/add`, params);
    }

    putCommissioni(params: any) {
        return this.http.put<any>(`${API_URL}/commissioni/edit`, params);
    }

    deleteCommisioni(params: any) {
        return this.http.post<any>(`${API_URL}/commissioni/delete`, params);
    }
}
