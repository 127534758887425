<div class="col-11 m-auto mt-5">
    <h2 class="mt-4">Riepilogo</h2>

    <!--EXPORT EXCEL-->
    <div class="row mt-2">
        <div class="col-12 col-md-3">
            <button (click)="exportExcel()" type="button" class="btn blu btn-block-sm w-100"
                [disabled]="internal_data.length == 0 && external_data.length == 0 && special_course_data.length == 0">
                EXPORT LISTA SCADENZARIO
            </button>
        </div>
        <div *ngIf="selectedCourse.length > 0 && role_id == 1" class="col-12 col-md-2 margin-sm-1">
            <button type="button" class="btn rosso btn-block-sm w-100" data-bs-toggle="modal"
                data-bs-target="#cestino">ELIMINA</button>
        </div>
        <div class="col-12 col-md-3" *ngIf="role_id == 2">
            <button (click)="createCourse()" type="button" class="btn blu btn-block-sm w-100">
                INSERISCI NUOVA SCADENZA
            </button>
        </div>
        <div class="col-12 col-md-3" *ngIf="role_id == 1">
            <button (click)="goToManagement()" type="button" class="btn blu btn-block-sm w-100">
                GESTIONE CORSISTI
            </button>
        </div>
    </div>


    <div class="mt-4" *ngIf="role_id == 2">
        <h5>Corsi Interni</h5>
        <nz-datatable [columns]="internal_columns" [data]="internal_data" [data_count]="internal_count"
            [row_actions]="true" (action_clicked)="onActionClick($event)" (row_clicked)="onRowClick($event)"
            [clickable]="true" (sort_changed)="onSortChange($event, true)" (page_changed)="onPageChange($event, true)">
        </nz-datatable>
    </div>

    <div class="mt-4" *ngIf="role_id == 1">
        <h5>Corsi Interni</h5>
        <nz-datatable (selected_row)="onRowCheck($event)" [columns]="special_course_columns" [clickable]="true"
            [data]="special_course_data" [data_count]="special_course_count" [row_actions]="true" [actions]="['edit']"
            (row_clicked)="onRowClick($event)" (action_clicked)="onActionClick($event)"
            (sort_changed)="onSortChange($event, true)" (page_changed)="onPageChange($event, true)">
        </nz-datatable>
    </div>


    <div class="mt-4" *ngIf="role_id == 2">
        <h5>Corsi Esterni</h5>
        <nz-datatable [columns]="external_columns" [data]="external_data" [data_count]="external_count"
            [actions]="['edit']" (action_clicked)="onActionClick($event)" (sort_changed)="onSortChange($event)"
            (page_changed)="onPageChange($event)">
        </nz-datatable>
    </div>

    <!-- Modal -->
    <div *ngIf="selectedCourse?.length!=0">
        <div class="modal fade" id="cestino" tabindex="-1" aria-labelledby="cestinoLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content p-4">
                    <div class="modal-body">
                        <p>Sei sicuro di voler eliminare {{selectedCourse?.length}}
                            <span *ngIf="selectedCourse?.length == 1; else multiple">elemento</span>
                            <ng-template #multiple>elementi</ng-template>?
                        </p>
                    </div>
                    <div class="d-flex flex-row justify-content-between mb-4">
                        <button class="btn annulla pr-3 me-2" data-bs-dismiss="modal" aria-label="Close"
                            id="closebutton">
                            ANNULLA
                        </button>
                        <button type="button" demon-step-btn="hide" class="btn elimina ms-2" data-bs-dismiss="modal"
                            (click)="deleteCourse()">
                            ELIMINA
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>