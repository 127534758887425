import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { WordpressService } from 'src/app/modules/application/services/wordpress.service';
import { DatatableService } from 'src/app/modules/application/services/datatable.service';
import { UserService } from 'src/app/modules/application/services/user.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Sort } from '@angular/material/sort';
import { NzUploadFile } from 'ng-zorro-antd/upload';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

//const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-dataTable',
  templateUrl: './dataTable.component.html',
  styleUrls: ['./dataTable.component.scss'],
})
export class DataTableComponent implements OnInit {
  disableStatus = false;

  @Input() cols: any = [];
  @Input() colsHead: string[] = [];
  @Input() scaricaFile: any;
  @Input() free: boolean = true;
  dataSource = new MatTableDataSource<any>();
  @Output('datatable') datatable: EventEmitter<any> = new EventEmitter<any>();
  @Output('element') element: EventEmitter<any> = new EventEmitter<any>();

  @Output('checkedPost') checkedPost: EventEmitter<any> = new EventEmitter<any>();

  @Output('reloadData') reloadData: EventEmitter<any> = new EventEmitter<any>();
  @Output('sortChange') sortChange: EventEmitter<any> = new EventEmitter<any>();
  @Output('course') course: EventEmitter<any> = new EventEmitter<any>();
  @Output('corsi') corsi: EventEmitter<any> = new EventEmitter<any>();
  @Output('terna') terna: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator | any;

  uploading: boolean = false;
  fileList: NzUploadFile[] = [
  ];
  blob: any;
  file: any;
  fileName: any;
  fileUploaded = false;

  allegafile: FormGroup | any;
  title: any;
  rowArray: any[] = [];
  updatePost: FormGroup;
  user_id = new Array();
  id: any;
  ternaId: any;
  wp_id: any;
  pathCourse: any;
  user_wp_id: any;
  postStatus: any;
  status = [
    { name: 'publish', it: 'Pubblicato' },
    { name: 'draft', it: 'Bozza' },
  ];

  path: any;
  url: any;
  showCheckbox = true;
  showCourse = false;
  sort: Sort = { active: '', direction: '' };
  client: any;
  status_certificate = false;
  toDate: any;
  post_id: any;
  userId: any;
  post_ord_id: any;
  post: any;
  fileAttestato: any;
  role = localStorage.getItem('role');

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public datatableService: DatatableService,
    public user: UserService,
    private _wp: WordpressService,
    private datePipe: DatePipe,
  ) {
    this.getUserId();
    this.updatePost = this.fb.group({
      id: null,
      title: null,
      status: null,
      total_seats: null,
      cfp: null,
      duration: null,
      content: null,
      start_date: null,
      end_date: null,
      category_id: [null, Validators.required],
    });

    this.allegafile = this.fb.group({
      user_id: null,
      post_id: null,
      attachmentPaths: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.getUserId();
    // this.downloadFile()
    this.toDate = this.datePipe.transform(new Date(), 'dd-MM-YYYY HH:mm');
    this.getStatusPost();
    this.pathCourse = this.route.snapshot.params.slug;

    this.url = this.router.url;
    if (this.url.includes('/subscriptions')) this.client = true;
    if (this.url.includes('/booked')) this.showCheckbox = true;
  }

  //!! popola la datatable appena l'array cols si è valorizzato - NON TOGLIERE ALTRIMENTI NON FUNZIONA NIENTE
  ngOnChanges() {
    if (this.cols.length) {
      this.dataSource = new MatTableDataSource<any>(this.cols);
    }
  }


  reloadDataEvent() {
    this.reloadData.emit()
    this.rowArray = []
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getStatusPost() {
    this.cols?.forEach((el: any) => {
      if (el.status === 'trash') this.disableStatus = true;
    });
  }

  selection = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(item: any) {
    // const numSelected = this.selection.selected.length;
    // const numRows = this.dataSource.data.length;
    // // //console.log('is all', this.dataSource.data);
    // // return numSelected === numRows;
    //
    // this.dataSource.data.forEach(val => {
    //   if (val.id == item.id) val.isSelected = !val.isSelected;
    //   else {
    //     val.isSelected = false;
    //   }
    //
    //   console.log(val)
    // });
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    // if (this.isAllSelected()) {
    //   this.selection.clear();
    //   return;
    // }
    this.selection.select(...this.dataSource.data);

    // al check globale seleziono tutti gli altri id delle singole checkbox!
    this.dataSource.data.forEach((el) => {
      this.user_id.push(el.id);
      this.corsi.emit(this.user_id);
    });
  }

  /** The label for the checkbox on the passed row */
  // checkboxLabel(row?: PeriodicElement): string {
  //   if (!row) {
  //     return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
  //   }
  //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1
  //     }`;
  // }

  goTo(path: string): void {
    this.url = this.router.url;
    this.router.navigate([`${this.url}/${path}`]);
  }

  isUserAdmin() {
    let role = localStorage.getItem('role');
    if (role == '1') {
      return true;
    } else {
      return false;
    }
  }

  getSinglePost(element: any) {
    if (element.status == 'trash') this.goTo('/restore' + '/' + element?.id);
    else this.goTo('/edit-post' + '/' + element?.id);
  }

  takedatarow(element: any, event: any) {
    let index = this.rowArray.indexOf(element);

    if (event.checked) {
      this.checkedPost.emit(true);
      this.user_id.push(element.id);
      this.element.emit(element.id)
      this.terna.emit(element.id);
      if (index == -1) this.rowArray.push(element);
    }
    else {
      this.checkedPost.emit(false);
      this.user_id.pop();
      if (index != -1) this.rowArray.splice(index, 1);
    }
    this.corsi.emit(this.rowArray);
    this.datatable.emit(this.rowArray);
  }

  getUserId() {
    this.user.me().subscribe((element) => {
      this.user_wp_id = element.data.id;
      console.log(this.user_wp_id);
    });
  }

  getPostId(element: any) {
    this.wp_id = element.id;
    this.url = this.router.url;
    // if (this.url.includes('/corsi'))
    //   this.goTo(
    //     '/show' + '/' + element?.id + '/' + element?.wp_id + '/payment'
    //   );
  }

  subscribe(id: any) {
    Swal.fire({
      title: '',
      text: "Sei certo di voler procedere all'iscrizione?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Chiudi'
    }).then((result) => {
      if (result.isConfirmed) {
        this.datatableService.subscription(id).subscribe({
          next: response => {
            this.course.emit(true);

            Swal.fire({
              icon: 'success',
              title: '<div style="font-size:18px;">Iscrizione effettuata</div>',
              showConfirmButton: false,
              timer: 3000,
            });

            this.reloadData.emit();
          },
          error: response => {
            Swal.fire({
              icon: 'error',
              title: '<div style="font-size:18px;">Non è possibile effettuare l\'iscrizione: nessun posto disponibile.</div>',
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
      }
    });
  }

  changeStatusCertificate(user_id: any, post_id: any, event: any) {
    let number = 0;
    if (event.checked == true) {
      number = 2;
    } else {
      number = 1;
    }
    this.user
      .changeStatusCertificate({
        post_id: post_id,
        user_id: user_id,
        status_request: number,
      })
      .subscribe((res) => {
        this.reloadData.emit();
      });
  }

  checked(el: any): boolean {
    if (el == 'Richiesto') {
      return false;
    } else if (el == 'Consegnato') {
      return true;
    } else {
      return false;
    }
  }

  isInThePast(element: any): boolean {
    let inPast: boolean = false;
    let today: number = Date.now();

    if (element < this.toDate) {
      inPast = true;
    }

    return inPast;
  }

  postScaduti(element: any): boolean {
    this.cols.forEach((ele: any) => {
      if (ele.postStatus == 'scaduto') {
        this.disableStatus = true;
      }
    });
    return this.disableStatus;
  }

  getElement(element: any) {
    this.userId = element.id;
    this.post_id = element.post_id;
  }

  // *Controlla il path del componenete
  getPath(element: any) {
    this.url = this.router.url;
    if (this.url.includes('/subscriptions')) {
      this.showCourse = true;
      this.goTo('/show' + '/' + element?.id);
    } else if (this.url.includes('/newsletter')) {
      this.goTo(element?.id);
    } else {
      this.goTo('/booked' + '/' + element?.id);
    }
  }

  // * l'Admin disiscrive l'utente dopo essersi iscritto ad un corso 
  unsubscribe(id: any) {
    Swal.fire({
      title: '',
      text: "Sei certo di voler procedere alla disiscrizione?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Chiudi'
    }).then((result) => {
      if (result.isConfirmed) {
        this._wp.unsubscribe(id).subscribe((res) => {
          Swal.fire({
            icon: 'success',
            title: '<div style="font-size:18px;">Disiscrizione effettuata con successo</div>',
            showConfirmButton: false,
            timer: 3000,
          });
          this.reloadData.emit();
        });
      }
    });
  }

  checkDate(date: any) {
    return this.datePipe.transform(new Date(date), 'dd-MM-YYYY HH:mm')! < this.toDate;
  }

  // * Manda la richiesta dell'utente per ricevere l'attestato ad uno specifico corso
  sendRequestCertificate() {
    let request = true;
    let data = {
      user_id: this.user_wp_id,
      post_id: this.userId,
      request: request
    }
    this.datatableService.sendRequestCertificate(data).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">' + res.message + '</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this.reloadData.emit();
    })
  }

  // * L'Admin carica l'attestato dell'utente che ne ha fatto richiesta
  uploadFile() {
    this.allegafile.patchValue({
      user_id: this.userId,
      post_id: this.post_id,
    })
    this.datatableService.uploadFile(this.allegafile.value).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">Attestato inviato con successo</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this.reloadData.emit();
      this._initAllegaFile();
    })
  }

  // * L'utente carica la distinta di pagamento per un corso
  uploadFileForCoursePayment() {
    this.datatableService.subscription(this.post, this.allegafile.get('attachmentPaths').value).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">Richiesta di iscrizione inviata con successo</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this.reloadData.emit();
      this._initAllegaFile();
    })
  }


  // * prende gli elementi per il download dell'attestato
  downloadFile(element: any) {
    this.post_ord_id = element.id;
    // //console.log(element);
    // window.open(environment.storageBE + element.path);
  }


  // * l'utente al click scarica l'attestato richiesto
  print() {
    let data = {
      user_id: this.user_wp_id,
      post_id: this.post_ord_id
    }
    this.datatableService.download_attachment(data).subscribe(res => {
      window.open(res.data.certificate_path);
    })
  }



  // * Allegato ng-zorro
  _initAllegaFile() {
    this.fileList = [];
  }

  // * Allegato ng-zorro
  beforeUpload = (file: NzUploadFile): boolean => {
    this.uploading = false;
    this.fileList = this.fileList.concat(file);
    this.handleUpload();
    return false;
  };

  // * Allegato ng-zorro
  handleUpload(): void {
    this.uploading = true;
    let data: any = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.fileList.forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let new_file = { name: file.name, base64: reader.result };
        data.push(new_file);
      };
    });

    this.allegafile.patchValue({ attachmentPaths: data });
    this.uploading = false;
  }

  onSortChange($event: Sort) {
    this.sort = $event;
    this.sortChange.emit($event);
  }

  openFiles(row: any) {
    row.attachments.forEach((attachment: any) => {
      window.open(`${attachment}`, '_blank');
    });
  }
}
