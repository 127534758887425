<div class="row justify-content-between search col-12">
  <div class="col-12 mt-3 col-xl-3">
    <button [disabled]="listOfData.length == 0" (click)="exportExcel()" type="button"
      class="btn blu btn-block-sm w-100">EXPORT LISTA OFFERTE</button>
  </div>

  <form [formGroup]="form" class="col-xl-3">
    <mat-label class="fw-bold">Specializzazione</mat-label>
    <select class="form-select col-12" formControlName="specialization_id" (ngModelChange)="onSpecializationChange()">
      <option [value]="null">Scegli...</option>

      <option *ngFor="let specialization of specializations" [value]="specialization.id">
        {{ specialization.name }}
      </option>
    </select>
    <!-- <mat-form-field appearance="fill">
        <mat-label>Ricerca</mat-label>
        <input matInput formControlName="search" (ngModelChange)="getData()">
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field> -->
  </form>
</div>

<!--EXPORT EXCEL-->
<div class="row mt-2 mb-1">

</div>

<div class="table-responsive">
  <nz-table #basicTable [nzData]="listOfData" [nzPageIndex]="pageIndex" [nzPageSize]="pageSize" [nzTotal]="total"
    [nzFrontPagination]="false" (nzPageIndexChange)="changePage($event)" [nzLoading]="!isTableLoaded">
    <thead>
      <tr>
        <th *ngFor="let column of columns">{{ column.name }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td *ngFor="let column of columns">
          <ng-container [ngSwitch]="column.type">
            <span *ngSwitchCase="'date'">
              {{ data[column.key] | date: 'dd-MM-YYYY'}}
            </span>
            <span *ngSwitchCase="'name'">
              {{ data.company.name }}
            </span>
            <span *ngSwitchCase="'email'">
              {{ data.company.email }}
            </span>
            <span *ngSwitchCase="'phone'">
              {{ data.company.phone }}
            </span>
            <span *ngSwitchCase="'status'">
              {{ data.validated == 1 ? 'Approvata' : data.validated == 0 ? 'Rigettata': 'In attesa di
              approvazione' }}
            </span>
            <span *ngSwitchCase="'contract'">
              {{ getContractType(data.contract) }}
            </span>
            <span *ngSwitchCase="'specialization'">
              {{ getSpecialization(data.specialization_id) }}
            </span>
            <span *ngSwitchCase="'actions'">
              <div class="d-flex flex-row" style="gap:0.5rem">
                <button class="btn blu me-2" [routerLink]="'offers/' + data.id">
                  <span nz-icon nzType="eye" nzTheme="outline"></span>
                </button>
                <button class="btn blu me-2" (click)="validateOffer(data['id'], true)"
                  *ngIf="data['validated'] == null && client == '1'">
                  <span nz-icon nzType="check" nzTheme="outline"></span>
                </button>
                <button class="btn rosso" (click)="validateOffer(data['id'], false)"
                  *ngIf="data['validated'] == null && client == '1'">
                  <span nz-icon nzType="close" nzTheme="outline"></span>
                </button>
              </div>
            </span>
            <span *ngSwitchDefault>
              {{ data[column.key] }}
            </span>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>



<button #openModal type="button" class="ms-3 btn blu" data-bs-toggle="modal" data-bs-target="#caricafile"
  [hidden]="true">
</button>

<!-- Modal VISUALIZZA RICHIESTE -->
<div class="modal fade" id="caricafile" tabindex="-1" aria-labelledby="caricafileLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="caricafileLabel">Candidature Inoltrate</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <label>Inserisci un feedback per le tue candidature inoltrate</label>
        <mat-table [dataSource]="offers_with_no_feedback">
          <ng-container matColumnDef="azienda">
            <mat-header-cell *matHeaderCellDef>Azienda</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.offer.company.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="specializzazione">
            <mat-header-cell *matHeaderCellDef>Specializzazione</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.offer.specialization.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="data">
            <mat-header-cell *matHeaderCellDef>Data Candidatura</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.created_at | date:'dd/MM/yyyy' }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="Contattato">
            <mat-header-cell *matHeaderCellDef>Contattato</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="d-flex">
                <button type="button" [disabled]="row.feedback === true" class="btn btn-primary btn-sm me-2"
                  (click)="sendFeedback(row, true)">Si</button>

                <button type="button" [disabled]="row.feedback === false" class="btn btn-primary btn-sm"
                  (click)="sendFeedback(row, false)">No</button>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="['azienda', 'specializzazione', 'data', 'Contattato']"></mat-header-row>
          <mat-row *matRowDef="let row; columns: ['azienda', 'specializzazione', 'data', 'Contattato']"></mat-row>
        </mat-table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn grey" data-bs-dismiss="modal" #closeModal>Chiudi</button>
      </div>
    </div>
  </div>
</div>