<div class="m-auto mt-5">
    <div class="">
        <h2 class="text-color">Registrati come Azienda</h2>
        <div class="col-12 input-group center mb-3 p-2">
            <form [formGroup]="registerForm" class="example-form">
                <mat-form-field class="example-full-width" appearance="fill">
                    <input type="text" class="text" matInput placeholder="Partita IVA" formControlName="vat_number">
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                    <input type="text" class="text" matInput placeholder="Ragione sociale" formControlName="name">
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                    <input type="text" class="text" matInput placeholder="Username" formControlName="username">
                </mat-form-field>
                <div class="invalid-input" *ngIf="registerForm.controls.username.errors?.validationError">
                    <span *ngFor="let error of registerForm.controls.username.errors?.validationError">{{error}}</span>
                </div>

                <mat-form-field class="example-full-width" appearance="fill">
                    <input type="email" class="text" matInput placeholder="E-mail" formControlName="email">
                </mat-form-field>
                <div class="invalid-input" *ngIf="registerForm.controls.email.errors?.validationError">
                    <span *ngFor="let error of registerForm.controls.email.errors?.validationError">{{error}}</span>
                </div>

                <mat-form-field class="example-full-width" appearance="fill">
                    <input type="text" class="text" matInput placeholder="Telefono" formControlName="phone">
                </mat-form-field>
                <div class="invalid-input" *ngIf="registerForm.controls.phone.errors?.validationError">
                    <span *ngFor="let error of registerForm.controls.phone.errors?.validationError">{{error}}</span>
                </div>

                <mat-form-field class="example-full-width" appearance="fill">
                    <input type="text" class="text" matInput placeholder="Sede principale" formControlName="address">
                </mat-form-field>
                <div class="invalid-input" *ngIf="registerForm.controls.address.errors?.validationError">
                    <span *ngFor="let error of registerForm.controls.address.errors?.validationError">{{error}}</span>
                </div>

                <mat-form-field class="example-full-width" appearance="fill">
                    <input type="text" class="text" matInput placeholder="Settore" formControlName="sector">
                </mat-form-field>
                <div class="invalid-input" *ngIf="registerForm.controls.sector.errors?.validationError">
                    <span *ngFor="let error of registerForm.controls.sector.errors?.validationError">{{error}}</span>
                </div>

                <mat-form-field class="example-full-width" appearance="fill">
                    <input type="text" class="text" matInput placeholder="Rappresentante legale"
                        formControlName="chief">
                </mat-form-field>
                <div class="invalid-input" *ngIf="registerForm.controls.chief.errors?.validationError">
                    <span *ngFor="let error of registerForm.controls.chief.errors?.validationError">{{error}}</span>
                </div>

                <mat-form-field class="example-full-width " appearance="fill">
                    <input #password type="password" class="password position" matInput placeholder="Password"
                        formControlName="password">
                    <!-- <i class="float fa-solid fa-eye"></i> -->
                </mat-form-field>
                <span class="border right" id="basic-addon2" (click)="changePasswordInput(password, 'password')"
                    *ngIf="!isPasswordShowed; else passwordHided">
                    <i class="fa-solid fa-eye"></i>
                </span>

                <ng-template #passwordHided>
                    <span class="border right" id="basic-addon2" (click)="changePasswordInput(password, 'password')">
                        <i class="fa-solid fa-eye-slash"></i>
                    </span>
                </ng-template>

                <mat-form-field class="example-full-width " appearance="fill">
                    <input #password_confirmation type="password" class="password position" matInput
                        placeholder="Conferma Password" formControlName="password_confirmation">
                    <!-- <i class="float fa-solid fa-eye"></i> -->
                </mat-form-field>

                <span class="border right" id="basic-addon2"
                    (click)="changePasswordInput(password_confirmation, 'confirm')"
                    *ngIf="!isConfirmPasswordShowed; else confirmPasswordHided">
                    <i class="fa-solid fa-eye"></i>
                </span>

                <ng-template #confirmPasswordHided>
                    <span class="border right" id="basic-addon2"
                        (click)="changePasswordInput(password_confirmation, 'confirm')">
                        <i class="fa-solid fa-eye-slash"></i>
                    </span>
                </ng-template>

                <div class="accedi">
                    <button type="submit" (click)="onSubmit();" class="btn button"
                        [disabled]="registerForm.invalid">REGISTRATI</button>
                </div>
                <p class="d-flex align-items-center justify-content-center h-100">
                    Non sei un'azienda? Registrati&nbsp;<a href="register">qui</a>.
                </p>
                <a href="login" class='register'>Torna alla login</a>
            </form>
        </div>
    </div>
</div>

<ng-template #notShowed><i class="fa-solid fa-eye"></i></ng-template>

<!-- <section class="my-5">
    <h2 class="text-color ">Registrati</h2>
    <form nz-form [nzLayout]="'vertical'" [formGroup]="registerForm" class="col-6 mx-auto">
        <section nz-row [nzGutter]="24">
            <article nz-col [nzXs]="24" [nzXl]="12">
                <nz-form-item>
                    <nz-form-label nzRequired>Nome</nz-form-label>
                    <nz-form-control nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
                        <input nz-input formControlName="name" placeholder="Nome" />
                        <ng-template #userErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">Il nome è obbligatorio</ng-container>
                            <ng-container *ngIf="control.hasError('duplicated')">Il nome è già presente</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </article>

            <article nz-col [nzXs]="24" [nzXl]="12">
                <nz-form-item>
                    <nz-form-label nzRequired>Cognome</nz-form-label>
                    <nz-form-control nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
                        <input nz-input formControlName="lastname" placeholder="Cognome" />
                        <ng-template #userErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">Il cognome è obbligatorio</ng-container>
                            <ng-container *ngIf="control.hasError('duplicated')">Il cognome è già
                                presente</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </article>

            <article nz-col [nzXs]="24" [nzXl]="12">
                <nz-form-item>
                    <nz-form-label nzRequired>Username</nz-form-label>
                    <nz-form-control nzHasFeedback nzValidatingTip="Validating..." [nzErrorTip]="userErrorTpl">
                        <input nz-input formControlName="userName" placeholder="Username" />
                        <ng-template #userErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">L'username è obbligatorio</ng-container>
                            <ng-container *ngIf="control.hasError('duplicated')">L'username è già
                                presente</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </article>

            <article nz-col [nzXs]="24" [nzXl]="12">
                <nz-form-item>
                    <nz-form-label nzRequired>E-mail</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="emailErrorTpl">
                        <input nz-input formControlName="email" placeholder="E-mail" type="email" />
                        <ng-template #emailErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('email')">L'indirizzo e-mail non è
                                valido</ng-container>
                            <ng-container *ngIf="control.hasError('required')">L'indirizzo e-mail è
                                obbligatorio!</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </article>

            <article nz-col [nzXs]="24" [nzXl]="12">
                <nz-form-item>
                    <nz-form-label nzRequired>Password</nz-form-label>
                    <nz-form-control nzHasFeedback nzErrorTip="La password è obbligatoria">
                        <input nz-input type="password" formControlName="password"
                            (ngModelChange)="validateConfirmPassword()" />
                    </nz-form-control>
                </nz-form-item>
            </article>

            <article nz-col [nzXs]="24" [nzXl]="12">
                <nz-form-item>
                    <nz-form-label nzRequired>Conferma Password</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="passwordErrorTpl">
                        <input nz-input type="password" formControlName="confirm" placeholder="Password" />
                        <ng-template #passwordErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">È necessario confermare la
                                password</ng-container>
                            <ng-container *ngIf="control.hasError('confirm')">Le password non coincidono</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </article>

            <article nz-col [nzXs]="24" [nzXl]="8" class="mx-auto">
                <nz-form-item>
                    <nz-form-control>
                        <button class="col-12 btn blu" nz-button nzType="primary"
                            (click)="onSubmit()">Registrati</button>
                    </nz-form-control>
                </nz-form-item>
            </article>
        </section>
    </form>
</section> -->