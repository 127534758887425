import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TernaService {
    route = `${environment.api}`;

    constructor(private http: HttpClient) { }

    getTerna(params?: any) {
        return this.http.post<FormattedResponse<any>>(`${environment.api}/getTerna`, params);
    }

    getTernaUsers(id: any, params?: any) {
      return this.http.post<any>(`${environment.api}/getTernaUsers/${id}`, params);
    }

    updateOrCreateTerna(params: any) {
        return this.http.post<FormattedResponse<any>>(`${environment.api}/updateOrCreateTerna`, params, {});
    }

    getTernaById(id?: any) {
        return this.http.get<FormattedResponse<any>>(`${environment.api}/getTernaById/${id}`);
    }

    uploadFile(params: any) {
        return this.http.post<FormattedResponse<any>>(`${environment.api}/uploadFile`, params);
    }

    approveTernaUsers(id: any, params: any) {
        return this.http.post<FormattedResponse<any>>(`${environment.api}/approveTernaUsers/${id}`, params);
    }

    confirmTerna(id: any, confirm: boolean) {
        return this.http.post<FormattedResponse<any>>(`${environment.api}/confirmTerna/${id}`, {confirm});
    }
}
