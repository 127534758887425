import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { WordpressService } from '../../../services/wordpress.service';
import { UtilsServices } from "../../../services/utils.service";

@Component({
  selector: 'app-iscrizione',
  templateUrl: './iscrizione.component.html',
  styleUrls: ['./iscrizione.component.scss']
})
export class IscrizioneComponent implements OnInit {
  allCategories = new Array();
  profile_data: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(

    public router: Router,
    private route: ActivatedRoute,
    public _posts: WordpressService,
    private userService: UserService,
    private _utils: UtilsServices
  ) { }

  ngOnInit() {
    this._utils.getCategories(true).subscribe({
      next: response => {
        this.allCategories = response.data.map((category: any) => {
          category.icon = category.icon.replace("-white", "");
          return category;
        });
      }
    });

    this.getUser();
  }

  getUser() {
    this.userService.me().subscribe(response => {
      this.profile_data.next(response.data)
    })
  }
}
