<div class="mx-5 my-4">
  <div class="d-flex justify-content-between align-items-center">
    <h3 class="pb-0 mb-0">Variazione albo</h3>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
  </div>

  <form [formGroup]="form_albo" class="mt-3">


    <div class="my-3">

      <!--? Comunicazioni  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">Desidera ricevere comunicazioni dalla Fondazione dell’Ordine via mail?</p>
        </div>
        <div class="col-sm-6 col-md-8 d-flex align-items-center">
          <select class="form-select" formControlName="comunicazioni">
            <option value="SI"><span class="text-black">SI</span></option>
            <option value="NO"><span class="text-black">NO</span></option>
          </select>
        </div>
      </div>

      <!--? Inserire mail segreteria  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">Inserire mail segreteria</p>
        </div>
        <div class="col-sm-6 col-md-8">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="email" class="text" matInput formControlName="mailSegreteria" required>
          </mat-form-field>
        </div>
      </div>

      <!--? Telefono fisso  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">Telefono fisso</p>
        </div>
        <div class="col-sm-6 col-md-8">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" class="text" matInput formControlName="landlinePhone" required>
          </mat-form-field>
        </div>
      </div>

      <!--? Fax  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">Fax</p>
        </div>
        <div class="col-sm-6 col-md-8">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" class="text" matInput formControlName="fax" required>
          </mat-form-field>
        </div>
      </div>

      <h3>Recapito postale:</h3>

      <!--? Indirizzo  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">Indirizzo</p>
        </div>
        <div class="col-sm-6 col-md-8">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" class="text" matInput formControlName="indirizzo_posta" required>
          </mat-form-field>
        </div>
      </div>

      <!--? CAP  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">CAP</p>
        </div>
        <div class="col-sm-6 col-md-8">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" class="text" matInput formControlName="cap_posta" required>
          </mat-form-field>
        </div>
      </div>

      <!--? Città  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">Città</p>
        </div>
        <div class="col-sm-6 col-md-8">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" class="text" matInput formControlName="residenza_posta" required>
          </mat-form-field>
        </div>
      </div>

      <!--? Provincia  -->
      <div class="d-flex row col-12">
        <div class="col-sm-6 col-md-4">
          <p class="marginText">Provincia</p>
        </div>
        <div class="col-sm-6 col-md-8">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" class="text" matInput formControlName="provincia_posta" required>
          </mat-form-field>
        </div>
      </div>

    </div>

    <div class="d-flex justify-content-end">
      <button class="btn aggiorna mt-3 px-4" (click)="invia()">Invia</button>
    </div>
  </form>
</div>