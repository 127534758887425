import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TernaService } from "../../../../../../shared/services/terna.service";
import Swal from "sweetalert2";
import { EventService } from "../../../../services/event.service";

@Component({
    selector: 'detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
    columns = [
        { key: 'name', name: 'Nome', sortable: true },
        { key: 'created_at', name: 'Data di partecipazione', type: 'date', sortable: true },
        { key: 'status', name: 'Stato', type: 'string', sortable: false }
    ];
    data: any = [];
    data_count = 0;
    table_config = {
        pagination: { index: 0, size: 10 },
        sorting: { key: 'created_at', direction: 'desc' }
    }

    selected_rows: any = [];
    terna_id: string | null = null;
    terna: any = null;
    fileList: any = [];
    isVisible: boolean = false;
    label_azioni: string | null = null;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _terna: TernaService,
        private _eventService: EventService,
    ) { }

    ngOnInit(): void {
        this.terna_id = this._route.snapshot.paramMap.get('id');
        if (this._router.url.includes('/admin')) {
            // if user is an admin, add the participants_count column
            this.columns.splice(
                2,
                0,
                { key: 'participants_count', name: 'N. Iscritti', sortable: false }
            );
            this.label_azioni = 'ALLEGATI';
        }
        this.getData();
    }

    getData() {
        this._terna.getTernaUsers(this.terna_id, this.table_config).subscribe({
            next: response => {
                this.data = response.data.data;
                this.data_count = response.total ?? 0;
                this.terna = response.data.terna;

                if (!this.terna.isPast && !this.columns.find(item => item.key == 'check'))
                    this.columns.unshift({ key: 'check', name: 'check', type: 'check', sortable: false });
            }
        })
    }

    onPageChange(data: any) {
        this.table_config.pagination = data;
        this.getData();
    }

    /**
     * Al cambio di sorting
     * @param data
     */
    onSortChange(data: any) {
        this.table_config.sorting = data;
        this.getData();
    }

    /**
     * Viene richiamata al click sulle azioni delle datatable
     * @param data informazioni sull'azione e sulla riga cliccata
     */
    onActionClick(data: any) {
        switch (data.action) {
            case "download":
                this.fileList = data.data.files;
                this.isVisible = true;
                break;
        }
    }

    goTo(path: any) {
        this._router.navigate([path]);
    }

    onRowCheck(data: any) {
        if (data.checked) {
            let element = this.data.find((item: any) => item.id == data.id);
            this.selected_rows.push(element)
        }
        else {
            let index = this.selected_rows.findIndex((item: any) => item.id == data.id);
            this.selected_rows.splice(index, 1);
        }
    }

    approveTernaUsers(admitted: boolean) {
        let text = admitted ? "all'ammissione" : "alla non ammissione";
        let list = `<div style='text-align: left'> Sei sicuro di voler procedere ${text} dei seguenti utenti? <ul class='mt-3'>`;

        this.selected_rows.forEach((item: any) => {
            list += `<li>${item.name}</li>`;
        })

        list += "</ul></div>";

        Swal.fire({
            html: list,
            title: '',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            reverseButtons: true,
            confirmButtonText: 'Procedi',
            cancelButtonText: 'Chiudi'
        }).then((result) => {
            if (result.isConfirmed) {
                let params = { selected: this.selected_rows.map((item: any) => item.id), admitted: admitted };
                this._terna.approveTernaUsers(this.terna_id, params).subscribe({
                    next: response => {
                        Swal.fire({
                            icon: 'success',
                            title: '<div style="font-size:18px;">' + response.message + '</div>',
                            showConfirmButton: false,
                            timer: 3000,
                        });

                        this.getData();
                        this.selected_rows = []
                    },
                    error(error) {
                        Swal.fire({
                            icon: 'error',
                            title: "Errore durante la modifica",
                            text: error,
                        });
                    }
                })
            }
        });
    }

    exportExcel() {
        this._eventService.exportExcelEvent({ id: this.terna_id, type: "terna-subscriber" }).subscribe(
            (res: Blob) => {
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const filename = this.terna.title + '.xlsx';
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
    }
}
