import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { UserService } from "../../../services/user.service";
import { PageEvent } from "@angular/material/paginator";
import { ResourcesServices } from 'src/app/shared/services/resources.service';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import Swal from "sweetalert2";
import { Sort } from "@angular/material/sort";
import { debounceTime } from "rxjs/operators";
import { EventService } from '../../../services/event.service';
import { EngineerService } from '../../../services/engineer.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Router } from "@angular/router";
import * as utils from "src/app/modules/application/user/components/engineers-list/utilities/utilities.component";
import * as moment from "moment/moment";
import { UtilsServices } from 'src/app/shared/services/utils.service';

type Engineer = {
  id: string,
  resource_id: string,
  name: string,
  lastname: string,
  email: string,
  sector: string,
  registrationNumber: string,
  videocurriculum_request: boolean,
}

type FieldList = {
  [key: string]: FieldListItem[]
}

type FieldListItem = {
  label: string;
  control: string;
  type: string;
  values?: any;
}

@Component({
  selector: 'app-engineers-list',
  templateUrl: './engineers-list.component.html',
  styleUrls: ['./engineers-list.component.scss']
})

export class EngineersListComponent implements OnInit {

  fileList: NzUploadFile[] = [];
  uploading: boolean = false;
  form: FormGroup
  currentPage = 0;
  pageSize = 5;
  data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  count: any;
  pageEvent: PageEvent | undefined;
  displayedColumns: string[] = ['select', 'registrationNumber', 'lastname', 'name', 'email', 'sector', 'sospeso', 'actions'];
  selected_user: Engineer | null = null;
  link: FormControl = new FormControl(null);
  isVisible: boolean = false;
  isVisibleQueryBuilder: boolean = false;
  isVisibleStaticData: boolean = false;
  sort: Sort = { active: '', direction: '' };
  filters: FormGroup = new FormGroup({
    registrationNumber: new FormControl(null),
    name: new FormControl(null),
    lastname: new FormControl(null),
  });

  queryBuilderForm: FormGroup = new FormGroup({
    formArray: new FormArray([this.createGroup()])
  })

  staticDataForm: FormGroup = new FormGroup({
    staticDataFormArray: new FormArray([this.createStaticDataGroup()])
  })

  queryBuilderSymbols = utils.getSymbols();
  queryBuilderFieldsList: FieldList = utils.getFields();
  clauseOptions = utils.getClauseOptions();
  staticDataFieldsList: FieldList = utils.getFieldsStaticData();

  filtersApplied = false;
  storageForm = localStorage.getItem('queryBuilder') ? JSON.parse(localStorage.getItem('queryBuilder') ?? '') : null;
  selected_rows: string[] = [];
  specializations: any = [];

  constructor(
    private _users: UserService,
    private _resource: ResourcesServices,
    private _eventService: EventService,
    private _engineerService: EngineerService,
    private fb: FormBuilder,
    public _router: Router,
    public _utils: UtilsServices
  ) {
    this.form = this.fb.group({
      file: [null, Validators.required]
    })
  }

  ngOnInit(): void {
    this._utils.getSpecializations().subscribe({
      next: (response: any) => {
        this.specializations = response.data;
        console.log(this.specializations);
      }
    })

    this.link.setValidators(this.linkValidation);
    this.link.updateValueAndValidity();

    if (this.storageForm) {
      this.storageForm.forEach((item: any, i: number) => {
        if (i != this.storageForm.length - 1) this.addItem();
      })

      this.queryBuilderForm.get('formArray')?.patchValue(this.storageForm);
      this.filtersApplied = true;
    }

    this.getData();
    this.getStaticData();

    this.filters.valueChanges.pipe(debounceTime(1000)).subscribe({
      next: value => {
        this.currentPage = 0
        this.getData();
      }
    })
  }

  getData() {
    let params = {
      start: this.currentPage * this.pageSize,
      length: this.pageSize,
      filters: this.queryBuilderForm.get('formArray')?.value,
      ...this.sort
    }

    this._users.getEngineersList(params).subscribe({
      next: response => {
        this.data.next(response.data);

        this.records.next(response.total);
      }
    })
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.uploading = false;
    this.fileList = [];
    this.fileList = this.fileList.concat(file);
    return false;
  };

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getData();
    return e;
  }

  printCv(resource_id: string) {
    this._resource.printCV(resource_id).subscribe(
      (blob: any) => {
        const blobFile: Blob = new Blob([blob], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(blobFile);
        window.open(fileURL, '_blank');
      });
  }

  onCloseModal() {
    this.selected_user = null;
    this.link.reset();
  }

  onSaveModal() {
    this._users.saveEngineerVideoCv(this.selected_user!.resource_id, { link: this.link.value }).subscribe({
      next: response => {
        this.getData();
        this.onCloseModal();

        Swal.fire({
          icon: 'success',
          title: response.message
        });
      }
    })
  }


  exportExcel() {
    this._eventService.exportExcelEvent({ type: "engineer", filters: this.queryBuilderForm.get('formArray')?.value }).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = 'Ingegneri' + '.xlsx';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  importEngineer() {

    if (this.form.valid) {
      this._engineerService.importCsv(this.form.value).subscribe(
        (response) => {
          Swal.fire({
            icon: 'success',
            title: 'Import eseguito correttamente'
          });

          this.getData();
        },
        (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Errore nell\'import del file'
          });
        }
      );
      this.getData();
    }
  }

  handleUpload(): void {
    let data: any = [];
    this.fileList.forEach((file: any) => {
      data.push({
        file: file
      });
    });
    this.form.patchValue({ file: data[0].file });
    this.uploading = true;
  }

  changeStatus(id: string) {
    this._users.changeStatus(id).subscribe(
      (response) => {
        Swal.fire({
          icon: 'success',
          title: 'Stato modificato correttamente'
        });
      },
      (error) => {
        Swal.fire({
          icon: 'error',
          title: 'Errore nella modifica dello stato'
        });
      }
    );
    this.getData();
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.importEngineer()
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  public linkValidation: ValidatorFn = (form): ValidationErrors | null => {
    const isValid = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(form.value);
    if (form.value && !isValid) return { linkError: true };
    return null;
  }

  onSortChange($event: Sort) {
    this.sort = $event;
    this.getData();
  }

  goTo(path: any) {
    this._router.navigate([path]);
  }

  addEngineer() {
    this.goTo('app/admin/engineers_list/detail');
  }


  //funzioni per la gestione della ricerca avanzata
  private createGroup() {
    return new FormGroup({
      control: new FormControl(null, Validators.required),
      value: new FormControl(null, Validators.required),
      symbol: new FormControl(null, Validators.required),
      clause: new FormControl('where', Validators.required),
      type: new FormControl(null)
    })
  }

  addItem() {
    this.formArray.push(this.createGroup());
  }

  removeItem(index: any) {
    if (this.formArray.length == 1) return;
    this.formArray.removeAt(index);
  }

  getFormArrayElement(index: number): FieldListItem {
    let control = this.formArray.controls[index].get('control')?.value;
    let element = { label: '', control: '', type: '' };

    for (let field in this.queryBuilderFieldsList) {
      this.queryBuilderFieldsList[field].forEach((item: FieldListItem) => {
        if (item.control == control) element = item;
      })
      if (element.control != '') break;
    }

    return element;
  }

  onSelectChange(index: number) {
    let formArray = this.formArray.controls[index];

    formArray?.get('symbol')?.reset();
    formArray?.get('value')?.reset();
    formArray.patchValue({ type: this.getFormArrayElement(index).type });
  }

  onQueryBuilderSubmit() {
    this.formArray.value.forEach((item: any, index: number) => {
      if (item.type == 'date')
        this.formArray.at(index).patchValue({ value: moment(this.formArray.at(index).value.value).toISOString(true) });
    })

    if (this.queryBuilderForm.invalid) {
      this.queryBuilderForm.markAllAsTouched()
    }
    else {
      this.isVisibleQueryBuilder = false;
      this.filtersApplied = true;

      localStorage.setItem('queryBuilder', JSON.stringify(this.formArray.value));
      this.getData();
    }
  }

  onQueryBuilderClose() {
    this.isVisibleQueryBuilder = false;
    if (this.storageForm) this.formArray.patchValue(this.storageForm);
  }

  onQueryBuilderReset() {
    this.queryBuilderForm.reset();

    const length = JSON.parse(JSON.stringify(this.formArray['controls'].length));

    for (let i = length - 1; i > 0; i--) {
      this.formArray.removeAt(i);
    }

    this.formArray.at(0).patchValue({ clause: 'where' });

    localStorage.removeItem('queryBuilder');
    this.storageForm = null;
    this.getData();
    this.isVisibleQueryBuilder = false;
    this.filtersApplied = false;
  }

  getSymbols(i: number) {
    let array: any = [];

    this.queryBuilderSymbols.forEach(item => {
      if (item.type.includes(this.getFormArrayElement(i).type)) array.push(item);
    })

    return array;
  }

  onQueryBuilderOpen() {
    this.isVisibleQueryBuilder = true;
  }

  get formArray() {
    return (<FormArray>this.queryBuilderForm.get('formArray'));
  }


  //funzioni per la gestione dei dati statici
  onStaticDataOpen() {
    this.isVisibleStaticData = true;
  }

  onStaticDataClose() {
    this.isVisibleStaticData = false;
  }

  onStaticDataSubmit() {
    this.staticDataFormArray.value.forEach((item: any, index: number) => {
      if (item.type == 'date')
        this.staticDataFormArray.at(index).patchValue({ value: moment(this.staticDataFormArray.at(index).value.value).toISOString(true) });
    })

    if (this.staticDataForm.invalid) {
      this.staticDataForm.markAllAsTouched();
    }
    else {
      let params = { data: JSON.stringify(this.staticDataFormArray.value), reset: false };
      this.setStaticData(params);
    }
  }

  get staticDataFormArray() {
    return (<FormArray>this.staticDataForm.get('staticDataFormArray'));
  }

  private createStaticDataGroup() {
    return new FormGroup({
      control: new FormControl(null, Validators.required),
      value: new FormControl(null, Validators.required),
      type: new FormControl(null)
    })
  }

  getStaticDataFormArrayElement(index: number): FieldListItem {
    let control = this.staticDataFormArray.controls[index].get('control')?.value;
    let element = { label: '', control: '', type: '' };

    for (let field in this.staticDataFieldsList) {
      this.staticDataFieldsList[field].forEach((item: FieldListItem) => {
        if (item.control == control) element = item;
      })
      if (element.control != '') break;
    }

    return element;
  }

  addItemStaticData() {
    this.staticDataFormArray.push(this.createStaticDataGroup());
  }

  removeItemStaticData(index: any) {
    if (this.staticDataFormArray.length == 1) return;
    this.staticDataFormArray.removeAt(index);
  }

  onStaticDataSelectChange(index: number) {
    let staticDataFormArray = this.staticDataFormArray.controls[index];

    staticDataFormArray?.get('value')?.reset();
    staticDataFormArray.patchValue({ type: this.getStaticDataFormArrayElement(index).type });
  }

  onStaticDataReset() {
    this.staticDataForm.reset();

    const length = JSON.parse(JSON.stringify(this.staticDataFormArray['controls'].length));

    for (let i = length - 1; i > 0; i--) {
      this.staticDataFormArray.removeAt(i);
    }

    let params = { data: this.staticDataFormArray.value, reset: true };
    this.setStaticData(params);
  }

  onCheck(element: any) {
    let index = this.selected_rows.findIndex((item: any) => item == element.id);

    if (index != -1) this.selected_rows.splice(index, 1);
    else this.selected_rows.push(element.id);
  }

  getStaticData() {
    this._engineerService.getStaticData().subscribe({
      next: response => {
        let data = response.data;
        if (data) {
          data.forEach(() => {
            if (this.staticDataFormArray.length < data.length) this.addItemStaticData();
          })
          this.staticDataForm.get('staticDataFormArray')?.patchValue(response.data);
        }
      }
    });
  }

  setStaticData(params: any) {
    this._engineerService.setStaticData(params).subscribe({
      next: response => {
        this.getStaticData();
        this.onStaticDataClose();

        Swal.fire({
          icon: 'success',
          title: response.message,
          timer: 3000,
          showConfirmButton: false
        });
      }
    });
  }

  applyStaticData() {
    this._engineerService.applyStaticData(this.selected_rows).subscribe({
      next: response => {
        this.selected_rows = [];
        this.getData();

        Swal.fire({
          icon: 'success',
          title: response.message,
          timer: 3000,
          showConfirmButton: false
        });
      }
    });
  }
}
