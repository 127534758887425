import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/modules/application/services/user.service';
import { UtilsServices } from 'src/app/modules/application/services/utils.service';
import { WordpressService } from 'src/app/modules/application/services/wordpress.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';


@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {
  activeRoute: string | any;
  name: any;
  routes_fine = new Array();
  categories = new Array();
  client: any;
  profile: any;
  url: any;

  header_text = '';
  header_url = null;
  fondazione: boolean = false;

  constructor(
    private router: Router,
    private _post: WordpressService,
    private utils: UtilsServices,

    public user: UserService,
    private _auth: AuthService
  ) { }

  ngOnInit() {
    this.fondazione = localStorage.getItem('fondazione') == 'true' ? true : false;
    this.getUser();
    this.activeRoute = this.router.url;
    this.getCategory();
    this.client = localStorage.getItem('role');

    let url = this.router.url;
    if (url.includes('/app/admin')) this.profile = '/app/admin/profile'
    else if (this.url.includes('/app/client')) this.profile = '/app/client/profile';
    else if (this.url.includes('/app/guest')) this.profile = '/app/guest/profile';
    else if (this.url.includes('/app/fondazione')) this.profile = '/app/fondazione/profile';

    this.user.getUser().subscribe((userForm: any) => {
      if (userForm) this.getUser();
    })

    this.user.getHeaderInfo().subscribe({
      next: response => {
        this.header_text = response.data.text;
        this.header_url = response.data.url;
      }
    })
  }


  getUser() {
    this.user.me().subscribe(element => {
      this.name = element.data.name + ' ' + element.data.lastname;
      this._auth.currentUser.next(element);
    })
  }

  resertToken() {
    localStorage.removeItem('Authorization');
    this.router.navigate(['/auth/login']);
  }

  isActiveRoute(url: string) {
    return this.activeRoute.includes(url);
  }


  getCategory() {
    this.url = this.router.url;
    if (this.url.includes('/app/admin/dashboard')) this.url = '/app/admin/dashboard'
    else if (this.url.includes('/app/fondazione/dashboard')) this.url = '/app/client/subscriptions';
    else if (this.url.includes('/app/guest/subscriptions')) this.url = '/app/guest/subscriptions';
    this.utils.getCategories(true).subscribe({
      next: response => {
        this.client = localStorage.getItem('role');
        if (this.client == 1) this.url = '/app/admin/dashboard'
        else if (this.client == 4) this.url = '/app/fondazione/dashboard';

        this.categories = response.data.map((category: any) => {
          category.url = this.url + '/' + category.slug + '/' + category.id;
          return category;
        });

        this.activeRoute = this.router.url;

        this.client = localStorage.getItem('role');
        // if (this.client == 1) this.routes_fine = this.routes_admin;
        // else if (this.client == 4) this.routes_fine = this.routes_fond;

        this.routes_fine.splice(1, 0, ...this.categories);
      }
    });
  }

}
