import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-manifestazioneInteresseDialog',
  templateUrl: './manifestazioneInteresseDialog.component.html',
  styleUrls: ['./manifestazioneInteresseDialog.component.scss']
})
export class ManifestazioneInteresseDialogComponent implements OnInit {
  tipo: any;
  filter_status: any = false;

  form_manifestazioni: FormGroup = this.formBuilder.group({
    ente: null,
    oggetto: null
  });

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  invia() {
    if (!this.form_manifestazioni.value.ente || !this.form_manifestazioni.value.oggetto) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Swal.fire({
        icon: 'warning',
        text: 'Compilare i campi'
      })
    } else {
      this.getDocument(this.tipo);
    }
  }

  getDocument(tipo: any) {
    this.userService.getDocument(tipo, null, null, null, null, null, null, null, null, null, null, this.form_manifestazioni.value.ente, this.form_manifestazioni.value.oggetto).subscribe((r: Blob) => {
      const blobFile: Blob = new Blob([r], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(blobFile);
      window.open(fileURL, '_blank');
    })
  }
}
