<!-- <app-headers></app-headers>
<div class="d-flex flex-row">
  <div class="col-2">
    <app-sidebar></app-sidebar>
  </div>
  <div class="col-10 p-4 mt-5">
    <div *ngIf="loading">
      <app-loader></app-loader>
    </div>

    <router-outlet></router-outlet>
  </div>
</div> -->

<section class="page-body">
  <nz-layout>
    <app-headers></app-headers>
    <nz-layout>
      <app-sidebar (sidebarStatus)="changeSize($event)"></app-sidebar>
      <nz-layout class="inner-layout" id="inner">
        <div *ngIf="loading">
          <app-loader></app-loader>
        </div>

        <router-outlet></router-outlet>
      </nz-layout>
    </nz-layout>
  </nz-layout>
</section>