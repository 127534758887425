import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { UserService } from '../../../services/user.service';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators'
import { Location, registerLocaleData } from "@angular/common";
import localeIt from '@angular/common/locales/it';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { EventService } from '../../../services/event.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExternalCourseService } from "../../../../../shared/services/external-course.service";
import Swal from "sweetalert2";
registerLocaleData(localeIt);

@Component({
  selector: 'app-scadenzario',
  templateUrl: './scadenzario.component.html',
  styleUrls: ['./scadenzario.component.scss']
})
export class ScadenzarioComponent implements OnInit {
  external_columns = [
    { key: 'title', name: 'Corso', sortable: true },
    { key: 'start_date', name: 'Data inizio', type: 'date', sortable: true },
    { key: 'end_date', name: 'Data fine', type: 'date', sortable: true }
  ];

  internal_columns = [
    { key: 'title', name: 'Corso', sortable: true },
    { key: 'start_date', name: 'Data inizio', type: 'date', sortable: true },
    { key: 'end_date', name: 'Data fine', type: 'date', sortable: true }
  ];

  special_course_columns = [
    { key: 'check', name: 'check', type: 'check', sortable: false },
    { key: 'title', name: 'Corso', sortable: false },
    { key: 'start_date', type: 'date', name: 'Data di inizio', sortable: false },
    { key: 'end_date', type: 'date', name: 'Data di fine', sortable: false },
  ];

  external_data = [];
  special_course_data = [];
  internal_data = [];
  external_count = 0;
  special_course_count = 0;
  internal_count = 0;
  selectedCourse = new Array()
  external_table_config = {
    pagination: { index: 0, size: 10 },
    sorting: { key: 'created_at', direction: 'desc' }
  }
  internal_table_config = {
    pagination: { index: 0, size: 10 },
    sorting: { key: 'created_at', direction: 'desc' }
  }
  role_id: any

  constructor(
    private userService: UserService,
    private router: Router,
    private _eventService: EventService,
    private _externalCourses: ExternalCourseService,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.role_id = localStorage.getItem('role')
    this.getData();
    this.getExternalCourse();
    if (this.role_id == 1) {
      this.getSpecialCourses()
    }
  }

  createCourse() {
    this.router.navigate(['app/client/external-course']);
  }


  onRowClick($event: any) {
    this.router.navigate([`${this.router.url}/detail/${$event.id}`]);
  }


  getData() {
    this.userService.getUserSubscriptions(this.internal_table_config).subscribe(response => {
      this.internal_data = response.data;
      this.internal_count = response.total;
    });
  }

  onRowCheck(data: any) {
    if (data.checked) {
      let element = this.special_course_data.find((item: any) => item.id == data.id);
      this.selectedCourse.push(element)
    }
    else {
      let index = this.selectedCourse.findIndex((item: any) => item.id == data.id);
      this.selectedCourse.splice(index, 1);
    }
  }

  deleteCourse() {
    const selectedRowsIds = this.selectedCourse.map((row: any) => row.id);
    this._externalCourses.deleteSpecialeCourse(selectedRowsIds).subscribe(response => {
      Swal.fire({
        icon: 'success',
        title: 'Corso eliminato con successo',
        showConfirmButton: false,
        timer: 1500
      })
      this.getSpecialCourses()
    })
  }
  getExternalCourse() {
    this._externalCourses.getExternalCourses(this.external_table_config).subscribe({
      next: response => {
        this.external_data = response.data;
        this.external_count = response.total;
      }
    })
  }

  getSpecialCourses() {
    this.selectedCourse = [];
    this._externalCourses.getSpecialCourses(this.external_table_config).subscribe({
      next: response => {
        this.special_course_data = response.data;
        this.special_course_count = response.total;
      }
    })
  }

  goToEdit(id: any) {
    if (this.role_id == 2) {
      this.router.navigate(['app/client/external-course/' + id]);
    } else {
      this.router.navigate(['app/admin/scadenzario/special-course/' + id]);

    }
  }

  goToManagement() {
    this.router.navigate(['app/admin/scadenzario/users-management'])
  }


  downloadFile(data: any) {
    let file_url = data.special_course_iscritti[0].certificate.url
    window.open(file_url)
  }

  sendRequest(post_id: any = null) {
    this.userService.sendSpecialCourseRequestCertificate(post_id).subscribe({
      next: response => {
        Swal.fire({
          icon: 'success',
          title: '<div style="font-size:18px;">' + response.message + '</div>',
          showConfirmButton: false,
          timer: 3000,
        });
        this.getData();
      }
    })
  }

  exportExcel() {
    let isAdmin = this.role_id == 1 ? true : false;

    this._eventService.exportExcelEvent({ type: "timetable", isAdmin: isAdmin }).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = 'Scadenzario' + '.xlsx';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }


  goBack() {
    this.location.back();
  }

  onActionClick($event: any) {
    if (typeof $event.action == 'object') $event.action = $event.action.text;

    switch ($event.action) {
      case 'edit': {
        this.goToEdit($event.data.id);
        break;
      }
      case 'Richiedi attestato': {
        this.sendRequest($event.data.id)
        break;
      }
      case 'Scarica attestato': {
        this.downloadFile($event.data)
        break;
      }

    }
  }



  onSortChange($event: any, internal = false) {
    if (internal) {
      this.internal_table_config.sorting = $event;
      this.getData();
    }
    else {
      this.external_table_config.sorting = $event;
      this.getExternalCourse();
    }
  }

  onPageChange($event: any, internal = false) {
    if (internal) {
      this.internal_table_config.pagination = $event;
      this.getData();
    }
    else {
      this.external_table_config.pagination = $event;
      this.getExternalCourse();
    }
  }
}
