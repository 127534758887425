<div class="m-auto mt-5 col-11">
  <h2 class="mb-4">Bacheca</h2>
  <p class="size mb-5">In sintesi</p>

  <div class="mb-5 row ms-2">
    <div class="col d-flex align-items-center space-bottom">
      <div class="d-flex row responsive col-12 gap-3 gap-xl-0">
        <div class="d-flex flex-row col-12 col-md-3" *ngFor="let category of allCategories">
          <a [href]="router.url + '/' + category.slug + '/' + category.id" style="width:100%">
            <div class="card center">
              <div class="card-body">
                <img [src]="category.icon" alt="">

                <p class="size p-2">{{category.name}}</p>
                <p class="size_number">{{category.count}}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

  </div>
</div>