import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NzFormLayoutType } from 'ng-zorro-antd/form';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NewsletterService } from 'src/app/modules/application/services/newsletter.service';
import Swal from 'sweetalert2';
import { config } from '../../../../../../../environments/config';
import { BehaviorSubject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-new-mail',
  templateUrl: './new-mail.component.html',
  styleUrls: ['./new-mail.component.scss']
})
export class NewMailComponent implements OnInit {

  @Output() cambiaTabEvent = new EventEmitter<number>();

  public newsletter: any = null;
  public newsletter_id: string | null = null;
  public lists: any[] = [];
  public templates: any[] = [];
  displayedColumns: string[] = ['email', 'success'];
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  mailNewsletter: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentPage = 0;
  pageSize = 5;
  pageEvent: PageEvent | undefined;
  fileList: NzUploadFile[] = [
  ];

  uploading: boolean = false;

  form!: FormGroup;
  body: any;
  editorConfig: AngularEditorConfig = config.angular_editor;
  role_id: any;

  constructor(
    private formBuilder: FormBuilder,
    private _newsletter: NewsletterService,
    private router: ActivatedRoute,
    private route: Router
  ) { }

  ngOnInit() {
    this.role_id = localStorage.getItem('role');
    this.initForm();
    this._newsletter.getLists({ role_id: this.role_id }).subscribe({
      next: (response) => {
        this.lists = response.data;
        if (this.role_id == 1) {
          this.form.get('list_id')?.setValue(this.lists.find(list => list.name == 'Iscritti Ordine').id);
        } else {
          this.form.get('list_id')?.setValue(this.lists.find(list => list.name == 'Iscritti Fondazione').id);
        }
      }
    });

    this._newsletter.getTemplates({ role_id: this.role_id }).subscribe({
      next: (response) => {
        this.templates = response.data;
        this.form.get('template_id')?.setValue(this.templates[0].id);
      }
    });

    this.newsletter_id = this.router.snapshot.params['id'];
    if (this.newsletter_id) {
      this.editorConfig.editable = false;
      this._newsletter.getNewsletterById(this.newsletter_id).subscribe({
        next: (response) => {
          this.newsletter = response.data;
          this.getDataNewsletter();
          this.initForm(response.data);
        }
      })
    } else {
      this.editorConfig.editable = true;
    }
  }


  getDataNewsletter() {
    let params = { start: this.currentPage * this.pageSize, length: this.pageSize, id: this.newsletter_id };
    this._newsletter.getlistsEmails(params).subscribe(response => {
      this.mailNewsletter.next(response.data);
      this.records.next(response.total);
    });
  }


  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getDataNewsletter();
    return e;
  }

  initForm = (data?: any) => {
    this.form = this.formBuilder.group({
      name: data ? data.name : null,
      subject: [data ? data.subject : null, Validators.required],
      list_id: [data ? data.list_id : null, Validators.required],
      template_id: [data ? data.static_template_id : null, Validators.required],
      body: [data ? data.template?.body : null, Validators.required],
      files: data ? data?.newsletter_attach : null,
      role_id: this.role_id,
      sendViaTelegram: [data && data?.sendViaTelegram == 1 ? true : false]
    });

    this.fileList = data ? data.newsletter_attach : [];
  }

  submitForm(): void {
    if (this.form.valid) {
      this._newsletter.sendNewsletter(this.form.value).subscribe({
        next: (response) => {
          Swal.fire({
            icon: 'success',
            title: '<div style="font-size:18px;">Newsletter inviata correttamente</div>',
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            this._newsletter.refresh.next(true);
            this.form.reset();
            this.fileList = [];
            this.initForm();
            this.cambiaTabEvent.emit(1)
          });
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            title: '<div style="font-size:18px;">Errore imprevisto</div>',
            showConfirmButton: false,
            timer: 3000,
          });
        }
      })
    } else {
      Object.values(this.form.controls).forEach((control: any) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.uploading = false;
    this.fileList = this.fileList.concat(file);
    this.handleUpload();
    return false;
  };

  handleUpload(): void {
    this.uploading = true;
    let data: any = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.fileList.forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let new_file = { name: file.name, base64: reader.result };
        data.push(new_file);
      };
    });

    this.form.patchValue({ files: data });
    this.uploading = false;
  }
}
