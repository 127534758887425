import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { EventService } from 'src/app/modules/application/services/event.service';

@Component({
  selector: 'app-registered-users',
  templateUrl: './registered-users.component.html',
  styleUrls: ['./registered-users.component.scss']
})
export class RegisteredUsersComponent implements OnInit {

  columns = [
    { key: 'registrationNumber', name: 'n.matricola', sortable: false },
    { key: 'name', name: 'Nome', sortable: true },
    { key: 'email', name: 'Email', sortable: false },
    { key: 'created_at', name: 'Data di partecipazione', type: 'date', sortable: true }
  ];
  event_id: string | null = null
  filters: FormGroup
  data: any = []
  data_count = 0
  event: any
  table_config = {
    pagination: { index: 0, size: 10 },
    sorting: { key: 'created_at', direction: 'desc' }
  }

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _eventService: EventService,
    private fb: FormBuilder
  ) {
    this.filters = this.fb.group({
      search: null,
    })
  }

  ngOnInit(): void {
    this.event_id = this._route.snapshot.paramMap.get('id');
    this.getData()
    this.getEventById()
  }


  ngAfterViewInit(): void {
    this.filters.valueChanges.pipe(debounceTime(1000)).subscribe({
      next: value => {
        this.getData();
      }
    })
  }

  getEventById() {
    if (this.event_id) {
      this._eventService.getEventById(this.event_id).subscribe(response => {
        this.event = response.data
      })
    }

  }
  resetFilter() {
    this.filters.reset()
  }


  exportExcel() {
    this._eventService.exportExcelEvent({ type: "signed_up_event", id: this.event_id }).subscribe(
      (res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = 'Iscritti_eventi.xlsx';
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  getData() {
    let params = { table_config: this.table_config, search: this.filters.get('search')?.value }
    this._eventService.getEventUsers(this.event_id, params).subscribe({
      next: response => {
        this.data = response.data.data;
        this.data_count = response.total ?? 0;
      }
    })
  }

  goTo(path: any) {
    this._router.navigate([path]);
  }


  onPageChange(data: any) {
    this.table_config.pagination = data;
    this.getData();
  }

  /**
 * Al cambio di sorting
 * @param data
 */
  onSortChange(data: any) {
    this.table_config.sorting = data;
    this.getData();
  }



}
