<div class="m-auto mt-5 col-11">
  <h2 class="mb-4">Newsletter</h2>
  <!-- <p class="size mb-5">In sintesi</p> -->

  <div class="d-flex flex-row col-sm-4 col-md-12 mb-5 responsive">
    <nz-tabset nzSize="large" class="col-12" [nzSelectedIndex]="selectedTab">
      <nz-tab nzTitle="Liste">
        <app-subscription-list></app-subscription-list>
      </nz-tab>
      <nz-tab nzTitle="Elenco">
        <app-mail-list></app-mail-list>
      </nz-tab>
      <nz-tab nzTitle="Nuova">
        <app-new-mail (cambiaTabEvent)="changeTab($event)"></app-new-mail>
      </nz-tab>
    </nz-tabset>
  </div>
</div>