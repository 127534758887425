import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ExternalCourseService } from 'src/app/shared/services/external-course.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-special-courses-detail',
  templateUrl: './special-courses-detail.component.html',
  styleUrls: ['./special-courses-detail.component.scss']
})
export class SpecialCoursesDetailComponent implements OnInit, AfterViewInit {

  registered_users_columns = [
    { key: 'name', name: 'Nome', sortable: false },
    { key: 'lastname', name: 'Cognome', sortable: false },
    { key: 'fiscal_code', name: 'Codice fiscale', sortable: false },
  ];

  registered_users_data = [];
  registered_users_count = 0;
  special_course_id: string = ''
  role_id: any = ''
  fileList: NzUploadFile[] = [
  ];
  uploading: boolean = false;
  allegafile: FormGroup
  isVisibleModal: boolean = false;


  registered_users_table_config = {
    pagination: { index: 0, size: 10 },
    sorting: { key: 'created_at', direction: 'desc' }
  }

  constructor(
    private _externalCourse: ExternalCourseService,
    private route: ActivatedRoute,
    private location: Location,
    private fb: FormBuilder
  ) {
    this.allegafile = this.fb.group({
      special_course_inscription_id: null,
      attachmentPaths: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.special_course_id = this.route.snapshot.params.id;
    this.role_id = localStorage.getItem('role');
  }

  ngAfterViewInit(): void {
    this.getData();
  }


  onActionClick($event: any) {
    if (typeof $event.action == 'object') $event.action = $event.action.text;
    switch ($event.action) {
      case 'Carica attestato': {
        this.isVisibleModal = true;
        this.allegafile.get('special_course_inscription_id')?.setValue($event.data.id);
        break;
      }
      case 'Richiedi attestato': {
        // this.sendRequest($event.data.id)
        break;
      }
    }
  }

  // * Allegato ng-zorro
  beforeUpload = (file: NzUploadFile): boolean => {
    this.uploading = false;
    this.fileList = this.fileList.concat(file);
    this.handleUpload();
    return false;
  };

  // * Allegato ng-zorro
  handleUpload(): void {
    this.uploading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.fileList.forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let new_file = { name: file.name, base64: reader.result };
        this.allegafile.patchValue({ attachmentPaths: new_file });
        this.uploading = false;
      };
    });


  }

  getData(): void {
    this._externalCourse.getSpecialCoursesInscriptions(this.special_course_id, this.registered_users_table_config).subscribe({
      next: res => {
        this.registered_users_data = res.data['special_courses_inscriptions'];
        this.registered_users_count = res.data['special_courses_inscriptions'].length;
      },
      error: err => {
      }
    })
  }

  onSortChange($event: any) {
    this.registered_users_table_config.sorting = $event;
    this.getData();
  }

  uploadFile() {
    this.allegafile.patchValue({
      // special_course_inscription_id: this.post_id,
    })
    this._externalCourse.uploadFile(this.allegafile.value).subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: '<div style="font-size:18px;">Attestato inviato con successo</div>',
        showConfirmButton: false,
        timer: 3000,
      });
      this._initAllegaFile();
      this.getData()

    })
  }

  handleCancelCreate(): void {
    this.isVisibleModal = false;
  }

  goBack() {
    this.location.back();
  }

  _initAllegaFile() {
    this.isVisibleModal = false
    this.fileList = [];
  }



  onPageChange($event: any) {
    this.registered_users_table_config.pagination = $event;
    this.getData();
  }





}
