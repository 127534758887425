<div class="m-auto col-11 mt-5">
    <h2 *ngIf="client == 1">{{eventId?'Modifica Evento' : 'Aggiungi evento'}}</h2>
    <h2 *ngIf="client == 4 && !eventId">{{'Aggiungi evento'}}</h2>
    <h2 *ngIf="(client == 2 || client == 4) && eventId">{{'Dettaglio Evento'}}</h2>

    <div *ngIf="eventId" class="col-12 col-md-3 mb-3">
        <button (click)="exportIcs()" type="button" class="btn blu btn-block-sm w-100 margin-sm-1">SCARICA EVENTO</button>
    </div>

    <form [formGroup]="eventForm">
        <div class="row">
            <div class="col-12">
                <label class="form-label">Titolo</label>
                <p *ngIf="client ==2;else title">{{eventForm.get('title')?.value}}</p>
                <ng-template #title>
                    <nz-form-control>
                        <input nz-input id="titolo" name="titolo" formControlName="title" />
                    </nz-form-control>
                    <div *ngIf="eventForm.get('title')?.hasError('required') && eventForm.get('title')?.touched"
                        class="text-danger">
                        Titolo è obbligatorio
                    </div>
                </ng-template>
            </div>
            <div class="col-12 col-md-6 mt-2">
                <label class="form-label" nzFor="data">Data e ora di inizio</label>
                <p *ngIf="client == 2; else startDate">
                    {{ eventForm.get('start_date')?.value | date: 'dd-MM-YYYY HH:mm' }}
                </p>
                <ng-template #startDate>
                    <nz-form-control>
                        <nz-date-picker [nzFormat]="'dd-MM-yyyy HH:mm'" [nzPlaceHolder]="'Seleziona data e ora'"
                            class="w-100" nzShowTime formControlName="start_date">
                        </nz-date-picker>
                    </nz-form-control>
                    <div *ngIf="eventForm.get('start_date')?.hasError('required') && eventForm.get('start_date')?.touched"
                        class="text-danger">
                        Data e ora di inizio è obbligatoria
                    </div>
                </ng-template>

            </div>

            <div class="col-12 col-md-6 mt-2">
                <label class="form-label" nzFor="data">Data e ora di fine</label>
                <p *ngIf="client == 2; else endDate">
                    {{ eventForm.get('end_date')?.value | date: 'dd-MM-YYYY HH:mm' }}
                </p>
                <ng-template #endDate>
                    <nz-form-control>
                        <nz-date-picker [nzFormat]="'dd-MM-yyyy HH:mm'" class="w-100"
                            [nzPlaceHolder]="'Seleziona data e ora'" nzShowTime formControlName="end_date">
                        </nz-date-picker>
                    </nz-form-control>
                    <div *ngIf="eventForm.get('end_date')?.hasError('required') && eventForm.get('end_date')?.touched"
                        class="text-danger">
                        Data e ora di fine è obbligatoria
                    </div>
                    <div *ngIf="eventForm.get('end_date')?.hasError('dateTimeRange') && eventForm.get('end_date')?.touched"
                        class="text-danger">
                        La data e l'ora di fine non possono essere inferiori a quelle di inizio
                    </div>
                </ng-template>

            </div>

            <div class="col-12 mt-2">
                <label class="form-label">Descrizione</label>
                <p *ngIf="client == 2; else description" [innerHTML]="eventForm.get('description')?.value">
                </p>
                <ng-template #description>
                    <nz-form-control>
                        <angular-editor id="descrizione" name="descrizione" formControlName="description"
                            [config]="editorConfig"></angular-editor>
                    </nz-form-control>
                    <div *ngIf="eventForm.get('description')?.hasError('required') && eventForm.get('description')?.touched"
                        class="text-danger">
                        Descrizione è obbligatoria
                    </div>
                </ng-template>

            </div>
        </div>

        <div class="mt-4" *ngIf="client !=2 ">
            <label class="me-2" for="status">Stato</label>
            <nz-select formControlName="status">
                <nz-option nzValue="publish" nzLabel="Pubblicato" [nzDisabled]="client == 4"></nz-option>
                <nz-option nzValue="draft" nzLabel="Bozza"></nz-option>
                <nz-option nzValue="trash" nzLabel="Cestinato"></nz-option>
            </nz-select>
        </div>

        <div class="mt-4" *ngIf="!eventId && client==1">
            <label class="me-2" for="choise">Pubblica sul portale della fondazione </label>
            <nz-radio-group formControlName="choise">
                <label nz-radio [nzValue]="true">SI</label>
                <label nz-radio [nzValue]="false">NO</label>
            </nz-radio-group>
        </div>

        <div class="mt-4" *ngIf="!eventId && client==1">
            <label class="me-2" for="choise">Invia una comunicazione via mail </label>
            <nz-radio-group formControlName="sendViaMail">
                <label nz-radio [nzValue]="true">SI</label>
                <label nz-radio [nzValue]="false">NO</label>
            </nz-radio-group>
        </div>

        <div class="mt-4 d-flex justify-content-end mt-2">
            <div>
                <button class="me-1 btn grey space ng-star-inserted" (click)="goBack()">Annulla</button>
            </div>
            <div>
                <button *ngIf="client == 1 || (client == 4 && !eventId)" [disabled]="!eventForm.valid" class="btn blu"
                    (click)="onSubmit()">Salva</button>
            </div>
        </div>
    </form>
</div>