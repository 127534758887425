import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { ConsultingsService } from '../../../services/consultings.service';
import Swal from 'sweetalert2';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { UserService } from '../../../services/user.service';
import * as moment from 'moment';
import { EventService } from '../../../services/event.service';

@Component({
    selector: 'app-consultings-list',
    templateUrl: './consultings-list.component.html',
    styleUrls: ['./consultings-list.component.scss']
})
export class ConsultingsListComponent implements OnInit {

    currentPage = 0;
    pageSize = 5;
    data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
    pageEvent: PageEvent | undefined;
    displayedColumns: string[] = ['type', 'date', 'hour_slot', 'consultant_name', 'actions'];
    sort: Sort = { active: '', direction: '' };
    modalForm: FormGroup = this.createForm();
    isVisibleCreate: boolean = false;
    isVisibleUpdate: boolean = false;
    typeList: Array<string> = [];
    hour_slots: Array<string> = [
        '09:00-09:30',
        '09:30-10:00',
        '10:00-10:30',
        '10:30-11:00',
        '11:00-11:30',
        '11:30-12:00',
        '12:00-12:30',
        '12:30-13:00',
        '13:00-13:30',
        '13:30-14:00',
        '14:00-14:30',
        '14:30-15:00',
        '15:00-15:30',
        '15:30-16:00',
        '16:00-16:30',
        '16:30-17:00',
        '17:00-17:30',
        '17:30-18:00'
    ];
    current_user: BehaviorSubject<any> = new BehaviorSubject<any>({});
    updateIds: Array<string> = [];
    isVisibleRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    consultantsList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(
        private _consultings: ConsultingsService,
        private _eventService: EventService,
        private fb: FormBuilder,
        private datePipe: DatePipe,
        private _user: UserService
    ) { }

    ngOnInit(): void {
        this.getData();
        this.modalForm = this.createForm();
        this._user.me().subscribe((value) => {
            this.current_user.next(value.data);
        });
    }

    getData() {
        let params = { skip: this.currentPage * this.pageSize, take: this.pageSize, ...this.sort };
        this._consultings.getList(params).subscribe(response => {
            this.data.next(response.data);
            this.records.next(response.total);
        });

        this._consultings.getConsultantTypes().subscribe(response => {
            this.typeList = response.data.map(el => el.type);
        });
    }

    handlePage(e: any) {
        this.currentPage = e.pageIndex;
        this.pageSize = e.pageSize;
        this.getData();
        return e;
    }

    onSave() {
        if (this.isVisibleCreate) this.onSaveModalCreate();
        else if (this.isVisibleUpdate) this.onSaveModalUpdate();
    }

    onSaveModalCreate() {
        let form = this.formatRequest();
        this._consultings.createConsulting(form).subscribe({
            next: (response: any) => {
                this.getData();

                Swal.fire({
                    icon: 'success',
                    title: response.message
                });

                this.handleCancelCreate();
            }
        });
    }

    handleCancelCreate(): void {
        this.isVisibleCreate = false;
        this.isVisibleUpdate = false;
        this.consultantsList.next([]);
        this.modalForm = this.createForm();
    }

    get dateFields() {
        return this.modalForm.get('date') as FormArray;
    }

    addInputCreate() {
        this.dateFields.push(this.fb.control('', Validators.required));
        this.dateFields.markAsDirty();
    }

    removeInputCreate(index: number) {
        const dateArray = this.modalForm.get('date') as FormArray;
        dateArray.removeAt(index);
        dateArray.updateValueAndValidity();
    }


    createForm(): FormGroup {
        return new FormGroup({
            type: new FormControl('', Validators.required),
            date: this.fb.array([this.fb.control(null, Validators.required)]),
            hour_slot: new FormControl([], [Validators.required, Validators.min(1)]),
            consultant_id: new FormControl(null, Validators.required),
            consultant_email: new FormControl(null, Validators.required)
        });
    }


    exportExcel() {
        this._eventService.exportExcelEvent({ type: "set_consultations" }).subscribe(
            (res: Blob) => {
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const filename = 'Consulenze_impostate' + '.xlsx';
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
    }

    onSaveModalUpdate() {
        let form = this.formatRequest();
        form['ids'] = this.updateIds;
        this._consultings.massiveUpdate(form).subscribe({
            next: () => {
                this.getData();
                this.handleCancelCreate();
                Swal.fire({
                    icon: 'success',
                    title: '<div style="font-size:18px;">Elenco di consulenze modificate con successo</div>',
                    showConfirmButton: false,
                    timer: 3000
                });
            },
            error: (error) => {
                this.getData();
                this.handleCancelCreate();
                Swal.fire({
                    icon: 'error',
                    title: '<div style="font-size:18px;">' + error + '</div>',
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        });
    }

    updateMassive(element: any) {
        this.isVisibleUpdate = true;
        let form: any = [];
        console.log(element);
        form['consultant_id'] = element.consultant.id;
        form['consultant_email'] = element.consultant.email;
        form['type'] = element.consultant.type;
        this.getConsultantsByType(element.consultant);
        form['hour_slot'] = element.hour_slot;
        form['date'] = element.date;
        this.updateIds = element.ids;
        let dates = [];
        for (let i = 0; i < element.date.length; i++) {
            dates[i] = element.date[i];
            if (i + 1 < element.date.length)
                this.addInputCreate();
        }
        this.modalForm.controls['date'].setValue(dates);
        this.modalForm.setValue(form);
    }

    private formatRequest() {
        let form = this.modalForm.value;
        form['date'] = [];
        this.dateFields.value.forEach((element: any) => {
            form['date'].push(this.datePipe.transform(element, 'yyyy-MM-dd'));
        });
        form['hour_slot'] = this.modalForm.get('hour_slot')?.value;
        return form;
    }

    formatDates(dates: any): any {
        let res: string = '';
        dates.forEach((element: any) => {
            if (res != '')
                res += ', ';
            res += moment(element).format('DD-MM-YYYY');
        });

        return res;
    }

    getConsultantsByType(consultant: any) {
        if (typeof consultant === 'string' || consultant instanceof String) {
            // here, consultant is just the consultant type, so string
            this._consultings.getConsultantsByType({ filter: consultant }).subscribe(response => {
                if (response.data.length > 0) {
                    this.consultantsList.next(response.data);
                }
                this.modalForm.patchValue({ consultant_id: null, consultant_email: null });
            });
        } else {
            // in this case, consultant is the complete consultant object
            this._consultings.getConsultantsByType({ filter: consultant.type }).subscribe(response => {
                if (response.data.length > 0) {
                    this.consultantsList.next(response.data);
                }
                this.modalForm.patchValue({ consultant_id: consultant.id, consultant_email: consultant.email });
            });
        }
    }

    setConsultantEmail(selectedConsultantId: string) {
        if (selectedConsultantId != null) {
            const selectedConsultant = this.consultantsList.value.find(consultant => consultant.id === selectedConsultantId);
            const selectedConsultantEmail = selectedConsultant ? selectedConsultant.email : null;

            this.modalForm.patchValue({ consultant_email: selectedConsultantEmail });
        }
    }
}
