import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from '../models/formatted-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternalCourseService {
  constructor(private http: HttpClient) { }

  getExternalCourseTypes() {
    return this.http.get<any>(`${environment.api}/getExternalCourseTypes`);
  }

  getExternalCourses(params: any) {
    return this.http.get<any>(`${environment.api}/externalCourse?index=${params.pagination.index}&size=${params.pagination.size}&key=${params.sorting.key}&direction=${params.sorting.direction}`);
  }

  getSpecialCourses(params: any) {
    return this.http.get<any>(`${environment.api}/SpecialCourse/all?index=${params.pagination.index}&size=${params.pagination.size}&key=${params.sorting.key}&direction=${params.sorting.direction}`);
  }

  getExternalCourse(id: any) {
    return this.http.get<any>(`${environment.api}/externalCourse/${id}`);
  }

  createExternalCourse(params: any) {
    return this.http.post<any>(`${environment.api}/externalCourse`, params);
  }

  updateExternalCourse(params: any, id: any) {
    return this.http.put<any>(`${environment.api}/externalCourse/${id}`, params);
  }

  getSpecialCoursesInscriptions(id: string, params?: any) {
    return this.http.get<any>(`${environment.api}/SpecialCourse/${id}?index=${params?.pagination.index}&size=${params?.pagination.size}&key=${params?.sorting.key}&direction=${params?.sorting.direction}`);
  }

  createCourseByCsvUser(params: any) {
    const blob: Blob = params.file;
    const newFile = new File([blob], params.file.name);
    const formData: FormData = new FormData();
    formData.append('file', newFile, params.file.name);
    formData.append('external_course_type_id', params.external_course_type_id);
    formData.append('start_date', params.start_date);
    formData.append('end_date', params.end_date);
    return this.http.post(`${environment.api}/saveSpecialCourse`, formData);
  }

  deleteSpecialeCourse(ids: string[]) {
    return this.http.post(`${environment.api}/SpecialCourse/delete`, { ids });
  }

  updateSpecialCourse(id: string, params: any) {
    return this.http.put(`${environment.api}/SpecialCourse/${id}`, params);
  }

  uploadFile(data: any): Observable<any> {
    return this.http.post<any>(`${environment.api}/SpecialCourse/storeCertificate`, data);
  }


}
