import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TernaService } from "../../../../../shared/services/terna.service";
import { NzUploadFile } from "ng-zorro-antd/upload";
import * as moment from "moment/moment";
import { EventService } from '../../../services/event.service';

@Component({
    selector: 'app-terna',
    templateUrl: './terna.component.html',
    styleUrls: ['./terna.component.scss']
})
export class TernaComponent implements OnInit {
    columns = [
        { key: 'title', name: 'Titolo', sortable: true },
        { key: 'description', name: 'Descrizione', sortable: true },
        { key: 'created_at', name: 'Data di creazione', type: 'date', sortable: true },
        { key: 'end_date', name: 'Data di chiusura', type: 'date', sortable: true }
    ];
    data = [];
    data_count = 0;
    data_expired = 0;

    status: any;

    client = false;
    admin = false;
    table_config = {
        closed: false,
        pagination: { index: 0, size: 10 },
        sorting: { key: 'created_at', direction: 'desc' }
    }

    terna_form: FormGroup = new FormGroup({
        id: new FormControl(null),
        title: new FormControl(null, Validators.required),
        description: new FormControl(null, Validators.required),
        end_date: new FormControl(null, Validators.required),
        attachmentsFile: new FormControl('')
    })
    activeStatus: any
    isVisible: boolean = false;
    insert: boolean = false;
    confirm: boolean = false;
    partecipation: any = null;
    readOnly: boolean = false;

    fileList: any = [];
    userFileList: any = [];

    constructor(
        private _router: Router,
        public _terna: TernaService,
        public _eventService: EventService
    ) { }

    disabledDate = (current: Date): boolean => current < new Date();

    ngOnInit() {

        if (this._router.url.includes('/client')) {
            this.client = true;
            this.columns.push({ key: 'status', name: 'Stato', type: 'string', sortable: false });
        }

        if (this._router.url.includes('/admin')) {
            this.admin = true;
        }
        this.getData(this.table_config.closed);
    }

    getData(expired: boolean) {
        this.activeStatus = expired;
        console.log('activeStatus', this.activeStatus);

        if (expired != null) this.table_config.closed = expired;
        this._terna.getTerna(this.table_config).subscribe({
            next: response => {
                this.data = response.data.terne;

                if (expired == false) this.data_count = response.data.totale_terne ?? 0;

                this.data_expired = response.data.totale_terne_scadute ?? 0;
            }
        });
    }

    /**
     * Al cambio di paginazione
     * @param data
     */
    onPageChange(data: any) {
        this.table_config.pagination = data;
        this.getData(this.table_config.closed);
    }

    /**
     * Al cambio di sorting
     * @param data
     */
    onSortChange(data: any) {
        this.table_config.sorting = data;
        this.getData(this.table_config.closed);
    }

    /**
     * Viene richiamata al click sulle azioni delle datatable
     * @param data informazioni sull'azione e sulla riga cliccata
     */
    onActionClick(data: any) {
        switch (data.action) {
            case "edit":
                this.fileList = data.data.files;
                this.insert = false;
                this.showModal();
                this.terna_form.patchValue({ id: data.data.id, title: data.data.title, description: data.data.description, end_date: data.data.end_date });
                break;

            case "upload":
                this.fileList = data.data.files;
                this.userFileList = data.data.user_files;
                this.terna_form.patchValue({ id: data.data.id, title: data.data.title });
                this.showModal();
                break;

            case "confirm":
                this.confirm = true;
                this.userFileList = data.data.user_files;
                this.terna_form.patchValue({ id: data.data.id });
                this.showModal();
                break;

            case "detail":
                if (this.client) {
                    this.userFileList = data.data.user_files;
                    this.terna_form.patchValue({ id: data.data.id });
                    this.readOnly = true;
                    if (data.data.status == "Ammesso e partecipante alla terna") {
                        this.partecipation = true;
                    } else {
                        this.partecipation = false;
                    }
                    this.showModal();
                }
                else {
                    this.fileList = data.data.files;
                    this.terna_form.patchValue({ id: data.data.id, title: data.data.title, description: data.data.description, end_date: data.data.end_date });
                    this.readOnly = true;
                    this.showModal();
                }

                break;
        }
    }

    onCloseModal() {
        this.fileList = [];
        this.isVisible = false;
        this.readOnly = false;
        this.confirm = false;
        this.terna_form.reset();
    }

    onSaveModal() {
        if (this.client) {
            let data = {
                terna_id: this.terna_form.get('id')!.value,
                file: this.terna_form.get('attachmentsFile')!.value
            }

            this._terna.uploadFile(data).subscribe({
                next: response => {
                    this.successResponse(response);
                },
                error: response => {
                    this.onCloseModal();

                    Swal.fire({
                        icon: 'error',
                        title: '<div style="font-size:18px;">' + response + '</div>',
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            })
        }
        else {
            this.terna_form.patchValue({
                end_date: moment(this.terna_form.value.end_date).toISOString(true)
            }, { emitEvent: false });

            this._terna.updateOrCreateTerna(this.terna_form.value).subscribe({
                next: response => {
                    this.successResponse(response);
                }
            })
        }
    }

    showModal(): void {
        this.isVisible = true;
    }

    onRowClick($event: any) {
        this._router.navigate([`${this._router.url}/detail/${$event.id}`]);
    }

    beforeUpload = (file: NzUploadFile): boolean => {
        this.fileList = this.fileList.concat(file);
        this.handleUpload();
        return false;
    };

    handleUpload(): void {
        let data: any[] = [];
        this.fileList.forEach((file: any) => {
            this.uploadLogic(data, file)
        });
    }

    exportExcel() {
        this._eventService.exportExcelEvent({ type: "terna" }).subscribe(
            (res: Blob) => {
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const filename = 'Terna' + '.xlsx';
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
    }


    uploadLogic(data: any[], file: any) {
        const reader = new FileReader();
        const blob = new Blob([file]);
        reader.readAsDataURL(blob);
        reader.onload = () => {
            data.push({ name: file.name, base64: reader.result, id: typeof file.uid == 'number' ? file.uid : null });

            if (data.length === this.fileList.length) {
                this.terna_form.patchValue({ attachmentsFile: data });
            }
        };
    }

    handleRemove = (file: any): void => {
        let data: any[] = [];
        file.fileList.forEach((file: any) => {
            this.uploadLogic(data, file)
        });
    };

    userBeforeUpload = (file: NzUploadFile): boolean => {
        this.userFileList = this.userFileList.concat(file);
        this.userHandleUpload();
        return false;
    };

    userHandleUpload(): void {
        let data: any[] = [];
        this.userFileList.forEach((file: any) => {
            this.userUploadLogic(data, file)
        });
    }

    userUploadLogic(data: any[], file: any) {
        const reader = new FileReader();
        const blob = new Blob([file]);
        reader.readAsDataURL(blob);
        reader.onload = () => {
            data.push({ name: file.name, base64: reader.result, id: typeof file.uid == 'number' ? file.uid : null });
            this.terna_form.patchValue({ attachmentsFile: data });
        };
    }

    userHandleRemove = (file: any): void => {
        let data: any[] = [];
        file.fileList.forEach((file: any) => {
            this.userUploadLogic(data, file)
        });
    };

    confirmTerna(confirm: boolean) {
        this._terna.confirmTerna(this.terna_form.get('id')!.value, confirm).subscribe({
            next: response => {
                this.successResponse(response);
                this.partecipation = !confirm;
            },
            error(error) {
                Swal.fire({
                    icon: 'error',
                    title: "Errore durante la modifica",
                    text: error,
                });
            }
        })
    }

    successResponse(response: any) {
        Swal.fire({
            icon: 'success',
            title: '<div style="font-size:18px;">' + response.message + '</div>',
            showConfirmButton: false,
            timer: 3000,
        });

        this.onCloseModal();
        this.getData(this.table_config.closed)
    }
}
