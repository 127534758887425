import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private _service: AuthService) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkUser();
  }

  checkUser(): any {
    // FACCIO CHIAMATA AL BACKEND//
    return new Promise((resolve, reject) => {
      this._service.me().subscribe(
        (res) => {
          resolve(res.code === 200);
        },
        (error) => {
          // Gestisci gli errori qui se necessario
          reject(error);
        });
    });
  }



}
