import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UtilsServices } from 'src/app/shared/services/utils.service';
import { UserService } from "../../../../services/user.service";
import Swal from "sweetalert2";
import * as utils from "src/app/modules/application/user/components/engineers-list/utilities/utilities.component";
import {EngineerService} from "../../../../services/engineer.service";
import * as moment from "moment";

@Component({
  selector: 'app-engineer-detail',
  templateUrl: './engineer-detail.component.html',
  styleUrls: ['./engineer-detail.component.scss']
})
export class EngineerDetailComponent implements OnInit {
  id: string = this._activatedRoute.snapshot.params.id ?? null;
  tabs = ['Dati anagrafici', 'Informazioni varie'];
  form: FormGroup = this.initForm();
  specializations: any = [];
  currentYear = new Date().getFullYear();
  pec_password_type = 'password';

  genders = utils.getGenders();
  jobs = utils.getJobs();
  general_options = utils.getGeneralOptions();
  sector_options = utils.getSectorOptions();

  constructor(
    public _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _users: UserService,
    private _utils: UtilsServices,
    private _engineerService: EngineerService,
  ) { }

  ngOnInit(): void {
    this._utils.getSpecializations().subscribe({
      next: (response: any) => {
        this.specializations = response.data;
      }
    })

    if (this.id) this.getData();

    this.form.get('sospeso')?.valueChanges.subscribe({
      next: value => {
        if (value) {
          this.form.get('sospensioni')?.enable();
        }
        else {
          this.form.get('sospensioni')?.reset();
          this.form.get('sospensioni')?.disable();
        }
      }
    })

    this.form.get('sector')?.valueChanges.subscribe({
      next: () => {
        this.form.get('Aa')?.reset();
        this.form.get('Ab')?.reset();
        this.form.get('Ac')?.reset();
        this.form.get('Ba')?.reset();
        this.form.get('Bb')?.reset();
        this.form.get('Bc')?.reset();
      }
    })
  }

  subSector() {
    return this.sector_options.find((item: any) => item.value == this.form.get('sector')?.value)?.sub_sectors;
  }

  private getData() {
    this._users.getUserDetail(this.id).subscribe({
      next: result => {
        this.form.patchValue(result.data, { emitEvent: false });
        this.form.patchValue({
          nuovo_ordinamento: result.data.nuovo_ordinamento ? true : false,
          exam_law: result.data.exam_law ? true : false,
          sospeso: result.data.sospeso ? true : false,
          elenco_494: result.data.elenco_494 ? true : false,
          dichiarazione: result.data.dichiarazione ? true : false
        })

        window.scroll({ top: 0 });
      }
    })
  }

  private initForm() {
    return new FormGroup({
      registrationNumber: new FormControl(null, Validators.required),
      n_prot: new FormControl(null, Validators.required),

      //dati anagrafici
      name: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      fiscalCode: new FormControl(null, Validators.required),
      gender: new FormControl(null, Validators.required),
      birthPlace: new FormControl(null, Validators.required),
      birthProvince: new FormControl(null, Validators.required),
      birthDate: new FormControl(null, Validators.required),
      categoria: new FormControl(null, Validators.required),
      attività: new FormControl(null, Validators.required),

      //sezione albo
      deliberation: new FormControl(null, Validators.required),
      nuovo_ordinamento: new FormControl(null, Validators.required),
      sector: new FormControl(null, Validators.required),
      Aa: new FormControl(null),
      Ab: new FormControl(null),
      Ac: new FormControl(null),
      Ba: new FormControl(null),
      Bb: new FormControl(null),
      Bc: new FormControl(null),

      //laurea
      degree_place: new FormControl(null, Validators.required),
      degreed_at: new FormControl(null, Validators.required),
      specialization: new FormControl(null, Validators.required),

      //esame di stato e ordine
      exam_place_state: new FormControl(null),
      session_examination_state: new FormControl(null),
      exam_year: new FormControl(null),
      exam_law: new FormControl(null),
      previous_subscription: new FormControl(null),
      subscription: new FormControl(null),
      verbal_number: new FormControl(null),
      vecchio_ordine: new FormControl(null),
      seniority: new FormControl(null),
      cancellation: new FormControl(null),

      //residenza
      city_residence: new FormControl(null, Validators.required),
      cap_residence: new FormControl(null, Validators.required),
      street_residence: new FormControl(null, Validators.required),
      phone_residence: new FormControl(null),

      //posta
      city_posta: new FormControl(null),
      province_posta: new FormControl(null),
      cap_posta: new FormControl(null),
      street_posta: new FormControl(null),
      email: new FormControl(null, [Validators.email, Validators.required]),

      //posta bonus
      city_posta_bonus: new FormControl(null),
      prov_posta_bonus: new FormControl(null),
      cap_posta_bonus: new FormControl(null),
      street_posta_bonus: new FormControl(null),
      mail_posta_bonus: new FormControl(null, Validators.email),

      //albo
      city_studio: new FormControl(null),
      cap_studio: new FormControl(null),
      street_studio: new FormControl(null),
      tel_studio: new FormControl(null),
      pec_mail: new FormControl(null, Validators.email),
      pec_studio: new FormControl(null, Validators.email),
      seniority_studio: new FormControl(null),

      //domicilio
      city_domicilio: new FormControl(null),
      cap_domicilio: new FormControl(null),
      street_domicilio: new FormControl(null),
      tel_domicilio: new FormControl(null),

      //informazioni varie
      web_site: new FormControl(null),
      note: new FormControl(null),
      sospeso: new FormControl(null),
      sospensioni: new FormControl(null),

      //dichiarazioni requisiti professionali
      dichiarazione: new FormControl(null),
      codice_dichiarazione: new FormControl(null),
      elenco_494: new FormControl(null),
      data_delibera_494: new FormControl(null),
      due: new FormControl(null),
      cinque: new FormControl(null),
      dieci: new FormControl(null),

      //pec
      pec: new FormControl(null, Validators.email),
      pec_password: new FormControl(null),
      ingpec: new FormControl(null, Validators.email),
      pec_ritirata: new FormControl(null)
    });
  }

  goTo(path: any) {
    this._router.navigate([path]);
  }

  printPdf(id: any) {
    this._users.printPdf({ id: id }).subscribe(res => {
      window.open(res.data);
    })
  }

  printDoc(id: any) {
    this._users.printDoc({ id: id }).subscribe(res => {
      window.open(res.data);
    })
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();

      Swal.fire({
        icon: 'error',
        title: `<div style="font-size:18px;">Controlla i campi</div>`,
        showConfirmButton: false,
        timer: 2000
      });

      return;
    }

    this._users.saveEngineerData(this.id, this.form.value).subscribe({
      next: response => {
        Swal.fire({
          icon: 'success',
          title: `<div style="font-size:18px;">${response.message}</div>`,
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          if (this.id) this.getData();
          else this.goTo('app/admin/engineers_list');
        });
      },
      error: response => {
        Swal.fire({
          icon: 'error',
          title: `<div style="font-size:18px;">${response}</div>`,
          showConfirmButton: false,
          timer: 2000
        });
      }
    })
  }

  isRequiredField(field: string) {
    return this.form.get(field)?.hasValidator(Validators.required);
  }

  showPassword() {
    const togglePassword = document.querySelector('#togglePassword');
    const password = document.querySelector('#pec_password');
    const type = password?.getAttribute('type');

    if (type == 'password') {
      password?.setAttribute('type', 'text');
      togglePassword?.classList.remove('fa-eye-slash');
      togglePassword?.classList.add('fa-eye');
    }
    else {
      password?.setAttribute('type', 'password');
      togglePassword?.classList.add('fa-eye-slash');
      togglePassword?.classList.remove('fa-eye');
    }
  }

  get(control: string) {
    return this.form.get(control)?.value;
  }

  getStaticData(){
    this._engineerService.getStaticData().subscribe({
      next: response => {
        if(response.data) {
          response.data.forEach((item: any) => {
            if(item.type == 'date') item.value = moment(item.value).format('yyyy-MM-DD');
            this.form.patchValue({[item.control]: item.value});
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            title: `<div style="font-size:18px;">Non sono stati definiti dei dati statici</div>`,
            showConfirmButton: false,
            timer: 2000
          });
        }
      }
    });
  }
}
